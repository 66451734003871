export const MONTH_OPTIONS = [
  {
    'text': 'Jan',
    'id': 0
  },
  {
    'text': 'Feb',
    'id': 1
  },
  {
    'text': 'Mar',
    'id': 2
  },
  {
    'text': 'Apr',
    'id': 3
  },
  {
    'text': 'May',
    'id': 4
  },
  {
    'text': 'Jun',
    'id': 5
  },
  {
    'text': 'Jul',
    'id': 6
  },
  {
    'text': 'Aug',
    'id': 7
  },
  {
    'text': 'Sep',
    'id': 8
  },
  {
    'text': 'Oct',
    'id': 9
  },
  {
    'text': 'Nov',
    'id': 10
  },
  {
    'text': 'Dec',
    'id': 11
  }
];

export const DATE_OPTIONS = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30, 31
];

const THIRTY_MONTH_DATE_OPTIONS = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29, 30
];

const FEBRUARY_DATE_OPTIONS = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28
];

const FEBRUARY_DATE_OPTIONS_LEAP_YEAR = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23, 24, 25, 26, 27, 28, 29
];

export const OPTIONS = [
  DATE_OPTIONS,
  FEBRUARY_DATE_OPTIONS,
  DATE_OPTIONS,
  THIRTY_MONTH_DATE_OPTIONS,
  DATE_OPTIONS,
  THIRTY_MONTH_DATE_OPTIONS,
  DATE_OPTIONS,
  DATE_OPTIONS,
  THIRTY_MONTH_DATE_OPTIONS,
  DATE_OPTIONS,
  THIRTY_MONTH_DATE_OPTIONS,
  DATE_OPTIONS,
  FEBRUARY_DATE_OPTIONS_LEAP_YEAR
];
