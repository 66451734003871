export interface DeactivationState {
  isRenewalsOrderCreated: boolean;
  reasons?: DeactivateReason[];
  issues?: Issue[];
  selectedReason?: DeactivateReason;
  total?: number;
  currencyIso?: string;
  orderCode: string;
  orderStore: string;
  updated?: boolean;
  loading?: boolean;
}

export interface DeactivateReason {
  id: string;
  label: string;
  resignType: string;
}

export interface DeactivationState {
  isRenewalsOrderCreated: boolean;
  reasons?: DeactivateReason[];
  issues?: Issue[];
  selectedReason?: DeactivateReason;
  total?: number;
  currencyIso?: string;
  orderCode: string;
  orderStore: string;
  updated?: boolean;
  loading?: boolean;
}

export enum RequestedByMap {
  DeletedByDistReq = 'ABO',
  DeletedByAmwayReq = 'Company',
}

export interface Issue extends RenewalProduct {
  selected?: boolean;
}

export interface RenewalProduct {
  code: string;
  label: string;
  price: number;
  currency: string;
  feeType: FeeType;
  selected?: boolean;
}

export enum FeeType { Product, Service }
