import { ADDRESSES_GET, ADDRESSES_GET_SUCCESS, ADDRESSES_GET_FAIL } from "./addresses.actions";

export function AddressesReducer(state, action){
  switch(action.type) {
    case ADDRESSES_GET:
      return Object.assign({ loaded: false });
    case ADDRESSES_GET_SUCCESS:
      return Object.assign({ loaded: true }, { data: action.payload });
    case ADDRESSES_GET_FAIL:
      return Object.assign({ loaded: true }, { data: action.payload });
    default:
      return state;
    }
}
