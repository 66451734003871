export enum CellType {
  Option,
  Link,
  Status
}

export class Schema {
  title: string;
  property: string;
  type?: CellType;
  key?: string;
  constructor({
    title = '',
    property = '',
    type = CellType.Option,
    key = ''
  }) {
    this.title = title;
    this.property = property;
    this.type = type;
    this.key = key;
  }
}
