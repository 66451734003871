import { Injectable } from '@angular/core';
import { ErrorResponse } from '../models/amway/index';

import * as _ from 'lodash';
import { DEFAULT_ERROR } from '../configs/constants';

const COMMON_SERVICE_ERROR_PATH = 'body.errorMessage.message';
const ADDRESS_SERVICE_ERROR_PATH = 'body.AddressTaxJurisdictionValidationInformation.Errors.Error.ErrorMessageText';
const ADDRESS_VALIDATION_ERROR_PATH = 'ErrorMessage';
const RUNTIME_ERROR_PATH = 'message';
const ERROR_PATH = 'error';
const BODY_MSG = 'body';
const INTERNAL_SERVER_ERROR_STATUS = 500;
const MAX_SUCCESS_STATUS_CODE = 226;

const ERROR_MESSAGE_PATHS = [
  COMMON_SERVICE_ERROR_PATH,
  ADDRESS_SERVICE_ERROR_PATH,
  ADDRESS_VALIDATION_ERROR_PATH,
  RUNTIME_ERROR_PATH,
  ERROR_PATH,
  BODY_MSG,
];

@Injectable()
export class ErrorService {

  constructor() {
  }

  processError(errorObj): ErrorResponse {
    const errorToSHow = {
      error: this.getErrorMessage(errorObj, ERROR_MESSAGE_PATHS),
      statusCode: errorObj.status,
      requestUrl: _.get(errorObj, 'url', null),
    };
    return errorToSHow;
  }

  parseEmployeeError(errorObj, key){
    try{
      let res = []
      let errorMap = {}
      let errArr = []
      _.cloneDeepWith(errorObj, (v,k) => {k==key && res.push(v)}) && res
      res.forEach(errList => {
        errList.forEach(err => {
          let k
          if (err.fieldName == 'PHONE_NO'){k = 'phoneLocalNum'}
          if (err.fieldName == 'TAX_ID'){k = 'cid'}
          if (err.fieldName == 'FST_NM'){k = 'givenName'}
          if (err.fieldName == 'LST_NM'){k = 'familyName'}
          if (err.fieldName == 'ADDR1'){k = 'addrStreet'}
          if (err.fieldName == 'STATE'){k = 'province'}
          if (err.fieldName == 'POST_CD'){k = 'postalCd'}
          errorMap[k] = err.message
          errArr.push(errorMap)
        })
      })
    return errArr[0]
    } catch (er) {
      return DEFAULT_ERROR;
    }
  }

  getErrorMessage(errorObj, path): string {
    return !_.isEmpty(errorObj) && errorObj.error
      ? this.getMessage(errorObj.error)
      : DEFAULT_ERROR;
  }

  isError(item): boolean {
    return !!_.get(item, 'error', null);
  }

  getMessage(errorObj) {
    try {
      const erObj = JSON.parse(errorObj);
      if (erObj && erObj.errorMessage) {
        if (erObj.errorMessage.code === -9006) {
          return DEFAULT_ERROR;
        }
        return erObj.errorMessage.message || DEFAULT_ERROR;
      }
    } catch (er) {
      return DEFAULT_ERROR;
    }
  }

  hasFailStatus(statusCode: number): boolean {
    return statusCode && statusCode > MAX_SUCCESS_STATUS_CODE;
  }

  handleError(errorData, resp): void {
    const rawErrors = Array.isArray(errorData) ? errorData : [errorData];
    const errorDataItems: ErrorResponse[] = rawErrors.map((item) => this.processError(item));
    const statusCode = _.get(errorDataItems.find((item) => {
      return this.hasFailStatus(item.statusCode);
    }), 'statusCode', null);
    resp.status(statusCode || INTERNAL_SERVER_ERROR_STATUS).send(errorDataItems);
  }
}
