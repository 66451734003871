import { Action } from '@ngrx/store';

export const DEACTIVATION_GET_REASONS = '[Deactivation] get reasons';
export const DEACTIVATION_GET_REASONS_SUCCESS = '[Deactivation] get reasons success';
export const DEACTIVATION_GET_REASONS_FAIL = '[Deactivation] get reasons fail';
export const DEACTIVATION_GET_RENEWAL_ORDER = '[Deactivation] get renewal order';
export const DEACTIVATION_GET_RENEWAL_ORDER_SUCCESS = '[Deactivation] get renewal order success';
export const DEACTIVATION_GET_RENEWAL_ORDER_FAIL = '[Deactivation] get renewal order fail';
export const DEACTIVATION_SELECT_REASON = '[Deactivation] reasons select';
export const DEACTIVATION_GET_ISSUES = '[Deactivation] get issues';
export const DEACTIVATION_GET_ISSUES_SUCCESS = '[Deactivation] get issues success';
export const DEACTIVATION_GET_ISSUES_FAIL = '[Deactivation] get issues fail';
export const DEACTIVATION_SELECT_ISSUES = '[Deactivation] issues select';
export const DEACTIVATION_CLEAR = '[Deactivation] clear';
export const DEACTIVATION_POST = '[Deactivation] post';
export const DEACTIVATION_POST_SUCCESS = '[Deactivation] success';
export const DEACTIVATION_POST_FAIL = '[Deactivation] fail';

export class DeactivateGetReasons implements Action {
  readonly type = DEACTIVATION_GET_REASONS;

  constructor(public payload: any = null) { }
}

export class DeactivateGetReasonsSuccess implements Action {
  readonly type = DEACTIVATION_GET_REASONS_SUCCESS;

  constructor(public payload: any = null) { }
}

export class DeactivateGetReasonsFail implements Action {
  readonly type = DEACTIVATION_GET_REASONS_FAIL;

  constructor(public payload: any = null) { }
}

export class DeactivateSelectReason implements Action {
  readonly type = DEACTIVATION_SELECT_REASON;

  constructor(public payload: any = null) { }
}

export class DeactivateGetIssues implements Action {
  readonly type = DEACTIVATION_GET_ISSUES;

  constructor(public payload: any = null) { }
}

export class DeactivateGetIssuesSuccess implements Action {
  readonly type = DEACTIVATION_GET_ISSUES_SUCCESS;

  constructor(public payload: any = null) { }
}
export class DeactivateGetIssuesFail implements Action {
  readonly type = DEACTIVATION_GET_ISSUES_FAIL;

  constructor(public payload: any = null) { }
}

export class DeactivateGetRenewalOrder implements Action {
  readonly type = DEACTIVATION_GET_RENEWAL_ORDER;

  constructor(public payload: any) { }
}

export class DeactivateGetRenewalOrderSuccess implements Action {
  readonly type = DEACTIVATION_GET_RENEWAL_ORDER_SUCCESS;

  constructor(public payload: boolean = null) { }
}

export class DeactivateGetRenewalOrderFail implements Action {
  readonly type = DEACTIVATION_GET_RENEWAL_ORDER_FAIL;

  constructor(public payload: any = null) { }
}

export class DeactivateSelectIssues implements Action {
  readonly type = DEACTIVATION_SELECT_ISSUES;

  constructor(public payload: any = null) { }
}

export class DeactivateClear implements Action {
  readonly type = DEACTIVATION_CLEAR;

  constructor(public payload: any = null) { }
}

export class DeactivatePost implements Action {
  readonly type = DEACTIVATION_POST;

  constructor(public payload: any = null) { }
}

export class DeactivatePostSuccess implements Action {
  readonly type = DEACTIVATION_POST_SUCCESS;

  constructor(public payload: any = null) { }
}
export class DeactivatePostFail implements Action {
  readonly type = DEACTIVATION_POST_FAIL;

  constructor(public payload: any = null) { }
}

export type Actions =
  | DeactivateGetReasons
  | DeactivateGetReasonsSuccess
  | DeactivateGetReasonsFail
  | DeactivateGetRenewalOrder
  | DeactivateGetRenewalOrderSuccess
  | DeactivateGetRenewalOrderFail
  | DeactivateSelectReason
  | DeactivateGetIssues
  | DeactivateGetIssuesSuccess
  | DeactivateGetIssuesFail
  | DeactivateSelectIssues
  | DeactivateClear
  | DeactivatePost
  | DeactivatePostSuccess
  | DeactivatePostFail;
