import { Injectable, Inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { CanActivate, Router } from '@angular/router';
import { StorageService, AuthService } from '../services/index';
import { coreConfig } from '../core.config';
import { CoreConfig, MasheryToken } from '../../shared/models/index';
import { IAppState } from '../../store';
import { UserSignOut } from '../../store/user/user.actions';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    public router: Router,
    private storage: StorageService,
    private authService: AuthService,
    private store: Store<IAppState>,
    @Inject(coreConfig) public config: CoreConfig
  ) {
  }

  canActivate(): boolean {
    const token = <MasheryToken>this.storage.getItem(this.config.masheryTokenKey);

    if (token === null || token.access_token === null || !token || ! token.access_token) {
      this.store.dispatch(new UserSignOut());
      this.router.navigateByUrl(this.config.redirectionOnNullToken);
      return false;
    } else {
      this.authService.updateUserData(token);
      return true;
    }
  }

}
