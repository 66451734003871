import { Action } from '@ngrx/store';

export const TAX_CERTIFICATE_LOAD = '[TaxCertificate] load';
export const TAX_CERTIFICATE_LOAD_SUCCESS = '[TaxCertificate] load success';
export const TAX_CERTIFICATE_LOAD_FAIL = '[TaxCertificate] load fail';

export class TaxCertificateLoad implements Action {
  readonly type = TAX_CERTIFICATE_LOAD;

  constructor(public payload: any = null) { }
}

export class TaxCertificateLoadSuccess implements Action {
  readonly type = TAX_CERTIFICATE_LOAD_SUCCESS;

  constructor(public payload: any) { }
}

export class TaxCertificateLoadFail implements Action {
  readonly type = TAX_CERTIFICATE_LOAD_FAIL;

  constructor(public payload: any = null) { }
}

export type Actions =
  | TaxCertificateLoad
  | TaxCertificateLoadSuccess
  | TaxCertificateLoadFail;
