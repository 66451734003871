import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { FindAboEventsService } from '../../shared/services/find.abo.events.service';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/index';
import { GetLinkedAccounts } from '../../store/find-amway-id-events/find-amway-id-events.actions';

import { AmwayAccountList } from '../../shared/models/logins.model';

@Component({
  selector: 'app-select-account',
  templateUrl: './select-account.component.html',
  styleUrls: ['./select-account.component.scss']
})
export class SelectAccountComponent implements OnInit {
  @Input() partyId: string;
  @Output() onSubmit = new EventEmitter<AmwayAccountList>();
  @Output() onCancel = new EventEmitter<null>();
  accounts$: Observable<AmwayAccountList[]>;
  loading$: Observable<boolean>;
  loaded$: Observable<boolean>;
  accounts: any;
  loading: Boolean;
  constructor(
    private store: Store<IAppState>,
    private findAboEventsService: FindAboEventsService
  ) { }

  ngOnInit() {
    const state$ = this.store.select('searchforceConnectIdSearch').pipe(filter(Boolean),map(state => state.accounts));

    this.accounts$ = state$.pipe(map(accounts => accounts.list['amwayAccountList']));
    this.loading$ = state$.pipe(map(accounts => accounts.loading));
    this.loaded$ = state$.pipe(map(accounts => accounts.loaded));
    this.store.dispatch(new GetLinkedAccounts(this.partyId));
  }

  /**
   * @returns void
   */
  cancel(): void {
    this.onCancel.emit();
  }

  /**
   * @param  {AmwayAccountList} account
   * @returns void
   */
  selectAccount(account: AmwayAccountList): void {
    this.onSubmit.emit(account);
  }

}
