import { Action } from '@ngrx/store';

export const PARTIES_ACCOUNT_GET = '[Account] get accounts related to party';
export const PARTIES_ACCOUNT_GET_SUCCESS = '[Account] get accounts related to party success';
export const PARTIES_ACCOUNT_GET_FAIL = '[Account] get accounts related to party fail';


export class PartiesAccount implements Action {
  readonly type = PARTIES_ACCOUNT_GET;

  constructor(public payload: any = null) { }
}
export class PartiesAccountSuccess implements Action {
  readonly type = PARTIES_ACCOUNT_GET_SUCCESS;

  constructor(public payload: any = null) { }
}
export class PartiesAccountFail implements Action {
  readonly type = PARTIES_ACCOUNT_GET_FAIL;

  constructor(public payload: any = null) { }
}
export type Actions =
  | PartiesAccount
  | PartiesAccountSuccess
  | PartiesAccountFail;
