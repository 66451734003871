import { Action } from '@ngrx/store';
import { User } from '../../shared/models/index';

export const USER_SET_DATA = '[User] get data from token string';
export const USER_GET_DATA_FAIL = '[User] get new token fail';
export const USER_SIGN_OUT = '[User] sign out';
export const USER_SIGN_OUT_SUCCESS = '[User] sign out success';

export class UserSetData implements Action {
  readonly type = USER_SET_DATA;

  constructor(public payload: User) { }
}

export class UserGetDataFail implements Action {
  readonly type = USER_GET_DATA_FAIL;

  constructor(public payload = null) { }
}

export class UserSignOut implements Action {
  readonly type = USER_SIGN_OUT;

  constructor(public payload = null) { }
}

export class UserSignOutSuccess implements Action {
  readonly type = USER_SIGN_OUT_SUCCESS;

  constructor(public payload = null) { }
}



export type Actions =
  | UserSetData
  | UserSignOut
  | UserGetDataFail
  | UserSignOutSuccess;
