import { Action } from '@ngrx/store';

export const AWARDS_GET = '[Awards] get';
export const AWARDS_GET_SUCCESS = '[Awards] get success';
export const AWARDS_GET_FAIL = '[Awards] get fail';

export class AwardsGet implements Action {
  readonly type = AWARDS_GET;

  constructor(public payload: any = null) { }
}

export class AwardsGetSuccess implements Action {
  readonly type = AWARDS_GET_SUCCESS;

  constructor(public payload: any = null) { }
}

export class AwardsGetFail implements Action {
  readonly type = AWARDS_GET_FAIL;

  constructor(public payload: any = null) { }
}

export type Actions =
  | AwardsGet
  | AwardsGetSuccess
  | AwardsGetFail;
