import {
  PHOTO_LOAD,
  PHOTO_LOAD_SUCCESS,
  PHOTO_LOAD_FAIL,
  PHOTO_CHANGE,
  PHOTO_CHANGE_FAIL,
  PHOTO_CHANGE_SUCCESS,
  Actions
} from './photo.actions';

export function photoReducer(state: any, action: Actions): any {
  switch (action.type) {
    case PHOTO_LOAD:
      return {};

    case PHOTO_LOAD_SUCCESS:
      return { ...state, ...action.payload, loaded: true };

    case PHOTO_LOAD_FAIL:
      return { ...state, loaded: true };
    case PHOTO_CHANGE:
    return {...state, loader: true};
    case PHOTO_CHANGE_SUCCESS:
    return {...state, loader: false};
    case PHOTO_CHANGE_FAIL:
    return {...state, loader: false};
    default:
      return state;
  }
}
