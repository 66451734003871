import { IAppState } from './../store/index';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { History, RouteInfo } from '../shared/models/index';
import { AccountGet } from '../store/account/account.actions';
import { CountriesLoad } from '../store/countries/countries.actions';
import { SettingsSetMarketCode } from '../store/settings/settings.actions';
import { StatesLoad } from '../store/regions/regions.actions';
import { PartiesAccount } from '../store/account-parties/account-parties.actions';
import { AccountDetail } from '../shared/models/amway';
import { Observable } from 'rxjs';
import { SetRoles } from '../store/roles/roles.actions';
import { CommonService } from '../core/services';
import { AuthService } from '../core/services/auth.service';
import { AddressesGet } from '../store/addresses/addresses.actions';

@Component({
  selector: 'app-abo',
  templateUrl: './abo.component.html',
  styleUrls: ['./abo.component.scss']
})
export class ABOComponent implements OnInit, OnDestroy {
  account$: Observable<AccountDetail>;
  accountDetails;
  loading$: Observable<boolean>;
  arBalancetitle: string;
  arBalance: string;
  businessNature: string;
  placeholder: string;
  ARBalanceCollapse: boolean = true;

  private sub: Subscription;
  public searchRoute: RouteInfo;

  constructor(
    private store: Store<IAppState>,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.placeholder = 'Enter ABO/Member/Employee ID, Name, Email ID, Mobile, CID';
    const params = this.activatedRoute.snapshot.params;
    this.store.dispatch(new AccountGet(params));
    this.store.dispatch(new SettingsSetMarketCode(params.aff));
    this.store.dispatch(new CountriesLoad());
    this.store.dispatch(new StatesLoad(params));
    this.store.dispatch(new AddressesGet(params));

    this.account$ = this.store.select('account');

    this.loading$ = this.account$.pipe(map(data => !data.loaded));

    this.account$.pipe(filter(({ loaded }) => loaded)).subscribe(accountDetail => {
      this.accountDetails = accountDetail;

      switch (accountDetail.data.accountMst.accountSubTypeCd) {
        case 'Employee':
          this.arBalancetitle = 'Staff Entitlement'
          break;
        case 'Member':
          this.arBalancetitle = 'Amplus'
          break;
        case 'BusinessOwner':
          this.arBalancetitle = 'AR'
          break;
      }

      this.businessNature = CommonService.getBusinessNature(this.accountDetails.data.accountMst)
      let occTokenDetails: any = this.authService.getOccTokenDetails();
      const organizationRoles = occTokenDetails.organizationRoles.replace(/\[|\]/g, '').split(',');
      let accountSubTypeCd = accountDetail.data.accountMst.accountSubTypeCd;
      let managerStr=''
      organizationRoles.forEach(function(role: string) {
        if (role.toUpperCase() === 'AREAMANAGER') {
          managerStr += 'AREAMANAGER-'
          return
        }
        if (role.toUpperCase() === 'SHOPMANAGER') {
          managerStr += 'SHOPMANAGER-'
          return
        }
      })
      this.store.dispatch(new SetRoles({ roles: [`${managerStr}BOSS-${accountSubTypeCd.toUpperCase()}`]}));

    })

    const expectedList = ['find-abo'];
    this.sub = this.store.select('history').subscribe(({ breadcrumbs }: History) => {
      this.searchRoute = breadcrumbs.find((item) => (
        expectedList.some(expected => expected === item.path)));
    });
  }

  calculatedAR(balance) {
    this.arBalance = balance;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
