import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpService } from '../../core/services/index';
import { AccountSearchParam } from '../models/index';
import { ApiPathConfigService } from '../configs/api';
import { salesAff, aboNum, partyId, taxPayerInformationAPI } from './../configs/constants';

@Injectable()
export class TaxPayerService {

  constructor(
    private httpService: HttpService,
    private apiPathService: ApiPathConfigService
  ) {
  }

  getTaxePayer(req: AccountSearchParam) {
    const api = this.apiPathService.getApiPath(taxPayerInformationAPI);
    api.path = api.path.replace(salesAff, req.aff).replace(aboNum, req.abo)
    return this.httpService.callServerGET(api, null)
      .pipe(map(response => {
        return response
      }),catchError((error) => {
        return error;
      }));
  }

}
