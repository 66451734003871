import { Effect, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { VoucherService } from '../../shared/services/voucher.service';
import {
    ABO_VOUCHER_DETAIL_LOAD,
    AboVouchersDetailLoad,
    AboVouchersDetailLoadFail,
    AboVouchersDetailLoadSuccess
} from './voucher.actions';
@Injectable()
export class AboVoucherEffects {
    @Effect()
    loadVoucherDetails$ = this.actions$
      .ofType(ABO_VOUCHER_DETAIL_LOAD).pipe(
      switchMap(({ payload }: AboVouchersDetailLoad) => {
        return this.voucherService.getVouchersDetailAndBalance(payload)
        .pipe(catchError(error => of(new AboVouchersDetailLoadFail()))
        ,map((response) => {
          return new AboVouchersDetailLoadSuccess(response);
        }));
      }));
  constructor(private actions$: Actions, private voucherService: VoucherService) {
  }
}
