import { Observable, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';

import { ABO_SEARCH, AboSearch, AboSearchSuccess, AboSearchFail, ABO_SEARCH_FAIL } from './abo-search.actions';
import { ABOService } from '../../shared/services/index';
import { ABOSearchQuery } from '../../shared/models/index';

@Injectable()
export class AboSearchEffects {

  @Effect()
  aboSearch$ = this.actions$
    .ofType(ABO_SEARCH).pipe(
    switchMap((action: AboSearch) => {
      return this.aboService.getABOSearch(action.payload)
        .pipe(catchError(error => of(new AboSearchFail(error)))
        ,map((response) => {
          return new AboSearchSuccess(response);
          // if (response.type == ABO_SEARCH_FAIL) {
          //   return new AboSearchFail(response);
          // } else {
          //   return new AboSearchSuccess(response);
          // }
        }));
    }));

  constructor(
    private actions$: Actions,
    private aboService: ABOService
  ) {
  }
}
