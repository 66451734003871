import { ABOSearchResult } from './abo-search-result.model';

export class ABOSearchResponse {
  statusCode: number;
  resultsCount: number;
  page: number;
  offset: number;
  data: Array<ABOSearchResult> = [];
  constructor({
    statusCd = 0,
    resultsCount = 0,
    page = 0,
    offset = 0,
    searchResults = [{}],
    searchByEmail = false
  }) {
    this.statusCode = statusCd;
    this.resultsCount = resultsCount;
    this.page = page;
    this.offset = offset;
    if (!searchByEmail) {
      searchResults.forEach((element) => {
        element['searchByEmail'] = searchByEmail;
        this.data.push(new ABOSearchResult(element));
      });
      this.reduceList(page, offset);
      this.resultsCount = this.data.length;
    }
  }

  reduceList(currentPage, offset) {
    const startItem = (currentPage * offset) - offset;
    const endItem = startItem + offset;
    this.data.slice(startItem, endItem);
  }
}
