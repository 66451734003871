import { Type } from '@angular/core';
import { Action } from '@ngrx/store';
import { ContactPointTypeCdEnum } from '../enums/contact-point-type.enum';
import {
  RestrictionBlock,
  RestrictionBlockConfigService,
  RestrictionBlockData
} from './restriction-blocks.model';
import { AboDetailActionType } from '../enums/abo-detail-action.enum';
import { AbstractFieldComponent } from './field.model';
import { Constructable } from '../common.model';
import { AboDetailNature } from '../enums/abo-detail-nature.enum';
import { AboDetailValueType } from '../enums/abo-detail-value.enum';
import { ABOAddress } from './address.model';
import { PartyList, PersonalId } from './party-list.model';
import * as _ from 'lodash';
import { ContactPointPurposeCdEnum } from '../enums/contact-point-purpose.enum';
import { getPhoneNumber, getFullName, getActiveEmailInfo, getFullNameLatinName } from '../../helpers/common.helper';
import { ABOBusinessInfo } from '../abo-business-details.model';
import { AddressList } from '../party-basic.models';
import { Account } from './account.model';
import * as moment from 'moment';
import {
  defaultLanguageCode,
  NON_CITIZEN_ID_TYPE,
  defaultAdvancedYearsValue,
  TAX_TYPE_CODE
} from '../../configs/constants';
export type BusinessNatureName = 'BusinessOwner' | 'Customer';
export const BusinessNatureName = {
  ABO: 'BusinessOwner' as BusinessNatureName,
  Customer: 'Customer' as BusinessNatureName,
  Member: 'Member' as BusinessNatureName,
  Employee: 'Employee' as BusinessNatureName  
};

export interface Phone {
  phoneCntryCd: string;
  phoneAreaCd: string;
  phoneLocalNum: string;
  usageList: Array<UsageListItem>;
  cntryCd: string;
  contactPointTypeCd: string;
  contactId: string;
  engagedPhone?: string;
  dayFlag?: boolean;
  eveningFlag?: boolean;
  partyId: string;
}

export interface UsageListItem {
  contactPointPurposeCd: string;
  primaryFlag: boolean;
}

export interface PersonalDetails {
  data?: Account;
  loaded?: boolean;
  saved?: boolean;
  action?: string;
}

export class ABOBasicInfo {
  familyName: string;
  givenName: string;
  l_familyName: string;
  l_givenName: string;
  l_name: string;
  name: string;
  title: string;
  czshpName: string;
  iCrdNum: string;
  languageCd?: string;
  identityDetail: PersonalId;
  czshpCntryCd: any;
  email: string;
  birthdate: string;
  localebirthdate: string;
  genderCd: string;
  mobilePhone: string;
  alternatePhone: string;
  ecommContactId?: string;
  mobilePhoneInfo: Phone;
  alternatePhoneInfo: Phone;
  emailInfo: any;
  isCitizen: boolean;
  partyTypeCd: string;
  maritalStatusCd: string;
  currentParty: PartyList;
  isPrimaryUser: boolean;
  cntryName: string;
  czshpCntryName: string;
  segmentCd?: string;
  cntryCd?: string;
  preferredLanguage?: string;
  markAsCoapplicant: boolean;
  cid: string;
  taxAddressData?: any;
  passportNumber?: any;
  languagesKnown?: Array<{}>;
  bioDescription?: string;
  c_givenName: string;
  c_familyName: string;
  c_l_givenName: string;
  c_l_familyName: string;
  constructor(party: PartyList = null) {
    this.currentParty = party;
    this.isPrimaryUser = !!party.isPrimary;
    if (party && !_.isEmpty(party)) {
      if (party.partyMst && !_.isEmpty(party.partyMst)) {
        const prtyMst = party.partyMst;
        this.genderCd = prtyMst.genderCd || '';
        this.birthdate = prtyMst.birthdate || '';
        this.localebirthdate = prtyMst.birthdate ? moment(prtyMst.birthdate).add(defaultAdvancedYearsValue, 'year').format()  : ''
        this.czshpCntryCd = prtyMst.czshpCntryCd;
        this.maritalStatusCd = prtyMst.maritalStatusCd || '';
        this.languageCd = prtyMst.languageCd || defaultLanguageCode;
        this.partyTypeCd = prtyMst.partyTypeCd || 'Person';
      }
      if (party.isPrimary) {
        this.isCitizen = true;
      } else {
        this.isCitizen =
          party.personalIdList &&
          party.personalIdList.length > 0 &&
          party.personalIdList[0].personalIdTypeCd !== NON_CITIZEN_ID_TYPE;
      }

      if (party.taxList && party.taxList.length > 0) {
        let taxPayer:any = _.filter(party.taxList, function(o){ return o.taxTypeCd == TAX_TYPE_CODE })
        this.iCrdNum = (taxPayer.length > 0) ? taxPayer[0].taxId : '';
      }
      
      if (party.personalIdList && party.personalIdList.length > 0) {
        const prsnlInfo:any = _.filter(party.personalIdList, function(o){ return o.personalIdTypeCd == 'PP' })[0]
        if (prsnlInfo){
          this.identityDetail = prsnlInfo;
        } else {
          this.identityDetail = {} as PersonalId;
        }
      } else {
        this.identityDetail = {} as PersonalId;
      }
      if (party.nameList && party.nameList.length > 0) {
        this.name = getFullName(party.nameList);
        this.l_name = getFullNameLatinName(party.nameList);
        this.givenName = party.nameList[0].localeName.givenName;
        this.familyName = party.nameList[0].localeName.familyName;
        this.l_familyName = party.nameList[0].latinName.familyName;
        this.l_givenName = party.nameList[0].latinName.givenName;
        this.title = party.nameList[0].localeName.title;
      }
      if (party.ecommList && party.ecommList.length > 0) {
        const emailInfo = getActiveEmailInfo(party.ecommList);
        if (emailInfo.length > 0) {
          this.emailInfo = emailInfo[0];
          this.email = emailInfo[0].ecommAddr;
          this.ecommContactId = emailInfo[0].contactId;
        }
      }

      if (party.phoneList && party.phoneList.length > 0) {
        const mobileInfo = party.phoneList.filter(phone => {
          return (
            phone.contactPointTypeCd ===
            ContactPointTypeCdEnum.Mobile.toString() &&
            phone.usageList[0].primaryFlag
          );
        });
        const phoneInfo = party.phoneList.filter(phone => {
          return (
            phone.contactPointTypeCd ===
            ContactPointTypeCdEnum.AlternatePhone.toString()
          );
        });
        
        if (phoneInfo.length > 0) {
          this.alternatePhoneInfo = phoneInfo[0];
          this.alternatePhone = getPhoneNumber(
            phoneInfo[0].phoneCntryCd,
            phoneInfo[0].phoneLocalNum
          );
        } else
        {
          this.alternatePhoneInfo = { phoneLocalNum: '' } as Phone;
        }

        if (mobileInfo.length > 0) {
          this.mobilePhoneInfo = mobileInfo[0];
          this.mobilePhone = getPhoneNumber(
            mobileInfo[0].phoneCntryCd,
            mobileInfo[0].phoneLocalNum
          );
        } else
        {
          this.mobilePhoneInfo = {phoneLocalNum: ''} as Phone;
        }
      } else {
        this.mobilePhoneInfo = {phoneLocalNum: ''} as Phone;
        this.alternatePhoneInfo = { phoneLocalNum: '' } as Phone;
      }
    }
  }
}

export interface ABOBasicInfoUpdatePayload {
  data: ABOBasicInfo;
  initialData: ABOBasicInfo;
}

export interface ValidationError {
  [key: string]: string;
}

export interface BusinessDetailsData {
  balance: string;
  sponsor: string;
  international?: string;
  diamond: string;
  emerald: string;
  platinum: string;
  country: string;
  legalName: string;
  nationalAccounts: string;
  spa: string;
  blockPrivilegeList: RestrictionBlock[];
}

export interface BusinessDetails {
  data?: ABOBusinessInfo;
  loaded?: boolean;
  action?: string;
  saving?: Saving;
}

export interface Saving {
  [key: string]: boolean;
}

export interface AboDetailAction {
  type: AboDetailActionType;
  action: string | Type<AbstractFieldComponent> | Constructable<Action>;
}

export interface AboDetail {
  name: string;
  keys: string[];
  nature: AboDetailNature;
  action?: AboDetailAction;
  value?: string[];
  valueType?: AboDetailValueType;
  isButton?: boolean;
  isEditDisabled?: boolean;
  editLinkTitle?: string;
  multiline?: boolean;
  isDisabledForInactive?: boolean;
  isDisabledForActive?: boolean;
}

export interface AddressEventData {
  updatedData: AddressList;
  initialData: AddressList;
  forceSave: boolean;
}

export interface AddressDeletionEventData {
  addressBookId: string;
}
export {
  TaxJurisdictionOptionDetail as TaxJurisdiction
} from './tax-jurisdiction-option-detail.model';

interface RestrictionBlockStoreData {
  all: RestrictionBlockConfigService[];
  active: RestrictionBlockData[];
}

export interface RestrictionBlocks {
  data?: RestrictionBlockStoreData;
  loadedActive: boolean;
  loadedAll: boolean;
}

export interface RestrictionsBlocksStore {
  [key: string]: RestrictionBlocks;
}

export { RestrictionBlockData };
