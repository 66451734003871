import { Injectable } from '@angular/core';
import { PartyList, Account } from '../models/amway';
import { ApiPathConfigService } from '../configs/api';
import { HttpParams } from '@angular/common/http';
import { salesAff, aboNum, getAmwayAccountByAboNumAPI } from '../configs/constants';
import { HttpService } from '../../core/services/http.service';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  getCurrentParty,
  getPrimaryParty,
  getCoApplicantParty,
  getSuccessorParties
} from '../helpers/account.helper';

const DEFAULT_DETAIL_CODE = 'FullDetail';
const PERSONAL_NAME_TYPE_CD = 'Legal';
const WRONG_PARTY_ID_ERROR = {
  statusCode: 404,
  error: 'party Id not found'
};

@Injectable()
export class AccountService {
  constructor(private httpService: HttpService,
    private apiPathService: ApiPathConfigService) { }
  /**
   * Returns account data
   * @param {any} [aff=req.query.aff]
   * @param {any} [abo=req.query.abo]
   * @param {any} [detailLevelCd=DEFAULT_DETAIL_CODE]
   * @returns
   */
  getAccountData(aff, abo, partyId, detailLevelCd = DEFAULT_DETAIL_CODE) {
    const api = this.apiPathService.getApiPath(getAmwayAccountByAboNumAPI);
    api.path = api.path
      .replace(salesAff, aff + '')
      .replace(aboNum, abo);

    let urlParams: HttpParams = new HttpParams();
    urlParams = urlParams.set(
      'detailLevelCd',
      detailLevelCd || DEFAULT_DETAIL_CODE
    );

    return this.httpService
      .callServerGET(api, urlParams, null)
      .pipe(map(body => body.account)
      ,map(account => {
        const partyList = this.reducePartiesByNameTypeCD(account.partyList);
        const currentParty = getCurrentParty(partyList, partyId);
        const primaryParty = getPrimaryParty(partyList);
        const coApplicantParty = getCoApplicantParty(partyList);
        const successorParties = getSuccessorParties(partyList);
        return {
          ...account,
          partyList,
          currentParty,
          primaryParty,
          coApplicantParty,
          successorParties
        };
      }));
  }

  /**
   * According to data mapping document we need to return parties with appropriate code
   * Filtering parties by Names with PERSONAL_NAME_TYPE_CD == "Legal"
   * @param parties <Party[]>
   * @return parties <Party[]>
   *
   */
  private reducePartiesByNameTypeCD(parties: PartyList[]) {
    return parties.reduce((memo, party) => {
      // We need to filter all the names in NameList only to type Legal
      party.nameList = party.nameList.filter(
        item => item.personNameTypeCd === PERSONAL_NAME_TYPE_CD
      );
      return party.nameList.length ? [...memo, party] : memo;
    }, []);
  }

  getAccountDetail(
    { abo, aff, partyId },
    detailLevelCd = DEFAULT_DETAIL_CODE
  ): Observable<Account> {
    return this.getAccountData(aff, abo, partyId, detailLevelCd).pipe(switchMap(
      (account: Account) => {
        return account.partyList.some(
          party => party.partyMst.partyId === partyId
        )
          ? of(account)
          : Observable.throw(WRONG_PARTY_ID_ERROR);
      }
    ));
  }
}
