import { Directive, Input, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';

const MASK_DELIMETER = '-';
const DEFAULT_MASK = [9];

@Directive({
  selector: '[appMask]'
})
export class MaskDirective {

  mask: number[];
  @Output() ngModelChange = new EventEmitter();

  constructor(
    private elementRef: ElementRef
  ) { }

  @Input('appMask') set appMask(mask: string) {
    this.mask = mask ? mask.split(MASK_DELIMETER).map(s => s.length) : DEFAULT_MASK;
    this.onChange();
  }

  @HostListener('input') onChange() {
    this.elementRef.nativeElement.value = this.processInputWithMask(this.elementRef.nativeElement.value);
    if (this.elementRef.nativeElement.value) {
      this.ngModelChange.emit(this.elementRef.nativeElement.value);
    }
  }

  private processInputWithMask(input: string): string {
    input = input.replace(/\D/g, '');
    return this.mask.map(segment => {
      const result = input.slice(0, segment);
      input = input.slice(segment);
      return result;
    }).filter(segment => segment).join(MASK_DELIMETER);
  }

}
