import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { ApiPathConfigService } from '../configs/api';
import { emailsActivationAPI, enrollmentId, enrollmentSendEmail } from '../configs/constants';
import { HttpService } from '../../core/services/http.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AboEmailActivationService {
  client_app: String;
  redirect_url: String;
  constructor(
    private httpService: HttpService,
    private apiPathService: ApiPathConfigService) { }

    emailActivation(emailId) {
      const api = this.apiPathService.getApiPath(emailsActivationAPI);
      // let urlParams: HttpParams = new HttpParams();
      // // urlParams = urlParams.set('ecommAddr', 'cslrfk@zwjneso.pbd.qe');
      // urlParams = urlParams.set('partyid', partyIDValue);
      const bodyParams = this.emailActivationBodyParams(emailId);
      return this.httpService.callServerPOST(api, null, bodyParams).pipe(map(
        response => {
          return response;
          // const locations =
          //   response && response.length
          //     ? response.map(loc => new AreaLocation(loc))
          //     : ([] as AreaLocation[]);
          // observer.next({ loading: false, data: locations });
          // observer.complete();
        },
        error => {
          console.log(error);
          // this.LogError(error);
          // observer.next({ loading: false, data: [] as AreaLocation[] });
          // observer.complete();
        }
      ));
    }

  enrollSendEmail(Id) {
    const api = this.apiPathService.getApiPath(enrollmentSendEmail);
    api.path = api.path.replace(enrollmentId, Id);
    return this.httpService.callServerPOST(api, null).pipe(map(
      response => {
        return response;
      },
      error => {
        console.log(error);
        // this.LogError(error);
      }
    ));
  }

  
  emailActivationBodyParams(emailId) {
    
    this.client_app = environment.amCommClientApp;
    this.redirect_url = environment.amCommRedirectUrl;

    return {
      "userId": emailId,
      "application": {
        "clientApp": this.client_app,
        "redirect": this.redirect_url
      }
    }
  }

}
