import { Action } from '@ngrx/store'

export const TAX_PAYER_LOAD = '[TAX_PAYER] Load'
export const TAX_PAYER_LOAD_SUCCESS = '[TAX_PAYER] Load Success'
export const TAX_PAYER_LOAD_FAIL = '[TAX_PAYER] Load Fail'

export class TaxPayerLoad implements Action {
  readonly type = TAX_PAYER_LOAD;
  constructor(public payload: any = null) { }
}

export class TaxPayerLoadSuccess implements Action {
  readonly type = TAX_PAYER_LOAD_SUCCESS;
  constructor(public payload: any = null) { }
}

export class TaxPayerLoadFail implements Action {
  readonly type = TAX_PAYER_LOAD_FAIL;
  constructor(public payload: any = null) { }
}


export type Actions = 
    | TaxPayerLoad
    | TaxPayerLoadSuccess
    | TaxPayerLoadFail 
