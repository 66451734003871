import { Actions, AWARDS_GET_SUCCESS } from './awards.actions';
import { AwardsResponse } from '../../shared/models/index';

export function awardsReducer(state: AwardsResponse, { type, payload }: Actions): AwardsResponse {
  switch (type) {
    case AWARDS_GET_SUCCESS:
      return payload;

    default:
      return state;
  }
}
