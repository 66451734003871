import { defaultImage } from './assets';
import { DropdownOption } from '../models/dropdown.model';

// tslint:disable-next-line:max-line-length
export const DEFAULT_ERROR = `We're sorry, the site is unable to respond to your request. Please try to refresh the page. If you continue to see this message, please contact Customer Service`;
export const redirectUrl = '{redirectUrl}';
export const clientId = '{clientId}';
export const salesAff = '{salesAff}';
export const userName = '{userName}';
export const aboNum = '{aboNum}';
export const partyId = '{partyId}';
export const enrollmentId = '{enrollmentId}';
export const contractId = '{contractId}'
export const blockPrivTypeId = '{blockPrivTypeId}';
export const ecommContactId = '{ecommContactId}';
export const taxType = '{taxType}';
export const contactId = '{contactId}';
export const voucherId = '{voucherId}';
export const bankAcctId = '{bankAcctId}';
export const personalIdTypeCd = '{personalIdTypeCd}';
export const cntryCd = '{cntryCd}';
export const addressBookId = '{addressBookId}';
export const defaultProfileImage = defaultImage;
export const defaultDateFormat = 'MMMM d, yyyy';
export const defaultCalanderFormat = 'MM/DD/YYYY';
export const defaultAdvancedYearsValue = '543';
export const defaultCountryCode = 'AU';
export const defaultCountryName = 'Australia';
export const defaultLanguageCode = 'en';
export const defaultCurrencyCode = 'AUD';
export const TAX_COUNTRY_CODE = 'AU';
//export const TAX_TYPE_CODE = 'NPWP';
export const TAX_TYPE_CODE = 'CID';
export const DEFAULT_COUNTRY_SALES_AFF_CODE = '30';
export const TAX_CERT_CODE = 'SPP';
export const CITIZEN_ID_TYPE = 'KTP';
export const NON_CITIZEN_ID_TYPE = 'Passport';
export const translate_lang_key = 'BOSS_TRANSLATION_LANG';
export const LglEntityTypeCompany = 'Company Limited';
export const PartyTypeCd = 'Person';
export const DEFAULT_COUNTRY_OPTION: DropdownOption = new DropdownOption({
  id: defaultCountryCode,
  text: 'AUSTRALIA',
  code: Number(DEFAULT_COUNTRY_SALES_AFF_CODE)
});

export const DEFAULT_MOBILE_OPTION: DropdownOption = new DropdownOption({
  id: defaultCountryCode,
  text: '61'
});

export const DEFAULT_PRIMARY_CITIZENSHIP_DATA: DropdownOption = new DropdownOption(
  {
    id: defaultCountryCode,
    text: defaultCountryName
  }
);

export const DEFAULT_NONPRIMARY_CITIZENSHIP_DATA: Array<DropdownOption> = [
  {
    id: 'NR',
    text: 'Non Resident'
  },
  {
    id: 'C',
    text: 'Citizen'
  }
];
export const AR_BALANCE_TYPE_CODE = 'Monetary';
export const AMPLUS_BALANCE_TYPE_CODE = 'MemberPoint';
export const CLIENT_NAME = 'CUSTOMER';
export const ABO_NAME = 'IBO';
export const MEMBER_NAME = 'Member';
export const EMPLOYEE_NAME = 'Employee';
export const Active_Status = 'Active';
export const contractIdNo = '100005';
export const aboSubscriptions = ['Amway Updates'];
export const aboSubscriptionsCountry = ['Leads ABO', 'Amway Magazine', 'Campaign Updates', 'Amway Market Research', 'Amway Weekly News', 'My IBO Business Info', 'Amway Updates', 'IBOAI Newsletter'];
export const maxAllowedFileSizeInBytes = 2000000;
export const allowedUploadImageExt = ['.jpg', '.jpeg', '.png'];
export const allowedUploadFileExt = ['.pdf'];
export const masheryTokenSuccessMessage = 'MasheryTokenSuccess';

/** API Constants */
export const getMasheryTokenAPI = 'getMasheryTokenAPI';
export const getMasheryAuthCodeAPI = 'getMasheryAuthCodeAPI';
export const getOccTokenAPI = 'getOccTokenAPI';
export const getOktaJwtAuthCodeAPI = 'getOktaJwtAuthCodeAPI';
export const stateRandom = '{stateRandom}';
export const nonceRandom = '{nonceRandom}';
export const oktaJwtClientId = '{oktaJwtClientId}';
export const oktaJwtRedirectUrl = '{oktaJwtRedirectUrl}';
export const emailsActivationAPI = 'emailsActivationAPI';
export const enrollmentSendEmail = 'enrollmentSendEmail';
export const updateEnrolledAmwayIdAPI = 'updateEnrolledAmwayIdAPI';
export const checkAmwayIdByPartyIdAPI = 'checkAmwayIdByPartyIdAPI';
export const createAmwayIdAPI = 'createAmwayIdAPI';
export const editAmwayIdAPI = 'editAmwayIdAPI';
export const getAmwayIdAPI = 'getAmwayIdAPI';
export const getEnrollRecordByPartyId = 'getEnrollRecordByPartyId';
export const aboEventsAPI = 'aboEventsAPI';
export const accountByPartyIdAPI = 'accountByPartyIdAPI';
export const getBanksandBranchesAPI = 'getBanksandBranchesAPI';
export const updateBankAccountAPI = 'updateBankAccountAPI';
export const createBankAccountAPI = 'createBankAccountAPI';
export const accountContractsAPI = 'accountContractsAPI';
export const restrictionBlocksAPI = 'restrictionBlocksAPI';
export const updateRestrictionBlockAPI = 'updateRestrictionBlockAPI';
export const getAmwayAccountByAboNumAPI = 'getAmwayAccountByAboNumAPI';
export const updateShippingAddressAPI = 'updateShippingAddressAPI';
export const createPersonalIdAPI = 'createPersonalIdAPI';
export const updatePersonalIdAPI = 'updatePersonalIdAPI';
export const updateAccountAddressAPI = 'updateAccountAddressAPI';
export const createShippingAddressAPI = 'createShippingAddressAPI';
export const areaLocatorAPI = 'areaLocatorAPI';
export const provinceAPI = 'provinceAPI';
export const provinceStateCdAPI = 'provinceStateCdAPI';
export const districtAPI = 'districtAPI';
export const subdistrictAPI = 'subdistrictAPI';
export const postalCodeAPI = 'postalCodeAPI';
export const getShippingAddressAPI = 'getShippingAddressAPI';
export const getABOContractsAPI = 'getABOContractsAPI';
export const getContractInfoAPI = 'getContractInfoAPI';
export const updateAllRestrictionBlocksAPI = 'updateAllRestrictionBlocksAPI';
export const getAffiliatesListAPI = 'getAffiliatesListAPI';
export const getCountryInfoAPI = 'getCountryInfoAPI';
export const getRegionsListAPI = 'getRegionsListAPI';
export const getStatesListAPI = 'getStatesListAPI';
export const getCountriesListAPI = 'getCountriesListAPI';
export const updateARBalanceAPI = 'updateARBalanceAPI';
export const deactivateAccountAPI = 'deactivateAccountAPI';
export const getLanguageInfoAPI = 'getLanguageInfoAPI';
export const deletePartyLinkAPI = 'deletePartyLinkAPI';
export const getABOTaxAPI = 'getABOTaxAPI';
export const taxPayerInformationAPI = 'taxPayerInformationAPI';
export const voucherTransactionAPI = 'voucherTransactionAPI';
export const accountVouchersAPI = 'accountVouchersAPI';
export const uplineInfoAPI = 'uplineInfoAPI';
export const updateSubscriptionAPI = 'updateSubscriptionAPI';
export const wareHoueseListAPI = 'wareHoueseListAPI';
export const getPlatinumSponsorAPI = 'getPlatinumSponsorAPI';
export const getVolumeInfoAPI = 'getVolumeInfoAPI';
export const getPeriodInfoAPI = 'getPeriodInfoAPI';
export const getTaxCertificateAPI = 'getTaxCertificateAPI';
export const uploadTaxCertificateAPI = 'uploadTaxCertificateAPI';
export const retrieveImageByUrlAPI = 'retrieveImageByUrlAPI';
export const updateProfileImageAPI = 'updateProfileImageAPI';
export const getABOImageAPI = 'getABOImageAPI';
export const reactivatePartyAPI = 'reactivatePartyAPI';
export const deleteCoapplicantAPI = 'deleteCoapplicantAPI';
export const accountsAssociatedWithPartyAPI = 'accountsAssociatedWithPartyAPI';
export const addPartyLinkAPI = 'addPartyLinkAPI';
export const updateAccountTaxAPI = 'updateAccountTaxAPI';
export const getSalutaionsAPI = 'getSalutaionsAPI';
export const registerPartialUserAPI = 'registerPartialUserAPI';
export const registerFullAccountAPI = 'registerFullAccountAPI';
export const updateAccountNameMultiLangAPI = 'updateAccountNameMultiLangAPI';
export const updateAccountPartiesAPI = 'updateAccountPartiesAPI';
export const updateAccountEmailAPI = 'updateAccountEmailAPI';
export const createAccountPhoneAPI = 'createAccountPhoneAPI';
export const updateAccountPhoneAPI = 'updateAccountPhoneAPI';
export const createAccountEmailAPI = 'createAccountEmailAPI';
export const createAccountTaxAPI = 'createAccountTaxAPI';
export const updateDocumentAPI = 'updateDocumentAPI';
export const getABOSearchListAPI = 'getABOSearchListAPI';
export const getAwardInfoAPI = 'getAwardInfoAPI';
export const listAccountBalanceAPI = 'listAccountBalanceAPI';
export const accountBalanceAPI = 'accountBalanceAPI';
export const renewalPartyAPI= 'renewalPartyAPI';
export const renewalDetailsAPI = 'renewalDetailsAPI';
export const accountContractsUpdateAPI = 'accountContractsUpdateAPI';




