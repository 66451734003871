import { ApplicationConfig } from './app.config';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { LoginModule } from './login/login.module';
import { SharedModule } from './shared/shared.module';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './store/index';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './store/user/user.effects';
import { HomeModule } from './home/home.module';
import { CountryEffects } from './store/countries/countries.effects';
import { AboSearchEffects } from './store/abo-search/abo-search.effects';
import { FindABOModule } from './abo/find-abo/find-abo.module';
import { ABOModule } from './abo/abo.module';
import { AwardsEffects } from './store/awards/awards.effects';
import { AccountEffects } from './store/account/account.effects';
import { PhotoEffects } from './store/photo/photo.effects';
import { AboPersonalDetailsEffects } from './store/abo-personal-details/abo-personal-details.effects';
import { AboBusinessDetailsEffects } from './store/abo-business-details/abo-business-details.effects';
import { SearchforceConnectIdSearchEffects } from './store/find-amway-id-events/find-amway-id-events.effects';
import { ConfigEffects } from './store/config/config.effects';
import { RegionsEffect } from './store/regions/regions.effects';
import { AboRestrictionBlocksEffects } from './store/abo-restriction-blocks/abo-restriction-blocks.effects';
import { DeactivationEffects } from './store/deactivation/deactivation.effects';
import { AboContractsEffects } from './store/abo-contracts/abo-contracts.effects';
import { AboVoucherEffects } from './store/voucher/voucher.effects';
import { TaxCertificateEffects } from './store/tax-certificate/tax-certificate.effects';
import { AboBankEffects } from './store/bank/bank.effects';
import { TaxPayersEffects } from './store/tax-payer/tax-payer.effects';
import { AccountPartiesEffects } from './store/account-parties/account.-parties.effects';
import { AddressesEffects } from './store/addresses/addresses.effects';
import { CountryEnvironmentService } from './shared/services/country-environment.service';
import { CommonService } from './shared/services';
import { SearchforceFindConnectIdEventsModule } from './find-amway-id-events/find-connect-id-events.module';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    SearchforceFindConnectIdEventsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([
      UserEffects,
      CountryEffects,
      AboSearchEffects,
      AccountEffects,
      AwardsEffects,
      PhotoEffects,
      AboPersonalDetailsEffects,
      AboBusinessDetailsEffects,
      AboRestrictionBlocksEffects,
      ConfigEffects,
      RegionsEffect,
      DeactivationEffects,
      AboContractsEffects,
      AboVoucherEffects,
      TaxCertificateEffects,
      AboBankEffects,
      TaxPayersEffects,
      AccountPartiesEffects,
      AddressesEffects,
      SearchforceConnectIdSearchEffects
    ]),
    HomeModule,
    LoginModule.forRoot(ApplicationConfig.loginConfig),
    CoreModule.forRoot(ApplicationConfig.coreConfig),
    FindABOModule,
    ABOModule
  ],
  providers: [CountryEnvironmentService,CommonService],
  bootstrap: [AppComponent]
})
export class AppModule {}
