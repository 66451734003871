import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './landing-page/landing-page.component';
import { LoginRoutingModule } from './login-routing.module';
import { MasheryRedirectComponent } from './redirect/mashery/mashery-redirect.component';
import { loginConfig } from './login.config';
import { LoginConfig } from '../shared/models/login-config.model';
import { LoginService } from './shared/login.service';
import { OccRedirectComponent } from './redirect/occ/occ-redirect.component';
import { RedirectComponent } from './redirect/redirect.component';


@NgModule({
  imports: [CommonModule, LoginRoutingModule],
  providers: [LoginService],
  declarations: [
    LoginComponent,
    MasheryRedirectComponent,
    OccRedirectComponent,
    RedirectComponent
  ],
  exports: [RedirectComponent]
})
export class LoginModule {
  static forRoot(config: LoginConfig): ModuleWithProviders {
    return {
      ngModule: LoginModule,
      providers: [{ provide: loginConfig, useValue: config }]
    };
  }
}
