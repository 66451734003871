import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { User, DropdownOption } from '../../models/index';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../store/index';
import { Subscription } from 'rxjs';
import { UserSignOut } from '../../../store/user/user.actions';
import { AuthService, StorageService } from '../../../core/services/index';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  translate_lang_key,
  defaultLanguageCode
} from '../../configs/constants';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private user$: Observable<User>;
  private sub: Subscription;
  user: User;
  signInClicked: boolean;
  langShow: boolean;
  selectedLangOption: DropdownOption;
  langOptions: DropdownOption[];
  occEnable: boolean = environment.occEnable;
  constructor(
    private store: Store<IAppState>,
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService,
    private storageService: StorageService
  ) {}

  ngOnInit() {
    this.langOptions = [
      { id: 'en', text: 'English' }
      // ,{ id: 'id', text: 'Bahasa' }
      // ,{ id: 'th', text: 'Thai' }
    ] as DropdownOption[];

    this.langShow = this.langOptions.length > 1 ? true: false;

    let selectedTranslateLng = <any>(
      this.storageService.getItemFromLocalStorage(translate_lang_key)
    );

    if (!selectedTranslateLng) {
      selectedTranslateLng = defaultLanguageCode;
      this.translateService.use(defaultLanguageCode);
      this.storageService.setItemInLocalStorage(translate_lang_key, defaultLanguageCode);
    }

    this.selectedLangOption = this.langOptions.filter(
      item => item.id === selectedTranslateLng
    )[0];

    this.storageService.setItem('token_loaded', '{ isLoaded: false }');
    this.user$ = this.store.select('user');
    this.sub = this.user$.subscribe(data => {
      this.user = data;
      if (!data) {
        this.storageService.setItem('token_loaded', '{ isLoaded: false }');
      }
      if (this.user && this.user.name) {
        const token = <any>this.storageService.getItem('token_loaded');

        if (!token || !token.isLoaded) {
          this.authService.refreshToken();
          this.storageService.setItem('token_loaded', { isLoaded: true });
        }
      }
    });
  }
  changeLanguage(event) {
    this.translateService.use(event.id);
    this.storageService.setItemInLocalStorage(translate_lang_key, event.id);
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  signOut(): void {
    this.authService.refreshTokenSub.unsubscribe();
    this.store.dispatch(new UserSignOut());
    this.router.navigateByUrl('/login');
  }

  signIn(): void {
    this.signInClicked = true;
    if(this.occEnable){
      window.location.href = this.authService.buildOccAuthTokenUrl();
    } else {
      window.location.href = this.authService.buildMasheryAuthCodePath();
    } 
  }
}
