import { Action } from '@ngrx/store';
import { ABOAddress } from '../../shared/models/amway/index';
import { AddressList } from '../../shared/models/party-basic.models';
import { BankDetail } from '../../shared/models/bank-detail.model';
import { StateActionPair } from '../../../../node_modules/@ngrx/store/src/state';
import { AboAddressChangePayload, AboBankDetailChangePayload, AboAddressDeletePayload } from '../../shared/models/abo-personal-details.model';

export const ABO_DETAILS_LOAD = '[Abo details] load';
export const ABO_DETAILS_LOAD_SUCCESS = '[Abo details] load success';
export const ABO_DETAILS_LOAD_FAIL = '[Abo details] load fail';
export const ABO_DETAILS_SAVE = '[Abo details] edit';
export const ABO_DETAILS_SAVE_SUCCESS = '[Abo details] edit success';
export const ABO_DETAILS_SAVE_FAIL = '[Abo details] edit fail';
export const ABO_DETAILS_ADDRESS_CHANGE = '[Abo details] address change';
export const ABO_DETAILS_ADDRESS_CHANGE_SUCCESS = '[Abo details] address change success';
export const ABO_DETAILS_ADDRESS_SAVE = '[Abo details] address save';
export const ABO_DETAILS_ADDRESS_SAVE_SUCCESS = '[Abo details] address save success';
export const ABO_DETAILS_ADDRESS_SAVE_FAIL = '[Abo details] address save fail';
export const ABO_DETAILS_ADDRESS_DELETE = '[Abo details] address delete'
export const ABO_DETAILS_ADDRESS_DELETE_SUCCESS = '[Abo details] address delete success'
export const ABO_DETAILS_ADDRESS_DELETE_FAIL = '[Abo details] address delete fail'
export const ABO_TAX_JURISDICTIONS_SHOW = '[Abo details] tax jurisdictions show';
export const ABO_BANK_DETAIL_CHANGE = '[Abo details] bank details change';
export const ABO_BANK_DETAIL_CHANGE_SUCCESS = '[Abo details] bank details change success';
export const ABO_BANK_DETAIL_CHANGE_FAIL = '[Abo details] bank details change fail';
export const ABO_TAX_DETAIL_CHANGE = '[Abo tax detail] change';
export const ABO_TAX_DETAIL_CHANGE_SUCCESS = '[Abo tax detail] change success';
export const ABO_TAX_DETAIL_CHANGE_FAIL = '[Abo tax detail] change fail';
export const ABO_TAX_CHANGE = '[Abo tax] only tax update';
export const ABO_TAX_CHANGE_SUCCESS = '[Abo tax] only tax update success';
export const ABO_TAX_CHANGE_FAIL = '[Abo tax] only tax update fail';
export const ABO_DOCUMENT_RECEIVED_SAVE = '[Abo tax] document received save';

export class AboDetailsLoad implements Action {
  readonly type = ABO_DETAILS_LOAD;

  constructor(public payload: any = null) { }
}

export class AboDetailsLoadSuccess implements Action {
  readonly type = ABO_DETAILS_LOAD_SUCCESS;

  constructor(public payload: any) { }
}

export class AboDetailsLoadFail implements Action {
  readonly type = ABO_DETAILS_LOAD_FAIL;

  constructor(public payload: any = null) { }
}

export class AboDetailsSave implements Action {
  readonly type = ABO_DETAILS_SAVE;

  constructor(public payload: any) { }
}

export class AboDetailsSaveSuccess implements Action {
  readonly type = ABO_DETAILS_SAVE_SUCCESS;

  constructor(public payload: any = null) { }
}

export class AboDetailsSaveFail implements Action {
  readonly type = ABO_DETAILS_SAVE_FAIL;

  constructor(public payload: any = null) { }
}

export class AboDetailsAddressSave implements Action {
  readonly type = ABO_DETAILS_ADDRESS_SAVE;

  constructor(public payload: any) { }
}

export class AboAddressChange implements Action {
  readonly type = ABO_DETAILS_ADDRESS_CHANGE;

  constructor(public payload: AboAddressChangePayload) {
  }
}
export class AboAddressDelete implements Action {
  readonly type = ABO_DETAILS_ADDRESS_DELETE;

  constructor(public payload: AboAddressDeletePayload) {
  }
}
export class AboAddressDeleteSuccess implements Action {
  readonly type = ABO_DETAILS_ADDRESS_DELETE_SUCCESS;

  constructor(public payload: AboAddressDeletePayload) {
  }
}
export class AboAddressDeleteFail implements Action {
  readonly type = ABO_DETAILS_ADDRESS_DELETE_FAIL;

  constructor(public payload: AboAddressDeletePayload) {
  }
}
export class AboBankDetailChange implements Action {
  readonly type = ABO_BANK_DETAIL_CHANGE;

  constructor(public payload: AboBankDetailChangePayload) {}
}

export class AboBankDetailChangeSuccess implements Action {
  readonly type = ABO_BANK_DETAIL_CHANGE_SUCCESS;
  constructor(public payload: any = null) {}
}

export class AboBankDetailChangeFail implements Action {
  readonly type = ABO_BANK_DETAIL_CHANGE_FAIL;
  constructor(public payload: any = null) {}
}

export class AboAddressChangeSuccess implements Action {
  readonly type = ABO_DETAILS_ADDRESS_CHANGE_SUCCESS;

  constructor(public payload: any = null) {
  }
}

export class AboDetailsAddressSaveFail implements Action {
  readonly type = ABO_DETAILS_ADDRESS_SAVE_FAIL;

  constructor(public payload: any = null) { }
}

export class AboTaxJurisdictionsShow implements Action {
  readonly type = ABO_TAX_JURISDICTIONS_SHOW;

  constructor(public payload: any) { }
}

export class AboTaxDetailChange implements Action {
  readonly type = ABO_TAX_DETAIL_CHANGE;

  constructor(public payload: any) { }
}
export class AboTaxDetailChangeSuccess implements Action {
  readonly type = ABO_TAX_DETAIL_CHANGE_SUCCESS;

  constructor(public payload: any) { }
}
export class AboTaxDetailChangeFail implements Action {
  readonly type = ABO_TAX_DETAIL_CHANGE_FAIL;

  constructor(public payload: any) { }
}
export class AboTaxChangeOnly implements Action {
  readonly type = ABO_TAX_CHANGE;

  constructor(public payload: any) { }
}
export class AboTaxChangeOnlySuccess implements Action {
  readonly type = ABO_TAX_CHANGE_SUCCESS;

  constructor(public payload: any) { }
}
export class AboTaxChangeOnlyFail implements Action {
  readonly type = ABO_TAX_CHANGE_FAIL;

  constructor(public payload: any) { }
}

export class AboDocumentReceivedSave implements Action {
  readonly type = ABO_DOCUMENT_RECEIVED_SAVE;

  constructor(public payload: any) {}
}


export type Actions =
  AboDetailsLoad
  | AboDetailsLoadSuccess
  | AboDetailsLoadFail
  | AboDetailsSave
  | AboDetailsSaveSuccess
  | AboDetailsSaveFail
  | AboDetailsAddressSave
  | AboAddressChange
  | AboAddressChangeSuccess
  | AboDetailsAddressSaveFail
  | AboAddressDelete
  | AboAddressDeleteSuccess
  | AboAddressDeleteFail
  | AboTaxJurisdictionsShow
  | AboBankDetailChange
  | AboBankDetailChangeSuccess
  | AboBankDetailChangeFail
  | AboTaxDetailChange
  | AboTaxDetailChangeSuccess
  | AboTaxDetailChangeFail
  | AboTaxChangeOnly
  | AboTaxChangeOnlySuccess
  | AboTaxChangeOnlyFail
  | AboDocumentReceivedSave;
  