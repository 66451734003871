import { Component, OnInit, ChangeDetectorRef, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IAppState } from '../../store';
import {
  RestrictionBlocks,
  RestrictionBlockData
} from '../../shared/models/amway';
import { DropdownOption } from '../../shared/models';
import { InheritanceData } from '../../shared/models/amway/inheritance.model';
import { PartyService } from '../../shared/services';
import { AboDetailsValidationService } from '../abo-details-validation.service';
import * as _ from 'lodash';
import { Modal } from 'ngx-modal';
import { Store } from '@ngrx/store';
import { PartyList } from '../../shared/models/party-basic.models';
import { AccountGet } from '../../store/account/account.actions';
import { Observable } from 'rxjs';
import { PartiesAccount } from '../../store/account-parties/account-parties.actions';

@Component({
  selector: 'app-abo-inheritance',
  templateUrl: './abo-inheritance.component.html',
  styleUrls: ['./abo-inheritance.component.scss']
})
export class AboInheritanceComponent implements OnInit {
  @Input() accountDetails;
  aboinheritanceInfo: InheritanceData= new InheritanceData({});
  reasonDropdownOptions: DropdownOption[];
  loader: boolean = false;
  errors: any;
  accountParties$: any;
  amwayAccountData: any;
  @ViewChild('editInheritanceInfo') editInheritanceInfo: Modal;
  @ViewChild('deleteConfirmation') deleteConfirmation: Modal;
  account$;
  accountFullDetail;
  successorPartyList: PartyList[] = [];
  selectedSuccessorId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private partyService: PartyService,
    private cdr: ChangeDetectorRef,
    private aboValidationService: AboDetailsValidationService,
    private store: Store<IAppState>,
  ) {}

  ngOnInit() {
    let successorPartyList = this.accountDetails.data.successorParties
    const {aff, abo} = this.activatedRoute.snapshot.params;
    const _this = this
    _.forEach(successorPartyList, function(successor, index){
      const sPartyId = successor.partyMst.partyId
      _this.partyService.getAccountsAssociatedWithParty({aff, abo, sPartyId}).subscribe(res => {
        if (res.amwayAccountList.length >= 1) {
          let aboAccountId = ''
          let linkedABOAccount = _.filter(res.amwayAccountList, function(o) { return o.roleCd == 'BusinessOwner' && o.statusCd == 'ACTIVE' });
          if (linkedABOAccount.length >= 1){ aboAccountId = linkedABOAccount[0].accountId }
          _this.successorPartyList.push(Object.assign({}, successorPartyList[index], {aboNum: aboAccountId}));
        }
      });
    })

    this.reasonDropdownOptions = [
      { id: 'SaleOfBusiness', text: 'Sale of Business' },
      { id: 'BusinessTransfer', text: 'Business Transfer' },
      { id: 'DeathInheritance', text: 'Death and Inheritance' },
      { id: 'Integration', text: 'Integration' },
      { id: 'LegalEntities', text: 'Legal Entities' },
      { id: 'MgmtSrvAgmnt', text: 'Management or Servicing Agreement' }
    ]
  }

  showLoader(){
    this.loader = true
  }

  makePopUpClose(){
    this.aboinheritanceInfo = new InheritanceData({});
    this.editInheritanceInfo.close()
  }

  deleteSuccessor() {
    this.deleteConfirmation.close();
    this.loader = true
    const params = this.activatedRoute.snapshot.params;
    this.partyService.deletePartyLink(params.aff, params.abo, this.selectedSuccessorId).subscribe(res => {
      if (res.status == 400) {this.loader = false; Observable.throw(res.statusText)}
      this.store.dispatch(new AccountGet(params));
    })
  }

  openModalBox() {
    this.editInheritanceInfo.open()
  }

  opendeleteConfirmationBox(successorId){
    this.deleteConfirmation.open();
    this.selectedSuccessorId = successorId
  }

  getPartyReason(reasonCd) {
    if (reasonCd){
      const reason:any =  _.filter(this.reasonDropdownOptions, function(o) { return o.id === reasonCd})[0]
      return reason ? reason.text : ''
    }
  }
}
