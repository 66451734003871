import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {Store} from '@ngrx/store';
import { IAppState } from '../../store';
import { AboVouchersDetailLoad } from '../../store/voucher/voucher.actions';
import { ActivatedRoute } from '@angular/router';
import { VouchersData, Voucher, VoucherTransactionDetail } from '../../shared/models/voucher.model';
import * as _ from 'lodash';
import { AccountBalance } from '../../shared/models/amway';
import { OfflinePaginationService } from '../../shared/services';

@Component({
  selector: 'app-abo-vouchers',
  templateUrl: './abo-vouchers.component.html',
  styleUrls: ['./abo-vouchers.component.scss']
})
export class AboVouchersComponent  implements OnInit {
  status = false;
  statusIcon = false;
  loaded$: Observable<boolean>;
  vouchers$: Observable<VouchersData>;
  vouchersDetail: Voucher[] = [];
  pager: any = {};
  pagedItems: any[];
  accountBalance: AccountBalance;

  constructor(private store: Store<IAppState>,
    private activatedRoute: ActivatedRoute,
    private offlinePaginationService: OfflinePaginationService) {}
  ngOnInit() {
    this.vouchers$ = this.store.select('aboVouchersDetails').pipe(filter(data => data.loaded));
    this.store.dispatch(new AboVouchersDetailLoad(this.activatedRoute.snapshot.params));
    this.loaded$ = this.vouchers$
                  .pipe(filter(data => !!data)
                  ,map(({ loaded }: VouchersData) => !loaded));
   this.vouchers$.subscribe((result) => {
      this.vouchersDetail = _.cloneDeep(result.data.voucherList);
      this.setPage(1);
      this.accountBalance = result.data.balance;
    }, error => {
     Observable.throw(error);
    });
  }

  toggleRow(voucher) {
    voucher.showDetails = !(voucher.showDetails);
  }

  onPageChange(page) {
    this.setPage(page.page);
  }

  setPage(page) {
    if (page < 1 || page > this.pager.totalPages) { return; }
    // get pager object from service
    this.pager = this.offlinePaginationService.getPager(this.vouchersDetail.length, page);
    // get current page of items
    this.pagedItems = this.vouchersDetail.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  isVoucherConsumedValid(transactionDetails: VoucherTransactionDetail[]): boolean {
    let totalSum = _.sumBy(transactionDetails, 'redeemAmount');
    return (totalSum > 0) ? true : false;
  }

}
