import { Component, Input } from '@angular/core';

@Component({
  selector: 'amw-abo-connect-confirmation',
  templateUrl: './abo-connect-id-confirmation.component.html',
  styleUrls: ['./abo-connect-id-confirmation.component.scss']
})
export class AboConnectIdConfirmationComponent {
  @Input() confirmationStatus: string;
  @Input() userName: string;

}
