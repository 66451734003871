import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DeactivationState, DeactivateReason, RequestedByMap, AccountDetail } from '../../../../shared/models/amway/index';
import { AccountCard } from '../../../../shared/models/index';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../store/index';
import { CommonService } from '../../../../core/services/index';
import { getAccountCardData } from '../../../../shared/helpers/account.helper';
import { defaultCurrencyCode } from '../../../../shared/configs/constants';

@Component({
  selector: 'app-deactivation-confirm',
  templateUrl: './deactivation-confirm.component.html',
  styleUrls: ['./deactivation-confirm.component.scss']
})
export class DeactivationConfirmComponent implements OnInit {

  @Input() isRenewal: boolean;
  store$: Observable<DeactivationState>;
  account$: Observable<AccountDetail>;
  total$: Observable<string>;
  selectedReason$: Observable<DeactivateReason>;
  dateFormat$: Observable<string>;
  expDate: string;
  requestedBy$: Observable<string>;
  refundAmount: number;
  accountInfo: AccountCard;
  defaultCurrencyCode: string =  defaultCurrencyCode;
  constructor(
    private store: Store<IAppState>,
    private common: CommonService
  ) {
  }

  ngOnInit() {
    this.expDate = new Date().toUTCString();
    this.store$ = this.store.select('deactivation').pipe(filter(Boolean));
    this.account$ = this.store.select('account').pipe(filter(data => data.loaded));
    this.dateFormat$ = this.store.select('settings').pipe(map(({ dateFormat }) => dateFormat));

    this.selectedReason$ = this.store$.pipe(map(({ selectedReason }) => selectedReason));
    this.requestedBy$ = this.store$
      .pipe(filter(({ selectedReason }) => !!selectedReason)
      ,map(({ selectedReason }) => RequestedByMap[selectedReason.id]));

    this.account$.subscribe((account: AccountDetail) => {
      this.accountInfo = getAccountCardData(account);
      this.refundAmount = CommonService.getRefundAmount(account.data.accountMst.aboEntryDate);
    });
  }


}
