import {
  Component,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import { DropdownOption } from '../../models/index';
import * as _ from 'lodash';

const MIN_LENGTH = 2;

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnChanges {
  @Input()
  textOnly: boolean;
  @Input()
  readOnly: boolean;
  @Input()
  hideSelectedFromOptions = false;
  @Input()
  sortField = 'text';
  @Input()
  addEmpty: boolean;
  @Input()
  currentValue: string;
  @Input()
  currentOption: DropdownOption;
  @Input()
  defaultText = 'Select One';
  @Input()
  label: string;
  @Input()
  options: DropdownOption[];
  @Output()
  currentValueChange = new EventEmitter<DropdownOption | string | number>();
  @Input()
  filter = false;
  @Input()
  isError = false;
  filteredOptions: DropdownOption[];
  chosenOption: DropdownOption;
  isExpanded = false;
  disabled = false;

  ngOnChanges({ options }: SimpleChanges) {
    if (
      options &&
      options.currentValue &&
      options.currentValue !== options.previousValue
    ) {
      this.setReadOnly();
      this.setChosenOption();
      this.updateDropdownList();
    }
  }

  /**
   * Sets current value and emits change event
   * @param value New current value
   */
  setValue(option: DropdownOption): void {
    this.chosenOption = option;
    this.currentValueChange.emit(this.textOnly ? option.id : option);
    this.close();
  }

  /**
   * toggle dropdown
   * @memberOf DropdownComponent
   */
  toggle() {
    if (!this.readOnly && this.options) {
      this.isExpanded = !this.isExpanded;
    }
  }

  /**
   * Closes dropdown
   */
  close(event = null) {
    if (
      event &&
      event.relatedTarget &&
      (event.relatedTarget.id === 'optionFilter' ||
        event.relatedTarget.id === 'optionFilterContainer')
    ) {
      return;
    }
    this.isExpanded = false;
    this.updateDropdownList();
  }

  scrollTo(el) {
    const elem = el.currentTarget;
    setTimeout(() => {
      const el_selected = elem.parentElement.lastElementChild;
      if (el_selected && el_selected.querySelector('#selected')) {
        el_selected
          .querySelector('#selected')
          .scrollIntoView({
            block: 'center',
            inline: 'center',
            behavior: 'auto'
          });
      } else {
        el_selected.scrollTo(0, 200);
      }
    }, 100);
  }

  /**
   * in case hideSelectedFromOptions set true
   * filters out selected item from displayed options
   */
  private updateDropdownList() {
    this.filteredOptions = [...(this.options || [])];

    if (this.addEmpty && this.chosenOption) {
      this.filteredOptions.unshift(
        _.mapValues(this.filteredOptions[0], () => '')
      );
    }

    if (this.hideSelectedFromOptions && this.chosenOption) {
      this.filteredOptions = this.filteredOptions
        .filter(item => item.id !== this.chosenOption.id)
        .filter(item => item.id && item.text); // prevent from displaying empty items
    }
  }

  /**
   * sets chosen option
   */
  private setChosenOption() {
    this.chosenOption = this.options.find(option => {
      return (
        option.id ===
        (this.currentOption ? this.currentOption.id : this.currentValue)
      );
    });
  }

  /**
   * defines if dropdown should be disabled
   */
  private setReadOnly() {
    this.disabled =
      this.readOnly ||
      (this.hideSelectedFromOptions && this.options.length < MIN_LENGTH);
  }

  filterOptions(event) {
    const searchText = event.target.value.toLowerCase().trim();
    if (searchText) {
      if (this.options && this.options.length) {
        this.filteredOptions = this.options.filter(op => {
          return op.text.toLowerCase().includes(searchText);
        });
      }
    } else {
      this.filteredOptions = this.options;
    }
  }
}
