import * as moment from 'moment';
import { defaultCurrencyCode } from '../configs/constants';

export class BankDetail {
  acctUsageList: BankDetailUsage[];
  bankAcctHolderName: string;
  bankAcctId: string;
  bankAcctName: string;
  bankAcctNum: string;
  bankAcctTypeCd: string;
  bankBranchCode: string;
  bankBranchName: string;
  bankAccStatus: string;
  bankCntryCd: string;
  currencyCd: string;
  effectiveEndDate: string;
  effectiveStartDate: string;
  issuingBankId: string;
  issuingBankName: string;
  partyId: string;
  primaryRoutingNum: string;
  secondaryRoutingNum: string;
  bankAcctTypeName?: string;
  bankAccStatusText?: string;
}

export class BankDetailUsage {
  acctUseCode: string;
  useFlag: string;
}

export class CreateBankAccount extends BankDetail {
  statusFlg: string;
  accountUseCodes: string [];
  constructor(detail: BankDetail) {
    super();
    {
      this.bankAcctTypeCd = detail.bankAcctTypeCd;
      this.bankAcctNum = detail.bankAcctNum;
      this.effectiveStartDate = moment().format();
      this.effectiveEndDate = '';
      this.primaryRoutingNum = '';
      this.issuingBankId = detail.issuingBankId;
      this.issuingBankName = detail.issuingBankName;
      this.bankBranchCode = detail.bankBranchCode;
      this.bankBranchName = detail.bankBranchName;
      this.secondaryRoutingNum = '';
      this.bankAcctHolderName = detail.bankAcctHolderName;
      this.bankAcctName = '';
      this.currencyCd = defaultCurrencyCode;
      this.statusFlg = '1';
      this.accountUseCodes = ['BonusPayments'];
      this.bankAccStatus = detail.bankAccStatus;
    }
  }
}

export class UpdateBankAccount extends BankDetail {
  statusFlg: string;
  accountUseCodes: string [];
  constructor(detail: BankDetail) {
    super();
    {
      this.bankAcctId = detail.bankAcctId;
      this.bankAcctTypeCd = '';
      this.bankAcctNum = detail.bankAcctNum;
      this.effectiveStartDate = moment().format();
      this.effectiveEndDate = '';
      this.primaryRoutingNum = '';
      this.issuingBankId = detail.issuingBankId;
      this.issuingBankName = detail.issuingBankName;
      this.bankBranchCode = detail.bankBranchCode;
      this.bankBranchName = detail.bankBranchName;
      this.secondaryRoutingNum = '';
      this.bankAcctHolderName = detail.bankAcctHolderName;
      this.bankAcctName = '';
      this.currencyCd = defaultCurrencyCode;
      this.statusFlg = '1';
      this.accountUseCodes = [detail.acctUsageList[0].acctUseCode];
      this.bankAcctTypeCd = detail.bankAcctTypeCd;
      this.bankAccStatus = detail.bankAccStatus;
    }
  }
}
