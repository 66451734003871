import { OnInit, Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { BankDetail } from '../../../shared/models/bank-detail.model';
import { CountryEnvironmentService } from '../../../shared/services/country-environment.service';

@Component({
  selector: 'app-abo-bank-info',
  templateUrl: './abo-bank-info.component.html',
  styleUrls: ['./abo-bank-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ABOBankInfoComponent implements OnInit {
  @Input() bankDetails: BankDetail;

  constructor(public countryEnvironmentService: CountryEnvironmentService) {
  }
  ngOnInit() {}
}
