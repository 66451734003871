export class SplunckLog {
  id: string;
  url: string;
  statusCode: string;
  message: string;
  errorCode: string;
  constructor(id, url, statusCode, message, errorCode) {
    this.id = id || '';
    this.url = url || '';
    this.statusCode = statusCode || '';
    this.message = message || '';
    this.errorCode = errorCode || '';
  }
}
