export class AccountSearchParam {
  aff: string;
  abo: string;
  partyId: string;
  constructor({
    aff = '',
    abo = '',
    partyId = ''
  }) {
    this.aff = aff;
    this.abo = abo;
    this.partyId = partyId;
  }
}
