import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import * as _ from 'lodash';
import { Modal } from 'ngx-modal';

import { Store } from '@ngrx/store';
import {
  SearchforceConnectIdEventsSearch,
  SearchforceConnectIdEventsSearchClear
} from '../store/find-amway-id-events/find-amway-id-events.actions';
import { IAppState } from '../store';

import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { Schema, CellType } from '../shared/models/schema.model';
import {
  SearchforceConnectIdReqPayload,
  SearchforceConnectIdResponse,
  SearchforceConnectIdResults
} from '../shared/models/searchforce';
import {
  AmwayAccountList
} from '../shared/models/logins.model';
import { SearchforceConnectIdState } from '../shared/models/searchforce/connect-id.model';
import { FindAboEventsService } from '../shared/services/find.abo.events.service';
import { OktaJwtToken } from '../shared/models/index';
import { StorageService, AuthService } from '../core/services/index';
import { LoginService } from '../login/shared/index';

const SEARCH_RESULTS_SCHEMA = [
  {
    title: 'Amway id',
    property: 'username',
    type: CellType.Link,
  },
  {
    title: 'Event type',
    property: 'eventType'
  },
  {
    title: 'Date/time stamp',
    property: 'occurred'
  },
  {
    title: 'party id',
    property: 'partyId'
  },
];

@Component({
  selector: 'app-searchforce-find-connect-id',
  templateUrl: './find-connect-id-events.component.html',
  styleUrls: ['./find-connect-id-events.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SearchforceFindConnectIdEventsComponent implements OnInit, OnDestroy {
  @ViewChild('selectAccountModal') modal: Modal;
  connectIdEventsStore$: Observable<SearchforceConnectIdState>;
  results$: Observable<SearchforceConnectIdResponse>;
  loading$: Observable<boolean>;
  loaded$: Observable<boolean>;
  selectedPartyId: string;
  sub: Subscription;
  queryParams: Params;
  resultSchema: Schema[];
  loading: boolean = false;
  results: any;
  loaded: boolean;

  constructor(
    private route: ActivatedRoute,
    private store: Store<IAppState>,
    private router: Router,
    private findAboEventsService: FindAboEventsService,
    private authService: AuthService,
    private storage: StorageService,
    private loginService: LoginService,
    private activateRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    const token = <OktaJwtToken>this.storage.getItem('okta-jwt-token');

    if (token === null || token['id_token'] === undefined) {
      this.handleRouteParams();
    }

    this.resultSchema = SEARCH_RESULTS_SCHEMA;
    this.connectIdEventsStore$ = this.store.select('searchforceConnectIdSearch').pipe(filter(Boolean));
    this.results$ = this.connectIdEventsStore$.pipe(map(store => store.results));
    this.loaded$ = this.connectIdEventsStore$.pipe(map(store => store.loaded));
    this.loading$ = this.connectIdEventsStore$.pipe(map(store => store.loading));

    this.sub = this.route.queryParams
      .pipe(filter(params => !_.isEmpty(_.omit(params, 'page'))))
      .subscribe((data: SearchforceConnectIdReqPayload) => {
        this.queryParams = data;
        this.store.dispatch(new SearchforceConnectIdEventsSearch(data));
      });
  }

  handleRouteParams() {
    let route = this.activateRoute.snapshot.fragment;
    let formData = this.storage.getItem('formData');
    this.router.navigate(['/searchforce-find-connect-id'], { queryParams: formData });
    this.storage.removeItem('formData');
    const token = {};
    if (route) {
      route = route.substring(route.indexOf('#'), route.length);
      const routeParams = route.split('&');
      routeParams.forEach(param => {
        const property: { key: string; value: string } = {
          key: param.split('=')[0],
          value: param.split('=')[1]
        };
        token[property.key] = property.value;
      });
      this.loginService.storeOktaJwtToken(<OktaJwtToken>token);
    } else {
      this.findAboEventsService.storeEventSearchFormData();
      // window.location.href = this.authService.buildOktaJwtAuthCodePath();
    }

  }

  ngOnDestroy() {
    this.store.dispatch(new SearchforceConnectIdEventsSearchClear());
    this.sub.unsubscribe();
  }

  /**
  * Change route with new query params
  * @param {any} $event
  * @memberOf SearchforceFindPartyComponent
  */
  onPageChange($event): void {
    this.router.navigate([], {
      queryParams: Object.assign({}, this.queryParams, $event)
    });
  }

  selectAccount({ salesPlanAff, accountId, partyId }: AmwayAccountList) {
    this.router.navigate(['/ibo', salesPlanAff, accountId, partyId]);
  }

  clearSelectedPartyId() {
    this.selectedPartyId = null;
    this.modal.close();
  }

  openModal({ partyId }: SearchforceConnectIdResults) {
    this.selectedPartyId = partyId;
    this.modal.open();
  }
}
