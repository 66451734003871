import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/index';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { Roles, RoleEntry } from '../models/index';
import { DEFAULT_PRIVILEGES } from '../configs/roles';


/**
 * usage: *roleGroup="{groupName};mode:{view|edite|delete}"
 *        *roleGroup="{[groupName1, groupName2, ...]};mode:{view|edite|delete}"
 * example: <div *roleGroup="'iboBasicInfo';mode:'view'"></div>
 * @export
 * @class RoleGroupDirective
 */
@Directive({ selector: '[appRoleGroup]' })
export class RoleGroupDirective implements OnDestroy {
  _privileges$: Observable<Roles>;
  sub: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<IAppState>
  ) { }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  @Input() set appRoleGroup(group: string | string[]) {
    this._privileges$ = this.store
      .select('roles').pipe(
      filter(Boolean)
      ,map(roles => this.getMergedRolesGroup(roles, group)));
  }

  @Input() set appRoleGroupMode(mode: string) {
    this.sub = this._privileges$
      .pipe(filter(group => _.some(_.values(group), [mode, true])))
      .subscribe(() => this.viewContainer.createEmbeddedView(this.templateRef));
  }

  /**
   *
   * @param {Roles} roles
   * @param {string | string[]} groups
   * @returns {Roles}
   */
  private getMergedRolesGroup(roles: Roles, groups: string | string[]): Roles {
    return (Array.isArray(groups) ? groups : [groups]).reduce((memo, group) => ({
      ...memo,
      ...roles[group]
    }), {});
  }
}

/**
 * usage: *role="{groupName.fieldName};mode:{view|edite|delete}"
 * example: <div *role="'iboBasicInfo.legalName';mode:'view'"></div>
 * @export
 * @class RoleDirective
 */
@Directive({ selector: '[appRole]' })
export class RoleDirective implements OnDestroy {
  _privileges$: Observable<RoleEntry>;
  sub: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store<IAppState>
  ) { }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  @Input() set appRole(path: string) {
    this._privileges$ = this.store
      .select('roles').pipe(
      filter(Boolean)
      ,map(roles => _.get(roles, path, DEFAULT_PRIVILEGES) as RoleEntry));
  }

  @Input() set appRoleMode(mode: string) {
    this.sub = this._privileges$.pipe(
      filter(privileges => !!privileges[mode]))
      .subscribe(() => this.viewContainer.createEmbeddedView(this.templateRef));
  }
}
