import { Injectable, Inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { coreConfig } from '../core.config';
import { CoreConfig } from '../../shared/models/index';
import { StorageService } from './storage.service';
import { MasheryToken } from '../../shared/models/mashery-token.model';
import { SplunckLog } from '../../shared/models/splunck-log.model';
import { LoggerDebugLevel } from '../../shared/models/logger-debug.model';
import { environment } from '../../../environments/environment';
import { WorkerMessage } from '../../shared/models/worker-message.model';

@Injectable()
export class LoggerService {
  // Declaring worker instance
  worker: Worker;
  workerObject: any = {};

  // Declaring debuglevel instance
  workerConfig: LoggerDebugLevel;
  constructor(
    private storageService: StorageService,
    @Inject(coreConfig) public config: CoreConfig
  ) {
    this.initWebWorker();
   }

  private initWebWorker(): void {
    // Checking for the existence / support of web worker
    if (typeof Worker !== 'undefined') {
      if (!this.worker) {
        this.worker = new Worker('/assets/js/logger-worker.js');
        if (!this.workerConfig) {
          this.workerConfig = {
            appenderDest: 'server',
            loggingEnabled: true,
            mode: 'debug', //
            logMsgBuffer: false,
            logMsgThreshold: 1024,
            logTimerEnabled: false,
            logTimeThreshold: 1
          };
        }
        this.workerObject['type'] = 'init';
        this.workerObject['config'] = this.workerConfig;
        this.postToWorker(this.workerObject);

        this.worker.addEventListener('message', function(e) {
          console.log(e);
        });

        this.worker.addEventListener('error', function(e) {
          console.log(e);
        });
      }
    } else {
      // If web worker is not supported
      console.log('No Web worker supported');
    }
  }

 private  postToWorker(message: WorkerMessage): void {
   if (this.workerConfig.loggingEnabled) {
     const serverDetail = this.getAuthTokenAndUrlForLogging();
     message.message = message.message;
     message.token = serverDetail.token;
     message.url = serverDetail.url;

     this.worker.postMessage(message);
   }
  }

  /**
   * To set the logger level to DEBUG
   */
  logDebug(message: any): void {
    this.workerObject['type'] = 'log';
    this.workerObject['mode'] = 'debug';
    this.workerObject['message'] = message;
    this.postToWorker(this.workerObject);
  }

  /**
   * To set the logger level to ERROR
   */
  logError(message: any): void {
    this.workerObject['type'] = 'log';
    this.workerObject['mode'] = 'error';
    this.workerObject['message'] = message;
    this.postToWorker(this.workerObject);
  }

  /**
   * To set the logger level to WARNING
   */
  logWarning(message: any): void {
    this.workerObject['type'] = 'log';
    this.workerObject['mode'] = 'warning';
    this.workerObject['message'] = message;
    this.postToWorker(this.workerObject);
  }

  /**
   * To set the logger level to INFO
   */
  logInfo(message: any): void {
    this.workerObject['type'] = 'log';
    this.workerObject['mode'] = 'info';
    this.workerObject['message'] = message;
    this.postToWorker(this.workerObject);
  }
  LogHttpError(error: HttpErrorResponse) {
    if (error) {
      const message = this.getErrorMessage(error);
      const errorCode = this.getErrorCode(error);
      const logMessage = this.getLogMessage(
        message,
        error.url,
        error.status.toString(),
        errorCode
      );
      if (logMessage) {
        this.logError(logMessage);
      }
    }
  }

  private getErrorMessage(error: any): string {
    let message = '';
    let errorsObj: any;
    let errorData: any;
    try {
      if (error.error) {
        try {
          errorData = JSON.parse(error.error);
        } catch (e) {
          errorData = error.error;
        }
      } else {
        errorData = {};
      }
      if (errorData.errors && errorData.errors.length > 0) {
        errorsObj = errorData.errors;
      } else {
        errorsObj = errorData.errorMessage ? errorData.errorMessage : errorData;
      }
      if (errorsObj && errorsObj instanceof Array) {
        errorsObj.forEach(errorObj => {
          if (errorObj.message) {
            message += `${errorObj.message}\n`;
          }
        });
      } else if (errorsObj && errorsObj instanceof Object) {
        if (errorsObj.message) {
          message += `${errorsObj.message}\n`;
        }
      } else {
        message += `${errorsObj} - ${error.message}`;
      }
      if (!message || message.length === 0) {
        if (error.message) {
          message += error.message;
        }
      }
    } catch (er) {
      console.log(er);
    }
    return message;
  }

  private getLogMessage(
    message: string,
    url: string,
    statusCode: string = null,
    errorCode: string = null
  ): string {
    let messageTobeLogged = '';
    try {
      let user = '';
      const token = <MasheryToken>(
        this.storageService.getItem(this.config.masheryTokenKey)
      );
      if (token) {
        user = token.user_context || '';
      }
      const appId = 'BOSS';
      const time = new Date().getTime();
      const id = `${appId}-${user}-${time}`;
      const logObj = new SplunckLog(id, url, statusCode, message, errorCode);
      messageTobeLogged = JSON.stringify(logObj);
    } catch (error) {
      messageTobeLogged = '';
      console.log(error);
    }
    return messageTobeLogged;
  }
  private getErrorCode(error: any) {
    let errorCode = '';
    let errorData: any;
    if (error.error) {
      try {
        errorData = JSON.parse(error.error);
        if (errorData && errorData.errorMessage) {
          errorCode = errorData.errorMessage.code;
        }
      } catch (e) {}
    }
    return errorCode;
  }

  private getAuthTokenAndUrlForLogging(): any {
    return {
      token: this.getToken(),
      url: environment.loggerUrl
    };
  }
  private getToken() {
    let token = '';
    try {
      const occToken = <any>this.storageService.getItem(this.config.occTokenKey);
      if (occToken) {
        token = occToken.access_token;
      }
    } catch (error) {
      console.log(error);
    }
    return token;
  }
}
