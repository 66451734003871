import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { ModalModule } from 'ngx-modal';
import { DpDatePickerModule } from 'ng2-date-picker';
import { HeaderComponent } from './components/header/header.component';
import { NotificationComponent } from './components/notification/notification.component';
import { ErrorGrowlComponent } from './components/error-growl/error-growl.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CollapseComponent } from './components/collapse/collapse.component';
import { ABOSearchComponent } from './components/search/abo/abo-search.component';
import {
  CountryService, ABOService, ModalService, VolumeService,
  PhotoService, TaxService, LanguageService, ConfigService,
  UplineService, AccountService, AccountBalanceService, ErrorService,
  UpdateDetailsService, AddressService, DeactivationService, ContractsService, RegistrationsService,
  TaxPayerService, PartyService, OfflinePaginationService, BusinessInfoService
} from './services';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoadingIndicatorComponent } from './components/loader/loading-indicator/loading-indicator.component';
import { TableComponent } from './components/table/table.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { RoleDirective, RoleGroupDirective, MaskDirective, ValidationsOnInputFieldDirective} from './directives';
import { ArraySortPipe, TaxPipe, AppDatePipe, FormatedDatePipe } from './pipes';
import { ModalComponent } from './components/modal/modal.component';
import { NavigationService } from '../core/services';
import { AboBusinessDetailService } from './services/abo-business-details.service';
import { CalendarComponent } from './components/calendar/calendar.component';
import { SponsorService } from './services/sponsor.service';
import { AboRestrictionBlockService } from './services/abo-restriction-blocks.service';
import { BlockDetailsComponent } from './components/block-details/block-details.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { AboDetailComponent } from './components/abo-detail/abo-detail.component';
import { AboDetailListComponent } from './components/abo-detail-list/abo-detail-list.component';
import { ModalPlaceholderComponent } from './components/modal-placeholder/modal-placeholder.component';
import { VoucherService } from './services/voucher.service';
import { TaxCertificateService } from './services/tax-certificate.service';
import { AboAwardsService } from './services/abo-awards.service';
import { SubscriptionService } from './services/subscriptions.service';
import { AboBankService } from './services/abo-bank.service';
import { AutoCompleteComponent } from './components/autocomplete/autocomplete.component';
import { ErrorMessagesViewComponent } from './components/error-message-view/error-messages-view.component';
import { ApiPathConfigService } from './configs/api';
import { FindAboEventsService } from './services/find.abo.events.service';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { AboConnectService } from './services/abo-connect.service';
import { AboIdCheckService } from './services/abo-id-check.service';
import { AboEmailActivationService } from './services/abo-email-activation.service';

@NgModule({
  declarations: [
    HeaderComponent,
    NotificationComponent,
    ErrorGrowlComponent,
    BreadcrumbsComponent,
    CollapseComponent,
    ABOSearchComponent,
    DropdownComponent,
    LoaderComponent,
    LoadingIndicatorComponent,
    TableComponent,
    PaginationComponent,
    ArraySortPipe,
    TaxPipe,
    AppDatePipe,
    FormatedDatePipe,
    RoleDirective,
    RoleGroupDirective,
    MaskDirective,
    ValidationsOnInputFieldDirective,
    ModalComponent,
    CalendarComponent,
    BlockDetailsComponent,
    ToggleComponent,
    AboDetailComponent,
    AboDetailListComponent,
    ModalPlaceholderComponent,
    AutoCompleteComponent,
    ErrorMessagesViewComponent,
    DatePickerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    TranslateModule.forChild(),
    ModalModule,
    DpDatePickerModule
  ],
  exports: [
    HeaderComponent,
    NotificationComponent,
    ErrorGrowlComponent,
    BreadcrumbsComponent,
    CollapseComponent,
    ABOSearchComponent,
    DropdownComponent,
    LoaderComponent,
    LoadingIndicatorComponent,
    TableComponent,
    PaginationComponent,
    ArraySortPipe,
    TaxPipe,
    AppDatePipe,
    FormatedDatePipe,
    RoleDirective,
    RoleGroupDirective,
    MaskDirective,
    ValidationsOnInputFieldDirective,
    ModalComponent,
    CalendarComponent,
    BlockDetailsComponent,
    ToggleComponent,
    AboDetailComponent,
    AboDetailListComponent,
    ModalPlaceholderComponent,
    AutoCompleteComponent,
    ErrorMessagesViewComponent,
    DatePickerComponent
  ],
  providers: [
    CountryService,
    ABOService,
    AboBusinessDetailService,
    ModalService,
    VolumeService,
    PhotoService,
    NavigationService,
    TaxService,
    LanguageService,
    ConfigService,
    SponsorService,
    UplineService,
    AccountService,
    AccountBalanceService,
    AboRestrictionBlockService,
    ErrorService,
    UpdateDetailsService,
    AddressService,
    ContractsService,
    DeactivationService,
    VoucherService,
    TaxCertificateService,
    AboAwardsService,
    SubscriptionService,
    AboBankService,
    RegistrationsService,
    TaxPayerService,
    PartyService,
    OfflinePaginationService,
    BusinessInfoService,
    ApiPathConfigService,
    FindAboEventsService,
    AboConnectService,
    AboIdCheckService,
    AboEmailActivationService,
    AppDatePipe
  ]
})
export class SharedModule { }
