import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { AddressEventData, AddressDeletionEventData } from '../../../../shared/models/amway/index';
import { DropdownOption } from '../../../../shared/models/index';
import {
  ContactPointTypeCdEnum
} from '../../../../shared/models/enums/index';
import { IAppState } from '../../../../store/index';
import { AddressList } from '../../../../shared/models/party-basic.models';
import { AreaLocation } from '../../../../shared/models/area-locator.model';
import { AddressService } from '../../../../shared/services';
import { defaultCountryCode, defaultCountryName, defaultLanguageCode } from '../../../../shared/configs/constants';

@Component({
  selector: 'app-abo-address-form',
  templateUrl: './abo-address-form.component.html',
  styleUrls: ['./abo-address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboAddressFormComponent implements OnInit {
  @Input()
  address: AddressList;
  @Input()
  contactPointPurposeCd: string;
  @Input()
  cntryCd: string;
  @Output()
  updateAddress = new EventEmitter<AddressEventData>();
  @Output()  
  deleteAddress = new EventEmitter<AddressDeletionEventData>();
  @Input()
  aboAddresses;
  @Input()
  newAddress;
  @Input()
  idx;
  editableInfo: AddressList;
  states$: Observable<DropdownOption[]>;
  province$: Observable<any>;
  addressForm: FormGroup;
  locations$: Observable<AreaLocation[]>;
  locationFileldsHasError: boolean;
  addressProvince: DropdownOption[];
  getProvinceOption: DropdownOption[];
  getProvince;
  provincesList;
  districtList$;
  subDistrictList$;
  listLoaded: boolean = false
  postalCode$;
  provinceStateCd;
  currentProvince;
  currentDistrict;
  currentSubDistrict;
  disabledFormFields: boolean = false
  showDeleteBtn: boolean = false;
  defaultCountryName: string = defaultCountryName;
  constructor(
    private store: Store<IAppState>,
    private addressService: AddressService,
    private cdr: ChangeDetectorRef
   
  ) {}

  ngOnInit() {
    this.editableInfo = _.cloneDeep(this.address);
    this.addressForm = this.createAddressForm(this.editableInfo);
    this.formFieldsUpdate()
  }

  formFieldsUpdate(){
    const _this = this
    this.addressService
      .getProvinceForCountry()
      .pipe(filter((result: any) => !result.loading))
      .subscribe(res => {
        _this.provincesList = res.data.map(_this.getProvinceDropdownOption)
        _this.currentProvince = _.filter(_this.provincesList, function(province) { return province.id == _this.editableInfo.stateCd})[0]
        if (_this.currentProvince){
          _this.updateProvince(_this.currentProvince)
          _this.currentDistrict = _this.getDropdownOption(_this.editableInfo.districtName)
          _this.updateDistrict(_this.currentDistrict)
          _this.currentSubDistrict = _this.getDropdownOption(_this.editableInfo.subDistrictName)
          _this.updateSubDistrict(_this.currentSubDistrict)
        }
        _this.listLoaded = true
        _this.cdr.detectChanges();
      });
  }

  getProvinceDropdownOption(data): DropdownOption {
    return {
      id: data.isocode,
      text: data.name
    };
  }

  getProvinceCurrentDropdownOption(id, value): DropdownOption {
    return {
      id: id,
      text: value
    };
  }

  getDropdownOption(data): DropdownOption {
    return {
      id: data,
      text: data
    };
  }

  saveChanges(): void {
    this.locationFileldsHasError = this.validateLocationsfields();
    if (!this.locationFileldsHasError && this.addressForm.valid) {
      this.saveAddressInfomation(this.editableInfo, this.addressForm)
      this.checkForShippingPrimaryAddress()
    } else {
      this.addressForm.controls['adrLine1'].markAsTouched();
      this.addressForm.controls['district'].markAsTouched();
      this.addressForm.controls['subDistrict'].markAsTouched();
      this.addressForm.controls['province'].markAsTouched();
      this.addressForm.controls['postalCode'].markAsTouched();
    }
  }

  saveAddressInfomation(editableInfo, addressForm){
    if (this.newAddress){
      let primaryShippingAddress = _.filter(this.aboAddresses, function(o) { return (o.usageList[0].primaryFlag && o.usageList[0].contactPointPurposeCd == 'Shipping')})[0]
      primaryShippingAddress = _.cloneDeep(primaryShippingAddress)
      primaryShippingAddress.addressBookId = ''
      primaryShippingAddress.usageList[0].primaryFlag = false
      editableInfo = primaryShippingAddress
    }

    let updatedEditableInfo = this.getDataForUpdate(
      editableInfo,
      addressForm
    );

    this.updateAddress.emit({
      updatedData: _.cloneDeep(updatedEditableInfo),
      initialData: _.cloneDeep(addressForm),
      forceSave: false
    });
  }

  checkForShippingPrimaryAddress(){
    if (this.editableInfo.usageList[0].contactPointPurposeCd == 'Mailing'){
      let primaryShippingAddress = _.filter(this.aboAddresses, function(ad){ 
        return ad.usageList[0].contactPointPurposeCd == 'Shipping' 
                && ad.usageList[0].primaryFlag == true
                })[0]
      if (primaryShippingAddress){
        this.saveAddressInfomation(primaryShippingAddress, this.addressForm)
      }
    }
  }

  private getDataForUpdate(
    addressInfo: AddressList,
    adrform: FormGroup
  ): AddressList {
    if (adrform && addressInfo) {
      addressInfo.addrStreet = adrform.controls['adrLine1'].value
      addressInfo.districtName = adrform.controls['district'].value;
      addressInfo.subDistrictName = adrform.controls['subDistrict'].value;
      addressInfo.stateName = adrform.controls['province'].value;
      addressInfo.postalCd = adrform.controls['postalCode'].value;
      addressInfo.stateCd =  adrform.controls['stateCd'].value;
      addressInfo.cntryCd = defaultCountryCode
    }
    return addressInfo.contactId || addressInfo.addressBookId
      ? addressInfo
      : {
          ...addressInfo,
          contactPointTypeCd: ContactPointTypeCdEnum.BusinessAddress,
          addrStreet: addressInfo.addrStreet || '',
          addrLineTwo: addressInfo.addrLineTwo || '',
          languageCd: defaultLanguageCode
        };
  }

  private createAddressForm(addressInfo: AddressList) {
    const adrForm: any = {};
    this.disabledFormFields = addressInfo.usageList[0].contactPointPurposeCd == 'Shipping' 
                              && addressInfo.usageList[0].primaryFlag == true
    this.showDeleteBtn = addressInfo.usageList[0].primaryFlag || this.newAddress

    let contactPointPurposeCd
    switch(addressInfo.usageList[0].contactPointPurposeCd) {
      case 'Mailing':
        contactPointPurposeCd = 'Tax Address'
        break;
      case 'Shipping':
        if (this.newAddress){
          contactPointPurposeCd = 'Shipping'
        } else if (addressInfo.usageList[0].contactPointPurposeCd == 'Shipping'  && addressInfo.usageList[0].primaryFlag == true) {
          contactPointPurposeCd = 'Mailing/Primary Address'
        } else if (addressInfo.usageList[0].contactPointPurposeCd == 'Shipping'  && addressInfo.usageList[0].primaryFlag == false){
          contactPointPurposeCd = 'Shipping Address ' + (this.idx - 1)
        }
        break;
      default:
        contactPointPurposeCd = addressInfo.usageList[0].contactPointPurposeCd
    }

    adrForm['adrPurpose'] = new FormControl(
      {
        value: contactPointPurposeCd,
        disabled: true
      },
      [Validators.required]
    );
    adrForm['adrLine1'] = new FormControl(
      {
        value: addressInfo.addrStreet || '',
        disabled: this.disabledFormFields
    },
      [Validators.required]
    );
    adrForm['district'] = new FormControl(
      {
        value: addressInfo.districtName,
        disabled: this.disabledFormFields
      },
      [Validators.required]
    );
    adrForm['subDistrict'] = new FormControl(
      {
        value: addressInfo.subDistrictName,
        disabled: this.disabledFormFields
      },
      [Validators.required]
    );
    adrForm['province'] = new FormControl(
      {
        value: addressInfo.stateName,
        disabled: this.disabledFormFields
      },
      [Validators.required]
    );
    adrForm['postalCode'] = new FormControl(
      {
        value: addressInfo.postalCd,
        disabled: true
      },
      [Validators.required]
    );
    adrForm['stateCd'] = new FormControl(
      {
        value: addressInfo.stateCd,
        disabled: this.disabledFormFields
      },
      [Validators.required]
    );

    return new FormGroup(adrForm);
  }

  getProvinces(event) {
    this.locations$ = this.addressService
      .getAddressLocations(event)
      .pipe(filter((result: any) => !result.loading)
      ,map((response: any) => response.data));
  }
  
  private validateLocationsfields() {
    let isValid = true;
    if (!this.addressForm.controls['adrLine1'].value) {
      this.addressForm.controls['adrLine1'].setErrors({
        required: true,
        incorrect: true
      });
      isValid = false;
      return isValid;
    }
    if (!this.addressForm.controls['district'].value) {
      this.addressForm.controls['district'].setErrors({
        required: true,
        incorrect: true
      });
      isValid = false;
      return isValid;
    }
    if (!this.addressForm.controls['subDistrict'].value) {
      this.addressForm.controls['subDistrict'].setErrors({
        required: true,
        incorrect: true
      });
      isValid = false;
      return isValid;
    }
    if (!this.addressForm.controls['province'].value) {
      this.addressForm.controls['province'].setErrors({
        required: true,
        incorrect: true
      });
      isValid = false;
      return isValid;
    }
    if (!this.addressForm.controls['postalCode'].value) {
      this.addressForm.controls['postalCode'].setErrors({
        required: true,
        incorrect: true
      });
      isValid = false;
      return isValid;
    }
  }

  updateProvince(province) {
    this.addressForm.controls['province'].setValue(province.text);
    this.subDistrictList$ = of([])
    this.addressForm.controls['subDistrict'].setValue('');
    this.addressForm.controls['postalCode'].setValue('');
    this.districtList$ = this.addressService
            .getDistrictForCountry(province.id)
            .pipe(filter((result: any) => !result.loading)
            ,map(res => res.data.map(this.getDropdownOption)));

   
    this.addressForm.controls['stateCd'].setValue(province.id);
  }

  updateDistrict(destrict) {
    this.addressForm.controls['district'].setValue(destrict.id);
    this.addressForm.controls['postalCode'].setValue('');
    this.subDistrictList$ = this.addressService
    .getSubDistrictForCountry(destrict.id)
    .pipe(filter((result: any) => !result.loading)
    ,map(res => res.data.map(this.getDropdownOption)));
  }

  updateSubDistrict(subDistrict){
    this.addressForm.controls['subDistrict'].setValue(subDistrict.id);
    this.addressService
          .getPostalCodeForCountry(subDistrict.id)
          .pipe(filter((result: any) => !result.loading))
          .subscribe(res => {
            this.addressForm.controls['postalCode'].setValue(res.data);
          });
  }

  deleteAddressForm(){
    this.deleteAddress.emit({addressBookId: this.editableInfo.addressBookId})
  }
}
