
import { TaxCertificate } from './tax-certificate.model';
import { AddressList } from './party-basic.models';
import { Tax } from './amway';

export class AboTaxDetail {
 taxId: string;
 taxAddress: AddressList;
 taxCertDetail: TaxCertificate;
 tax: Tax;
 taxFile?: File;
}
