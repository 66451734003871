export class TaxCertificate {
  public certificateEDoc: string;
  public docName: string;
  public controlNumber: string;
  public countryCode: string;
  public entryDate: string;
  public partyId: number;
  public taxCertificateCode: string;
  public taxCertificateName: string;
  public taxTypeCode: string;
  public taxTypeName: string;
  public userId: string;
}
