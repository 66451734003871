import { Component, OnInit, Input, OnDestroy, SimpleChanges, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'error-messages-view',
  templateUrl: './error-messages-view.component.html',
  styleUrls: ['./error-messages-view.component.scss']
})
export class ErrorMessagesViewComponent implements OnInit, OnDestroy {
  @Input() errorMessages;

  constructor(
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
  }

  ngOnDestroy(){
    this.errorMessages = []
  }

}
