import {
  USER_SET_DATA,
  USER_SIGN_OUT,
  Actions
} from './user.actions';
import { User } from '../../shared/models/index';

export function userReducer(state: User, action: Actions): User {
  switch (action.type) {
    case USER_SET_DATA:
    case USER_SIGN_OUT:
      return action.payload;

    default:
      return state;
  }
}
