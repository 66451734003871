import {
  ROUTE_CHANGE,
  Actions
} from './history.actions';
import { History } from '../../shared/models/index';
import * as _ from 'lodash';

export function historyReducer(state: History, action: Actions): History {
  switch (action.type) {
    case ROUTE_CHANGE:
      const breadcrumbs = _.get(state, 'breadcrumbs', [] as any);
      const parentsDefaults = action.payload.parents;
      const parentRouts = parentsDefaults.map((parent) => {
        return Object.assign({}, parent, breadcrumbs.find((item) => item.path === parent.path));
      });

      return Object.assign({}, state, { breadcrumbs: [...parentRouts, action.payload] });

    default:
      return state;
  }
}
