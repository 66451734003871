import { Observable, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { PhotoService, ErrorService } from '../../shared/services/index';
import { PHOTO_LOAD,
   PhotoLoad,
  PhotoLoadFail,
    PhotoLoadSuccess,
  PHOTO_CHANGE,
PhotoChange,
PhotoChangeFail,
PhotoChangeSuccess } from './photo.actions';
import { DomSanitizer } from '@angular/platform-browser';
import { ErrorLogService } from '../../core/services';

@Injectable()
export class PhotoEffects {

  constructor(
    private action$: Actions,
    private photoService: PhotoService,
    private sanitizer: DomSanitizer,
    private errorService: ErrorService,
    private errorLogService: ErrorLogService
  ) {
  }

  @Effect()
  loadPhoto$ = this.action$
    .ofType(PHOTO_LOAD).pipe(
    switchMap((action: PhotoLoad) => {
      return this.photoService.retrieveABOImage(action.payload).pipe(
        map((blob) => {
          const sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            window.URL.createObjectURL(blob)
          );
          return new PhotoLoadSuccess({ url: sanitizedUrl });
        }),        
        catchError(error => {
          this.LogError(error);
         return of(new PhotoLoadFail());
        }));
    }));
    @Effect()
    changePhoto$ = this.action$
    .ofType(PHOTO_CHANGE).pipe(
    switchMap((action: PhotoChange) => {
      return this.photoService.uploadProfileImage(action.payload.reqParams, action.payload.file)
        .pipe(map((response) => {
          return new PhotoChangeSuccess(response);
        })
        ,catchError(error => {
          this.LogError(error);
         return of(new PhotoChangeFail());
        }));
    }));
    private LogError(error) {
      const errorMessage = this.errorService.processError(error);
      this.errorLogService.log(errorMessage);
    }
}
