import { Action } from '@ngrx/store';

export const ABO_VOUCHER_DETAIL_LOAD = '[Abo voucher detail] load';
export const ABO_VOUCHER_DETAIL_LOAD_SUCCESS = '[Abo voucher detail] load success';
export const ABO_VOUCHER_DETAIL_LOAD_FAIL = '[Abo voucher detail] load fail';

export class AboVouchersDetailLoad implements Action {
  readonly type = ABO_VOUCHER_DETAIL_LOAD;
  constructor(public payload: any = null) {}
}

export class AboVouchersDetailLoadSuccess implements Action {
  readonly type = ABO_VOUCHER_DETAIL_LOAD_SUCCESS;
  constructor(public payload: any) {}
}

export class AboVouchersDetailLoadFail implements Action {
  readonly type = ABO_VOUCHER_DETAIL_LOAD_FAIL;
  constructor(public payload: any = null) {}
}

export type Actions =
  | AboVouchersDetailLoad
  | AboVouchersDetailLoadSuccess
  | AboVouchersDetailLoadFail;
