export class ABOSearchQuery {

  query: string;
  salesAff: number;
  countryCd: string;
  currentPage: number;
  offset: number;
  searchType: string;
  constructor({
    searchQuery = '',
    salesAff = 0,
    countryCd = '',
    page = 0,
    offset = 0,
    searchType = 'ibo'
  }) {
    this.query = searchQuery;
    this.salesAff = salesAff;
    this.countryCd = countryCd;
    this.currentPage = page;
    this.offset = offset;
    this.searchType = searchType;
  }
}
