import { HttpParams, HttpClient } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpService } from '../../core/services/index';
import { ApiPathConfigService } from '../configs/api';
import { salesAff, partyId, aboNum, personalIdTypeCd, taxType, cntryCd,
  updateAccountTaxAPI, updatePersonalIdAPI, deleteCoapplicantAPI,
  getSalutaionsAPI, registerPartialUserAPI, registerFullAccountAPI,
  updateAccountNameMultiLangAPI, updateAccountPartiesAPI, defaultCountryCode,
  TAX_TYPE_CODE } from './../configs/constants';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { ErrorService } from '../../shared/services/error.service';
import { ErrorLogService } from '../../core/services/error-log.service';

@Injectable()
export class RegistrationsService {
    constructor(private httpService: HttpService, private http: HttpClient,
                private activatedRoute: ActivatedRoute,
                private errorService: ErrorService,
                private errorLogService: ErrorLogService,
                private apiPathService: ApiPathConfigService
              ){ }

    registerPartialAccount(payload) {
      const api = this.apiPathService.getApiPath(registerPartialUserAPI);
      api.path = api.path.replace(salesAff, payload.editableBasicInfo.salesAff)
                          .replace(aboNum, payload.editableBasicInfo.abo)
      return this.httpService.callServerPOST(api, null, payload.abobasicInfo).pipe(map(response => {
          return response
        }),catchError(error => {
          this.LogError(error);
          return of(error)
        }));
    }

    
    updateCoApplicantInformation(payload) {
      const apis = []
      if (!_.isEmpty(payload.editableBasicInfo.passportno)) {
        apis.push(this.updatePidInformation(payload.editableBasicInfo.salesAff,
                                            payload.editableBasicInfo.abo,
                                            payload.abobasicInfo.partyMst.partyId,
                                            payload.abobasicInfo['personalIdList'][0].personalId))
      }
      if (!_.isEmpty(payload.editableBasicInfo.cid)) {
        apis.push(this.updateCidInformation(payload.editableBasicInfo.salesAff,
                                            payload.editableBasicInfo.abo,
                                            payload.abobasicInfo.partyMst.partyId,
                                            payload.abobasicInfo['taxList'][0].taxId
                                        )
                      )
      }
      apis.push(this.changePartyRole(payload))
      apis.push(this.saveAccountNames(payload['editableBasicInfo'], payload.abobasicInfo))

      return forkJoin(apis)
    }

    saveAccountNames(update, abobasicInfo) {
      const { c_givenName, c_familyName, c_l_givenName, c_l_familyName } = update;
      const api = this.apiPathService.getApiPath(updateAccountNameMultiLangAPI);
      api.path = api.path
        .replace(salesAff, update.salesAff)
        .replace(aboNum, update.abo)
        .replace(partyId, abobasicInfo.partyMst.partyId);
      const body = {
        nameList: [{
          localeName: { givenName: c_givenName, familyName: c_familyName },
          latinName: { givenName: c_l_givenName, familyName: c_l_familyName },
          languageCd: abobasicInfo.partyMst.languageCd,
          partyId: abobasicInfo.partyMst.partyId
        }]
      };
      return this.httpService
        .callServerPUT(api, null, body)
        .pipe(map(response => {
          return response;
        })
        ,catchError(error => {
          return Observable.throw(error);
        }));
    }

    updateCidInformation(aff, abo, party, taxId){
      const api = this.apiPathService.getApiPath(updateAccountTaxAPI);
      api.path = api.path.replace(salesAff, aff)
                          .replace(aboNum, abo)
                          .replace(partyId, party)
                          .replace(taxType, TAX_TYPE_CODE);

      const CIDPayload = this.buildCidPayload(taxId)
      return this.httpService.callServerPUT(api, null, CIDPayload).pipe(map(response => {
        return response
      }),catchError(error => {
        return of(error)
      }));
    }

    buildCidPayload(taxId){
      return {
        "tax": {
          "expirationDate": "",
          "taxId": taxId,
          "cntryCd": defaultCountryCode
        }
      }
    }

    updatePidInformation(aff, abo, party, Pid) {
      const api = this.apiPathService.getApiPath(updatePersonalIdAPI);
      api.path = api.path.replace(salesAff, aff)
                          .replace(aboNum, abo)
                          .replace(partyId, party)
                          .replace(personalIdTypeCd, 'PID')
      const PIDPayload = this.buildPidPayload(Pid)
      return this.httpService.callServerPUT(api, null, PIDPayload).pipe(map(response => {
        return response
      }),catchError(error => {
        return of(error)
      }));
    }

    buildPidPayload(payload){
      return {
        "personalId": {
          "personalId": payload,
          "expirationDate": "",
          "effectiveDate": ""
        }
      }
    }

    changePartyRole(payload) {
      const api = this.apiPathService.getApiPath(updateAccountPartiesAPI);
      api.path = api.path.replace(salesAff, payload.editableBasicInfo.salesAff)
                          .replace(aboNum, payload.editableBasicInfo.abo)
                          .replace(partyId, payload.abobasicInfo.partyMst.partyId)
      const params = {'partyTypeCd': payload.abobasicInfo.partyMst.partyTypeCd,
                      'roleCd': payload.abobasicInfo.partyMst.roleCd}
      return this.httpService.callServerPUT(api, null, params).pipe(map(response => {
        return response
      }),catchError(error => {
        return of(error)
      }));
    }

    deleteCoapplicant(req) {
      const api = this.apiPathService.getApiPath(deleteCoapplicantAPI);
      api.path = api.path.replace(salesAff, req.params.aff)
                          .replace(aboNum, req.params.abo)
                          .replace(partyId, req.partyId)
      return this.httpService.callServerDELETE(api, null, null)
            .pipe(map(response => {
              return response;
            }),catchError((error) => error)
            ,retry(1));
    }

    registerAccount(payload, aff) {
      const api = this.apiPathService.getApiPath(registerFullAccountAPI);
      api.path = api.path.replace(salesAff, aff);
        return this.httpService.callServerPOST(api, null, payload).pipe(map(response => {
          return response
        }),catchError(error => {
          // this.LogError(error);
          return of(error)
        }));
    }

    getSalutaions(countryCd){
      const api = this.apiPathService.getApiPath(getSalutaionsAPI);
      api.path = api.path.replace(cntryCd, countryCd);
        return this.httpService.callServerGET(api).pipe(map(response => {
          return response
        }),catchError(error => {
          return of(error)
      }));
    }

    private LogError(error) {
      const errorMessage = this.errorService.processError(error);
      this.errorLogService.log(errorMessage);
    }
}
