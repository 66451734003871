import { Action } from '@ngrx/store';

export const CONFIG_GET = '[Config] get';
export const CONFIG_GET_SUCCESS = '[Config] get success';
export const CONFIG_GET_FAIL = '[Config] get fail';
export const CONFIG_CONTRACTS_GET = '[Config] contracts get';
export const CONFIG_CONTRACTS_GET_SUCCESS = '[Config] contracts get success';
export const CONFIG_CONTRACTS_GET_FAIL = '[Config] contracts get fail';

export class ConfigGet implements Action {
  readonly type = CONFIG_GET;

  constructor(public payload: any = null) {}
}

export class ConfigGetSuccess implements Action {
  readonly type = CONFIG_GET_SUCCESS;

  constructor(public payload: any = null) {}
}

export class ConfigGetFail implements Action {
  readonly type = CONFIG_GET_FAIL;

  constructor(public payload: any = null) {}
}

export class ConfigContractsGet implements Action {
  readonly type = CONFIG_CONTRACTS_GET;

  constructor(public payload: any = null) {}
}

export class ConfigContractsSuccess implements Action {
  readonly type = CONFIG_CONTRACTS_GET_SUCCESS;

  constructor(public payload: any = null) {}
}

export class ConfigContractsFail implements Action {
  readonly type = CONFIG_CONTRACTS_GET_FAIL;

  constructor(public payload: any = null) {}
}

export type Actions =
  | ConfigGet
  | ConfigGetSuccess
  | ConfigGetFail
  | ConfigContractsGet
  | ConfigContractsSuccess
  | ConfigContractsFail;
