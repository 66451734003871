import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { IAppState } from '../../store';
import { PartyParams } from '../../shared/models/index';
import { AccountGet } from '../../store/account/account.actions';
import { PhotoLoad } from '../../store/photo/photo.actions';
import { AboDetailsLoad } from '../../store/abo-personal-details/abo-personal-details.actions';
import { AboBusinessDetailsLoad } from '../../store/abo-business-details/abo-business-details.actions';

@Injectable()
export class NavigationService {
  constructor(private store: Store<IAppState>) { }

  /**
   * dispatches actions in order to update info for IBO component
   * @param {PartyParams} params
   */
  updateIboComponent(params: PartyParams): void {
    this.store.dispatch(new AccountGet(params));
    this.store.dispatch(new PhotoLoad(params));
    this.store.dispatch(new AboDetailsLoad(params));
    this.store.dispatch(new AboBusinessDetailsLoad(params));
  }

  /**
   * dispatches actions in order to update account data
   * @param {PartyParams} params
   */
  updateAccountData(params: PartyParams) {
    this.store.dispatch(new AccountGet(params));
    this.store.dispatch(new AboBusinessDetailsLoad(params));
  }

  /**
   * dispatches actions in order to update data after re-registration
   * @param params
   */
  updateInfoAfterReRegistration(params) {
    // this.store.dispatch(new AboActionsLoad(params));
    this.store.dispatch(new AccountGet(params));
  }
}
