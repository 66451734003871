import { Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { TAX_CERTIFICATE_LOAD, TaxCertificateLoad, TaxCertificateLoadFail, TaxCertificateLoadSuccess } from './tax-certificate.actions';
import { DomSanitizer } from '@angular/platform-browser';
import { TaxCertificateService } from '../../shared/services/tax-certificate.service';

@Injectable()
export class TaxCertificateEffects {

  constructor(
    private action$: Actions,
    private taxCertService: TaxCertificateService,
    private sanitizer: DomSanitizer,
  ) {
  }

  @Effect()
  loadPhoto$ = this.action$
    .ofType(TAX_CERTIFICATE_LOAD).pipe(
    switchMap((action: TaxCertificateLoad) => {
      return this.taxCertService.getTaxCertficate(action.payload)
        .pipe(catchError(error => of(new TaxCertificateLoadFail()))
        ,map((blob) => {
          if (blob && blob instanceof Blob && blob.size > 0) {
            const sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
              window.URL.createObjectURL(blob)
            );
            return new TaxCertificateLoadSuccess({ url: sanitizedUrl });
          } else {
            return new TaxCertificateLoadSuccess({ url: '' });
          }
        }));
    }));
}
