import { Component, ChangeDetectionStrategy, Input, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment/moment';
import {
  IDatePickerConfig,
  IDay,
  SingleCalendarValue,
} from 'ng2-date-picker';

import { Store } from '@ngrx/store';
import { IAppState } from '../../store/index';
import {
  SearchforceConnectIdGetEventTypes,
} from '../../store/find-amway-id-events/find-amway-id-events.actions';

import { CommonService } from '../../shared/services/common.service';
import { FindAboEventsService } from '../../shared/services/find.abo.events.service';
import { DropdownOption } from '../../shared/models/dropdown.model';
import { SearchforceConnectIdReqPayload } from '../../shared/models/searchforce/connect-id.model';

const SEARCH_DATE_FORMAT = 'MM/DD/YYYY';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-searchforce-search-connect-id',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchforceSearchConnectIdComponent implements OnInit {
  isValidDateFormat: boolean;
  showErrorMessage: boolean = false;
  searchParams = {} as SearchforceConnectIdReqPayload;
  @Input() set params(params: SearchforceConnectIdReqPayload) {
    this.searchParams = { ...this.searchParams, ...params };
  }
  eventTypeOptions$: any;

  dayPickerConfig: IDatePickerConfig = {
    format: SEARCH_DATE_FORMAT,
  };

  constructor(
    private router: Router,
    private commonService: CommonService,
    private store: Store<IAppState>,
    private eventsService: FindAboEventsService
  ) { }

  ngOnInit() {
    this.store.dispatch(new SearchforceConnectIdGetEventTypes());

    this.eventTypeOptions$ = this.store
      .select('searchforceConnectIdSearch')
      .pipe(filter(Boolean)
      ,map(store => store.eventTypes)
      ,filter(Boolean)
      ,map(types => this.getTypesOptions(types.data)));
  }

  /**
   * @param {IDay} event
   * @returns void
   */
  setOccurredDate(date): void {
    date = date.toString().trim();
    // date = date;
    if (!date) {
      this.searchParams = {
        ...this.searchParams,
        occurred: null
      }
      return;
    }
    else {
      this.showErrorMessage = false;
      this.searchParams = {
        ...this.searchParams,
        occurred: moment(date).format('MM/DD/YYYY'),
      };
    }
  }

  /**
   * @param {SingleCalendarValue} date
   * @returns string
   */
  castOccurredDate(date: SingleCalendarValue): string {
    const { format } = this.dayPickerConfig;

    return date ? moment(date).format(format) : null;
  }

  /**
   * @param  {DropdownOption} option
   * @returns void
   */
  setEventType(option: DropdownOption): void {
    this.searchParams = {
      ...this.searchParams,
      eventType: (option || {} as DropdownOption).id as string,
    };
  }

  /**
   * checks if search is enabled
   * @returns {boolean}
   */
  searchEnabled(): boolean {
    return _.some(_.omit(this.searchParams, 'page'), (v) => v && !!v.trim());
  }

  /**
   * handles key up event on search input
   * @param {KeyboardEvent} event
   */
  keyUpHandler(event: KeyboardEvent): void {
    if (this.searchEnabled() && this.commonService.isEnter(event)) {
      this.search();
    }
  }

  /**
   * search
   * @memberOf SearchforceSearchEventsComponent
   */
  search() {
    this.router.navigate([], {
      queryParams: { ..._.pickBy(this.searchParams, Boolean), page: 1 },
    });
  }

  /**
  * @param  {string[]} types
  * @returns DropdownOption
  */
  private getTypesOptions(types: string[]): DropdownOption[] {
    return types.map(type => ({
      id: type,
      text: this.commonService.createSearchforceEventTypeLabel(type),
    }));
  }

  validateDateFormat(date) {
    this.isValidDateFormat = moment(date, 'MM/DD/YYYY', true).isValid();
    if (date != "" && !this.isValidDateFormat) {
      this.showErrorMessage = true;
    }
    else {
      this.showErrorMessage = false;
    }
  }
}
