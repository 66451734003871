export interface UsageList {
  contactPointPurposeCd: string;
  primaryFlag: boolean;
  careOf?: string;
}

export class CommonResponse {
  serverName: string;
  errorMessage: ErrorMessage;
  type: string;
}

class ErrorMessage {
  code: number;
  message: string;
}
