import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AmwayUserProfile } from '../../../shared/models/abo-connect.model';

const CREATE_LABEL_TEXT = 'Enter an email address to create an AmwayID.';
const EDIT_LABEL_TEXT = 'Edit the user\'s existing AmwayID.';
const BTN_APPLY_TEXT = 'Apply';
const BTN_CREATE_TEXT = 'Create';
const EMAIL_REGEXP = '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))' +
  '@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])' +
  '|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,64}))$';

@Component({
  selector: 'amw-abo-connect-form',
  templateUrl: './abo-connect-form.component.html',
  styleUrls: ['./abo-connect-form.component.scss']
})
export class AboConnectFormComponent implements OnInit {
  @Input() userProfile: AmwayUserProfile;
  @Input() isLoading: boolean;
  @Input() errorMsg: string;
  @Output() onClose = new EventEmitter<void>();
  @Output() onSubmit = new EventEmitter<AmwayUserProfile>();

  connectForm: FormGroup;
  connectId: FormControl;
  oldUserName: string;
  btnText: string;
  labelText: string;

  constructor() { }

  ngOnInit() {
    this.oldUserName = this.userProfile ? this.userProfile.displayUsername : '';
    this.labelText = this.userProfile ? EDIT_LABEL_TEXT : CREATE_LABEL_TEXT;
    this.btnText = this.userProfile ? BTN_APPLY_TEXT : BTN_CREATE_TEXT;
    this.createFormControls();
    this.createForm();
  }

  /**
     * checks if connect id is invalid
     * @returns {boolean}
     */
  isConnectIdInvalid(): boolean {
    return this.connectId && this.connectId.errors && (this.connectId.dirty || this.connectId.touched);
  }

  /**
   * checks if connect id is valid
   * @returns {boolean}
   */
  isConnectIdValid(): boolean {
    return this.connectId && this.connectId.valid && (this.connectId.dirty || this.connectId.touched);
  }

  /**
     * checks if connect id is changed
     * @returns {boolean}
     */
  isConnectIdWasChanged(): boolean {
    return this.oldUserName !== this.connectId.value;
  }

  private createFormControls(): void {
    this.connectId = new FormControl(this.oldUserName, [
      Validators.required,
      Validators.pattern(EMAIL_REGEXP)
    ]);
  }

  private createForm(): void {
    this.connectForm = new FormGroup({
      connectId: this.connectId
    });
  }

  closeModal(): void {
    this.onClose.emit();
  }

  submitFormData(): void {
    this.onSubmit.emit({
      ...this.userProfile,
      userName: this.connectId.value.trim()
    });
  }

}
