import { Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';

import {  TAX_PAYER_LOAD,
  TaxPayerLoad,
  TaxPayerLoadSuccess,
  TaxPayerLoadFail
} from './tax-payer.actions';
import { TaxPayerService } from '../../shared/services/tax-payer.service';

@Injectable()

export class TaxPayersEffects {

  @Effect()
  registration$ = this.actions$
    .ofType(TAX_PAYER_LOAD).pipe(
    switchMap((action: TaxPayerLoad) => {
      return this.taxPayerService.getTaxePayer(action.payload)
        .pipe(catchError(error => of(new TaxPayerLoadFail(error)))
        ,map((response) => {
          return new TaxPayerLoadSuccess(response);
        }));
    }));

    constructor(private actions$: Actions, private taxPayerService: TaxPayerService){}
}
