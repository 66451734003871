import {
  COUNTRIES_LOAD,
  COUNTRIES_LOAD_SUCCESS,
  COUNTRIES_LOAD_FAIL,
  ALL_COUNTRIES_LOAD,
  ALL_COUNTRIES_LOAD_SUCCESS,
  ALL_COUNTRIES_LOAD_FAIL,
  Actions
} from './countries.actions';

export function countriesReducer(state: any, action: Actions): any {
  switch (action.type) {
    case COUNTRIES_LOAD:
      return Object.assign({}, state, { loading: true });

    case COUNTRIES_LOAD_SUCCESS:
      return Object.assign(
        {},
        state,
        { loading: false },
        {
          countryList: action.payload
        }
      );

    case COUNTRIES_LOAD_FAIL:
      return Object.assign(
        {},
        state,
        { loading: false },
        {
          countryList: []
        }
      );

    case ALL_COUNTRIES_LOAD:
      return Object.assign({}, state, { countriesListLoading: true });
    case ALL_COUNTRIES_LOAD_SUCCESS:
      return Object.assign(
        {},
        state,
        { countriesListLoading: false },
        { allCountriesList: action.payload }
      );

    case ALL_COUNTRIES_LOAD_FAIL:
      return Object.assign(
        {},
        state,
        { countriesListLoading: false },
        { allCountriesList: [] }
      );

    default:
      return state;
  }
}
