import {
  TAX_PAYER_LOAD,
  TAX_PAYER_LOAD_SUCCESS,
  TAX_PAYER_LOAD_FAIL,
  Actions
} from './tax-payer.actions';

export function taxPayerReducer(state: any, action: Actions): any {
  switch (action.type) {
    case TAX_PAYER_LOAD:
      return {...state, loaded: false};

    case TAX_PAYER_LOAD_SUCCESS:
      return { ...state, ...action.payload, loaded: true };

    case TAX_PAYER_LOAD_FAIL:
      return { ...state, loaded: true };

    default:
      return state;
  }
}
