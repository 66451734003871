import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  ABOAddress,
  AddressRequest,
  AddressValidationPayload,
  AddressInput,
  AddrDeliveryTypeCdEnum,
  ValidationResultCdEnum,
  AddressBookInput,
  AddressList
} from '../models/amway/index';
import * as _ from 'lodash';
import { ApiPathConfigService } from '../configs/api';
import { AccountSearchParam } from '../models/index';
import {
  salesAff, aboNum, partyId, contactId, addressBookId, updateShippingAddressAPI,
  updateAccountAddressAPI, createShippingAddressAPI, areaLocatorAPI,
  provinceAPI, provinceStateCdAPI, districtAPI, subdistrictAPI,
  postalCodeAPI, getShippingAddressAPI
} from '../configs/constants';
import { HttpService } from '../../core/services/http.service';
import { ErrorService } from './error.service';
import { ErrorLogService, AuthService } from '../../core/services';
import { HttpParams } from '@angular/common/http';
import { AreaLocation } from '../models/area-locator.model';

const ADDRESS_FIELDS_MAP = {
  AddressLine1Text: 'addrStreet',
  CityName: 'cityName',
  StateCode: 'stateCd',
  CountryCode: 'cntryCd',
  PostalCode: 'postalCd'
};
const DETAILS_PATH = 'body.Address';

@Injectable()
export class AddressService {
  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    private errorService: ErrorService,
    private errorLogService: ErrorLogService,
    private apiPathService: ApiPathConfigService
  ) { }

  updateAddress({ editedAddress, unchangedAddress, routeParams }) {
    if (_.isEqual(editedAddress, unchangedAddress)) {
      return of('');
    }
    const addressRequest = new AddressRequest();
    addressRequest.applyToAllLinkedAccountFlag = 'true';
    addressRequest.address = new AddressInput();
    Object.assign(addressRequest.address, editedAddress);

    addressRequest.address.taxJursidictionCd = _.get(
      addressRequest,
      'address.taxJursidictionCd',
      ''
    ).slice(-2);
    addressRequest.address.addrDeliveryTypeCd = AddrDeliveryTypeCdEnum.MailStop;
    addressRequest.address.validationResultCd = ValidationResultCdEnum.Valid;
    addressRequest.address.districtNm = editedAddress.districtName;
    addressRequest.address.subDistrictNm = editedAddress.subDistrictName;
    // if (
    //   unchangedAddress &&
    //   unchangedAddress.usageList &&
    //   unchangedAddress.usageList.length > 1
    // ) {
    //   addressRequest.address.contactId = '';
    //   return this.createAddress(routeParams, addressRequest);
    // } else 
    if (editedAddress.contactId) {
      addressRequest.address.contactId = editedAddress.contactId;
      return this.updateAddressRequest(routeParams, addressRequest);
    } else if (editedAddress.addressBookId) {
      return this.updateAddressRequestBook(routeParams, addressRequest);
    }
    else {
      addressRequest.address.contactId = '';
      return this.createAddress(routeParams, addressRequest);
    }
  }


  updateAddressRequestBook(req, body) {
    const addressBook = new AddressBookInput()
    addressBook.addressBookInput = new AddressList();
    addressBook.addressBookInput.addressList = [body.address];
    const api = this.apiPathService.getApiPath(updateShippingAddressAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId)
      .replace(addressBookId, body.address.addressBookId);

    return this.httpService.callServerPUT(api, null, addressBook).pipe(catchError(error => {
      this.LogError(error);
      return of(error);
    }));
  }

  deleteAddress(req) {
    const api = this.apiPathService.getApiPath(updateShippingAddressAPI);
    api.path = api.path
      .replace(salesAff, req.routeParams.aff)
      .replace(aboNum, req.routeParams.abo)
      .replace(partyId, req.routeParams.partyId)
      .replace(addressBookId, req.addressBookId);

    return this.httpService.callServerDELETE(api).pipe(catchError(error => {
      this.LogError(error);
      return of(error);
    }));
  }

  updateAddressRequest(req: AccountSearchParam, body: AddressRequest) {
    const api = this.apiPathService.getApiPath(updateAccountAddressAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId)
      .replace(contactId, body.address.contactId);

    return this.httpService.callServerPUT(api, null, body).pipe(catchError(error => {
      this.LogError(error);
      return of(error);
    }));
  }

  createAddress(req: AccountSearchParam, body: AddressRequest) {
    const api = this.apiPathService.getApiPath(createShippingAddressAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId);

    const addressBook = new AddressBookInput()
    addressBook.addressBookInput = new AddressList();
    addressBook.addressBookInput.addressList = [body.address]

    return this.httpService.callServerPOST(api, null, addressBook).pipe(catchError(error => {
      this.LogError(error);
      return of(error);
    }));
  }

  sameAddress(address1: any, address2: any): boolean {
    return !['addrStreet', 'addrLineTwo', 'stateCd', 'cityName'] // 'postalCd' could be auto-corrected
      .some(prop => address1[prop] !== address2[prop]);
  }

  convertAddress(
    address: AddressValidationPayload | AddressInput | ABOAddress,
    forValidation = false
  ): AddressValidationPayload | AddressInput | ABOAddress {
    const fieldsMap = forValidation
      ? ADDRESS_FIELDS_MAP
      : _.zipObject(_.values(ADDRESS_FIELDS_MAP), _.keys(ADDRESS_FIELDS_MAP));

    return <AddressValidationPayload | AddressInput | ABOAddress>(
      _.zipObject(
        _.keys(fieldsMap),
        _.values(fieldsMap).map((key: string) => address[key])
      )
    );
  }

  getValidatedAddress(validationResult): AddressValidationPayload {
    return _.get(validationResult, DETAILS_PATH) as AddressValidationPayload;
  }

  private LogError(error) {
    const errorMessage = this.errorService.processError(error);
    this.errorLogService.log(errorMessage);
  }
  getAddressLocations(request) {
    const locationObservable = new Observable(observer => {
      observer.next({ loading: true, data: [] as AreaLocation[] });
      const token = this.authService.getOccAccessToken();
      const api = this.apiPathService.getApiPath(areaLocatorAPI);
      let params = new HttpParams();
      params = params.append('access_token', token);
      params = params.append('term', request.query);
      this.httpService.callOccServerGET(api, params).subscribe(
        response => {
          const locations =
            response && response.length
              ? response.map(loc => new AreaLocation(loc))
              : ([] as AreaLocation[]);
          observer.next({ loading: false, data: locations });
          observer.complete();
        },
        error => {
          this.LogError(error);
          observer.next({ loading: false, data: [] as AreaLocation[] });
          observer.complete();
        }
      );
    });
    return locationObservable;
  }

  getProvince() {
    const locationObservable = new Observable(observer => {
      observer.next({ loading: true, data: [] as AreaLocation[] });
      const token = this.authService.getOccAccessToken();
      const api = this.apiPathService.getApiPath(areaLocatorAPI);
      // const api = areaLocatorAPI;
      let params = new HttpParams();
      params = params.append('access_token', token);
      params = params.append('term', 'del');
      this.httpService.callOccServerGET(api, params).subscribe(
        response => {
          const locations =
            response && response.length
              ? response.map(loc => new AreaLocation(loc))
              : ([] as AreaLocation[]);
          observer.next({ loading: false, data: locations });
          observer.complete();
        },
        error => {
          this.LogError(error);
          observer.next({ loading: false, data: [] as AreaLocation[] });
          observer.complete();
        }
      );
    });
    return locationObservable;
  }

  getProvinceForCountry() {
    const locationObservable = new Observable(observer => {
      observer.next({ loading: true, data: [] });
      const token = this.authService.getOccAccessToken();
      const api = this.apiPathService.getApiPath(provinceAPI);
      // const api = provinceApi;
      let params = new HttpParams();
      this.httpService.callOccServerGETApi(api, params).subscribe(
        response => {
          const locations =
            response && response.length
              ? response
              : [];
          observer.next({ loading: false, data: locations });
          observer.complete();
        },
        error => {
          this.LogError(error);
          observer.next({ loading: false, data: [] });
          observer.complete();
        }
      );
    });
    return locationObservable;
  }

  getStateCdForCountry(province) {
    const locationObservable = new Observable(observer => {
      observer.next({ loading: true, data: [] });
      const token = this.authService.getOccAccessToken();
      const api = this.apiPathService.getApiPath(provinceStateCdAPI);
      // const api = provinceStateCdApi;
      let params = new HttpParams();
      params = params.append('province', province);
      this.httpService.callOccServerGETApi(api, params).subscribe(
        response => {
          const locations =
            response && response.length
              ? response
              : [];
          observer.next({ loading: false, data: locations });
          observer.complete();
        },
        error => {
          this.LogError(error);
          observer.next({ loading: false, data: [] });
          observer.complete();
        }
      );
    });
    return locationObservable;
  }

  getDistrictForCountry(province) {
    const locationObservable = new Observable(observer => {
      observer.next({ loading: true, data: [] });
      const token = this.authService.getOccAccessToken();
      const api = this.apiPathService.getApiPath(districtAPI);
      // const api = districtApi;
      let params = new HttpParams();
      params = params.append('province', province);
      this.httpService.callOccServerGETApi(api, params).subscribe(
        response => {
          const locations =
            response && response.length
              ? response
              : [];
          observer.next({ loading: false, data: locations });
          observer.complete();
        },
        error => {
          this.LogError(error);
          observer.next({ loading: false, data: [] });
          observer.complete();
        }
      );
    });
    return locationObservable;
  }

  getSubDistrictForCountry(district) {
    const locationObservable = new Observable(observer => {
      observer.next({ loading: true, data: [] });
      const token = this.authService.getOccAccessToken();
      const api = this.apiPathService.getApiPath(subdistrictAPI);
      // const api = subdistrictApi;
      let params = new HttpParams();
      params = params.append('district', district);
      this.httpService.callOccServerGETApi(api, params).subscribe(
        response => {
          const locations =
            response && response.length
              ? response
              : [];
          observer.next({ loading: false, data: locations });
          observer.complete();
        },
        error => {
          this.LogError(error);
          observer.next({ loading: false, data: [] });
          observer.complete();
        }
      );
    });
    return locationObservable;
  }

  getPostalCodeForCountry(subdistrict) {
    const locationObservable = new Observable(observer => {
      observer.next({ loading: true, data: [] });
      const token = this.authService.getOccAccessToken();
      const api = this.apiPathService.getApiPath(postalCodeAPI);
      // const api = postalCodeApi;
      let params = new HttpParams();
      params = params.append('subDistrict', subdistrict);
      this.httpService.callOccServerGETApi(api, params).subscribe(
        response => {
          const locations =
            response
              ? response
              : [];
          observer.next({ loading: false, data: locations });
          observer.complete();
        },
        error => {
          this.LogError(error);
          observer.next({ loading: false, data: [] });
          observer.complete();
        }
      );
    });
    return locationObservable;
  }

  getAllAddresses(req) {
    const api = this.apiPathService.getApiPath(getShippingAddressAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId);

    return this.httpService.callServerGET(api)
      .pipe(map(res => { return res })
      ,catchError(error => {
        this.LogError(error);
        return of(error);
      }));
  }
}
