import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Employee, ValidationError } from '../../shared/models/amway';
import { RegistrationsService, AddressService, ErrorService } from '../../shared/services';
import { AboDetailsValidationService } from '../../abo/abo-details-validation.service';
import * as _ from 'lodash';
import { DropdownOption } from '../../shared/models';
import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { DEFAULT_COUNTRY_SALES_AFF_CODE, DEFAULT_MOBILE_OPTION,
   defaultLanguageCode, defaultCountryCode, TAX_TYPE_CODE } from '../../shared/configs/constants';

@Component({
  selector: 'app-abo-registration-box',
  templateUrl: './abo-registration-box.component.html',
  styleUrls: ['./abo-registration-box.component.scss']
})
export class AboRegistrationBoxComponent implements OnInit {

  @Output() cancel = new EventEmitter();
  employee: Employee = new Employee({});

  errors: ValidationError = {};
  getProvinceOption: DropdownOption[];
  getProvince;
  provincesList$;
  districtList$;
  subDistrictList$;
  listLoaded: boolean = false
  postalCode$;
  provinceStateCd;
  statusOptions;
  empServerErrors;
  showLoader: boolean = false;
  defaultCountryISDCode: string;
  defaultLanguageCode: string = defaultLanguageCode;
  
  constructor(
    private registrationsService: RegistrationsService,
    private aboDetailsValidationService: AboDetailsValidationService,
    private addressService: AddressService,
    private errorService: ErrorService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.provincesList$ = this.addressService
      .getProvinceForCountry().pipe(
      filter((result: any) => !result.loading)
      ,map(res => res.data.map(this.getProvinceDropdownOption)));
    this.defaultCountryISDCode = '+' + DEFAULT_MOBILE_OPTION.text;
    this.statusOptions = [{id: 'Permanent', text: 'Permanent'}, {id: 'Temporary', text: 'Temporary'}]
  }

  cancelPopup(){
    this.cancel.emit();
  }

  getDropdownOption(data): DropdownOption {
    return {
      id: data,
      text: data
    };
  }

  registerEmployee(){
    this.errors = this.aboDetailsValidationService.getEmployeeError(this.employee)
    this.empServerErrors = [];
    this.buildEmployeeParams()
    const empObj = new Employee(this.employee)
    const aff = DEFAULT_COUNTRY_SALES_AFF_CODE
    if (_.isEmpty(this.errors)) {
      this.showLoader = true
      this.registrationsService.registerAccount(empObj, aff)
        .subscribe(res => {
          if (res && res.error){
            let errorObj = JSON.parse(res.error);
            if (errorObj.partyGroupErrorDetail){
              this.errors = this.errorService.parseEmployeeError(errorObj.partyGroupErrorDetail, 'fieldErrorList'); 
            } else {
              let errorMessage = this.errorService.processError(res);
              errorMessage.error = errorMessage.error.replace('IMC', 'Employee')
              this.empServerErrors.push(errorMessage)
            }
            this.cdr.markForCheck();
          } else {
            this.cancelPopup();
          }
          this.showLoader = false
      })
    }
  }

  buildEmployeeParams(){
    this.employee['processTypeCd'] = 'EmployeeRegistration'
    this.employee['cntryCd'] = defaultCountryCode
    this.employee['languageCd'] = defaultLanguageCode
    this.employee['primaryPartyIndex'] = 1
    this.employee['accountSubTypeCd'] = 'Employee'
    this.employee['accountTypeCd'] = 'Customer'
    this.employee['personNameTypeCd'] = 'Legal'
    this.employee['phoneCntryCd'] = (DEFAULT_MOBILE_OPTION.text).toString();
    this.employee['taxTypeCd'] = TAX_TYPE_CODE
    this.employee['aff'] = DEFAULT_COUNTRY_SALES_AFF_CODE
    this.employee['taxPayerType'] = ''
  }

  updateProvince(province) {
    this.employee['province'] = province.id;
    this.removeError('province')
    this.subDistrictList$ = of([])
    this.employee['subDistrictNm'] = '';
    this.employee['postalCode'] = '';
    this.districtList$ = this.addressService
            .getDistrictForCountry(province.id).pipe(
            filter((result: any) => !result.loading)
            ,map(res => res.data.map(this.getDropdownOption)));

    this.employee['stateCd'] = province.id;
  }

  getProvinceDropdownOption(data): DropdownOption {
    return {
      id: data.isocode,
      text: data.name
    };
  }

  updateDistrict(destrict) {
    this.employee['districtNm'] = destrict.id;
    this.removeError('districtNm')
    this.subDistrictList$ = this.addressService
    .getSubDistrictForCountry(destrict.id).pipe(
    filter((result: any) => !result.loading)
    ,map(res => res.data.map(this.getDropdownOption)));
  }

  updateSubDistrict(subDistrict){
    this.employee['subDistrictNm'] = subDistrict.id;
    this.removeError('subDistrictNm')
    this.addressService
          .getPostalCodeForCountry(subDistrict.id).pipe(
          filter((result: any) => !result.loading))
          .subscribe(res => {
            this.employee['postalCd'] = res.data;
            this.removeError('postalCd')
          });
  }

  updateStatus(event) {
    this.employee['status'] = event.id
    this.removeError('status')
  }
  
  changeCurrentDate(event){
    if (this.aboDetailsValidationService.isFutureDate(event)) {
      this.errors['aboEntryDate'] = 'Future Date is not allowed'
      this.employee['aboEntryDate'] = event
      return
    }
    this.employee['aboEntryDate'] = event
    this.removeError('aboEntryDate')
  }

  removeError(key){
    if (key && this.errors[key])
      delete this.errors[key];
  }

  checkErrors(){
    return !_.isEmpty(this.errors)
  }

}
