import { AccountMst, PartyList, Sponsor, BusinessNatureName, SponsorsList } from './amway';
import * as _ from 'lodash';
import { ABO_NAME, CLIENT_NAME, defaultCountryCode, defaultCountryName } from '../configs/constants';
import { getFullName } from '../helpers/common.helper';
import { PlatinumUpline } from './platinum-upline.model';
import { AboAward } from './abo-award.model';

export class ABOBusinessDetails {
  sponsorName: string;
  sponsorNumber: string;
  legalName: string;
  aboNum: string;
  joinDate: string;
  expiryDate: string;
  businessNature: string;
  isCustomer: boolean;
  accountMissingInfoFlg?: boolean;
  countryName?: string

  constructor(accMst: AccountMst, sponsor?: Sponsor, currentParty?: PartyList) {
    if (accMst && !_.isEmpty(accMst)) {
        this.aboNum = accMst.aboNum;
        this.joinDate = accMst.aboEntryDate;
        this.expiryDate = accMst.aboExpireDate;
        this.isCustomer = accMst.accountSubTypeCd === BusinessNatureName.Customer;
        this.businessNature =  this.isCustomer ? CLIENT_NAME : ABO_NAME;
        this.accountMissingInfoFlg = accMst.accountMissingInfoFlg == 'Y';
        this.countryName = accMst.cntryCd === defaultCountryCode ? defaultCountryName : '';
    }

    if (sponsor && ! _.isEmpty(sponsor)) {
      this.sponsorName = sponsor.sponsorName;
      this.sponsorNumber = sponsor.sponsorABONo;
    }
    if (currentParty && ! _.isEmpty(currentParty)) {
        this.legalName = getFullName(currentParty.nameList);
    }
  }
}

export class ABOSponsorsInfo {
  platinumSonsorName: string;
  platinumSonsorAboNum: string;
  diamondSonsorName: string;
  diamondSonsorAboNum: string;

  constructor() {
  }
}

export class AboAwardsInfo {
  currentLevel: string;
  highestLevel: string;
}

export class ABOBusinessInfo {
  platinumSponsor: PlatinumUpline;
  sponsorList?: SponsorsList;
  awards: AboAward;
}
