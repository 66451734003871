import { Actions, SET_MARKET } from './settings.actions';
import { Settings } from '../../shared/models/settings.model';
import { MARKETS } from '../../shared/configs/market.settings';


export function settingsReducer(state: Settings, { type, payload }: Actions): Settings {
  switch (type) {
    case SET_MARKET:
      return Object.assign({}, state, MARKETS[payload]);

    default:
      return state;
  }
}
