export class Country {
  name: string;
  salesAff: number;
  code: string;
  phoneCntryCd: string;
  cntryCd: string;
  constructor({
    salesPlanAffName = '',
    cntryName = '',
    salesPlanAff = 0,
    defaultCntryCd = '',
    cntryCd = '',
    phoneCntryCd = ''
  }) {
    this.name = (salesPlanAffName) ? salesPlanAffName : cntryName;
    this.salesAff = salesPlanAff;
    this.code = (defaultCntryCd) ? defaultCntryCd : cntryCd;
    this.cntryCd = cntryCd;
    this.phoneCntryCd = phoneCntryCd;
  }
}
