import { Action } from '@ngrx/store';

export const COUNTRIES_LOAD = '[Countries] load';
export const COUNTRIES_LOAD_SUCCESS = '[Countries] load success';
export const COUNTRIES_LOAD_FAIL = '[Countries] load fail';
export const ALL_COUNTRIES_LOAD = '[All Countries] load';
export const ALL_COUNTRIES_LOAD_SUCCESS = '[All Countries] load success';
export const ALL_COUNTRIES_LOAD_FAIL = '[All Countries] load fail';

export class CountriesLoad implements Action {
  readonly type = COUNTRIES_LOAD;

  constructor(public payload: any = null) {}
}

export class CountriesLoadSuccess implements Action {
  readonly type = COUNTRIES_LOAD_SUCCESS;

  constructor(public payload: any) {}
}

export class CountriesLoadFail implements Action {
  readonly type = COUNTRIES_LOAD_FAIL;

  constructor(public payload: any = null) {}
}
export class AllCountriesLoad implements Action {
  readonly type = ALL_COUNTRIES_LOAD;

  constructor(public payload: any = null) {}
}

export class AllCountriesLoadSuccess implements Action {
  readonly type = ALL_COUNTRIES_LOAD_SUCCESS;

  constructor(public payload: any) {}
}

export class AllCountriesLoadFail implements Action {
  readonly type = ALL_COUNTRIES_LOAD_FAIL;

  constructor(public payload: any = null) {}
}

export type Actions =
  | CountriesLoad
  | CountriesLoadSuccess
  | CountriesLoadFail
  | AllCountriesLoad
  | AllCountriesLoadSuccess
  | AllCountriesLoadFail;
