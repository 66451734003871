import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AbstractFieldComponent, ReversalType } from '../../../../shared/models/amway/index';
import { Modal } from 'ngx-modal';

@Component({
  templateUrl: './renewal-field.component.html'
})
export class RenewalFieldComponent extends AbstractFieldComponent implements OnInit {

  @Input() params;
  @ViewChild('modal') modal: Modal;
  type = ReversalType.RENEWAL;

  constructor() {
    super();
  }

  ngOnInit() {
    this.modal.open();
  }
}
