export const LABELS = {
  state: {
    default: 'state',
    CA: 'province'
  },
  zipCode: {
    default: 'Zip code',
    CA: 'Postal Code'
  }
};
