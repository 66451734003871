import { HttpParams, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from '../../core/services/index';
import { AccountSearchParam } from '../models/index';
import { ApiPathConfigService } from '../configs/api';
import { salesAff, aboNum, defaultProfileImage, partyId, retrieveImageByUrlAPI,
  updateProfileImageAPI, getABOImageAPI } from './../configs/constants';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class PhotoService {
  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private apiPathService: ApiPathConfigService ) {}

  getABOImageInfo(request: AccountSearchParam) {
    const imageRefObservable: Observable<string> = new Observable(observer => {
      const api = this.apiPathService.getApiPath(getABOImageAPI);
      api.path = api.path
        .replace(salesAff, request.aff)
        .replace(aboNum, request.abo)
        .replace(partyId, request.partyId);
      this.httpService.callServerGET(api, null).subscribe(
        (response: { imageList: [{ imageRefUrl: string }] }) => {
          const imgUrl =
            response && response.imageList && response.imageList.length
              ? response.imageList[0].imageRefUrl
              : '';
          observer.next(imgUrl);
          observer.complete();
        },
        err => {
          observer.error(err);
          observer.complete();
        }
      );
    });
    return imageRefObservable;
  }

  retrieveABOImage(request: AccountSearchParam) {
    const aboImageObservable: Observable<any> = new Observable(observer => {
      this.getABOImageInfo(request).subscribe(
        (url: string) => {
          if (url) {
            const api = this.apiPathService.getApiPath(retrieveImageByUrlAPI);
            api.path = api.path
              .replace(salesAff, request.aff)
              .replace(aboNum, request.abo);
            let urlParams: HttpParams = new HttpParams();
            urlParams = urlParams.set('imageRefUrl', url);
            this.httpService
              .callServerGET(api, urlParams, null, 'blob')
              .subscribe(
                response => {
                  if (response) {
                    observer.next(response);
                  } else {
                    const blob = this.dataURItoBlob(defaultProfileImage);
                    observer.next(blob);
                  }
                  observer.complete();
                },
                err => {
                  const blob = this.dataURItoBlob(defaultProfileImage);
                  observer.next(blob);
                  observer.complete();
                }
              );
          } else {
            const blob = this.dataURItoBlob(defaultProfileImage);
            observer.next(blob);
            observer.complete();
          }
        },
        error => {
          const blob = this.dataURItoBlob(defaultProfileImage);
          observer.next(blob);
        }
      );
    });
    return aboImageObservable;
  }
  dataURItoBlob(dataURI) {
    const mime = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];
    const binary = atob(dataURI.split(',')[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: mime });
  }

  uploadProfileImage(request, file: File) {
    if (file) {
      const api = this.apiPathService.getApiPath(updateProfileImageAPI);
      api.path = api.path
        .replace(salesAff, request.aff)
        .replace(aboNum, request.abo)
        .replace(partyId, request.partyId);
    let urlParams = new HttpParams();
    urlParams = urlParams.set('imageSrcType', 'HybrisImage');
    urlParams = urlParams.set('imageUsage', 'Original');

    const payload = this.fileToFormData(file);
   return this.httpService.callServerPOSTFile(api, urlParams, payload);
    }
    return of({});
  }


  fileToFormData(file: File) {
   const form = new FormData();
   form.append('file' , file, file.name);
   return form;
  }

}
