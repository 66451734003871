import { Action } from '@ngrx/store';

export const CONTRACT_GET = '[Contract] get';
export const CONTRACT_GET_SUCCESS = '[Contract] get success';
export const CONTRACT_GET_FAIL = '[Contract] get fail';

export class ContractGet implements Action {
  readonly type = CONTRACT_GET;

  constructor(public payload: any = null) { }
}

export class ContractGetSuccess implements Action {
  readonly type = CONTRACT_GET_SUCCESS;

  constructor(public payload: any = null) { }
}

export class ContractGetFail implements Action {
  readonly type = CONTRACT_GET_FAIL;

  constructor(public payload: any = null) { }
}

export type Actions =
  | ContractGet
  | ContractGetSuccess
  | ContractGetFail;
