import { Effect, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { ADDRESSES_GET, AddressesGetSuccess, AddressesGetFail } from './addresses.actions';
import { AddressService } from '../../shared/services';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';

@Injectable()

export class AddressesEffects{

  constructor(
    private action$: Actions,
    private addressesService: AddressService
  ){}

  @Effect()
  addressesGet$ = this.action$
    .ofType((ADDRESSES_GET)).pipe(
    switchMap((action: any) => {
      return this.addressesService.getAllAddresses(action.payload)
        .pipe(map((response) => new AddressesGetSuccess(response))
        ,catchError(error => of(new AddressesGetFail())));
    }));
}
