import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { IAppState } from '../../store';
import { Store } from '@ngrx/store';
import { getAboSubscriptions } from '../../shared/helpers/account.helper';
import { AboSubscription, AboSubScriptionDelivery } from '../../shared/models/abo-subscriptions.model';
import { aboSubscriptionsCountry } from '../../shared/configs/constants';
import { SubscriptionService } from '../../shared/services/subscriptions.service';
import { ActivatedRoute } from '@angular/router';
import { Modal } from 'ngx-modal';
import { AccountGet } from '../../store/account/account.actions';
import * as _ from 'lodash';
import * as moment from 'moment';

@Component({
    selector: 'app-abo-subscription',
    templateUrl: './abo-subscriptions.component.html',
    styleUrls: ['./abo-subscriptions.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
  })
  export class AboAccountSubscriptionComponent implements OnInit, OnDestroy {
    @Input() accountDetails;
    partySubscription: AboSubscription[];
    loading: boolean = false;
    selectedSubscription: AboSubscription;
    @ViewChild('subscriptionPopup') subscriptionPopup: Modal;
    storePickupSelected: boolean = false;
    storePickUpData;
    wareHouseList;
    wareHouseData;
    storeReadOnly: boolean = true;
    selectedDelvOption;
    selectedWarehouse;
    selectedStore;
    wareHouseName;
    storeName;
    markDisable: boolean = false;
    subsTypeForAmwayMaz: string;
    currentMonthAmwayMzSub;
    futureAmwayMzSub;

    AMWAY_MAGINE = 'Amway Magazine'
    PICKUP = 'PK'
    EMAIL_CODE = 'AD'
    EM_CODE = 'EM'

  constructor(
    private store: Store<IAppState>,
    private subScriptionService: SubscriptionService,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute) {}

  ngOnInit () {
    this.loading = true
    this.partySubscription = getAboSubscriptions(this.accountDetails, aboSubscriptionsCountry);

    const self = this

    // fetch warehouse list
    this.subScriptionService.fetchWareHouseList().subscribe(function(res:any) {
      self.loading = res.loading
      if (!res.loading){
        self.wareHouseData = res.data
        self.wareHouseList = Object.keys(res.data).map(self.storeDropdownOption) 
      }
      self.cdr.detectChanges()
    })

    // if amway magzine subscribed
    let amwayMagzineSubscribed = this.partySubscription.find(subscription => {
      return (subscription.subscriptionName == this.AMWAY_MAGINE && subscription.subscribeFlag == 'Y')
    });
          
    if (amwayMagzineSubscribed) { this.getClosestEffectiveDateSubs(amwayMagzineSubscribed) }
  }

  getClosestEffectiveDateSubs(subscription){
    let prevDateIndexesByDiff:any = [];
    let nextDateIndexesByDiff: any = [] 
    subscription.subscriptionDeliveryList
      .forEach(subDel => {
        let todayDate:any = new Date();
        let effectiveDate:any = new Date(subDel.effectiveDate);
        let curDiff:any     = todayDate - effectiveDate;
        (curDiff > 0) ?
          prevDateIndexesByDiff.push([subDel, curDiff]) :
          nextDateIndexesByDiff.push([subDel, curDiff]);
      })

    prevDateIndexesByDiff.sort((a:any, b:any) => { 
      if(a[1] < b[1]) {return -1;}
      if(a[1] > b[1]) { return 1;}
      return 0;
    });

    nextDateIndexesByDiff.sort(function(a, b) {
      if(a.diff > b.diff) {return -1;}
      if(a.diff < b.diff) { return 1;}
      return 0;
    });

    if (!_.isEmpty(prevDateIndexesByDiff)){
      this.currentMonthAmwayMzSub = this.filterSubscriptionDeliveryCd(prevDateIndexesByDiff[0][0])
    }

    if (!_.isEmpty(nextDateIndexesByDiff)) {
      let nextMonthDate = moment().add(1, 'months').endOf('month').format()

      if  (new Date(nextDateIndexesByDiff[0][0].effectiveDate) < new Date(nextMonthDate)){
        this.futureAmwayMzSub = this.filterSubscriptionDeliveryCd(nextDateIndexesByDiff[0][0])
      }
    }

  }


  getStoreName(wareHouseCd){
    let storeName:any = ''
    if (wareHouseCd) {
      Object.keys(this.wareHouseData).forEach(warehouse => {
        if (!storeName){
          let store =  this.wareHouseData[warehouse].find(store => {
            return store.wareHouse == wareHouseCd
          })
          storeName = store ? store.storeName : ''
        }
      });
    }
    return storeName
  }

  filterSubscriptionDeliveryCd(subs) {
    if (subs && (subs.deliveryTypeCd == this.EMAIL_CODE || subs.deliveryTypeCd == this.PICKUP)) {
      subs['deliveryCode'] = 'Physical Subscription'
    }

    if (subs && subs.deliveryTypeCd == this.EM_CODE){
      subs['deliveryCode'] = 'E Subscription'
    }

    return subs
  }
  
  ngOnDestroy() {
  }

  updateSubscriptions() {
    this.partySubscription =  this.partySubscription
                .filter(sub => sub.subscriptionName != this.AMWAY_MAGINE)
                .map(sub => {

                      sub.subscriptionDeliveryList =  sub.subscriptionDeliveryList.map(delv => {
                        delv.deliveryChoiceCd = sub.isSubscribed ? 'Y' : 'N';
                        return delv;
                      });
      
      return sub;
    });
    this.updateSubscriptionRequest()
  }
  
  
  updateSubscriptionRequest(){
    this.loading = true
    const params = this.activatedRoute.snapshot.params;
    this.subScriptionService.updateSubscription(params, this.partySubscription)
    .subscribe(data => {
      this.loading = false
      this.store.dispatch(new AccountGet(params));
      this.cdr.detectChanges()
    });
  }

  subscriptionChange(sub){
    this.selectedSubscription = sub;
    this.subscriptionPopup.open();
  }

  checkForEMDeliveryCd(){
    return _.map(this.selectedSubscription.subscriptionDeliveryList, function(o) {
      return o.deliveryChoiceCd == 'Y' && o.deliveryTypeCd == this.EM_CODE
    })
  }
  
  alwaysSubsribeAmwayMagzine(){
    this.partySubscription =  this.partySubscription.map(sub => {
      
      if (sub.subscriptionName == this.AMWAY_MAGINE) {
        sub.isSubscribed = true;
      }
      return sub;
    });

    this.cdr.detectChanges();
  }

  updateWhareHouseInformation(event) {
    this.selectedWarehouse = event.id
    this.storePickUpData = this.wareHouseData[event.id].map(this.storePickUpDataDropdown)
  }

  updateStoreInformation(event) {
    this.selectedStore = event.id
  }

  storeDropdownOption(data){
    return {
      id: data,
      text: data
    }
  }

  storePickUpDataDropdown(data) {
    return {
      id: data.wareHouse,
      text: data.storeName
    }
  }

  cancelPopup(){
    this.alwaysSubsribeAmwayMagzine();
    this.subscriptionPopup.close()
  }

  changeDeliveryCode(event){
    this.selectedDelvOption = event.currentTarget.value
    if (event.currentTarget.value == this.PICKUP) {
      this.storeReadOnly = false
      this.wareHouseList = []
      this.storePickUpData = []
      this.wareHouseList = Object.keys(this.wareHouseData).map(this.storeDropdownOption)
    }
    if (event.currentTarget.value == this.EMAIL_CODE) {
      this.storeReadOnly = true
      this.wareHouseList = []
      this.storePickUpData = []
    }
    this.cdr.detectChanges();
  }

  proceed(){
    this.partySubscription =
      this.partySubscription
            .filter(sub => sub.subscriptionName == this.AMWAY_MAGINE)
            .map(sub => {

              const aboSubscription = new AboSubscription()
              aboSubscription.subscriptionId = sub.subscriptionId
              aboSubscription.subscriptionName = sub.subscriptionName

              const subscriptionDeliveryList = new AboSubScriptionDelivery()
              subscriptionDeliveryList.deliveryChoiceCd = 'Y'
              subscriptionDeliveryList.subscriptionCount = '1'

              if (this.selectedDelvOption == this.PICKUP) {
                subscriptionDeliveryList.deliveryTypeCd = 'PickUpInStore'
                subscriptionDeliveryList.warehouseCd = this.selectedStore
              }

              if (this.selectedDelvOption == this.EMAIL_CODE) {
                subscriptionDeliveryList.deliveryTypeCd = 'PostalMail'
              }

              aboSubscription.subscriptionDeliveryList = [subscriptionDeliveryList]
              return aboSubscription
            });

    this.subscriptionPopup.close();
    this.updateSubscriptionRequest()
  }

}
