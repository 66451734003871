import { ComponentFactoryResolver, Type } from '@angular/core';
import { Store, Action } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { IAppState } from '../../store';
import { Constructable } from '../../shared/models/common.model';
import { ModalService } from '../../shared/services/modal.service';
import { AboDetailActionType } from '../../shared/models/enums/index';
import { AboDetailAction, AbstractFieldComponent } from '../../shared/models/amway/index';

export class AbstractDetailsComponent {

  constructor(
    protected store: Store<IAppState>,
    protected activatedRoute: ActivatedRoute,
    protected modalService: ModalService,
    protected componentFactoryResolver: ComponentFactoryResolver
  ) { }

  /**
   * Fires edit action for detail
   */
  editAction(action: AboDetailAction): void {
    switch (action.type) {
      case AboDetailActionType.Modal:
        this.modalService.createFromFactory(
          this.componentFactoryResolver.resolveComponentFactory(action.action as Type<AbstractFieldComponent>),
          {
            // we are not able to get this params from component generated on-the-fly
            params: this.activatedRoute.snapshot.params
          }
        );
        break;
      case AboDetailActionType.StoreAction:
        const actionClass = action.action as Constructable<Action>;
        this.store.dispatch(new actionClass(this.activatedRoute.snapshot.params));
        break;
    }
  }
}
