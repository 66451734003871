import { AccountDetail } from '../models/amway/account.model';
import { PartyList } from '../models/amway/party-list.model';
import { AccountCard } from '../models/account.model';
import { AR_BALANCE_TYPE_CODE } from '../configs/constants';
import { AccountBalance } from '../models/amway';
import { AboSubscription } from '../models/abo-subscriptions.model';
import * as _ from 'lodash';
export function getAccountCardData(accountDetail: AccountDetail) {
  if (accountDetail && accountDetail.data) {
    return new AccountCard(
      accountDetail.data.accountMst,
      accountDetail.data.currentParty,
      accountDetail.data.coApplicantParty
    );
  }
  return {} as AccountCard;
}

export function getPrimaryParty(partyList: PartyList[]): PartyList {
  if (partyList && partyList.length > 0) {
    const primaryParty = partyList.filter(
      party => party.partyMst && party.partyMst.primaryOnAccount !== '0'
    );
    if (primaryParty && primaryParty.length > 0) {
      primaryParty[0].isPrimary = true;
      return Object.assign({}, primaryParty[0]);
    }
  }
  return {} as PartyList;
}

export function getCoApplicantParty(partyList: PartyList[]): PartyList {
  if (partyList && partyList.length > 0) {
    const coApplicant = partyList.filter(
      party => party.partyMst && party.partyMst.primaryOnAccount !== '1'
    );
    if (coApplicant && coApplicant.length > 0) {
      coApplicant[0].isPrimary = false;
      return Object.assign({}, coApplicant[0]);
    }
  }
  return {} as PartyList;
}

export function getSuccessorParties(partyList: PartyList[]): PartyList[] {
  if (partyList && partyList.length > 0) {
    const successorParties = partyList.filter(
      party => party.partyMst && party.partyMst.roleCd === 'Successor'
    );
    if (successorParties && successorParties.length > 0) {
      return successorParties;
    }
  }
  return {} as PartyList[];
}

export function getCurrentParty(partyList: PartyList[], partyId): PartyList {
  if (partyList && partyList.length > 0 && partyId) {
    const currentParty = partyList.filter(
      party => party.partyMst && party.partyMst.partyId === partyId
    );
    if (currentParty && currentParty.length > 0) {
      currentParty[0].isPrimary =
        currentParty[0].partyMst.primaryOnAccount === '1' ? true : false;
      return Object.assign({}, currentParty[0]);
    }
  }
  return {} as PartyList;
}

export function getArBalance(accountDetail: AccountDetail) {
  if (accountDetail && accountDetail.data) {
    if (
      accountDetail.data.accountBalanceList &&
      accountDetail.data.accountBalanceList.length > 0
    ) {
      const arBalanceInfo = accountDetail.data.accountBalanceList.filter(
        balanceInfo => {
          return balanceInfo.balanceTypeCd === AR_BALANCE_TYPE_CODE;
        }
      );
      if (arBalanceInfo.length > 0) {
        return arBalanceInfo[0];
      }
    }
  }
  return {} as AccountBalance;
}

export function getArOrAmplusBalance(accountDetail: AccountDetail, arBalannceTypeCode) {
  if (accountDetail && accountDetail.data) {
    if (
      accountDetail.data.accountBalanceList &&
      accountDetail.data.accountBalanceList.length > 0
    ) {
      const arBalanceInfo = accountDetail.data.accountBalanceList.filter(
        balanceInfo => {
          return balanceInfo.balanceTypeCd === arBalannceTypeCode;
        }
      );
      if (arBalanceInfo.length > 0) {
        return arBalanceInfo[0];
      }
    }
  }
  return {} as AccountBalance;
}

export function getAboSubscriptions(
  accounrdetail: AccountDetail,
  subscriptionTypes: string[]
) {
  if (accounrdetail && accounrdetail.data) {
    const party = accounrdetail.data.currentParty;
    const prtySubscriptionList = party.subscriptionList;
    if (prtySubscriptionList && prtySubscriptionList.length > 0) {
      const abosubscriptions  = _.cloneDeep(prtySubscriptionList.filter(sub =>
        subscriptionTypes.includes(sub.subscriptionName)
      ));

      return abosubscriptions.map(sub => {
        if (sub.subscriptionName == 'My IBO Business Info') {  sub.subscriptionName = "My ABO Business Info"}
        if (sub.subscriptionName == 'IBOAI Newsletter') {  sub.subscriptionName = "ABO Newsletter"}

        sub.subscriptionDeliveryList.map(subDl => {
          subDl['optOutStartDate'] = ''
          subDl['optOutEndDate'] = ''
          subDl['subscriptionCount'] = subDl['subscriptionCount'] == null ? '0' : subDl['subscriptionCount']
          return subDl
        })
       sub.isSubscribed = sub.subscribeFlag === 'Y' ? true : false;
       return sub;
      });

    }
  }
  return [] as AboSubscription[];
}
