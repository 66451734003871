import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { History } from '../../models/index';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../store/index';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent implements OnInit {
  history$: Observable<History>;
  @Input() labelName;

  constructor(
    private store: Store<IAppState>,
    private translateService: TranslateService
  ) {}
  getTranslatedString(value) {
    if (value) {
      return this.translateService.stream(value);
    } else {
      of('');
    }
  }
  ngOnInit() {
    this.history$ = this.store.select('history');
  }
}
