import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { AuthGuard } from '../core/guards/auth.guard';


@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      { path: 'home', component: HomeComponent, canActivate: [AuthGuard], data: { label: 'Home', parents: [] } },
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class HomeRoutingModule { }
