import * as _ from 'lodash';

import {
  ABO_RESTRICTION_BLOCKS_LOAD,
  ABO_RESTRICTION_BLOCKS_LOAD_SUCCESS,
  ABO_RESTRICTION_BLOCKS_LOAD_ACTIVE,
  ABO_RESTRICTION_BLOCKS_LOAD_ACTIVE_SUCCESS,
  ABO_RESTRICTION_BLOCKS_LOAD_ACTIVE_FAIL,
  ABO_RESTRICTION_BLOCKS_SAVE,
  ABO_RESTRICTION_BLOCKS_SAVE_SUCCESS,
  ABO_RESTRICTION_BLOCKS_SAVE_FAIL,
  ABO_RESTRICTION_BLOCKS_DEACTIVATE_ALL,
  ABO_RESTRICTION_BLOCKS_DEACTIVATE_ALL_FAIL,
  ABO_RESTRICTION_BLOCKS_DEACTIVATE_ALL_SUCCESS,
  Actions
} from './abo-restriction-blocks.actions';

import { CommonService } from '../../core/services/index';
import { RestrictionBlockData, RestrictionsBlocksStore, RestrictionBlocks } from '../../shared/models/amway/index';
import { RestrictionBlockConfigService } from '../../shared/models/amway/restriction-blocks.model';


/**
 * Formats given block to display in appropriate way
 * @param block Block to process
 * @param activeBlocks Blocks to set if current block is Active
 */
function preformatBlock(
  block: RestrictionBlockData | RestrictionBlockConfigService,
  activeBlocks: RestrictionBlockData[] = []
): RestrictionBlockData | RestrictionBlockConfigService {
  return Object.assign({}, block, {
    name: block.blockPrivTypeNm || block.blockPrivTypeId,
    isActive: activeBlocks.length
    && activeBlocks.find((b: RestrictionBlockData) => b.blockPrivTypeId === block.blockPrivTypeId.trim()),
    value: activeBlocks.length
    && activeBlocks.find((b: RestrictionBlockData) => b.blockPrivTypeId === block.blockPrivTypeId.trim()) ? [] : [],
    activatedBlock: activeBlocks.length
      && activeBlocks.find((b: RestrictionBlockData) => b.blockPrivTypeId === block.blockPrivTypeId.trim())
  });
}

function sortBlocks(
  b1: RestrictionBlockData | RestrictionBlockConfigService,
  b2: RestrictionBlockData | RestrictionBlockConfigService
): number {
  return b1.blockPrivTypeNm.trim() > b2.blockPrivTypeNm.trim() ? 1 : -1;
}

function addAboToState(
  state: RestrictionsBlocksStore, userKey: string, userState
): RestrictionsBlocksStore {
  return Object.assign({}, state, _.zipObject(
    [userKey],
    [Object.assign({}, state[userKey], userState)]
  ));
}

export function AboRestrictionBlocksReducer
  (state: RestrictionsBlocksStore, { type, payload }: Actions): RestrictionsBlocksStore {

  let userKey;
  let userState;

  if (payload && payload.params) {
    state = state || {};
    userKey = CommonService.getAboString(payload.params);
    userState = state[userKey] || { data: {} } as RestrictionBlocks;
  }

  switch (type) {

    case ABO_RESTRICTION_BLOCKS_LOAD:
    case ABO_RESTRICTION_BLOCKS_SAVE:
    case ABO_RESTRICTION_BLOCKS_DEACTIVATE_ALL:
      return addAboToState(state, userKey, { loadedAll: false });

    case ABO_RESTRICTION_BLOCKS_LOAD_SUCCESS:
      return addAboToState(state, userKey, {
        loadedAll: true,
        data: Object.assign({}, userState.data, {
          all: payload.data.map(block => preformatBlock(block, userState.data.active)).sort(sortBlocks)
        })
      });

    case ABO_RESTRICTION_BLOCKS_LOAD_ACTIVE:
      return addAboToState(state, userKey, { loadedActive: false });

    case ABO_RESTRICTION_BLOCKS_LOAD_ACTIVE_SUCCESS:
      return addAboToState(state, userKey, {
        loadedActive: true,
        data: Object.assign({}, userState.data, {
          active: payload.data.map(block => preformatBlock(block, payload.data)).sort(sortBlocks)
        })
      });

    case ABO_RESTRICTION_BLOCKS_LOAD_ACTIVE_FAIL:
      return addAboToState(state, userKey, { loadedActive: true });

    case ABO_RESTRICTION_BLOCKS_SAVE_FAIL:
    case ABO_RESTRICTION_BLOCKS_DEACTIVATE_ALL_FAIL:
      return addAboToState(state, userKey, { loadedAll: true });

    case ABO_RESTRICTION_BLOCKS_SAVE_SUCCESS:
    case ABO_RESTRICTION_BLOCKS_DEACTIVATE_ALL_SUCCESS:
      return addAboToState(state, userKey, {
        loadedAll: true,
        data: Object.assign({}, state.data, {
          all: userState.data && userState.data && userState.data.all ? userState.data.all.map(block => preformatBlock(block, payload.data)).sort(sortBlocks) : {},
          active: payload.data.map(block => preformatBlock(block, payload.data)).sort(sortBlocks)
        })
      });

    default:
      return state;
  }
}
