import {
  ABO_BUSINESS_DETAILS_COUNTRY_SAVE,
  ABO_BUSINESS_DETAILS_COUNTRY_SAVE_FAIL,
  ABO_BUSINESS_DETAILS_COUNTRY_SAVE_SUCCESS,
  ABO_BUSINESS_DETAILS_SPA_SAVE,
  ABO_BUSINESS_DETAILS_SPA_SAVE_FAIL,
  ABO_BUSINESS_DETAILS_SPA_SAVE_SUCCESS,
  ABO_BUSINESS_DETAILS_LOAD,
  ABO_BUSINESS_DETAILS_LOAD_SUCCESS,
  ABO_BUSINESS_DETAILS_LOAD_FAIL,
  CUSTOMER_SPONSOR_CHANGE,
  CUSTOMER_SPONSOR_CHANGE_FAIL,
  CUSTOMER_SPONSOR_CHANGE_SUCCESS,
  ABO_BUSINESS_DETAILS_SPA_CONTRACT_CREATE_SUCCESS,
  Actions
} from './abo-business-details.actions';
import { BusinessDetails } from '../../shared/models/amway';
import { ABOBusinessInfo } from '../../shared/models/abo-business-details.model';

export function AboBusinessDetailsReducer(
  state: BusinessDetails,
  { type, payload }: Actions
): BusinessDetails {
  switch (type) {
    case ABO_BUSINESS_DETAILS_LOAD:
      return { loaded: false, saving: {} };

    case ABO_BUSINESS_DETAILS_LOAD_SUCCESS:
      return Object.assign({ loaded: true }, { data: payload });
    case ABO_BUSINESS_DETAILS_LOAD_FAIL:
    return Object.assign({ loaded: true });

    case ABO_BUSINESS_DETAILS_COUNTRY_SAVE:
    case ABO_BUSINESS_DETAILS_SPA_SAVE:
    case CUSTOMER_SPONSOR_CHANGE:
      return Object.assign({}, state, {
        saving: Object.assign({}, state.saving, { [payload.type]: true })
      });

    case ABO_BUSINESS_DETAILS_COUNTRY_SAVE_FAIL:
    case CUSTOMER_SPONSOR_CHANGE_FAIL:
    case ABO_BUSINESS_DETAILS_SPA_SAVE_FAIL:
      return Object.assign({}, state, {
        saving: Object.assign({}, state.saving, { [payload.type]: false })
      });

    case ABO_BUSINESS_DETAILS_SPA_SAVE_SUCCESS:
    case ABO_BUSINESS_DETAILS_SPA_CONTRACT_CREATE_SUCCESS:
      return Object.assign({}, state, { action: type });

    case ABO_BUSINESS_DETAILS_COUNTRY_SAVE_SUCCESS:
    case CUSTOMER_SPONSOR_CHANGE_SUCCESS:
      return Object.assign({}, state, {
        action: type,
        saving: Object.assign({}, state.saving, { [payload.type]: false })
      });

    default:
      return state;
  }
}
