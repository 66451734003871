export class DropdownOption {
  id: string | number;
  text: string;
  code?: string | number;
  constructor({
    id = '',
    text = '',
    code = 0
  }) {
    this.id = id;
    this.text = text;
    this.code = code;
  }
}
