import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import * as moment from 'moment';
import { map, catchError } from 'rxjs/operators';
import { HttpService } from '../../core/services';
import { ApiPathConfigService } from '../configs/api';
import { salesAff, aboNum, contractId, accountContractsUpdateAPI,getABOContractsAPI } from '../configs/constants';
import { AccountSearchParam } from '../models/index';
import { ContractModel } from '../models/amway/index';

@Injectable()
export class BusinessInfoService {

  private dataSource = new BehaviorSubject<any>({});
  data = this.dataSource.asObservable();

  private sponsorNameData = new BehaviorSubject<any>({});
  sponsorName = this.sponsorNameData.asObservable();

  constructor(
    private httpService: HttpService,
    private apiPathService: ApiPathConfigService) {
  }

  updatedDataSelection(data) {
    this.dataSource.next(data);
  }

  updateSponsorName(data) {
    this.sponsorNameData.next(data);
  }

  updateTnCInformation(aff, abo, partyId, contractDetails) {
    const api = this.apiPathService.getApiPath(accountContractsUpdateAPI);
    api.path = api.path.replace(salesAff, aff)
              .replace(aboNum, abo)
              .replace(contractId, contractDetails);
    const TnCPayload = this.updateTnCInformationBodyParams(partyId);
    return this.httpService.callServerPUT(api, null, TnCPayload).pipe(map(response => {      
      return response;
    }));
  }


  updateTnCInformationBodyParams(partyId) {
    const TnCPayload = {
      contract: {
        contractType: "Registration",
        contractSignDate: moment().format(),
        partyId: partyId,
        seqNo: 1,
        acceptanceDate: moment().format(),
        esignSourceChannelId: "Web",
        sourceChannelId: "Web"
      }
    }
    return TnCPayload;
  }


  getContractsListforABO(params: AccountSearchParam) {
    
      const api = this.apiPathService.getApiPath(getABOContractsAPI);
      api.path = api.path.replace(salesAff, params.aff).replace(aboNum, params.abo);
      return this.httpService.callServerGET(api, null)
      .pipe(map(response => {      
        return response;
      }));
  }

}
