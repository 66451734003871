import { Injectable } from '@angular/core';
import { HttpService, ErrorLogService } from '../../core/services/index';
import { ApiPathConfigService } from './../configs/api';
import { salesAff, defaultCountryCode, getAffiliatesListAPI,
  getCountryInfoAPI, getRegionsListAPI, getStatesListAPI,
  getCountriesListAPI, DEFAULT_COUNTRY_SALES_AFF_CODE } from './../configs/constants';
import { Country } from '../models/index';
import { Region } from '../models/amway/index';
import { HttpParams } from '@angular/common/http';
import { ErrorService } from './error.service';
import { Observable } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';

@Injectable()
export class CountryService {
  constructor(
    private httpService: HttpService,
    private errorLogService: ErrorLogService,
    private errorService: ErrorService,
    private apiPathService: ApiPathConfigService
  ) {}

  getAffiliatesList() {
    const api = this.apiPathService.getApiPath(getAffiliatesListAPI);
    api.path = api.path.replace(salesAff, DEFAULT_COUNTRY_SALES_AFF_CODE);
    return this.httpService
      .callServerGET(api, null)
      .pipe(map((response: { affiliateList: [{}] }) => {
        let countriesList: Country[] = [];
        if (response && response.affiliateList) {
          countriesList = response.affiliateList.map(
            affiliate => new Country(affiliate)
          );
        }
        return countriesList;
      })
      ,catchError(error => error));
  }

  getCountryInfo(reqSalesAff) {
    // if (!reqSalesAff) {
    //   reqSalesAff = environment.salesAff;
    // }
    const api = this.apiPathService.getApiPath(getCountryInfoAPI);
    api.path = api.path.replace(salesAff, reqSalesAff);
    return this.httpService
      .callServerGET(api, null)
      .pipe(map(response => {
        let countriesList: Country[] = [];
        if (response && response.countryList) {
          countriesList = response.countryList.map(cntry => new Country(cntry));
        }
        return countriesList;
      })
      ,catchError(error => error)
      ,retry(1));
  }

  getRegionsList(reqSalesAff) {
    const api = this.apiPathService.getApiPath(getRegionsListAPI);
    api.path = api.path.replace(salesAff, reqSalesAff);
    return this.httpService
      .callServerGET(api, null)
      .pipe(map((response: { cntryRegionList: [{ regionList: [{}] }] }) => {
        const regionsList: Region[] = [];
        response.cntryRegionList[0].regionList.forEach(region => {
          regionsList.push(<Region>region);
        });
        return regionsList;
      })
      ,catchError(error => error)
      ,retry(1));
  }

  getStatesList(request): Observable<Region[]> {
    const api = this.apiPathService.getApiPath(getStatesListAPI);
    api.path = api.path.replace(salesAff, request.aff);
    let searchParams = new HttpParams();
    searchParams = searchParams.set('cntryCd', defaultCountryCode);
    return this.httpService.callServerGET(api, searchParams).pipe(map(response => {
      if (response) {
        const cntryList = response.cntryStateList;
        if (cntryList && cntryList.length > 0) {
          const statesList = cntryList[0].stateDetailList;
          if (statesList && statesList.length > 0) {
            return statesList;
          }
        }
      }
      return [] as Region[];
    }));
  }

  getCountriesList() {
    const api = this.apiPathService.getApiPath(getCountriesListAPI);
    // const api = getCountriesList;
    return this.httpService.callServerGET(api).pipe(map(response => {
      if (response) {
        const cntryList = response.countryList;
        if (cntryList && cntryList.length > 0) {
          return cntryList.map(cntry => new Country(cntry));
        }
      }
      return [] as Country[];
    }));
  }
  private LogError(error) {
    const errorMessage = this.errorService.processError(error);
    this.errorLogService.log(errorMessage);
  }
}
