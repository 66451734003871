import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import {
  REGIONS_LOAD,
  RegionsLoad,
  RegionsLoadSuccess,
  RegionsLoadFail,
  STATES_LOAD,
  StatesLoad,
  StatesLoadSuccess,
  StatesLoadFail
} from './regions.actions';
import { CountryService, ErrorService } from '../../shared/services/index';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { ErrorLogService } from '../../core/services/error-log.service';

@Injectable()
export class RegionsEffect {
  constructor(
    private action$: Actions,
    private countryService: CountryService,
    private errorLogService: ErrorLogService,
    private errorService: ErrorService
  ) {
  }

  @Effect()
  loadRegion$ = this.action$
    .ofType(REGIONS_LOAD)
    .pipe(map((action: RegionsLoad) => action.payload)
    ,switchMap((action) => {
      return this.countryService.getRegionsList(action)
        .pipe(map((response) => new RegionsLoadSuccess(response))
        ,catchError(error => of(new RegionsLoadFail())));
    }));

  @Effect()
  loadStates$ = this.action$
    .ofType(STATES_LOAD)
    .pipe(map((action: StatesLoad) => action.payload)
    ,switchMap((action) => {
      return this.countryService.getStatesList(action)
        .pipe(map((response) => {
          return new StatesLoadSuccess(response);
        })
        ,catchError(error => {
          this.LogError(error);
          return of(new StatesLoadFail());
        }));
    }));

  private LogError(error) {
    const errorMessage = this.errorService.processError(error);
    this.errorLogService.log(errorMessage);
  }
}
