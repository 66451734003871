import { Action } from '@ngrx/store';

export const PHOTO_LOAD = '[Photo] load';
export const PHOTO_LOAD_SUCCESS = '[Photo] load success';
export const PHOTO_LOAD_FAIL = '[Photo] load fail';
export const PHOTO_CHANGE = '[Photo] change';
export const PHOTO_CHANGE_SUCCESS = '[Photo] change success';
export const PHOTO_CHANGE_FAIL = '[Photo] change fail';
export class PhotoLoad implements Action {
  readonly type = PHOTO_LOAD;

  constructor(public payload: any = null) { }
}

export class PhotoLoadSuccess implements Action {
  readonly type = PHOTO_LOAD_SUCCESS;

  constructor(public payload: any) { }
}

export class PhotoLoadFail implements Action {
  readonly type = PHOTO_LOAD_FAIL;

  constructor(public payload: any = null) { }
}
export class PhotoChange implements Action {
  readonly type = PHOTO_CHANGE;

  constructor(public payload: any = null) { }
}

export class PhotoChangeSuccess implements Action {
  readonly type = PHOTO_CHANGE_SUCCESS;

  constructor(public payload: any) { }
}

export class PhotoChangeFail implements Action {
  readonly type = PHOTO_CHANGE_FAIL;

  constructor(public payload: any = null) { }
}
export type Actions =
  | PhotoLoad
  | PhotoLoadSuccess
  | PhotoLoadFail
  | PhotoChange
  | PhotoChangeFail
  | PhotoChangeSuccess;
