import { config } from '../../../config';
import { AboDetail } from '../../shared/models/amway/index';
import { AboDetailNature, AboDetailActionType, AboDetailValueType } from '../../shared/models/enums/index';
import { CommonService } from '../../core/services/index';
import { DeactivateFieldComponent } from './fields/deactivate/deactivate-field.component';
import { RenewalFieldComponent } from './fields/renewal/renewal-field.component';
import { ReactivateFieldComponent } from './fields/reactivate/reactivate-field.component';

export const ACTIONS_LIST: AboDetail[] = [
  {
    name: 'Resignation',
    keys: ['deactivate'],
    nature: AboDetailNature.ABO,
    action: {
      type: AboDetailActionType.Modal,
      action: DeactivateFieldComponent,
    },
    isDisabledForInactive: true,
    isButton: true,
    editLinkTitle: 'Resign'
  }, {
    name: 'Reactivation',
    keys: ['reactivate'],
    nature: AboDetailNature.ABO,
    action: {
      type: AboDetailActionType.Modal,
      action: ReactivateFieldComponent,
    },
    isDisabledForActive: true,
    isButton: true,
    editLinkTitle: 'Reactivate'
  }, {
    name: 'Add a Party',
    keys: ['refundRenewal'],
    nature: AboDetailNature.ABO,
    action: {
      type: AboDetailActionType.Modal,
      action: RenewalFieldComponent,
    },
    isDisabledForInactive: true,
    isButton: true,
    editLinkTitle: 'Add'
  }, {
    name: 'Delete a Party',
    keys: ['refundRenewal'],
    nature: AboDetailNature.ABO,
    action: {
      type: AboDetailActionType.Modal,
      action: RenewalFieldComponent,
    },
    isDisabledForInactive: true,
    isButton: true,
    editLinkTitle: 'Delete'
  }, {
    name: 'Auto Renewal',
    keys: ['refundRenewal'],
    nature: AboDetailNature.ABO,
    action: {
      type: AboDetailActionType.Modal,
      action: RenewalFieldComponent,
    },
    isDisabledForInactive: true,
    isButton: true
  }, {
    name: 'LEADS Enrollment',
    keys: ['refundRenewal'],
    nature: AboDetailNature.ABO,
    action: {
      type: AboDetailActionType.Modal,
      action: RenewalFieldComponent,
    },
    isDisabledForInactive: true,
    isButton: true
  } 
];
