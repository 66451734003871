import { Component, ChangeDetectionStrategy, Input, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { AddressList } from '../../../shared/models/party-basic.models';
import { Region } from '../../../shared/models/amway/region.model';
import {Store} from '@ngrx/store';
import { IAppState } from '../../../store';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AddressService } from '../../../shared/services';
import { Modal } from 'ngx-modal';
import { FormGroup, FormControl, Validators } from '@angular/forms';
@Component({
  selector: 'app-abo-address',
  templateUrl: './abo-address.component.html',
  styleUrls: ['./abo-address.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboAddressComponent implements OnInit {

  @Input() addresses: AddressList[];
  @ViewChild('addAddressModal') addAddressModal: Modal

  addressList: AddressList[];
  loading: boolean = false;
  states: Region[] = [] as Region[];
  provincesList;
  addressForm: FormGroup;
    constructor(private store: Store<IAppState>,
      private addressService: AddressService,
      private cdr: ChangeDetectorRef    ) {}
  ngOnInit() {
    this.addressList = _.cloneDeep(this.addresses);
    this.addressService
      .getProvinceForCountry()
      .pipe(filter((result: any) => !result.loading))
      .subscribe((res:any) => {
        this.addressList = this.addressList.map((adr) => {
          if (adr.stateCd) {
            adr.stateName = res.data.find(st => st.isocode === adr.stateCd) ?
            res.data.find(st => st.isocode === adr.stateCd).name : '';
          }
          return adr;
        });
        this.loading = true
        this.cdr.detectChanges();
      });
      
    }

}
