import { Component, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../store/index';
import { ClearNotification } from '../../../store/notification/notification.actions';

const MESSAGE_TTL = 4000;

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationComponent implements OnInit, OnDestroy {

  notification$: Observable<{ message: string }>;
  sub: Subscription;

  constructor(
    private store: Store<IAppState>
  ) { }

  ngOnInit() {
    this.notification$ = this.store.select('notification');
    this.sub = this.notification$
      .pipe(filter(Boolean)
      ,delay(MESSAGE_TTL))
      .subscribe(_ => this.clearNotification());

  }

  clearNotification() {
    this.store.dispatch(new ClearNotification());
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
