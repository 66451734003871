import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import {
  DEACTIVATION_GET_REASONS, DeactivateGetReasons,
  DeactivateGetReasonsSuccess, DeactivateGetReasonsFail, DEACTIVATION_POST, DeactivatePost, DeactivatePostSuccess, DeactivatePostFail
} from './deactivation.actions';
import { DeactivationService } from '../../shared/services/index';

@Injectable()
export class DeactivationEffects {

  constructor(
    private actions$: Actions,
    private deactivationService: DeactivationService,
  ) {
  }

  @Effect()
  ResonsGet$ = this.actions$
    .ofType(DEACTIVATION_GET_REASONS).pipe(
    switchMap((action: DeactivateGetReasons) => {
      return this.deactivationService.getDeactivationReasons()
        .pipe(map((response) => new DeactivateGetReasonsSuccess({ reasons: response }))
        ,catchError(error => of(new DeactivateGetReasonsFail())));
    }));

  @Effect()
  DeactivationPost$ = this.actions$
    .ofType(DEACTIVATION_POST).pipe(
     switchMap((action: DeactivatePost) => {
      return this.deactivationService.deactivateAccount(action.payload)
        .pipe(map((response) => new DeactivatePostSuccess({ response }))
        ,catchError(error => of(new DeactivatePostFail())));
    }));

}
