import { Injectable } from '@angular/core';
import { map, retry, catchError } from 'rxjs/operators';
import { HttpService } from '../../core/services/index';
import { ApiPathConfigService } from '../configs/api';
import { salesAff, getLanguageInfoAPI } from './../configs/constants';

@Injectable()
export class LanguageService {

  constructor(
    private httpService: HttpService,
    private apiPathService: ApiPathConfigService
  ) {
  }

  getLanguageInfo(reqAff) {
    const api = this.apiPathService.getApiPath(getLanguageInfoAPI);
    api.path = api.path.replace(salesAff, reqAff);
    return this.httpService.callServerGET(api, null)
      .pipe(map((response: { langPrefList: [{}] }) => {
        return response.langPrefList;
      }),catchError((error) => error)
      ,retry(1));
  }

}
