import { environment } from '../../../environments/environment';
import { Injectable, Inject } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { MasheryToken, LoginConfig, Token, OktaJwtToken } from '../../shared/models/index';
import {
  StorageService,
  HttpService,
  AuthService
} from '../../core/services/index';
import { loginConfig } from '../login.config';
import { User } from '../../shared/models/index';

@Injectable()
export class LoginService {
  constructor(
    private storage: StorageService,
    private httpService: HttpService,
    private authService: AuthService,
    @Inject(loginConfig) private config: LoginConfig
  ) {}

  getUserData() {
    const tokenDetails = <MasheryToken>(
      this.storage.getItem(this.config.masheryTokenKey)
    );
    return tokenDetails ? new User({ name: tokenDetails.user_context }) : null;
  }

  storeMasheryAuthCode(token: MasheryToken) {
    this.storage.setItem(this.config.masheryTokenKey, token);
    this.authService.retrieveMasheryAccessTokenViaAuthCode();
  }

  storeOccAuthToken(token: Token) {
    this.storage.setItem(this.config.occTokenKey, token);
  }

  storeOktaJwtToken(token: OktaJwtToken) {
    this.storage.setItem('okta-jwt-token', token);
  }

}
