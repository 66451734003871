import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from '@angular/core';
import {
  IDatePickerConfig,
  IDay,
  SingleCalendarValue
} from 'ng2-date-picker';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DatePickerComponent {
  @Input() model: SingleCalendarValue;
  @Input() placeholder: string;

  private _config;
  @Input() set config(cfg: IDatePickerConfig) {
    if (!cfg) {
      return;
    }

    const { drops = 'down' } = cfg;
    this._config = {
      showGoToCurrent: false,
      ...cfg,
      drops,
    };
  };

  get config(): IDatePickerConfig {
    return this._config;
  }

  @Output() keyup = new EventEmitter<KeyboardEvent>();
  @Output() selected = new EventEmitter<IDay>();
  @Output() dateEntered = new EventEmitter();

  /**
   * @param {KeyboardEvent} event
   */
  onKeyup(event: KeyboardEvent): void {
    this.keyup.emit(event);
  }

  /**
   * @param {IDay} event
   */
  onSelect(event: any): void {
    if (!event) {
      return;
    }
    this.selected.emit(event._d);
  }

  onBlur(event) {
    this.dateEntered.emit(event.target.value);
  }

}
