import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

const appRouter: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }
];

/**
 * @module AppRoutingModule
 * This module includes all the application root(first level) routes
 * @exports RouterModule Router module is exported bye this so no need to include Router module in the App module.
 */
@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRouter)
  ],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule { }
