import { Injectable } from '@angular/core';
import { HttpService } from '../../core/services';
import { ApiPathConfigService } from '../configs/api';
import { AboAward } from '../models/abo-award.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { getAwardInfoAPI } from '../configs/constants';

@Injectable()
export class AboAwardsService {
  constructor(
    private httpService: HttpService,
    private apiPathService: ApiPathConfigService
    ) {}

  getCurrentHighestAwards(request: any, period) {
    if (period) {
      const api = this.apiPathService.getApiPath(getAwardInfoAPI);
      api.path =
      api.path +
        `?affAbo=${request.aff},${request.abo}&period=${period}`;
      return this.httpService.callServerGET(api, null, null).pipe(map(response => {
        if (response) {
          if (
            response.periodAwardsInfo &&
            response.periodAwardsInfo.length > 0
          ) {
            const periodAwardInfo = response.periodAwardsInfo[0];
            if (
              periodAwardInfo.aboAwardsInfo &&
              periodAwardInfo.aboAwardsInfo.length > 0
            ) {
              return periodAwardInfo.aboAwardsInfo[0];
            }
          }
        }
        return {} as AboAward;
      }));
    }
    return of({} as AboAward);
  }
}
