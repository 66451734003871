import { Injectable } from '@angular/core';
import { URLSearchParams, RequestOptions, Headers, ResponseContentType } from '@angular/http';
import * as moment from 'moment/moment';
import * as _ from 'lodash';

import { PartyParams } from '../models/search-party.model';
// import { LABELS } from '../../settings/labels.settings';
import { PhoneInfo } from '../models/common.model';
import { FRN_TAX_TYPE } from '../../abo/abo-settings';
import { IBOBasicInfo, IboDetail } from '../models/ibo-details.model';

const ENTER_KEY = 'Enter';

@Injectable()
export class CommonService {

  /**
   * Formats IBO params to unique string
   * @param  {PartyParams} params IBO params
   * @return {string}             String with ABO/AFF numbers
   */
  static getIboString(params: PartyParams): string {
    return `u${params.aff}_${params.abo}`;
  }

  createRequestParams(search: Object, headers?: Headers, responseType?: ResponseContentType) {
    const searchParams = new URLSearchParams();

    Object.keys(search).forEach(key => searchParams.set(key, search[key]));

    return new RequestOptions({
      headers: headers || new Headers(),
      search: searchParams,
      responseType
    });
  }

  /**
   * Gets a search value by specified key
   * @param {string} queryKey
   * @returns {string}
   * @memberof CommonService
   */
  getQueryVal(queryKey: string): string {
    const query = window.location.search.substring(1);
    return query.split('&').reduce((memo, iter) => {
      const pair = iter.split('=');
      const lookupPair = decodeURIComponent(pair[0]) === queryKey;
      memo += lookupPair ? decodeURIComponent(pair[1]) : '';
      return memo;
    }, '');
  }

  // /**
  //  * Gets proper label by country code
  //  * @param {string} type
  //  * @param {string} cntryCd
  //  * @returns {string}
  //  * @memberOf CommonService
  //  */
  // getLabel(type: string, cntryCd: string): string {
  //   return _.get(LABELS, [type, cntryCd], _.get(LABELS, [type, 'default'], `{{${type}}}`));
  // }

  /**
   * Gets utc date in appropriate date format
   * @param {string} date
   * @param {string} format
   * @returns {string}
   * @memberof CommonService
   */
  getFormattedDate(date: string, format: string): string {
    return date ? moment.utc(date).format(format) : '';
  }

  /**
   * Gets difference between 2 dates
   * @param  {Date} date1
   * @param  {Date} date2
   */
  getDaysDiff(date1, date2) {
    const dayInMs = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());

    return Math.ceil(timeDiff / dayInMs);
  }

  /**
   * Gets engaged phone
   * @param {PhoneInfo} phone
   * @returns {string}
   * @memberOf CommonService
   */
  getEngagedPhone({phoneAreaCd, phoneCntryCd, phoneLocalNum}: PhoneInfo): string {
    return (phoneCntryCd ? `+${phoneCntryCd} ` : '') +
           (phoneAreaCd ? `(${phoneAreaCd}) ` : '') +
           (phoneLocalNum ? phoneLocalNum : '');
  }

  /**
   * checks if pressed key is enter
   * @param {KeyboardEvent} event
   * @returns {boolean}
   */
  isEnter(event: KeyboardEvent): boolean {
    return event.key === ENTER_KEY;
  }

  /**
   * currency formatter
   * @param {number|string} value
   * @param {string} code
   * @returns {string}
   * @memberof CommonService
   */
  getFormattedPrice(value: number|string, code: string): string { // TODO: make currency formatter
    return `$${value}`;
  }

  /**
   * returns tax id for displaying depending on tax id type
   * @param {IBOBasicInfo} info
   * @returns {string}
   */
  getDisplayedTaxId(info: IBOBasicInfo): string {
    const taxTypeCd = _.get(info, 'tax.taxTypeCd', '');
    const taxId = _.get(info, 'tax.taxId', '');
    return taxTypeCd === FRN_TAX_TYPE ? '' : taxId;
  }

  /**
   * updates option in order to add query params to MDMS url
   * @param {IboDetail} option
   * @param {string} aff
   * @param {string} abo
   * @returns {IboDetail}
   */
  getIboDetailOptionWithUpdatedLink(option: IboDetail, aff: string, abo: string): IboDetail {
    return {
      ...option,
      action: {
        ...option.action,
        action: this.getMDMSUrlWithQueryParams(option, aff, abo),
      }
    };
  }

  /**
   * @param  {string} type
   * @returns string
   */
  createSearchforceEventTypeLabel(type: string): string {
    return type.match(/[A-Z][a-z]+/g).join(' ');
  }

  /**
   * adds query params to MDMS url
   * @param {IboDetail} option
   * @param {string} aff
   * @param {string} abo
   * @returns {string}
   */
  private getMDMSUrlWithQueryParams(option: IboDetail, aff: string, abo: string): string {
    const baseUrl = option.action.action;
    return `${baseUrl}?selectedAffLeftContainer=${aff}&selectedIboNumber=${abo}`;
  }
}
