import { DropdownOption } from '../models';
import { Bank } from '../models/bank-list.model';
import { BankDetail } from '../models/bank-detail.model';

export function getBankOptions(bankList: Bank[]): DropdownOption[] {
  if (bankList && bankList.length > 0) {
    const bankOptions = bankList.map(bank => {
      return {
        id: bank.bankId,
        text: bank.bankName
      };
    });
    return bankOptions;
  }

  return [] as DropdownOption[];
}

export function getAccountTypesList(accountTypesList: Array<any>){
  if(accountTypesList && accountTypesList.length > 0){
    const accountTypeOptions = accountTypesList.map(account =>{
        return {
          id: account.AccCode,
          text: account.AccText
        }
    });
    return accountTypeOptions;
  }
}

export function getBankAccountStatusTypes(accountStatusTypes: Array<any>) {
  if(accountStatusTypes && accountStatusTypes.length > 0){
    const accountTypeOptions = accountStatusTypes.map(account =>{
        return {
          id: account.StatusCode,
          text: account.text
        }
    });
    return accountTypeOptions;
  }
}

export function setCurrentBankAccountStatusOption(bankdetail: BankDetail): DropdownOption {
  if (bankdetail) {
    return {
      id: bankdetail.bankAccStatus,
      text: bankdetail.bankAccStatusText
    };
  }
  return {
    id: '',
    text: ''
  } as DropdownOption;
}

export function setCurrentBankAccountTypeOption(bankdetail: BankDetail): DropdownOption {
  if (bankdetail) {
    return {
      id: bankdetail.bankAcctTypeCd,
      text: bankdetail.bankAcctTypeName
    };
  }
  return {
    id: '',
    text: ''
  } as DropdownOption;
}
export function setCurrentBankOption(bankdetail: BankDetail): DropdownOption {
  if (bankdetail) {
    return {
      id: bankdetail.issuingBankId,
      text: bankdetail.issuingBankName
    };
  }
  return {
    id: '',
    text: ''
  } as DropdownOption;
}
export function isBankDetailChanged(
  currentValue: BankDetail,
  initialValue: BankDetail
): boolean {
  if (!currentValue) {
    currentValue = {} as BankDetail;
  }
  if (!initialValue) {
    initialValue = {} as BankDetail;
  }
  return ! (
    currentValue.issuingBankId === initialValue.issuingBankId &&
    currentValue.issuingBankName === initialValue.issuingBankName &&
    currentValue.bankAcctNum === initialValue.bankAcctNum &&
    currentValue.bankBranchName === initialValue.bankBranchName &&
    currentValue.bankBranchCode === initialValue.bankBranchCode &&
    currentValue.bankAcctHolderName === initialValue.bankAcctHolderName &&
    currentValue.bankAcctTypeCd === initialValue.bankAcctTypeCd &&
    currentValue.bankAccStatus === initialValue.bankAccStatus
  );
}
