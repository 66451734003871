import {
  Actions,
  REGIONS_LOAD,
  REGIONS_LOAD_FAIL,
  REGIONS_LOAD_SUCCESS,
  STATES_LOAD,
  STATES_LOAD_FAIL,
  STATES_LOAD_SUCCESS
} from './regions.actions';
import { Region, RegionResponse } from '../../shared/models/amway/index';

export function regionsReducer(state: RegionResponse, action: Actions): RegionResponse {
  switch (action.type) {
    case REGIONS_LOAD:
    return Object.assign({}, state, {regionsLoading: true}, {regions: []});
    case REGIONS_LOAD_FAIL:
    return Object.assign({}, state, {regionsLoading: false}, {regions: []});
    case REGIONS_LOAD_SUCCESS:
    return Object.assign({}, state, {regionsLoading: false}, {regions: action.payload});
    case STATES_LOAD:
    return Object.assign({}, state, {statesLoading: true}, {states: []});
    case STATES_LOAD_FAIL:
    return Object.assign({}, state, {statesLoading: false}, {states: []});
    case STATES_LOAD_SUCCESS:
    return Object.assign({}, state, {statesLoading: false}, {states: action.payload});
    default:
      return state;
  }
}
