import { Action } from '@ngrx/store'

export const ADDRESSES_GET = '[Account] get addresses related to party';
export const ADDRESSES_GET_SUCCESS = '[Account] get addresses related to party success';
export const ADDRESSES_GET_FAIL = '[Account] get addresses related to party fail';

export class AddressesGet implements Action {
  readonly type = ADDRESSES_GET;
  constructor(public payload: any = null) { }
}

export class AddressesGetSuccess implements Action {
  readonly type = ADDRESSES_GET_SUCCESS;
  constructor(public payload: any = null) { }
}

export class AddressesGetFail implements Action {
  readonly type = ADDRESSES_GET_FAIL;
  constructor(public payload: any = null) { }
}

export type Actions = 
        | AddressesGet
        | AddressesGetSuccess
        | AddressesGetFail