import { MasheryRedirectComponent } from './redirect/mashery/mashery-redirect.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './landing-page/landing-page.component';
import { OccRedirectComponent } from './redirect/occ/occ-redirect.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      { path: 'login', component: LoginComponent },
      { path: 'apigateway/authresponse', component: MasheryRedirectComponent },
      { path: 'apigateway/authresponse/occ', component: OccRedirectComponent }
    ])
  ],
  exports: [RouterModule]
})
export class LoginRoutingModule {}
