import {
  NgModule,
  Component,
  ViewChild,
  ElementRef,
  AfterViewChecked,
  AfterContentInit,
  DoCheck,
  Input,
  Output,
  EventEmitter,
  Renderer2,
  forwardRef,
  ChangeDetectorRef,
  IterableDiffers,
  OnDestroy
} from '@angular/core';
import {
  trigger,
  state,
  style,
  transition,
  animate,
  AnimationEvent
} from '@angular/animations';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { DomHandler } from '../../helpers/dom-handler';
import { ObjectUtils } from '../../helpers/object-utils';

export const AUTOCOMPLETE_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => AutoCompleteComponent),
  multi: true
};

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  animations: [
    trigger('overlayAnimation', [
      state(
        'void',
        style({
          transform: 'translateY(5%)',
          opacity: 0
        })
      ),
      state(
        'visible',
        style({
          transform: 'translateY(0)',
          opacity: 1
        })
      ),
      transition('void => visible', animate('225ms ease-out')),
      transition('visible => void', animate('195ms ease-in'))
    ])
  ],
  host: {
    '[class.ui-inputwrapper-filled]': 'filled',
    '[class.ui-inputwrapper-focus]': 'focus && !disabled'
  },
  providers: [DomHandler, ObjectUtils, AUTOCOMPLETE_VALUE_ACCESSOR]
})
export class AutoCompleteComponent
  implements
    AfterViewChecked,
    AfterContentInit,
    DoCheck,
    ControlValueAccessor,
    OnDestroy {
  timeout: any;

  overlayVisible = false;

  documentClickListener: any;

  suggestionsUpdated: boolean;

  highlightOption: any;

  highlightOptionChanged: boolean;

  focus = false;

  filled: boolean;

  inputClick: boolean;

  inputKeyDown: boolean;

  noResults: boolean;

  differ: any;

  inputFieldValue: string = null;

  loading: boolean;
  @Input()
  minLength = 1;

  @Input()
  delay = 300;

  @Input()
  style: any;

  @Input()
  styleClass: string;

  @Input()
  inputStyle: any;

  @Input()
  inputId: string;

  @Input()
  inputStyleClass: string;

  @Input()
  placeholder: string;

  @Input()
  readonly: boolean;

  @Input()
  disabled: boolean;

  @Input()
  maxlength: number;

  @Input()
  required: boolean;

  @Input()
  size: number;

  @Input()
  appendTo: any;

  @Input()
  autoHighlight: boolean;

  @Input()
  forceSelection: boolean;

  @Input()
  type = 'text';

  @Input()
  autoZIndex = true;

  @Input()
  baseZIndex = 0;

  @Output()
  completeMethod: EventEmitter<any> = new EventEmitter();

  @Output()
  onSelect: EventEmitter<any> = new EventEmitter();

  @Output()
  onUnselect: EventEmitter<any> = new EventEmitter();

  @Output()
  onFocus: EventEmitter<any> = new EventEmitter();

  @Output()
  onBlur: EventEmitter<any> = new EventEmitter();

  @Output()
  onDropdownClick: EventEmitter<any> = new EventEmitter();

  @Output()
  onClear: EventEmitter<any> = new EventEmitter();

  @Output()
  onKeyUp: EventEmitter<any> = new EventEmitter();

  @Input()
  field: string;

  @Input()
  scrollHeight = '200px';

  @Input()
  dropdown: boolean;

  @Input()
  dropdownMode = 'blank';

  @Input()
  multiple: boolean;

  @Input()
  tabindex: number;

  @Input()
  dataKey: string;

  @Input()
  emptyMessage: string;

  @Input()
  immutable = true;

  @ViewChild('in')
  inputEL: ElementRef;

  overlay: HTMLDivElement;

  value: any;

  _suggestions: any[];

  onModelChange: Function = () => {};

  onModelTouched: Function = () => {};

  constructor(
    public el: ElementRef,
    public domHandler: DomHandler,
    public renderer: Renderer2,
    public objectUtils: ObjectUtils,
    public cd: ChangeDetectorRef,
    public differs: IterableDiffers
  ) {
    this.differ = differs.find([]).create(null);
  }

  @Input()
  get suggestions(): any[] {
    return this._suggestions;
  }

  set suggestions(val: any[]) {
    this._suggestions = val;

    if (this.immutable) {
      this.handleSuggestionsChange();
    }
  }

  ngDoCheck() {
    if (!this.immutable) {
      const changes = this.differ.diff(this.suggestions);

      if (changes) {
        this.handleSuggestionsChange();
      }
    }
  }

  ngAfterViewChecked() {
    // Use timeouts as since Angular 4.2, AfterViewChecked is broken and not called after panel is updated
    if (this.suggestionsUpdated && this.overlay && this.overlay.offsetParent) {
      setTimeout(() => this.alignOverlay(), 1);
      this.suggestionsUpdated = false;
    }

    if (this.highlightOptionChanged) {
      setTimeout(() => {
        const listItem = this.domHandler.findSingle(
          this.overlay,
          'li.ui-state-highlight'
        );
        if (listItem) {
          this.domHandler.scrollInView(this.overlay, listItem);
        }
      }, 1);
      this.highlightOptionChanged = false;
    }
  }

  handleSuggestionsChange() {
    if (this._suggestions != null && this.loading) {
      this.highlightOption = null;
      if (this._suggestions.length) {
        this.noResults = false;
        this.show();
        this.suggestionsUpdated = true;

        if (this.autoHighlight) {
          this.highlightOption = this._suggestions[0];
        }
      } else {
        this.noResults = true;

        if (this.emptyMessage) {
          this.show();
          this.suggestionsUpdated = true;
        } else {
          this.hide();
        }
      }

      this.loading = false;
    }
  }

  ngAfterContentInit() {}

  writeValue(value: any): void {
    this.value = value;
    this.filled = this.value && this.value !== '';
    this.updateInputField();
  }

  registerOnChange(fn: Function): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onModelTouched = fn;
  }

  setDisabledState(val: boolean): void {
    this.disabled = val;
  }

  onInput(event: Event) {
    if (!this.inputKeyDown) {
      return;
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    const value = (<HTMLInputElement>event.target).value;
    if (!this.multiple && !this.forceSelection) {
      this.onModelChange(value);
    }

    if (value.length === 0) {
      this.hide();
      this.onClear.emit(event);
    }

    if (value.length >= this.minLength) {
      this.timeout = setTimeout(() => {
        this.search(event, value);
      }, this.delay);
    } else {
      this.suggestions = null;
      this.hide();
    }
    this.updateFilledState();
    this.inputKeyDown = false;
  }

  onInputClick(event: MouseEvent) {
    if (this.documentClickListener) {
      this.inputClick = true;
    }
  }

  search(event: any, query: string) {
    // allow empty string but not undefined or null
    if (query === undefined || query === null) {
      return;
    }

    this.loading = true;

    this.completeMethod.emit({
      originalEvent: event,
      query: query
    });
  }

  selectItem(option: any, focus: boolean = true) {
    this.inputEL.nativeElement.value = this.field
      ? this.objectUtils.resolveFieldData(option, this.field) || ''
      : option;
    this.value = option;
    this.onModelChange(this.value);

    this.onSelect.emit(option);
    this.updateFilledState();

    if (focus) {
      this.focusInput();
    }
    this.overlayVisible = false;
  }

  show() {
    if (this.inputEL) {
      const hasFocus = document.activeElement === this.inputEL.nativeElement;

      if (!this.overlayVisible && hasFocus) {
        this.overlayVisible = true;
      }
    }
  }

  onOverlayAnimationStart(event: AnimationEvent) {
    switch (event.toState) {
      case 'visible':
        this.overlay = event.element;
        this.appendOverlay();
        if (this.autoZIndex) {
          this.overlay.style.zIndex = String(
            this.baseZIndex + ++DomHandler.zindex
          );
        }
        this.alignOverlay();
        this.bindDocumentClickListener();
        break;

      case 'void':
        this.onOverlayHide();
        break;
    }
  }

  onOverlayAnimationDone(event: AnimationEvent) {
    if (event.toState === 'void') {
      this._suggestions = null;
    }
  }

  appendOverlay() {
    if (this.appendTo) {
      if (this.appendTo === 'body') {
        document.body.appendChild(this.overlay);
      } else {
        this.domHandler.appendChild(this.overlay, this.appendTo);
      }
      this.overlay.style.minWidth =
        this.domHandler.getWidth(this.el.nativeElement.children[0]) + 'px';
    }
  }

  restoreOverlayAppend() {
    if (this.overlay && this.appendTo) {
      this.el.nativeElement.appendChild(this.overlay);
    }
  }

  alignOverlay() {
    if (this.appendTo) {
      this.domHandler.absolutePosition(
        this.overlay,
        this.inputEL.nativeElement
      );
    } else {
      this.domHandler.relativePosition(
        this.overlay,
        this.inputEL.nativeElement
      );
    }
  }

  hide() {
    this.overlayVisible = false;
  }

  handleDropdownClick(event) {
    this.focusInput();
    const queryValue = this.inputEL.nativeElement.value;

    if (this.dropdownMode === 'blank') {
      this.search(event, '');
    } else if (this.dropdownMode === 'current') {
      this.search(event, queryValue);
    }

    this.onDropdownClick.emit({
      originalEvent: event,
      query: queryValue
    });
  }

  focusInput() {
    this.inputEL.nativeElement.focus();
  }

  removeItem(item: any) {
    const itemIndex = this.domHandler.index(item);
    const removedValue = this.value[itemIndex];
    this.value = this.value.filter((val, i) => i !== itemIndex);
    this.onModelChange(this.value);
    this.updateFilledState();
    this.onUnselect.emit(removedValue);
  }

  onKeydown(event) {
    if (this.overlayVisible) {
      const highlightItemIndex = this.findOptionIndex(this.highlightOption);

      switch (event.which) {
        // down
        case 40:
          if (highlightItemIndex !== -1) {
            const nextItemIndex = highlightItemIndex + 1;
            if (nextItemIndex !== this.suggestions.length) {
              this.highlightOption = this.suggestions[nextItemIndex];
              this.highlightOptionChanged = true;
            }
          } else {
            this.highlightOption = this.suggestions[0];
          }

          event.preventDefault();
          break;

        // up
        case 38:
          if (highlightItemIndex > 0) {
            const prevItemIndex = highlightItemIndex - 1;
            this.highlightOption = this.suggestions[prevItemIndex];
            this.highlightOptionChanged = true;
          }

          event.preventDefault();
          break;

        // enter
        case 13:
          if (this.highlightOption) {
            this.selectItem(this.highlightOption);
            this.hide();
          }
          event.preventDefault();
          break;

        // escape
        case 27:
          this.hide();
          event.preventDefault();
          break;

        // tab
        case 9:
          if (this.highlightOption) {
            this.selectItem(this.highlightOption);
          }
          this.hide();
          break;
      }
    } else {
      if (event.which === 40 && this.suggestions) {
        this.search(event, event.target.value);
      }
    }

    this.inputKeyDown = true;
  }

  onKeyup(event) {
    this.onKeyUp.emit(event);
  }

  onInputFocus(event) {
    this.focus = true;
    this.onFocus.emit(event);
  }

  onInputBlur(event) {
    this.focus = false;
    this.onModelTouched();
    this.onBlur.emit(event);
  }

  onInputChange(event) {
    if (this.forceSelection && this.suggestions) {
      let valid = false;
      const inputValue = event.target.value.trim();

      if (this.suggestions) {
        for (const suggestion of this.suggestions) {
          const itemValue = this.field
            ? this.objectUtils.resolveFieldData(suggestion, this.field)
            : suggestion;
          if (itemValue && inputValue === itemValue.trim()) {
            valid = true;
            this.selectItem(suggestion, false);
            break;
          }
        }
      }

      if (!valid) {
        this.value = null;
        this.inputEL.nativeElement.value = '';

        this.onClear.emit(event);
        this.onModelChange(this.value);
      }
    }
  }

  onInputPaste(event: ClipboardEvent) {
    this.onKeydown(event);
  }

  isSelected(val: any): boolean {
    let selected = false;
    if (this.value && this.value.length) {
      for (let i = 0; i < this.value.length; i++) {
        if (this.objectUtils.equals(this.value[i], val, this.dataKey)) {
          selected = true;
          break;
        }
      }
    }
    return selected;
  }

  findOptionIndex(option): number {
    let index: number = -1;
    if (this.suggestions) {
      for (let i = 0; i < this.suggestions.length; i++) {
        if (this.objectUtils.equals(option, this.suggestions[i])) {
          index = i;
          break;
        }
      }
    }

    return index;
  }

  updateFilledState() {
    this.filled =
      (this.inputFieldValue && this.inputFieldValue !== '') ||
      (this.inputEL &&
        this.inputEL.nativeElement &&
        this.inputEL.nativeElement.value !== '');
  }

  updateInputField() {
    const formattedValue = this.value
      ? this.field
        ? this.objectUtils.resolveFieldData(this.value, this.field) || ''
        : this.value
      : '';
    this.inputFieldValue = formattedValue;

    if (this.inputEL && this.inputEL.nativeElement) {
      this.inputEL.nativeElement.value = formattedValue;
    }

    this.updateFilledState();
  }

  bindDocumentClickListener() {
    if (!this.documentClickListener) {
      this.documentClickListener = this.renderer.listen(
        'document',
        'click',
        event => {
          if (event.which === 3) {
            return;
          }

          if (!this.inputClick) {
            this.hide();
          }

          this.inputClick = false;
          this.cd.markForCheck();
        }
      );
    }
  }

  unbindDocumentClickListener() {
    if (this.documentClickListener) {
      this.documentClickListener();
      this.documentClickListener = null;
    }
  }

  onOverlayHide() {
    this.unbindDocumentClickListener();
    this.overlay = null;
  }

  ngOnDestroy() {
    this.restoreOverlayAppend();
    this.onOverlayHide();
  }
}
