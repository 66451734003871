import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApiPathConfigService } from '../configs/api';
import { HttpService } from '../../core/services';
import { HttpParams } from '@angular/common/http';
import { salesAff, aboNum, listAccountBalanceAPI, accountBalanceAPI } from '../configs/constants';
import { Observable } from 'rxjs';
import { AccountBalance } from '../models/amway';

@Injectable()
export class AccountBalanceService {
  constructor(
    private httpService: HttpService,
    private apiPathService: ApiPathConfigService ) {}
  getAccountBalances({ abo, aff }, accntBalType): Observable<AccountBalance[]> {
    const api = this.apiPathService.getApiPath(accountBalanceAPI);
    api.path = api.path.replace(salesAff, aff + '').replace(
      aboNum,
      abo
    );
    let urlParams: HttpParams = null;

    if (accntBalType) {
      urlParams = new HttpParams();
      urlParams = urlParams.set('accntBalType', accntBalType);
    }
    return this.httpService
      .callServerGET(api, urlParams, null)
      .pipe(map(response => {
        return response && response.accntBalList ? response.accntBalList : [];
      }));
  }

  getAccountBalancesList(abo, aff, page){
    const api = this.apiPathService.getApiPath(listAccountBalanceAPI);
    api.path = api.path.replace(salesAff, aff)
                                     .replace(aboNum, abo)
    let urlParams: HttpParams = new HttpParams();
    urlParams = urlParams.set('pageSize', '10');
    urlParams = urlParams.set('requestingPage', page);
    return this.httpService
      .callServerGET(api, urlParams, null)
  }
}
