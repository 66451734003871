import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IAppState } from '..';
import { CONFIG_GET, ConfigGet, ConfigGetFail, ConfigGetSuccess } from './config.actions';
import { ConfigService } from '../../shared/services';

@Injectable()
export class ConfigEffects {

  constructor(
    private actions$: Actions,
    private store$: Store<IAppState>,
    private configService: ConfigService
  ) {
  }

  @Effect()
  languageGet$ = this.actions$
    .ofType(CONFIG_GET)
    .pipe(map((action: ConfigGet) => action.payload)
    ,switchMap((payload) => of(new ConfigGetFail())));
    // .withLatestFrom(this.store$.select('config'))
    // .filter(([payload, storeState]) => {
    //   return !storeState || !storeState[payload.aff];
    // })
    // .switchMap(([payload]) => {
    //   return this.configService.getConfigs(payload)
    //     .catch(error => of(new ConfigGetFail()))
    //     .map((response) => {
    //       return new ConfigGetSuccess({
    //         data: response,
    //         aff: payload.aff
    //       });
    //     });
    // });

}
