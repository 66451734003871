import {
    Component, Input, Output, EventEmitter, Directive,
    ChangeDetectionStrategy, ViewChild, ElementRef
  } from '@angular/core';
  import { Modal } from 'ngx-modal';

  @Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'app-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
  })
  export class ModalComponent {
    @Input() title: string;
    @Input() cssClass: string;
    @Input() takeOverMode: boolean;
    @Input() hideCrossButton: boolean;
    @Input() closeOnEscape: boolean = true;
    @Input() closeOnOutsideClick: boolean = true;
    @Output() onOpen: EventEmitter<any> = new EventEmitter();
    @Output() onSubmit: EventEmitter<any> = new EventEmitter();
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @ViewChild('modal') modal: Modal;
    isOpen: boolean;

    /**
     * Open modal
     * @memberOf ModalNewComponent
     */
    open(): void {
      this.modal.open();
      this.modal.closeOnEscape = this.closeOnEscape;
      this.modal.closeOnOutsideClick = this.closeOnOutsideClick;
    }
    /**
     * Close modal
     * @memberOf ModalNewComponent
     */
    close(): void {
      this.modal.close();
    }

    /**
     * emits event on open
     * @param {any} event
     * @memberOf ModalNewComponent
     */
    actionOnOpen(event): void {
      this.isOpen = true;
      this.onOpen.emit(event);
    }

    /**
     * emits event on close
     * @param {any} event
     * @memberOf ModalNewComponent
     */
    actionOnClose(event): void {
      this.isOpen = false;
      this.onClose.emit(event);
    }

    /**
     * emits event on submit
     * @param {any} event
     * @memberOf ModalNewComponent
     */
    actionOnSubmit(event): void {
      this.onSubmit.emit(event);
    }
  }
