export class User {
  name: string;
  groups: string[];
  constructor({
    name = '',
    groups = []
  }) {
    this.name = name;
    this.groups = groups;
  }
}
