import { ContactPointTypeCdEnum } from '../enums/contact-point-type.enum';
import { ContactPointPurposeCdEnum } from '../enums/contact-point-purpose.enum';

export class AddressRequest {
  address: AddressInput;
  applyToAllLinkedAccountFlag: string;
}

export class AddressBookInput {
  addressBookInput: AddressList;
}

export class AddressList {
  addressList: AddressInput[];
}

export class AddressInput {
  contactId: string;
  contactPointTypeCd: ContactPointTypeCdEnum;
  contactPointName: string;
  usageList: AddressContactUsage[];
  addrStreet: string;
  addrLineTwo: string;
  addrLineThree: string;
  addrLineFour: string;
  statusCd: StatusCdEnum;
  cityName: string;
  cntryCd: string;
  countyName: string;
  addrDeliveryTypeCd: AddrDeliveryTypeCdEnum;
  taxJursidictionCd: string;
  geoCd: string;
  latitude: string;
  longitude: string;
  postalBoxNum: string;
  postalCd: string;
  stateCd: string;
  warehouseCd: string;
  tzOffSet: string;
  languageCd: string;
  charSetCd: string;
  deliveryInstr: string;
  territory: string;
  addressValidatedDate: string;
  districtNm: string;
  subDistrictNm: string;
  validationResultOverRideFlg: boolean;
  validationResultCd: ValidationResultCdEnum;
  partyId?: string;
}

export class AddressContactUsage {
  contactPointPurposeCd: ContactPointPurposeCdEnum;
  careOf: string;
}

enum StatusCdEnum {
  Deleted = <any>'Deleted',
  Invalid = <any>'Invalid',
  NotValidated = <any>'NotValidated',
  Valid = <any>'Valid'
}

export enum AddrDeliveryTypeCdEnum {
  MailStop = <any>'MailStop',
  MailingAddress = <any>'MailingAddress',
  PostOffice = <any>'PostOffice',
  RuralRoute = <any>'RuralRoute'
}

export enum ValidationResultCdEnum {
  Valid = <any>'Valid',
  Invalid = <any>'Invalid',
  Deleted = <any>'Deleted',
  NotValidated = <any>'NotValidated'
}

export class AddressValidationPayload {
  AddressLine1Text: string;
  CityName: string;
  StateCode: string;
  PostalCode: string;
  CountryCode: string;
  AddressStandardizationBypassFlag: boolean;
}

export interface ABOAddress {
  usageList: ABOAddressUsageListItem[];
  addrStreet: string;
  addrLineTwo: string;
  stateCd: string;
  postalCd: string;
  cityName: string;
  contactId: string;
  cntryCd: string;
  validationResultCd: string;
  languageCd: string;
  taxJursidictionCd?: string;
  contactPointTypeCd: ContactPointTypeCdEnum;
  addrDeliveryTypeCd: AddrDeliveryTypeCdEnum;
}

interface ABOAddressUsageListItem {
  contactPointPurposeCd: string;
  primaryFlag: boolean;
  careOf?: string;
}
