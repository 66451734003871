import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ABOComponent } from './abo.component';
import { AuthGuard } from '../core/guards/auth.guard';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: ':userType/:aff/:abo/:partyId', component: ABOComponent, canActivate: [AuthGuard],
        data: { label: 'ABO', parents: ['home', 'find-abo'] }
      },
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class ABORoutingModule { }
