export class Employee {
  name: string;
  givenName: string;
  familyName: string;
  processTypeCd: string;
  accountSubTypeCd: string;
  accountTypeCd: string;
  languageCd: string;
  primaryPartyIndex: number;
  partyList;
  aboEntryDate:string;
  contractAcceptFlg: string;
  authorizeEmail: boolean;
  status: string;
  subDistrictNm: string;
  stateCd: string;
  districtNm: string;
  stateName: string;
  addrStreet: string;
  postalCd: string;
  cid: string;
  taxTypeCd: string;
  empNum: string;
  phoneLocalNum: string
  aboNum: string;
  subCategoryCd: string;
  sponsor;
  aff;
  taxPayerType: string
  formerAccount: string;
  cntryCd: string;
  lglEnttyType: string;
  constructor(
    employee
  ) {
    this.aboNum = employee.empNum
    this.processTypeCd = employee.processTypeCd
    this.cntryCd = employee.cntryCd
    this.lglEnttyType = "",
    this.accountSubTypeCd = employee.accountSubTypeCd
    this.accountTypeCd = employee.accountTypeCd
    this.subCategoryCd = employee.status
    this.contractAcceptFlg = 'false'
    this.authorizeEmail = false
    this.languageCd = employee.languageCd
    this.aboEntryDate = employee.aboEntryDate ? employee.aboEntryDate.replace('Z','+00:00') : ''
    this.taxPayerType = employee.taxPayerType
    this.sponsor = {
      acctSponsorAffCd: employee.aff,
      acctSponsorAboNum: "2",
      acctIntlSponsorCntryCd: "",
      acctIntlSponsorAboNum: "",
      intlPrimaryBusFlg: false,
      sponsorListFlag: false,
      multiBusValidationOverRideFlg: false
    },
    this.formerAccount = null,
    this.primaryPartyIndex = employee.primaryPartyIndex
    this.partyList = [
    {
      partyIndex: 1,
      partyMst:  {
        partyTypeCd: "",
        roleCd: "6",
        birthCountryCd: employee.cntryCd,
        relationShipToPrimaryCd: "",
        languageCd: employee.languageCd
      },
      nameList: [{
        personNameTypeCd: employee.personNameTypeCd,
        localeName: {
          givenName: employee.givenName,
          familyName: employee.familyName
        },
        languageCd: employee.languageCd,
      }],
      addressList: [{
        contactId: null,
        contactPointTypeCd: "HomeAddress",
        contactPointName: null,
        usageList: [{
          careOf: "",
          contactPointPurposeCd: "Registration",
        }],
        addrStreet: employee.addrStreet,
        addrLineTwo: "",
        addrLineThree: "",
        addrLineFour: "",
        statusCd: null,
        cityName: "",
        cntryCd: employee.cntryCd,
        countyName: null,
        addrDeliveryTypeCd: "MailingAddress",
        postalCd: employee.postalCd,
        stateCd: employee.stateCd,
        languageCd: "",
        addressValidatedDate: "",
        districtNm: employee.districtNm,
        subDistrictNm: employee.subDistrictNm,
        validationResultOverRideFlg: "",
        validationResultCd: "notValidated"
      }],
      phoneList: [{
        contactId: null,
        contactPointTypeCd: "HomePhone",
        usageList: [{
          contactPointPurposeCd: "GeneralPurpose",
          primaryFlag: true
        }],
        phoneLocalNum: employee.phoneLocalNum,
        phoneCntryCd: employee.phoneCntryCd,
        phoneAreaCd: "",
        phoneExtNum: "",
        smsCapableFlag: false,
        cntryCd: employee.cntryCd,
        statusCd: null
      }],
      taxList: [
        {
          taxTypeCd: employee.taxTypeCd,
          expirationDate: "",
          taxId: employee.cid,
          cntryCd: employee.cntryCd,
        }
      ]

    }]
  }
}
