import { Observable, of } from 'rxjs';
import { map, switchMap, catchError, filter, flatMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IAppState } from '../index';
import {
  ABO_DETAILS_LOAD,
  AboDetailsLoad,
  AboDetailsLoadSuccess,
  AboDetailsLoadFail,
  ABO_DETAILS_SAVE,
  AboDetailsSave,
  AboDetailsSaveSuccess,
  AboDetailsSaveFail,
  ABO_DETAILS_ADDRESS_CHANGE,
  AboAddressChange,
  AboAddressChangeSuccess,
  AboDetailsAddressSaveFail,
  AboBankDetailChange,
  AboBankDetailChangeFail,
  AboBankDetailChangeSuccess,
  ABO_BANK_DETAIL_CHANGE,
  ABO_TAX_DETAIL_CHANGE,
  AboTaxDetailChange,
  AboTaxDetailChangeFail,
  AboTaxDetailChangeSuccess,
  ABO_TAX_CHANGE,
  AboTaxChangeOnly,
  AboTaxChangeOnlySuccess,
  AboTaxChangeOnlyFail,
  AboDocumentReceivedSave,
  ABO_DOCUMENT_RECEIVED_SAVE,
  ABO_DETAILS_ADDRESS_DELETE,
  AboAddressDelete,
  AboAddressDeleteSuccess,
  AboAddressDeleteFail,
} from './abo-personal-details.actions';
import {
  TaxService,
  UpdateDetailsService,
  AddressService,
  ErrorService
} from '../../shared/services/index';
import { AccountCard, TaxCertificate } from '../../shared/models/index';
import { PersonalId } from '../../shared/models/amway';
import { BankDetail } from '../../shared/models/bank-detail.model';
import { AboBankService } from '../../shared/services/abo-bank.service';
import { ErrorLogService } from '../../core/services';

@Injectable()
export class AboPersonalDetailsEffects {
  constructor(
    private action$: Actions,
    private store: Store<IAppState>,
    private updateService: UpdateDetailsService,
    private addressService: AddressService,
    private bankService: AboBankService,
    private errorService: ErrorService,
    private errorLogService: ErrorLogService
  ) {}

  @Effect()
  getAboPersonalDetails$ = this.action$
    .ofType(ABO_DETAILS_LOAD).pipe(
    switchMap(({ payload }: AboDetailsLoad) =>
      this.store
        .select('account')
        .pipe(filter(data => data.loaded)
        ,map(response => new AboDetailsLoadSuccess(response))
        ,catchError(error => {
          this.LogError(error);
          return of(new AboDetailsLoadFail());
        }))
    ));

  @Effect()
  updateAboBasicInfo$ = this.action$
    .ofType(ABO_DETAILS_SAVE).pipe(
    switchMap(({ payload }: AboDetailsSave) => {
      return this.updateService
        .updateAccount(payload)
        .pipe(map(response => new AboDetailsSaveSuccess({ type: payload.type }))
        ,catchError(error => {
          // this.LogError(error);
          return of(new AboDetailsSaveFail({ type: payload.type, error: error }));
        }));
    }));

  @Effect()
  changeAboAddress$ = this.action$
    .ofType(ABO_DETAILS_ADDRESS_CHANGE)
    .pipe(flatMap((action: AboAddressChange) => {
      return this.addressService
        .updateAddress(action.payload)
        .pipe(map(response => new AboAddressChangeSuccess({ type: 'ADDRESS_SAVE' }))
        ,catchError(error => {
          this.LogError(error);
          return of(
            new AboDetailsAddressSaveFail({ type: 'ADDRESS_SAVE', error: error })
          );
        }));
    }));

  @Effect()
  deleteAboAddress$ = this.action$
    .ofType(ABO_DETAILS_ADDRESS_DELETE).pipe(
    switchMap((action: AboAddressDelete) => {
      return this.addressService
        .deleteAddress(action.payload)
        .pipe(map(response => new AboAddressDeleteSuccess({ type: 'ADDRESS_DELETE' }))
        ,catchError(error => {
          this.LogError(error);
          return of(
            new AboAddressDeleteFail({ type: 'ADDRESS_DELETE' })
          );
        }));
  }));

  @Effect()
  changeAboBankDetails$ = this.action$
    .ofType(ABO_BANK_DETAIL_CHANGE).pipe(
    switchMap((action: AboBankDetailChange) => {
      return this.bankService
        .UpdateBankDetails(action.payload)
        .pipe(map(response => new AboBankDetailChangeSuccess({ type: 'BANK_SAVE' }))
        ,catchError(error => {
          // this.LogError(error);
          return of(
            new AboBankDetailChangeFail({ type: 'BANK_SAVE', error: error })
          );
        }));
    }));

  @Effect()
  changeAboTaxDetails$ = this.action$
    .ofType(ABO_TAX_DETAIL_CHANGE).pipe(
    switchMap((action: AboTaxDetailChange) => {
      return this.updateService
        .updateTaxDetails(action.payload)
        .pipe(map(response => new AboTaxDetailChangeSuccess({ type: 'TAX_SAVE' }))
        ,catchError(error => {
          this.LogError(error);
         return of(new AboTaxDetailChangeFail({ type: 'TAX_SAVE' }));
        }));
    }));

    @Effect()
    changeAboTaxOnly$ = this.action$
      .ofType(ABO_TAX_CHANGE).pipe(
      switchMap((action: AboTaxChangeOnly) => {
        return this.updateService
          .saveonlyTaxInformation(action.payload)
          .pipe(map(response => new AboTaxChangeOnlySuccess({ type: 'TAX_SAVE' }))
          ,catchError(error => {
            // this.LogError(error);
           return of(new AboTaxChangeOnlyFail({ type: 'TAX_SAVE', error: error  }));
          }));
      }));

      @Effect()
      changeAboDocumentInformation$ = this.action$
        .ofType(ABO_DOCUMENT_RECEIVED_SAVE).pipe(
        switchMap((action: AboDocumentReceivedSave) => {
          return this.updateService
            .updateAboDocuments(action.payload)
            .pipe(map(response => new AboTaxChangeOnlySuccess({ type: 'TAX_SAVE' }))
            ,catchError(error => {
              this.LogError(error);
             return of(new AboTaxDetailChangeFail({ type: 'TAX_SAVE' }));
            }));
        }));
    
  private LogError(error) {
    const errorMessage = this.errorService.processError(error);
    this.errorLogService.log(errorMessage);
  }
}
