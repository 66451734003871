import { EcommList } from './e-comms.model';
import { PhoneList } from './phones.model';
import { UsageList } from './common.model';
import { ContactPointTypeCdEnum } from './enums/contact-point-type.enum';
import { AddrDeliveryTypeCdEnum } from './enums/addr-delivery-type.enum';
import { PartyList } from './amway/party-list.model';

export interface PartyMst {
  activationTokenURL?: any;
  birthCountryCd: string;
  birthdate: any;
  czshpCntryCd?: any;
  czshpTypeCd?: any;
  educationTypeCd?: any;
  ethnicCd?: any;
  genderCd?: any;
  languageCd: string;
  maritalStatusCd?: any;
  natlCntryCd?: any;
  partyId: string;
  partyIsMinorFlg: string;
  partyTypeCd: string;
  primaryOnAccount: string;
  professionCd?: any;
  relationShipToPrimaryPartyCd?: any;
  roleCd: string;
  statusCd: string;
  tzOffset?: any;
  userId?: any;
  userPasswd?: any;
  userPin: string;
}

export interface PartyList {
  addressList: AddressList[];
  bankAccountDetailList?: any[];
  ecommList: EcommList[];
  missingInfoList?: any[];
  nameList?: NameList[];
  partyMst?: PartyMst;
  personalIdList?: any[];
  phoneList: PhoneList[];
  preferenceList?: any[];
  subscriptionList?: any[];
  taxList?: any[];
  partyId: string;
  aboNum?: string;
}

export interface PartySearchList extends PartyList {
  partyId: string;
  givenName: string;
  familyName: string;
  roleCd: string;
}

export interface PartyBasic {
  partyId: string;
  primaryOnAccount: boolean;
  email: Email;
  birthdate: string;
  familyName: string;
  givenName: string;
  nameLanguageCd: string;
  preferredName: string;
  engagedName: string;
  languageCd: string;
  preferredLanguage: string;
  hasMissingInfo: boolean;
  partyTypeCd: string;
}

export interface Party extends PartyBasic {
  mobilePhone: string;
  alternatePhone: string;
  addresses: AddressList[];
}

export class AddressList {
  contactId: string;
  contactPointName: string;
  partyId: string;
  usageList: UsageList[];
  addrLineFour: string;
  addrLineThree: string;
  addrLineTwo: string;
  addrStreet: string;
  addressValidatedDate: Date;
  charSetCd: string;
  cityName: string;
  cntryCd: string;
  countyName: string;
  districtName: string;
  geoCd: string;
  languageCd: string;
  latitude: string;
  longitude: string;
  postalBoxNum: string;
  postalCd: string;
  stateCd: string;
  stateName: string;
  statusCd: string;
  subDistrictName: string;
  taxJursidictionCd: string;
  territory: string;
  townName: string;
  tzOffSet: string;
  warehouseCd: string;
  contactPointTypeCd: ContactPointTypeCdEnum;
  addrDeliveryTypeCd: AddrDeliveryTypeCdEnum;
  addressBookId?:string;
}

export interface PartyName {
  familyName: string;
  givenName: string;
  middleName: any;
  suffix?: any;
  title?: any;
}

export interface NameList {
  charSetCd?: any;
  languageCd: string;
  latinName: PartyName;
  localeName: PartyName;
  partyId: number;
  personNameTypeCd: string;
  preferredName: string;
}

interface Email {
  contactId: string;
  ecommAddr: string;
}
