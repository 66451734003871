import {
  Injectable, ViewContainerRef, ComponentRef, Injector, Compiler, ReflectiveInjector, ComponentFactory
} from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { AbstractFieldComponent } from '../models/amway/index';

@Injectable()
export class ModalService {

  private vcRef: ViewContainerRef;
  private injector: Injector;
  public activeInstances = 0;

  constructor(private compiler: Compiler) { }

  registerViewContainerRef(vcRef: ViewContainerRef): void {
    this.vcRef = vcRef;
  }

  registerInjector(injector: Injector): void {
    this.injector = injector;
  }

  createFromFactory(componentFactory: ComponentFactory<AbstractFieldComponent>, parameters?: Object):
    Observable<any> {
    const componentRef$ = new ReplaySubject();
    const childInjector = ReflectiveInjector.resolveAndCreate([], this.injector);
    const componentRef = this.vcRef.createComponent(componentFactory, 0, childInjector);

    Object.assign(componentRef.instance, parameters);
    this.activeInstances++;
    componentRef.instance.destroy = () => {
      this.activeInstances--;
      componentRef.destroy();
    };

    componentRef$.next(componentRef);
    componentRef$.complete();
    return componentRef$.asObservable();
  }

}
