import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  ChangeDetectorRef
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SafeResourceUrl } from '@angular/platform-browser';
import { AccountDetail, TaxPayerDetail, Account } from '../../../shared/models/amway/index';
import { ActivatedRoute } from '@angular/router';
import { IAppState } from '../../../store';
import { TaxCertificateLoad } from '../../../store/tax-certificate/tax-certificate.actions';
import { AboTaxDetail } from '../../../shared/models/abo-tax-detail.model';

import * as _ from 'lodash';
import { getTaxPayerParty, taxAdressPayload } from '../../../shared/helpers/tax.helper';
import { AddressService } from '../../../shared/services';

@Component({
  selector: 'app-abo-tax-info',
  templateUrl: './abo-tax-info.component.html',
  styleUrls: ['./abo-tax-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AboTaxInfoComponent implements OnInit {
  @Input() taxInfo: AboTaxDetail;
  @Input() taxPayerInfo: TaxPayerDetail;
  @Input() accountFullDetail: Account;
  taxDetail: AboTaxDetail;
  taxCertForm$: Observable<SafeResourceUrl>;
  account$: Observable<AccountDetail>;
  taxPayerParty: any;
  loading: boolean = false;

  constructor(private route: ActivatedRoute,
    private store: Store<IAppState>,
    private addressService: AddressService,
    private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.taxDetail = _.cloneDeep(this.taxInfo);
    this.taxPayerParty = getTaxPayerParty(this.accountFullDetail, this.taxPayerInfo)
    this.taxDetail = _.cloneDeep(taxAdressPayload(this.taxPayerParty))

    this.taxDetail['aboBasicInfo'] = this.taxPayerParty

    if(this.taxDetail['aboBasicInfo'] && this.taxDetail['aboBasicInfo'].nameList){
      this.taxDetail['name'] = `${this.taxDetail['aboBasicInfo'].nameList[0].localeName.givenName || ''}
      ${this.taxDetail['aboBasicInfo'].nameList[0].localeName.familyName || ''}`;
    }

    this.addressService
    .getProvinceForCountry()
    .pipe(filter((result: any) => !result.loading))
    .subscribe((res:any) => {
      if (this.taxDetail.taxAddress.stateCd) {
        this.taxDetail.taxAddress.stateName = res.data.find(
          st => st.isocode === this.taxDetail.taxAddress.stateCd
        )
          ? res.data.find(
              st => st.isocode === this.taxDetail.taxAddress.stateCd
            ).name
          : '';
      }
      this.loading = true
      this.cdr.detectChanges();
    });

  }
}
