import { ReplaySubject } from 'rxjs';
import { Injectable, ErrorHandler } from '@angular/core';
import { get, isObject, isString } from 'lodash';
import { DEFAULT_ERROR } from '../../shared/configs/constants';
import { ErrorService } from '../../shared/services';

@Injectable()
export class ErrorLogService {
  private errors: any[] = [];
  errors$: ReplaySubject<any[]> = new ReplaySubject(1);

  public log(error: any): void {
    if (!error) {
      return;
    }
    let _error = isString(error) ? error : { ...error };
    // prevent displaying the same error message multiple times
    if (this.errors.includes(_error)) {
      return;
    }
    if (isObject(_error)) {
      const errorOb = get(_error, 'error', null);
      if (!errorOb) {
        return;
      }
      if (errorOb && isObject(<any> errorOb)) {
        const hasErrorMessage = JSON.parse(errorOb).errorMessage.message
          ? true
          : false;
        if (!hasErrorMessage) {
          return;
        }
      }
    }
    if (isObject(_error)) {
      _error = this.getErrorMessage(_error);
    }

    this.errors.push(_error);
    this.errors$.next(this.errors);
  }

  public logUnrecoverable(): void {
    this.errors = [DEFAULT_ERROR];
    this.errors$.next(this.errors);
  }

  public remove(error: any) {
    this.errors = this.errors.filter(e => e !== error);
    this.errors$.next(this.errors);
  }

  private getErrorMessage(errorObj: { [key: string]: any }): string {
    const error = get(errorObj, 'error', DEFAULT_ERROR);
    return isObject(error) ? this.getErrorMessage(error) : error;
  }
}
export class AppErrorHandler implements ErrorHandler {
  constructor(
    private errorLog: ErrorLogService,
    private erroService: ErrorService
  ) {}

  public handleError(error) {
    const message = this.erroService.processError(error);
    this.errorLog.log(message);
    throw error;
  }
}
