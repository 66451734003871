export class InheritanceData {
  aboNum: string;
  reason: string;
  party: string;
  constructor({
    aboNum = '',
    reason = '',
    party = ''
  }) {
    this.aboNum = aboNum;
    this.reason = reason;
    this.party = party;
  }
}
