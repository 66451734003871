import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class ArraySortPipe implements PipeTransform {
  transform<T>(array: T[], field: string): T[] {
    // note: empty value goes to beginning of array
    if (array && field) {
      array.sort((a, b) => {
        const a1 = a[field];
        const b1 = b[field];

        if (a1 === '' || a1 < b1) {
          return -1;
        } else if (a1 > b1) {
          return 1;
        } else {
          return 0;
        }
      });
    }

    return array;
  }
}
