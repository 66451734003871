export class AboSubscription {
  cancelCd: string;
  isPublicationCd: string;
  languageCd: string;
  languageCdList: string[];
  partyId: number;
  pauseCd: string;
  pubCategoryName: string;
  restoreCd: string;
  skuAliasId: string;
  subscribeFlag: string;
  subscriptionDeliveryList: AboSubScriptionDelivery[];
  subscriptionId: string;
  subscriptionName: string;
  isSubscribed: boolean;
}

export class AboSubScriptionDelivery {
  deliveryChoiceCd: string;
  deliveryTypeCd: string;
  subscriptionCount: string;
  optOutStartDate: string;
  optOutEndDate: string;
  effectiveDate: string;
  warehouseCd: string;
}
