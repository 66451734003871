import { TaxCertificate } from '../models/tax-certificate.model';
import * as _ from 'lodash';
import {
  TAX_COUNTRY_CODE,
  TAX_TYPE_CODE,
  TAX_CERT_CODE,
  defaultCountryCode
} from '../configs/constants';
import { AboTaxDetail } from '../models/abo-tax-detail.model';
import { Tax } from '../models/amway/tax.model';
import { AddressList } from '../models/party-basic.models';
import { ContactPointTypeCdEnum } from '../models/enums/contact-point-type.enum';
import { Account, TaxPayerDetail } from '../models/amway/index';


export function getTaxCertDetail(taxCertList: TaxCertificate[], aboNum) {
  if (taxCertList && taxCertList.length > 0) {
    taxCertList = taxCertList.filter(
      taxCert =>
        taxCert.countryCode === TAX_COUNTRY_CODE &&
        taxCert.taxTypeCode === TAX_TYPE_CODE &&
        taxCert.taxCertificateCode === TAX_CERT_CODE
    );
    if (taxCertList.length > 0) {
      const currentTaxCert = _.cloneDeep(
        taxCertList.reduce(
          (prev, current) =>
            prev.controlNumber > current.controlNumber ? prev : current
        )
      );
      currentTaxCert.docName = getTaxCertificateDocName(currentTaxCert, aboNum);
      return currentTaxCert;
    }
  }
  return {} as TaxCertificate;
}

export function getTaxCertificateDocName(taxCert: TaxCertificate, aboNum: any) {
  let docName = 'SPP Form';
  if (taxCert && taxCert.certificateEDoc) {
    const crtName = taxCert.certificateEDoc.split(aboNum + '_')[1];
    if (crtName) {
      docName = crtName;
    }
  }
  return docName;
}

export function generateTaxDetailsPayload(details: AboTaxDetail, partyId): AboTaxDetail {

  const taxdetail = {} as AboTaxDetail;
    taxdetail.tax = createTaxPayLoad(details.tax, partyId);
    taxdetail.taxAddress = createTaxAddressPayLoad(details.taxAddress);
    taxdetail.taxCertDetail = details.taxCertDetail;

    return taxdetail;
}

export function createTaxPayLoad(tax: Tax, partyId) {
  const taxDetail = {} as Tax;
  if (tax) {
  taxDetail.taxTypeCd = TAX_TYPE_CODE;
  taxDetail.expirationDate = null;
  taxDetail.taxId = tax.taxId;
  taxDetail.cntryCd = TAX_COUNTRY_CODE;
  taxDetail.partyId = partyId;
  taxDetail.effectiveDate = tax.effectiveDate;
  taxDetail.vatRegister = tax.vatRegister;
  taxDetail.taxPayerType = tax.taxPayerType;
  } else {
    taxDetail.taxTypeCd = TAX_TYPE_CODE;
    taxDetail.expirationDate = null;
    taxDetail.taxId = '';
    taxDetail.cntryCd = TAX_COUNTRY_CODE;
    taxDetail.partyId = partyId;
    taxDetail.effectiveDate = '';
    taxDetail.vatRegister = null;
    taxDetail.taxPayerType = 'Applicant';
  }
  return taxDetail;
}

export function createTaxAddressPayLoad(taxAddress: AddressList) {
  const address  = getDataForUpdate(taxAddress);
  return address;
}

export function getDataForUpdate(addressInfo: AddressList): AddressList {
  return addressInfo.contactId ? addressInfo : {
    ...addressInfo,
    contactPointTypeCd: ContactPointTypeCdEnum.BusinessAddress,
    addrLineTwo: addressInfo.addrLineTwo || '',
    languageCd: '',
    cntryCd: addressInfo.cntryCd || defaultCountryCode
  };
}


export function getTaxPayerParty(accountFullDetail: Account, taxPayerInfo: TaxPayerDetail){
  let primaryParty, taxPayerParty
  primaryParty = accountFullDetail.primaryParty;
  if (taxPayerInfo) {
    if (taxPayerInfo.partyList.length > 1){
      taxPayerParty = _.filter(taxPayerInfo.partyList, 
                          function(o) { return primaryParty.partyMst.partyId == o.partyId }
                      )
    } else {
      taxPayerParty = taxPayerInfo.partyList
    }
    return taxPayerParty[0]
  } else {
    return {}
  }
}

export function taxPayeableParties(accountFullDetail: Account){
  
  let taxPayeableParties = _.filter(accountFullDetail.partyList,  function(o) {
            return o.partyMst.roleCd == "BusinessOwner" && _.filter(o.taxList, function(t){
              return t.taxTypeCd == TAX_TYPE_CODE
            })
      })
  return taxPayeableParties
}

export function taxAdressPayload(party: any) {
  let taxPayer:any = _.filter(party && party.taxList, function(o){ return o.taxTypeCd == TAX_TYPE_CODE })[0]
  const taxId = taxPayer? taxPayer.taxId : ''

  const tax = party && party.taxList && party.taxList.length > 0 ?
              party.taxList[0] : {} as Tax;

  const taxCertDetail = {}
  const taxAddresses = party && party.addressList
  const taxAddress = taxAddresses && taxAddresses.length > 0 ? taxAddresses[0] : {} as AddressList;
  return {
    taxId,
    tax,
    taxAddress,
    taxCertDetail,
  } as AboTaxDetail;

}
