import { Component, OnInit, ViewChild } from '@angular/core';
import { Modal } from 'ngx-modal';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { AmwayUserProfile, AboConnectState } from '../../shared/models/abo-connect.model';
import { AboEmailUserStatus, AboConnectUserStatus } from '../../shared/models/enums/index';
import { IAppState } from '../../store';
import { AboConnectService } from '../../shared/services/abo-connect.service';
import { AboIdCheckService } from '../../shared/services/abo-id-check.service';
import { AboEmailActivationService } from '../../shared/services/abo-email-activation.service';
import {
  AboConnectGetUserProfile,
  AboConnectCreateUserProfile,
  AboConnectUpdateUserProfile,
  AboConnectResendUserProfile
} from '../../store/abo-connect/abo-connect.actions';

enum ConfirmationStatus {
  Edited = 'edited',
  Created = 'created'
}

enum StatusDescription {
  STAGED = 'ID created and email sent, not clicked by caller',
  PROVISIONED = 'Email link clicked by caller, but password not set up',
  ACTIVE = 'ID is fully enabled and linked to an Australia account',
  ENROLLED = 'ID is Enrolled',
  DEPROVISIONED = '',
}

const SEARCH_CONNECT_ID_EVENTS_PATH = '/searchforce-find-connect-id';
const NO_AMWAY_ID_DESCRIPTION = 'No ID Exists';
const EMAIL_REGEXP = '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))' +
  '@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])' +
  '|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,64}))$';

@Component({
  selector: 'amw-abo-connect-id',
  templateUrl: './abo-connect-id.component.html',
  styleUrls: ['./abo-connect-id.component.scss']
})
export class AboConnectIdComponent implements OnInit {
  @ViewChild('confirmIdformModal') confirmIdformModal: Modal;
  @ViewChild('connectIdFormModal') connectIdFormModal: Modal;

  userProfile$: Observable<AmwayUserProfile>;
  userProfileState$: Observable<AboConnectState>;
  aboEmailUserStatus = AboEmailUserStatus;
  userData: any;
  partyId$: Observable<string>;
  partyId: any;
  confirmationStatus: ConfirmationStatus;
  username: any;
  statusDescription = StatusDescription;
  modalTitle: string;
  loading: boolean = false;
  isEnrolledProfile: boolean = false;
  error: any;
  errorMessage: string = "";
  showHistoryPath: string;
  emailResentConf: boolean = false;

  constructor(
    private aboConnectService: AboConnectService,
    private aboIdCheckService: AboIdCheckService,
    private aboEmailActivationService: AboEmailActivationService,
    private store: Store<IAppState>,
    private route: ActivatedRoute) {
    this.partyId = this.route.snapshot.paramMap.get('partyId');
  }
  ngOnInit() {
    // this.store.dispatch(new AboConnectGetUserProfile());

    // this.partyId$ = this.store.select('account').filter(Boolean).map(state => state.party.partyId);
    this.showHistoryPath = SEARCH_CONNECT_ID_EVENTS_PATH;
    this.getUserBypartyId();
  }

  getUserBypartyId() {
    this.aboIdCheckService.aboIdCheck(this.partyId).subscribe((data) => {
      this.modalTitle = (data.result.length == 0) ? 'CREATE AMWAY ID' : 'EDIT AMWAY ID';
      
      if (data.result.length > 0) {
        let indexVal = 0;
        if (data.result.length > 1) {
          let pattern = new RegExp(EMAIL_REGEXP);
          _.forEach(data.result, function (value, key) {
            if (pattern.test(value.userProfile.displayUsername)) {
              indexVal = parseInt(key);
            }
          });
        }
        this.username = data.result[indexVal].userProfile['userName'];
        this.userProfile$ = data.result[indexVal].userProfile;
        this.userProfile$['id'] = data.result[indexVal].id;
      } else {
        this.getEnrollmentRecordByPartyId();
      }
    },
      error => {
        this.loading = false;
        this.error = JSON.parse(error);
        this.errorMessage = this.error.message;
      });
  }


  getEnrollmentRecordByPartyId() {
    this.loading = true;
    this.aboConnectService.getEnrollmentRecordByPartyId(this.partyId)
      .subscribe((data) => {
        this.modalTitle = (data['enrollmentId'] === undefined) ? 'CREATE AMWAY ID' : 'EDIT AMWAY ID';
        this.loading = false;
        this.isEnrolledProfile = true;
        this.userProfile$ = data;
        this.username = this.userProfile$['userName'] = this.userProfile$['displayUsername'] = data['email'].email;
        this.userProfile$['status'] = 'ENROLLED';
      }, err => {
        this.loading = false;
        this.error = JSON.parse(err);
        this.errorMessage = this.error.message;
      });
  }


  emailActivation(username) {
    this.aboEmailActivationService.emailActivation(username)
      .subscribe((data) => {
        this.loading = false;
      }, error => {
        this.loading = false;
      });
  }

  enrolledSendEmail(enrollmentId) {
    this.aboEmailActivationService.enrollSendEmail(enrollmentId)
      .subscribe((data) => {
        this.loading = false;
      }, error => {
        this.loading = false;
      });
  }

  createUserProfile(userProfile: AmwayUserProfile): void {
    this.loading = true;
    this.modalTitle = 'CREATE AMWAY ID';
    this.aboConnectService.createUserProfile(userProfile, this.partyId)
      .subscribe((data) => {
        this.loading = false;
        this.userProfile$ = data['enrollmentId'];
        this.getEnrollmentRecordByPartyId();
      }, err => {
        this.loading = false;
        this.error = JSON.parse(err.error);
        this.errorMessage = this.error.message;
      },
        () => {
          this.resendEnrolledEmail(this.userProfile$);
          this.handleConfirmation(this.confirmationStatus);
        });
  }

  updateUserProfile(userProfile: AmwayUserProfile): void {
    this.loading = true;
    this.modalTitle = 'EDIT AMWAY ID';
    this.aboConnectService.editUserProfile(userProfile, this.username)
      .subscribe((data) => {
        this.loading = false;
        this.userProfile$ = data['userProfile'];
        // this.userProfile$['id'] = data['id'];
        this.getUserBypartyId();
      }, err => {
        this.loading = false;
        this.error = JSON.parse(err.error);
        this.errorMessage = this.error.message;
      },
        () => {
          this.emailActivation(this.userProfile$['userName']);
          this.handleConfirmation(this.confirmationStatus);
        });
    // this.store.dispatch(new AboConnectUpdateUserProfile(userProfile));
  }

  updateEnrolledAmwayID(userProfile: AmwayUserProfile): void {
    this.loading = true;
    this.modalTitle = 'EDIT AMWAY ID';
    this.aboConnectService.updateEnrolledAmwayId(userProfile['enrollmentId'], userProfile['userName'])
      .subscribe((data) => {
        this.loading = false;
        this.userProfile$ = data['enrollmentId'];
        this.getEnrollmentRecordByPartyId();
      }, err => {
        this.loading = false;
        this.error = JSON.parse(err.error);
        this.errorMessage = this.error.message;
      },
        () => {
          this.resendEnrolledEmail(this.userProfile$);
          this.handleConfirmation(this.confirmationStatus);
        });
  }

  handleUserProfile(userProfile: AmwayUserProfile): void {
    const isEdited = userProfile.id;
    this.confirmationStatus = isEdited ? ConfirmationStatus.Edited : ConfirmationStatus.Created;
    isEdited ? this.updateUserProfile(userProfile) : this.createUserProfile(userProfile);

  }

  handleEnrolledProfile(userProfile: AmwayUserProfile): void {
    this.confirmationStatus = this.isEnrolledProfile ? ConfirmationStatus.Edited : ConfirmationStatus.Created;
    this.updateEnrolledAmwayID(userProfile);

  }


  openIdConnectModal() {
    this.errorMessage = "";
    this.connectIdFormModal.open();
  }

  resendUserProfile(userProfile: AmwayUserProfile): void {
    this.loading = true;
    // this.store.dispatch(new AboConnectResendUserProfile(userProfile));
    this.emailActivation(userProfile.userName);
  }

  resendEnrolledEmail(enrollmentId) {
    this.loading = true;
    this.enrolledSendEmail(enrollmentId);
  }

  showResend(status: AboConnectUserStatus): boolean {
    return !(this.isActive(status) || this.isDeprovisioned(status));
  }

  isDeprovisioned(status: AboConnectUserStatus): boolean {
    return status === AboConnectUserStatus.DEPROVISIONED;
  }

  isActive(status: AboConnectUserStatus): boolean {
    return status === AboConnectUserStatus.ACTIVE;
  }

  closeModal(modal): void {
    this.connectIdFormModal.close();
  }

  getUserStatusDescription(status?): string {
    return status ? this.statusDescription[status] : NO_AMWAY_ID_DESCRIPTION;
  }

  private handleConfirmation(status): void {
    this.connectIdFormModal.close();
    this.confirmationStatus = status;
    this.confirmIdformModal.open();
  }
}
