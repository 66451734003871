import { Component, OnInit, OnDestroy, ComponentFactoryResolver } from '@angular/core';
import { AbstractDetailsComponent } from '../abstract/abstract-details.component';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { AboActions, DeactivationState, AboDetail } from '../../shared/models/amway/index';
import { ACTIONS_LIST } from './abo-actions-list';
import { NavigationService } from '../../core/services/index';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/index';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '../../shared/services/index';
import { DeactivateGetRenewalOrder } from '../../store/deactivation/deactivation.actions';

import * as _ from 'lodash';

const RENEWAL_KEY = 'refundRenewal';

@Component({
  selector: 'app-abo-actions',
  templateUrl: 'abo-actions.component.html',
  styleUrls: ['./abo-actions.component.scss']
})
export class AboActionsComponent extends AbstractDetailsComponent implements OnInit, OnDestroy {

  // store$: Observable<AboActions>;
  deactivationStore$: Observable<DeactivationState>;
  sub: Subscription;
  actionsList: BehaviorSubject<AboDetail[]> = new BehaviorSubject(ACTIONS_LIST);
  baseSiteIdSub: Subscription;
  renewalOrderSub: Subscription;

  constructor(
    private navigationService: NavigationService,
    store: Store<IAppState>,
    activatedRoute: ActivatedRoute,
    modalService: ModalService,
    componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(
      store,
      activatedRoute,
      modalService,
      componentFactoryResolver
    );
  }

  ngOnInit() {
    // this.deactivationStore$ = this.store.select('deactivation').filter(Boolean);
    // this.baseSiteIdSub = this.store.select('account').filter(Boolean).subscribe(({ baseSiteId }) => {
    //   const params = { ...this.activatedRoute.snapshot.params, baseSiteId };

      // this.store.dispatch(new DeactivateGetRenewalOrder(params));
    // });

    // this.renewalOrderSub = this.deactivationStore$
    //   // disable refund renewal button when no renewal order's placed
    //   .subscribe(({ isRenewalsOrderCreated }) => {
    //     const renewalAction = ACTIONS_LIST.find(action => action.keys.indexOf(RENEWAL_KEY) > -1);
    //     const isRenewalDisabled = (renewalAction) ? (renewalAction).isEditDisabled : false;

    //     // if (!isRenewalDisabled) {
    //     //   this.setIsEditDisabledToAction(RENEWAL_KEY, !isRenewalsOrderCreated);
    //     // }
    //   });
  }

  private setIsEditDisabledToAction(actionKey: string, value: boolean) {
    const list = _.clone(ACTIONS_LIST);
    const updatedList = list.map(action => action.keys.indexOf(actionKey) > -1 ? { ...action, isEditDisabled: value } : action);
    this.actionsList.next(updatedList);
  }

  ngOnDestroy() {
    // if (this.baseSiteIdSub && this.renewalOrderSub) {
    //   this.baseSiteIdSub.unsubscribe();
    //   this.renewalOrderSub.unsubscribe();
    // }
  }
}
