import { Observable } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { HistoryService, AuthService } from './core/services/index';
import { Store } from '@ngrx/store';
import { IAppState } from './store/index';
import { UserSetData } from './store/user/user.actions';
import { User } from './shared/models/index';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  user$: Observable<User>;
  currentYear: number;
  constructor(
    private store: Store<IAppState>,
    private historyService: HistoryService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    this.currentYear = new Date().getUTCFullYear();
    this.historyService.startRecord();
    this.updateUserStatus();
  }

  updateUserStatus() {
    const user = this.authService.getUserData();
    this.store.dispatch(new UserSetData(user));
    this.user$ = this.store.select('user');
  }

  ngOnDestroy() {
    this.historyService.stopRecording();
  }
}
