import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountSearchParam } from '../../shared/models/index';
import { ContractModel } from '../../shared/models/amway/index';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/index';
import { ContractGet } from '../../store/abo-contracts/abo-contracts.actions';
import { ContractsResponse } from '../../store/abo-contracts/abo-contracts.reducer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Modal } from 'ngx-modal';
import { CommonService } from '../../core/services/common.service';

@Component({
  selector: 'app-abo-tc-blocks',
  templateUrl: './abo-tc.component.html',
  styleUrls: ['./abo-tc.component.scss']
})
export class AboTermsAndConditionsComponent implements OnInit {

  @ViewChild('termsModal') termsModal: Modal;
  contracts$: Observable<ContractsResponse>;
  loading$: Observable<boolean>;
  contractsList: Observable<ContractModel[]>;
  selectedPreviewContract: ContractModel;
  @Input() accountDetails;
  businessNature;

  constructor(
    private store: Store<IAppState>,
    private activateRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    let params = new AccountSearchParam(this.activateRoute.snapshot.params);

    params['businessNature'] = this.accountDetails.data.accountMst.accountSubTypeCd

    this.businessNature = CommonService.getBusinessNature(this.accountDetails.data.accountMst)

    this.store.dispatch(new ContractGet(params));

    this.contracts$ = this.store.select('aboContracts');
    this.loading$ = this.contracts$.pipe(map(data => !data.loaded));

    this.contractsList = this.contracts$.pipe(map(data => data.list));
  }

  previewContract(contract: ContractModel) {
    this.selectedPreviewContract = contract;
    this.termsModal.open();
  }


}
