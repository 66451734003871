export class AreaLocation {
  postalCode: string;
  province: string;
  city: string;
  district: string;
  subDistrict: string;
  stateCode: string;
  label: string;
  value: string;
  constructor({
    postalCode,
    province,
    city,
    district,
    subDistrict,
    stateCode
  }) {
    this.postalCode = postalCode;
    this.province = province;
    this.city = city;
    this.district = district;
    this.subDistrict = subDistrict;
    this.stateCode = stateCode;
    this.label = `${postalCode || ''}, ${district || ''}, ${city ||
      ''}, ${province || ''}, ${subDistrict || ''}`;
    this.value = `${postalCode || ''}, ${district || ''}, ${city ||
      ''}, ${province || ''}, ${subDistrict || ''}`;
  }
}
