import { Action } from '@ngrx/store';

export const ACCOUNT_GET = '[Account] get';
export const ACCOUNT_GET_SUCCESS = '[Account] get success';
export const ACCOUNT_GET_FAIL = '[Account] get fail';

export class AccountGet implements Action {
  readonly type = ACCOUNT_GET;

  constructor(public payload: any = null) { }
}

export class AccountGetSuccess implements Action {
  readonly type = ACCOUNT_GET_SUCCESS;

  constructor(public payload: any = null) { }
}

export class AccountGetFail implements Action {
  readonly type = ACCOUNT_GET_FAIL;

  constructor(public payload: any = null) { }
}

export type Actions =
  | AccountGet
  | AccountGetSuccess
  | AccountGetFail;
