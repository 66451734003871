import { AppConfig } from './shared/models/index';

export const ApplicationConfig: AppConfig = {
  loginConfig: {
    masheryTokenKey: 'token',
    masheryRedirectUri: '/apigateway/authresponse',
    occTokenKey: 'occToken',
    occRedirectUri: '/apigateway/authresponse/occ'
  },
  coreConfig: {
    masheryTokenKey: 'token',
    masheryRedirectUri: '/apigateway/authresponse',
    occTokenKey: 'occToken',
    occRedirectUri: '/apigateway/authresponse/occ',
    redirectionOnNullToken: '/login'
  }
};
