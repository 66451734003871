import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { HttpParams, HttpClient } from '@angular/common/http';
import { ApiPathConfigService } from '../configs/api';
import { aboEventsAPI, accountByPartyIdAPI, partyId } from '../configs/constants';
import { StorageService, HttpService, AuthService } from '../../core/services/index';
import { environment } from '../../../environments/environment';
import { OktaJwtToken } from '../../shared/models/index';

@Injectable()
export class FindAboEventsService {

  constructor(
    private httpService: HttpService,
    private apiPathService: ApiPathConfigService,
    private http: HttpClient,
    private storage: StorageService,
    private authService: AuthService,
    private activateRoute: ActivatedRoute
  ) { }

  aboEventsSearch(params) {
    const api = this.apiPathService.getApiPath(aboEventsAPI);
    let urlParams: HttpParams = new HttpParams();
    let pageNo = (parseInt(params.page) - 1) + '';
    urlParams = urlParams.set('pageNumber', pageNo);
    urlParams = urlParams.set('pageSize', '10');
    const token = <OktaJwtToken>this.storage.getItem('okta-jwt-token');
    if (token !== null && token['id_token'] !== undefined) {
      let jwtAuthToken = token['id_token'];
      let headerParams = { 'username': params.username, 'occurred': params.occurred, 'partyId': params.partyId, 'eventType': params.eventType, 'jwtAuthToken': jwtAuthToken };
      return this.httpService.callServerGET(api, urlParams, null, 'application/json', 'application/json', true, true, headerParams).pipe(map(
        (response => {
          return response;
        })));
    } else {
      this.storeEventSearchFormData();
    }
  }

  getAboEventTypes() {
    let apiUrl = './assets/api/eventTypes.json';
    return this.http.get(apiUrl).pipe(
      map((response) => {
        const data = response;
        return data;
      }));

  }

  getAccountByPartyId(Id) {
    const api = this.apiPathService.getApiPath(accountByPartyIdAPI);
    api.path = api.path.replace(partyId, Id);
    return this.httpService.callServerGET(api, null).pipe(
      map(response => {
        return response;
      },
      error => {
        console.log(error);
      }
    ))
  };

  storeEventSearchFormData() {
    let route = this.activateRoute.snapshot.queryParams;
    const formData = {};
    if (route) {
      this.storage.setItem('formData', route);
    }
    window.location.href = this.authService.buildOktaJwtAuthCodePath();
  }
}
