import {
  ABO_DETAILS_ADDRESS_SAVE,
  ABO_DETAILS_ADDRESS_SAVE_FAIL,
  ABO_DETAILS_ADDRESS_SAVE_SUCCESS,
  ABO_DETAILS_LOAD,
  ABO_DETAILS_LOAD_SUCCESS,
  ABO_DETAILS_SAVE,
  ABO_DETAILS_SAVE_SUCCESS,
  ABO_DETAILS_SAVE_FAIL,
  ABO_TAX_JURISDICTIONS_SHOW,
  Actions,
  ABO_DETAILS_ADDRESS_CHANGE_SUCCESS,
  ABO_DETAILS_ADDRESS_CHANGE,
  ABO_BANK_DETAIL_CHANGE,
  ABO_BANK_DETAIL_CHANGE_FAIL,
  ABO_BANK_DETAIL_CHANGE_SUCCESS,
  ABO_TAX_DETAIL_CHANGE,
  ABO_TAX_DETAIL_CHANGE_SUCCESS,
  ABO_TAX_DETAIL_CHANGE_FAIL,
  ABO_TAX_CHANGE_SUCCESS,
  ABO_DETAILS_ADDRESS_DELETE,
  ABO_DETAILS_ADDRESS_DELETE_FAIL,
  ABO_DETAILS_ADDRESS_DELETE_SUCCESS,
  ABO_TAX_CHANGE_FAIL
} from './abo-personal-details.actions';
import { PersonalDetails } from '../../shared/models/amway/index';

export function aboPersonalDetailsReducer(state: PersonalDetails, { type, payload }: Actions): PersonalDetails {

  switch (type) {

    case ABO_DETAILS_LOAD:
      return { loaded: false };

    case ABO_DETAILS_LOAD_SUCCESS:
      return { loaded: true, ...payload };

    case ABO_DETAILS_SAVE:
    case ABO_DETAILS_ADDRESS_SAVE:
    case ABO_DETAILS_ADDRESS_DELETE:
    case ABO_DETAILS_ADDRESS_CHANGE:
    case ABO_BANK_DETAIL_CHANGE:
    case ABO_TAX_DETAIL_CHANGE:
      return Object.assign({}, state, {
        [payload.type]: { loader: 'Saving' },
        action: type
      });

    case ABO_DETAILS_SAVE_FAIL:
    case ABO_DETAILS_ADDRESS_SAVE_FAIL:
    case ABO_DETAILS_ADDRESS_DELETE_FAIL:
    case ABO_BANK_DETAIL_CHANGE_FAIL:
    case ABO_TAX_DETAIL_CHANGE_FAIL:
    case ABO_TAX_CHANGE_FAIL:
      return Object.assign({}, state, {
        [payload.type]: { loader: null },
        error: payload.error
      });

    case ABO_DETAILS_SAVE_SUCCESS:
      return Object.assign({}, state, {
        [payload.type]: { loader: 'Updating' },
        saved: true,
        action: type
      });
    case ABO_DETAILS_ADDRESS_CHANGE_SUCCESS:
    case ABO_DETAILS_ADDRESS_DELETE_SUCCESS:
      return Object.assign({}, state, {
        [payload.type]: { loader: null },
        saved: true,
        action: type
      });

    case ABO_TAX_JURISDICTIONS_SHOW:
      return Object.assign({}, state, {
        [payload.type]: { loader: null },
        taxJurisdictions: payload.taxJurisdictions,
        addressData: payload.addressData,
        action: type
      });
    case ABO_BANK_DETAIL_CHANGE_SUCCESS:
     return Object.assign({}, state, {
      [payload.type]: { loader: null },
      saved: true,
      action: type
    });
    case ABO_TAX_DETAIL_CHANGE_SUCCESS:
     return Object.assign({}, state, {
      [payload.type]: { loader: null },
      saved: true,
      action: type
    });
    case ABO_TAX_CHANGE_SUCCESS:
     return Object.assign({}, state, {
      [payload.type]: { loader: null },
      saved: true,
      action: type
    });
    default:
      return state;
  }
}
