import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { defaultCountryCode } from '../../shared/configs/constants';

@Injectable()
export class CountryEnvironmentService {
    private applicationCountry: string;
    
    constructor(){
        if(environment.production){
            let appUrl = window.location.host;
            this.applicationCountry = appUrl.substr(appUrl.lastIndexOf('.') + 1);
        } else {
            this.applicationCountry = defaultCountryCode;
        }
    }

}