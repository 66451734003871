import { Pipe, PipeTransform } from '@angular/core';

const ASTERISK = '*';
const NUMS_REGEX = /\d(?=(?:[ -]*\d){4,99}(?![ -]*\d))/gm;

@Pipe({
  name: 'amwHiddenTax'
})
export class TaxPipe implements PipeTransform {

  transform(value: string, args?: any): string {
    return !value ? value : value.replace(NUMS_REGEX, ASTERISK);
  }

}
