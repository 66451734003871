import {
  Component, OnInit,
  OnDestroy, ChangeDetectionStrategy,
  Input, Output, EventEmitter
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, ReplaySubject } from 'rxjs';
import { filter, map, first, switchMap } from 'rxjs/operators';
import { ReversalType, DeactivationState } from '../../../shared/models/amway/index';
import { IAppState } from '../../../store/index';
import { NavigationService } from '../../../core/services/index';
import { DeactivateClear, DeactivatePost } from '../../../store/deactivation/deactivation.actions';
enum StepType {
  Reason,
  Confirm,
}

interface Step {
  index?: number;
  title: string;
  btnText: string;
  type: StepType;
}

const STEPS = [
  {
    title: 'select reason',
    btnText: 'next',
    type: StepType.Reason
  },
  {
    title: 'confirm',
    btnText: 'confirm',
    type: StepType.Confirm
  }
];

const RenewalSTEPS = [
  {
    title: 'confirm',
    btnText: 'confirm',
    type: StepType.Confirm
  }
];

@Component({
  selector: 'app-deactivation-modal',
  templateUrl: './deactivation-modal.component.html',
  styleUrls: ['./deactivation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeactivationModalComponent implements OnInit, OnDestroy {

  @Input() params;
  @Input() type: ReversalType;
  @Output() onCancel = new EventEmitter<void>();
  store$: Observable<DeactivationState>;
  updateSub: Subscription;
  storeSubScription: Subscription;
  disableBtn$: Observable<boolean>;
  baseSiteId$: Observable<string>;
  currentStep$ = new ReplaySubject(1) as ReplaySubject<Step>;
  steps: Step[];
  stepType = StepType;
  loader$: Observable<boolean>;
  isRenewal: boolean;

  constructor(
    private store: Store<IAppState>,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.isRenewal = this.type === ReversalType.RENEWAL;
    this.steps = (this.isRenewal) ? RenewalSTEPS : STEPS.slice(this.isRenewal ? 1 : 0);
    this.store$ = this.store.select('deactivation').pipe(filter(Boolean));
    this.updateSub = this.store$.pipe(filter(({ updated }) => updated)).subscribe(() => this.updateInfo());
    this.loader$ = this.store$.pipe(map(({ loading }) => loading));
    this.currentStep$.next(this.getStep());

    this.disableBtn$ = this.currentStep$.pipe(
      filter(step => step && step.type === StepType.Reason)
      ,switchMap((step: Step) => this.store$.pipe(map(store => !store.selectedReason))));
  }

  submit() {
    if (this.isRenewal) {
      this.store.dispatch(new DeactivatePost({
        req: this.params,
        isRenewal: this.isRenewal
      }));
    } else {
      let count = 0;
    this.storeSubScription =  this.store$.subscribe((response) => {
      if (count > 0) {
        return;
      }
        const { selectedReason, issues: products, orderCode, orderStore } = response;
        const { abo, aff, partyId } = this.params;
        count = 1;
        this.store.dispatch(new DeactivatePost({
          req: this.params,
          isRenewal: this.isRenewal,
          orderCode,
          orderStore,
          selectedReason,
          products,
        }));
      });
    this.storeSubScription.unsubscribe();
    }
  }

  next() {
    if (this.isRenewal) {
      this.submit();
    } else {
      this.currentStep$.pipe(first()
        ,map(step => this.getStep(step.index + 1)))
        .subscribe((nextStep) => {
          nextStep ? this.currentStep$.next(nextStep) : this.submit();
        });
    }
  }

  cancel(): void {
    this.store.dispatch(new DeactivateClear());
    this.onCancel.emit();
  }

  getStep(index = 0) {
    const step = this.steps[index];
    return step ? { ...step, index } : null;
  }

  private updateInfo() {
    this.cancel();
    this.navigationService.updateInfoAfterReRegistration(this.params);
  }

  ngOnDestroy() {
    this.updateSub.unsubscribe();
  }
}
