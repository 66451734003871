import { Action } from '@ngrx/store';

export const ABO_SEARCH = '[Abo search] search';
export const ABO_SEARCH_SUCCESS = '[Abo search] search success';
export const ABO_SEARCH_FAIL = '[Abo search] search fail';
export const ABO_SEARCH_CLEAR = '[Abo search] search clear';

export class AboSearch implements Action {
  readonly type = ABO_SEARCH;

  constructor(public payload: any = null) { }
}

export class AboSearchSuccess implements Action {
  readonly type = ABO_SEARCH_SUCCESS;

  constructor(public payload: any) { }
}

export class AboSearchFail implements Action {
  readonly type = ABO_SEARCH_FAIL;

  constructor(public payload: any = null) { }
}

export class AboSearchClear implements Action {
  readonly type = ABO_SEARCH_CLEAR;

  constructor(public payload: any = null) { }
}

export type Actions =
  | AboSearch
  | AboSearchSuccess
  | AboSearchFail
  | AboSearchClear;
