import * as _ from 'lodash';
import {
  Actions,
  DEACTIVATION_GET_RENEWAL_ORDER,
  DEACTIVATION_GET_ISSUES_SUCCESS,
  DEACTIVATION_GET_RENEWAL_ORDER_SUCCESS,
  DEACTIVATION_SELECT_ISSUES,
  DEACTIVATION_GET_REASONS_SUCCESS,
  DEACTIVATION_GET_RENEWAL_ORDER_FAIL,
  DEACTIVATION_SELECT_REASON,
  DEACTIVATION_CLEAR,
  DEACTIVATION_POST,
  DEACTIVATION_POST_SUCCESS,
  DEACTIVATION_POST_FAIL,
} from './deactivation.actions';
import { DeactivationState } from '../../shared/models/amway/index';

function getTotal(issues): number {
  return issues
    .filter(issue => issue.selected)
    .reduce((memo, issue) => memo + parseFloat(issue.price), 0);
}

export function deactivationReducer(state: DeactivationState, { type, payload }: Actions): DeactivationState {
  switch (type) {

    case DEACTIVATION_GET_REASONS_SUCCESS:
      return {
        ...state,
        ...payload,
        updated: false,
        loading: false,
      };

    case DEACTIVATION_GET_ISSUES_SUCCESS:
      return {
        ...state,
        ...payload,
        updated: false,
        loading: false,
        total: getTotal(payload.issues),
        currencyIso: _.get(payload, '[0].currency', ''),
      };

    case DEACTIVATION_GET_RENEWAL_ORDER:
      return {
        ...state,
        isRenewalsOrderCreated: false,
      };

    case DEACTIVATION_GET_RENEWAL_ORDER_SUCCESS:
      return {
        ...state,
        // NOTE:  possibly we'll need to include a `createdAt` here field to check creation date
        isRenewalsOrderCreated: payload,
      };

    case DEACTIVATION_GET_RENEWAL_ORDER_FAIL:
      return {
        ...state,
        isRenewalsOrderCreated: false,
      };

    case DEACTIVATION_SELECT_REASON:
      return {
        ...state,
        selectedReason: payload.reason,
      };

    case DEACTIVATION_POST:
      return {
        ...state,
        updated: false,
        loading: true,
      };

    case DEACTIVATION_POST_SUCCESS:
      return {
        ...state,
        updated: true,
        loading: false,
      };

    case DEACTIVATION_POST_FAIL:
      return {
        ...state,
        updated: false,
        loading: false,
      };

    case DEACTIVATION_SELECT_ISSUES:
      const issues = state.issues.map(issue => {
        return issue.code === payload.code ? { ...issue, selected: !issue.selected } : issue;
      });
      return {
        ...state,
        issues,
        total: getTotal(issues),
      };

    case DEACTIVATION_CLEAR:
      return {
        ...state,
        issues: null,
        selectedReason: null,
        total: 0,
        updated: false,
      };

    default:
      return state;
  }
}
