import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { LoginService } from '../shared/login.service';
import { MasheryToken } from '../../shared/models/index';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {

  constructor(
    private activateRoute: ActivatedRoute,
    private location: Location,
    private loginService: LoginService
  ) {
  }

  ngOnInit() {
    this.handleRouteParams();
  }

  handleRouteParams() {
    let route = this.activateRoute.snapshot.fragment;
    this.location.go('');
    const token = {};
    if (route) {
      route = route.substring(route.indexOf('#'), route.length);
      const routeParams = route.split('&');
      routeParams.forEach((param) => {
        const property: { key: string, value: string } = { key: param.split('=')[0], value: param.split('=')[1] };
        token[property.key] = property.value;
      });
    }
    this.loginService.storeMasheryAuthCode(<MasheryToken>token);
  }
}
