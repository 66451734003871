import { Injectable } from '@angular/core';
import { Response } from '@angular/http';

import { Observable, of, empty } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';

import { Effect, Actions } from '@ngrx/effects';
import {
  SEARCHFORCE_CONNECT_ID_SEARCH,
  SearchforceConnectIdEventsSearch,
  SearchforceConnectIdEventsSearchSuccess,
  SearchforceConnectIdEventsSearchFail,
  GET_EVENT_TYPES,
  SearchforceConnectIdGetEventTypes,
  SearchforceConnectIdGetEventTypesFail,
  SearchforceConnectIdGetEventTypesSuccess,
  GET_LINKED_ACCOUNTS,
  GetLinkedAccounts,
  GetLinkedAccountsSuccess,
  GetLinkedAccountsFail,
} from './find-amway-id-events.actions';

import { CommonService } from '../../shared/services';

import { SearchforceConnectIdResponse } from '../../shared/models/searchforce';
import { FindAboEventsService } from '../../shared/services/index';
import { StorageService } from '../../core/services/index';

@Injectable()
export class SearchforceConnectIdSearchEffects {
  @Effect()
  searchforceConnectIdSearch$ = this.actions$
    .ofType(SEARCHFORCE_CONNECT_ID_SEARCH).pipe(
    switchMap(({ payload }: SearchforceConnectIdEventsSearch) => {
      return this.findAboEventsService
        .aboEventsSearch(payload)
        .pipe(map(results => this.replaceEventTypeWithLabel(results))
        ,catchError(error => {
          if(error.status === 403 || error.status === 401) {
            this.storage.removeItem('okta-jwt-token');
            this.findAboEventsService.storeEventSearchFormData();
          }
          return empty();
        })
        ,map((response) => new SearchforceConnectIdEventsSearchSuccess(response)));
    }));

  @Effect()
  getEventTypes$ = this.actions$
    .ofType(GET_EVENT_TYPES).pipe(
    switchMap(({ payload }: SearchforceConnectIdGetEventTypes) => {
      return this.findAboEventsService
        .getAboEventTypes()
        // .map((response: Response) => response.json())
        .pipe(catchError(error => of(new SearchforceConnectIdGetEventTypesFail(error)))
        ,map((response) => new SearchforceConnectIdGetEventTypesSuccess(response)));
    }));

  @Effect()
  getlinkedAccounts$ = this.actions$
    .ofType(GET_LINKED_ACCOUNTS).pipe(
    // .map(({ payload }: GetLinkedAccounts) => this.common.createRequestParams({partyId: payload}))
    switchMap((params: GetLinkedAccounts) => {
      // return this.apiHttp
      //   .get(ACCOUNTS_BY_PARTY_ID, params)
      return this.findAboEventsService
        .getAccountByPartyId(params['payload'])
        // .map((response: Response) => response.json())
        .pipe(catchError(error => of(new GetLinkedAccountsFail(error)))
        ,map((response) => new GetLinkedAccountsSuccess(response)));
    }));

  constructor(
    private actions$: Actions,
    // private apiHttp: ApiHttp,
    private common: CommonService,
    private findAboEventsService: FindAboEventsService,
    private storage: StorageService
  ) { }

  private replaceEventTypeWithLabel(results: SearchforceConnectIdResponse): any {
   if(results.data !== undefined){
    return {
      ...results,
      data: results.data.map(item => ({
        ...item,
        eventType: this.common.createSearchforceEventTypeLabel(item.eventType),
      })),
    };
  } else {
    return false;
  }
  }
}
