import { Action } from '@ngrx/store';

export const REGIONS_LOAD = '[Regions] load';
export const REGIONS_LOAD_SUCCESS = '[Regions] load success';
export const REGIONS_LOAD_FAIL = '[Regions] load fail';
export const STATES_LOAD = '[States] load';
export const STATES_LOAD_SUCCESS = '[States] load success';
export const STATES_LOAD_FAIL = '[States] load fail';

export class RegionsLoad implements Action {
  readonly type = REGIONS_LOAD;

  constructor(public payload: any = null) { }
}

export class RegionsLoadSuccess implements Action {
  readonly type = REGIONS_LOAD_SUCCESS;

  constructor(public payload: any = null) { }
}

export class RegionsLoadFail implements Action {
  readonly type = REGIONS_LOAD_FAIL;

  constructor(public payload: any = null) { }
}
export class StatesLoad implements Action {
  readonly type = STATES_LOAD;

  constructor(public payload: any = null) { }
}

export class StatesLoadSuccess implements Action {
  readonly type = STATES_LOAD_SUCCESS;

  constructor(public payload: any = null) { }
}

export class StatesLoadFail implements Action {
  readonly type = STATES_LOAD_FAIL;

  constructor(public payload: any = null) { }
}

export type Actions =
  | RegionsLoad
  | RegionsLoadSuccess
  | RegionsLoadFail
  | StatesLoad
  | StatesLoadFail
  | StatesLoadSuccess;
