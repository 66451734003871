import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { DropdownOption } from '../../../shared/models';
import { PartyService, AccountService } from '../../../shared/services';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../store';
import { AboDetailsValidationService } from '../../abo-details-validation.service';
import { PartiesAccount } from '../../../store/account-parties/account-parties.actions';
import { Party } from '../../../shared/models/party-basic.models';
import { PartyList, Account, InheritanceData } from '../../../shared/models/amway';
import { AccountGet } from '../../../store/account/account.actions';


@Component({
  selector: 'app-abo-inheritance-box',
  templateUrl: './abo-inheritance-form.component.html',
  styleUrls: ['./abo-inheritance-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboInheritanceFormComponent implements OnInit {
  @Input() reasonDropdownOptions: DropdownOption[];
  loader: boolean = false;
  errors: any;
  accountFullDetail: Account;
  partyList;
  partyCurrent = {};
  @Input() aboinheritanceInfo;
  @Output() cancel = new EventEmitter();
  @Output() accountLoader = new EventEmitter();
  account$;
  serverError;
  currentPartyOption: DropdownOption = new DropdownOption({});
  reasonReadOnly: boolean = true;
  partyListReadOnly: boolean = true;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private partyService: PartyService,
    private cdr: ChangeDetectorRef,
    private store: Store<IAppState>,
    private aboValidationService: AboDetailsValidationService,
    private accountService: AccountService
  ) {}
  
  ngOnInit() {

  }
  
  updateReason(event) {
    this.aboinheritanceInfo['reason'] = event.id
  }
  
  cancelChanges(component){
    if (component){ this.dispatchAccount() };
    this.cancel.emit();
  }
  
  dispatchAccount(){
    this.accountLoader.emit();
    const params = this.activatedRoute.snapshot.params;
    this.store.dispatch(new AccountGet(params));
  }

  updateParty(event){
    this.aboinheritanceInfo['party'] = event.id
  }

  saveChanges(){
    this.serverError = ''
    this.errors = this.aboValidationService.validateInheritanceData(this.aboinheritanceInfo)
    if (_.isEmpty(this.errors)) {
      this.checkParty();
    }
  }

  checkParty() {
    let primartyPartyId, coApplicantpartyId
    this.loader = true
    if (this.aboinheritanceInfo['party'] == 'Primary') {
      primartyPartyId = this.accountFullDetail.primaryParty.partyMst.partyId
      this.createSuccessor(primartyPartyId, 'Single')
    } else if (this.aboinheritanceInfo['party'] == 'CoApplicant') {
      coApplicantpartyId = this.accountFullDetail.coApplicantParty.partyMst.partyId
      this.createSuccessor(coApplicantpartyId, 'Single');
    }

    if  (this.aboinheritanceInfo['party'] == 'Both') {
      primartyPartyId = this.accountFullDetail.primaryParty.partyMst.partyId
      coApplicantpartyId = this.accountFullDetail.coApplicantParty.partyMst.partyId
      this.createSuccessor([primartyPartyId, coApplicantpartyId] , 'Multiple');
    }
  }

  createSuccessor(partyIds, call){
    const {aff, abo} = this.activatedRoute.snapshot.params;
    const request = {}
    request['aff'] = aff;
    request['linkPartyAboNum'] = this.aboinheritanceInfo['aboNum']
    request['aboNum'] = abo;
    request['linkPartyId'] = partyIds;
    request['reasCd'] = this.aboinheritanceInfo['reason'];

    let partServiceRequest = this.partyService.updatePartyLink(request, call)
    if (call == 'Single') {
      partServiceRequest.subscribe((response) => {
        this.loader = false
        if (response.value && response.value.type == "linkPartyResponse"){
          this.cancelChanges(true);
          return
        } 
        if (response.error) {
          let errorObj = JSON.parse(response.error)
          this.serverError = errorObj.errorMessage.message
        }
        this.cdr.detectChanges();
      })
    } else if (call == 'Multiple') {
      partServiceRequest.subscribe(([res1, res2]) => {
        this.loader = false
        if (res1.error) {
          if (res1.error.errorMessage) { this.serverError = res1.error.errorMessage; return } 
          let errorObj = JSON.parse(res1.error);
          this.serverError = errorObj.errorMessage.message;
        }
        if (res2.error) {
          if (res2.error.errorMessage) { this.serverError = res2.error.errorMessage; return } 
          let errorObj = JSON.parse(res2.error);
          this.serverError = this.serverError ? this.serverError + '<br/>'+ errorObj.errorMessage.message : errorObj.errorMessage.message;
        }
        if (res1.type == "linkPartyResponse" || res2.type == "linkPartyResponse"){ this.dispatchAccount(); }
        if (res1.type == "linkPartyResponse" && res2.type == "linkPartyResponse"){ this.cancelChanges(true); }
        this.cdr.detectChanges();
      })
    }
  }

  checkAboLength(event) {
    const {abo} = this.activatedRoute.snapshot.params;
    if (this.aboinheritanceInfo.aboNum == abo) {
      this.serverError = 'Please enter another abo'
      return
    } else if (this.aboinheritanceInfo.aboNum.length == 7) {
      this.hitAccountRequest();
    } else {
      return
    }
  }

  hitAccountRequest(){
    const {aff} = this.activatedRoute.snapshot.params;
    this.serverError = ''
    this.loader = true
    this.account$ = this.accountService.getAccountData(aff, this.aboinheritanceInfo.aboNum, '', null).subscribe(
      response => {
        if (response.accountMst.statusCd == 'Inactive'){
          this.loader = false
          this.serverError = 'Entered abo is inactive'
          this.cdr.detectChanges();
          return false
        }
        this.reasonReadOnly = false
        this.accountFullDetail = response
        let aboPartyList = _.filter(response && response.partyList, function(o){ return o.partyMst.roleCd == "BusinessOwner" })
        let primaryPartyString = 'Primary'
        this.aboinheritanceInfo['party'] = primaryPartyString
        if (aboPartyList.length > 1) {
          this.partyList = [{id: primaryPartyString, text: primaryPartyString}, {id: 'CoApplicant', text: 'CoApplicant'}, {id: 'Both', text: 'Both'}]
          this.partyListReadOnly = false
        } else {
          this.partyList = [{id: primaryPartyString, text: primaryPartyString}]
          this.currentPartyOption = {id: primaryPartyString, text: primaryPartyString}
          this.partyListReadOnly = true
        }

        this.loader = false
        this.cdr.detectChanges();
      },
      err => {
        this.loader = false
        if (err.status == 404) {
          this.serverError = 'Kindly enter valid abo'
        }
        this.cdr.detectChanges();
      }
    )
  }

}
