export enum ContactPointPurposeCdEnum {
  AD = 'AD',
  AwardBoard = 'AwardBoard',
  Billing = 'Billing',
  Bonus = 'Bonus',
  Ecomm = 'Ecomm',
  Email = 'Email',
  GeneralPurpose = 'GeneralPurpose',
  Inquiries = 'Inquiries',
  Mailing = 'Mailing',
  Marketing = 'Marketing',
  PostalMail = 'PostalMail',
  Registration = 'Registration',
  Shipping = 'Shipping',
  SMS = 'SMS',
  Telecommunication = 'Telecommunication',
  Tax = 'Tax'
}
