import { catchError } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { retry, map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IAppState } from '../index';
import { VolumeService } from '../../shared/services/index';
import { AWARDS_GET, AwardsGet, AwardsGetSuccess, AwardsGetFail } from './awards.actions';

@Injectable()
export class AwardsEffects {

  constructor(
    private actions$: Actions,
    private store$: Store<IAppState>,
    private volService: VolumeService
  ) {
  }

  @Effect()
  awardsGet$ = this.actions$
    .ofType(AWARDS_GET)
    .pipe(switchMap((action: AwardsGet) => {
      return this.volService.getVolumeAwards(action.payload)
        .pipe(map((response) => new AwardsGetSuccess(response))
        ,catchError(error => of(new AwardsGetFail()))
        ,retry(1));
    }));

}
