import { Action } from '@ngrx/store';

export const SET_MARKET = '[Settings] set market code';

export class SettingsSetMarketCode implements Action {
  readonly type = SET_MARKET;

  constructor(public payload: any) {}
}

export type Actions =
  | SettingsSetMarketCode;
