import { Injectable } from '@angular/core';
import { catchError, map, retry } from 'rxjs/operators';
import { HttpService } from '../../core/services/index';
import { AccountSearchParam } from '../models/index';
import { ApiPathConfigService } from '../configs/api';
import { salesAff, aboNum, partyId, getABOTaxAPI } from './../configs/constants';
import { AboTaxDetailChangePayload } from '../models/abo-personal-details.model';
import { Observable } from '../../../../node_modules/rxjs';

const UPDATE_TAX_ID = 'UPDATE_TAX_ID';
const UPDATE_TAX_ID_TYPE = 'UPDATE_TAX_ID_TYPE';
const CREATE_NEW_TAX = 'CREATE_NEW_TAX';

@Injectable()
export class TaxService {

  constructor(
    private httpService: HttpService,
    private apiPathService: ApiPathConfigService
  ) {
  }

  getTaxes(req: AccountSearchParam) {
    const api = this.apiPathService.getApiPath(getABOTaxAPI);
    api.path = api.path.replace(salesAff, req.aff).replace(aboNum, req.abo).replace(partyId, req.partyId);
    return this.httpService.callServerGET(api, null)
      .pipe(map((response: { taxList: [{}] }) => {
        return (response && response.taxList) ? response.taxList[0] : {};
      }),catchError((error) => {
        return error;
      })
      ,retry(1));
  }

  getAction(originalTaxTypeCd: string, taxTypeCd: string): string {
    const actions = [
      {
        isValid: !originalTaxTypeCd,
        type: CREATE_NEW_TAX,
      },
      {
        isValid: originalTaxTypeCd && originalTaxTypeCd !== taxTypeCd,
        type: UPDATE_TAX_ID_TYPE,
      },
      {
        isValid: originalTaxTypeCd === taxTypeCd,
        type: UPDATE_TAX_ID,
      },
    ];

    return actions.find((action) => action.isValid).type;
  }

}
