import { AccountCard } from './../models/account.model';
import { HttpParams } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { filter, map, first } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpService, CommonService } from '../../core/services';
import {
  ABOSearchQuery,
  ABOSearchResponse,
  ABOSearchResult,
  Country,
  AccountSearch,
  AccountSearchParam,
  TaxCertificate
} from '../models';
import { ApiPathConfigService } from '../configs/api';
import { salesAff, aboNum, getAmwayAccountByAboNumAPI, getABOSearchListAPI, TAX_COUNTRY_CODE
, TAX_CERT_CODE } from '../configs/constants';
import { CountryService } from './country.service';
import { Usage } from '../models/amway-usage.model';
import { BusinessNatureName } from '../../shared/models/amway';
import { ABO_NAME, CLIENT_NAME, MEMBER_NAME } from '../configs/constants';

import {
  Account,
  PartyList,
  SearchAccount,
  Party,
  PartyBasic,
  PhoneList,
  UsageList,
  Tax,
  ABOBasicInfo,
  PersonalId
} from '../models/amway';
import * as _ from 'lodash';
import {
  ContactPointTypeCdEnum,
  ContactPointPurposeCdEnum,
  IboSearchType
} from '../models/enums';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store';
import { TaxService } from './tax.service';
import { BankDetail } from '../models/bank-detail.model';
import { AddressList } from '../models/party-basic.models';
import { ABOBusinessDetails } from '../models/abo-business-details.model';

const CUSTOMER_TYPE_CD = 'Customer';
const PERSONAL_NAME_TYPE_CD = 'Legal';
const COUNTRY_NAME_FIELD = 'name';
const DEFAULT_BASE_SITE_ID = 'lynx';
const PRIMARY_ON_ACCOUNT = '1';
const COUNTRY_CODES_MAP = {
  CA: `${DEFAULT_BASE_SITE_ID}-ca`,
  DO: `${DEFAULT_BASE_SITE_ID}-do`,
  US: DEFAULT_BASE_SITE_ID
};
const USAGE_ADDRESS_TYPES = [
  ContactPointPurposeCdEnum.Shipping,
  ContactPointPurposeCdEnum.Mailing,
  ContactPointPurposeCdEnum.Tax
];
const WRONG_PARTY_ID_ERROR = {
  statusCode: 404,
  error: 'party Id not found'
};
const PHONE_TYPES = {
  mobile: ContactPointTypeCdEnum.Mobile,
  alternate: ContactPointTypeCdEnum.AlternatePhone
};

const TAX_TYPE_CODE = 'NPWP';
const ERROR_INVALID_VALUE = 'Kindly enter valid value'

@Injectable()
export class ABOService {
  constructor(
    private httpService: HttpService,
    private countryService: CountryService,
    private taxService: TaxService,
    private store: Store<IAppState>,
    private apiPathService: ApiPathConfigService
  ) {}

  getABOSearch(searchQuery: ABOSearchQuery) {
    if (_.isEmpty(searchQuery)) { searchQuery = new ABOSearchQuery({}); }
    let query = searchQuery.query
    // return this.searchByAboNum(searchQuery);
    if (isNaN(Number(query))) { return this.queryForString(searchQuery); }
    if (!isNaN(Number(query))){ return this.queryForNumber(searchQuery); }
  }

  queryForNumber(searchQuery: ABOSearchQuery) {
    let searchRecordsParams = this.searchRecordParams(searchQuery)
    let searchResults;

    if(searchQuery.searchType === IboSearchType.ibo){
      searchRecordsParams = CommonService.replacePropertyValue('aboNum', searchQuery.query, searchRecordsParams);
    }else if(searchQuery.searchType === IboSearchType.mobile){
      searchRecordsParams = CommonService.replacePropertyValue('phoneLocalNum', searchQuery.query, searchRecordsParams)
    } else {
      return Observable.throw({error: ERROR_INVALID_VALUE});
    }

    searchResults =  this.searchRecords(searchQuery, searchRecordsParams);
    return searchResults;
  }

  queryForString(searchQuery: ABOSearchQuery) {
    let searchRecordsParams = this.searchRecordParams(searchQuery);
    let searchResults;
    // for search by email
    if(searchQuery.searchType === IboSearchType.ibo || searchQuery.searchType === IboSearchType.mobile){
      return Observable.throw({error: ERROR_INVALID_VALUE});
    }
    if (searchQuery.query.indexOf('@') > -1 && searchQuery.searchType === IboSearchType.email) {
      searchRecordsParams = CommonService.replacePropertyValue('emailAddress', searchQuery.query, searchRecordsParams)
    } else if (searchQuery.query.indexOf('@') === -1 && searchQuery.searchType === IboSearchType.name) {
      const query_array = searchQuery.query.trim().split(/\s+/)
      if (query_array.length === 2) {
        searchRecordsParams = CommonService.replacePropertyValue('lastName', query_array[1].trim(), searchRecordsParams)
        searchRecordsParams = CommonService.replacePropertyValue('firstName', query_array[0].trim(), searchRecordsParams)
      } else {
        return Observable.throw({error: ERROR_INVALID_VALUE})
      }
    } else {
      return Observable.throw({error: ERROR_INVALID_VALUE})
    }

    searchResults = this.searchRecords(searchQuery, searchRecordsParams)
    return searchResults;
  }

  searchByAboNum(searchQuery: ABOSearchQuery) {
    const api = this.apiPathService.getApiPath(getAmwayAccountByAboNumAPI);
    api.path = api.path
      .replace(salesAff, searchQuery.salesAff + '')
      .replace(aboNum, searchQuery.query);
    let urlParams: HttpParams = new HttpParams();
    urlParams = urlParams.set('detailLevelCd', 'FullDetail');
    return this.httpService
      .callServerGET(api, urlParams, null).pipe(
      map((response: { account: Account }) => {
        const amwayAccount: AccountSearch = new AccountSearch(response.account);
        amwayAccount.partyList = amwayAccount.partyList =
          amwayAccount.accountSubType === CUSTOMER_TYPE_CD
            ? []
            : amwayAccount.partyList;

        // filter Successor records
        amwayAccount.partyList = _.filter(amwayAccount.partyList, function(o){ 
          return o.roleCd != 'Successor'})

        const startItem =
          searchQuery.currentPage * searchQuery.offset - searchQuery.offset;
        const endItem = startItem + searchQuery.offset;
        const queryResult: ABOSearchResponse = new ABOSearchResponse({
          page: searchQuery.currentPage,
          offset: searchQuery.offset,
          statusCd: 200,
          searchByEmail: false,
          searchResults: amwayAccount.partyList,
          resultsCount: amwayAccount.partyList.length
        });

        // business nature 
        let businessDetaills: ABOBusinessDetails = new ABOBusinessDetails(response.account.accountMst)
        queryResult.data.forEach(item => Object.assign(item, {businessNature: businessDetaills.businessNature}))
        return queryResult;
      }));
  }

  searchRecordParams(searchQuery: ABOSearchQuery){
    return {
      isoCntryCd: [searchQuery.countryCd],
      lastName: '',
      emailAddress: '',
      postalCode: '',
      phoneInfo: {
        phoneLocalNum: '',
        phoneCntryCd: '',
        phoneAreaCd: ''
      },
      pageSize: searchQuery.offset + '',
      requestingPage: searchQuery.currentPage + '',
      firstName: '',
      aboNum: '',
      userName: '',
      cityName: '',
      stateCd: '',
      taxId: '',
      accountSubTypeCdList: ['BusinessOwner', 'Member', 'Employee'],
      personalId: ''
    };
  }

  searchRecords(searchQuery, searchRecordsParams) {
    const api = this.apiPathService.getApiPath(getABOSearchListAPI);
    api.path = api.path.replace(
      salesAff,
      searchQuery.salesAff + ''
    );
    const bodyParams = searchRecordsParams;
    const searchList: ABOSearchResult[] = [];
    return this.httpService
      .callServerPOST(api, null, bodyParams).pipe(
      map(response => {
        const accountList = response.accountList;
        accountList.filter(
          account => !(account.accountSubTypeCd === CUSTOMER_TYPE_CD)
        );

        accountList.forEach(account => {
          account.partyList = _.filter(account.partyList, function(o){
            return o.roleCd != 'Successor'
          })
          account.partyList.forEach(partyObj => {
            const emailInfo = this.getActiveEmailInfoForSearch(partyObj.ecommList);
            let emailAddr = '';
            if (emailInfo.length > 0) { emailAddr = emailInfo[0].ecommAddr; }
            searchList.push(
              new ABOSearchResult({
                abo: account.aboNum,
                aff: account.salesPlanAff,
                partyId: partyObj.partyId,
                givenName: partyObj.givenName,
                familyName: partyObj.familyName,
                status: account.statusCd,
                email: { ecommAddr: emailAddr },
                primaryOnAccount: partyObj.primaryOnAccount,
                roleCd: partyObj.roleCd,
                businessNature: CommonService.getBusinessNature(account),
                lglEntityType: account.lglEntityType
              })
            );
          })
        })

        const queryResult: ABOSearchResponse = new ABOSearchResponse({
          page: searchQuery.currentPage,
          offset: searchQuery.offset,
          statusCd: 200,
          searchByEmail: true,
          searchResults: searchList,
          resultsCount: searchList.length
        });

        queryResult.data = searchList;
        queryResult.resultsCount = searchList.length;
  
        return queryResult;
      }));
  }

  getActiveEmailInfoForSearch(ecommList){
    return ecommList.filter(eCom => {
      return (
        (eCom.ecommType ===
          ContactPointTypeCdEnum.PersonalEmail.toString() ||
          eCom.ecommType ===
            ContactPointTypeCdEnum.BusinessEmail.toString())
        && eCom.statusCd === 'Valid'
      );
    });
  }

  searchByEmailId(searchQuery: ABOSearchQuery) {
    const api = this.apiPathService.getApiPath(getABOSearchListAPI);
    api.path = api.path.replace(
      salesAff,
      searchQuery.salesAff + ''
    );
    const bodyParams = this.getABOSearchBodyParams(searchQuery);
    const searchList: ABOSearchResult[] = [];
    return this.httpService
      .callServerPOST(api, null, bodyParams).pipe(
      map((response: { accountList: Array<SearchAccount> }) => {
        const accountList = response.accountList;
        accountList.filter(
          account => !(account.accountSubTypeCd === CUSTOMER_TYPE_CD)
        );
        
        accountList.forEach(account => {
          const party = account.partyList.find(partyObj => {
            return !!partyObj.ecommList.filter(ecom => {
              return searchQuery.query.toLocaleLowerCase() === ecom.ecommAddr.toLocaleLowerCase();
            }).length;
          });

          // account.partyList = _.filter(account.partyList, function(o){ 
          //   return o.partyMst.roleCd != 'Successor'
          // })

          searchList.push(
            new ABOSearchResult({
              abo: account.aboNum,
              aff: account.salesPlanAff,
              partyId: party.partyId,
              givenName: party.givenName,
              familyName: party.familyName,
              status: account.statusCd,
              email: { ecommAddr: searchQuery.query },
              primaryOnAccount: party.primaryOnAccount
            })
          );
        });
        const startItem =
          searchQuery.currentPage * searchQuery.offset - searchQuery.offset;
        const endItem = startItem + searchQuery.offset;
        const queryResult: ABOSearchResponse = new ABOSearchResponse({
          page: searchQuery.currentPage,
          offset: searchQuery.offset,
          statusCd: 200,
          searchByEmail: true,
          searchResults: searchList.slice(startItem, endItem),
          resultsCount: searchList.length
        });
        queryResult.data = searchList.slice(startItem, endItem);
        queryResult.resultsCount = searchList.length;
        
        // business nature
        let isCustomer = accountList[0].accountSubTypeCd === BusinessNatureName.Customer;
        let businessNature =  isCustomer ? CLIENT_NAME : ABO_NAME;
        queryResult.data.forEach(item => Object.assign(item, {businessNature: businessNature}))

        return queryResult;
      }));
  }

  getABOSearchBodyParams(searchQuery: ABOSearchQuery) {
    return {
      isoCntryCd: [searchQuery.countryCd],
      lastName: '',
      emailAddress: searchQuery.query,
      postalCode: '',
      phoneInfo: {
        phoneLocalNum: '',
        phoneCntryCd: '',
        phoneAreaCd: ''
      },
      pageSize: searchQuery.offset + '',
      requestingPage: searchQuery.currentPage + '',
      firstName: '',
      aboNum: '',
      userName: '',
      cityName: '',
      stateCd: '',
      taxId: ''
    };
  }

  getABOAccountDetails(request: AccountSearchParam) {
    const api = this.apiPathService.getApiPath(getAmwayAccountByAboNumAPI);
    api.path = api.path
      .replace(salesAff, request.aff + '')
      .replace(aboNum, request.abo);
    let urlParams: HttpParams = new HttpParams();
    urlParams = urlParams.set('detailLevelCd', 'FullDetail');
    return this.httpService
      .callServerGET(api, urlParams, null).pipe(
      map((response: { account: Account }) => {
        const account: Account = response.account;
        account.partyList = this.reducePartiesByNameTypeCD(account.partyList);
        return account.partyList.some(
          party => party.partyMst.partyId === request.partyId
        )
          ? account
          : <Account>{};
      }));
  }

  // getAndPrepareAccountInfo(request: AccountSearchParam) {
  //   const cardObservable: Observable<AccountCard> = new Observable(observer => {
  //     forkJoin(
  //       this.getABOAccountDetails(request),
  //       this.countryService.getCountryInfo(request.aff)
  //     ).subscribe(
  //       (response: [Account, Country[]]) => {
  //         const account: Account = response[0];
  //         const countryList: Country[] = response[1];
  //         const cardDetails: AccountCard = new AccountCard({
  //           account: account,
  //           countryList: countryList
  //         });
  //         const currentParty = _.remove(
  //           account.partyList,
  //           party => party.partyMst.partyId === request.partyId
  //         )[0];
  //         cardDetails.country = this.getCountryName(
  //           countryList,
  //           cardDetails.cntryCd
  //         );
  //         cardDetails.baseSiteId = this.getBaseSiteId(cardDetails.cntryCd);
  //         cardDetails.party = this.extractPartyInfo(
  //           currentParty,
  //           cardDetails.aboNum,
  //           account.bankAccountDetailList
  //         );
  //         cardDetails.parties = account.partyList.map(party => {
  //           return this.extractBasicPartyInfo(party, aboNum, account.bankAccountDetailList);
  //         });
  //         observer.next(cardDetails);
  //         observer.complete();
  //       },
  //       error => {
  //         observer.error(error);
  //         observer.complete();
  //       }
  //     );
  //   });
  //   return cardObservable;
  // }

  getAndPrepareABOPersonalInfo(req: AccountSearchParam) {
    const personalInfoObservable: Observable<Object> = new Observable(
      observer => {
        forkJoin(
          this.store
            .select('account')
            .pipe(filter(Boolean)
            ,first()),
          this.taxService.getTaxes(req)
        ).subscribe(
          (response: [AccountCard, Tax[]]) => {
            const tax: Tax[] = response[1];
            const account: AccountCard = response[0];
            observer.next(response);
            observer.complete();
          },
          error => {
            observer.error(error);
            observer.complete();
          }
        );
      }
    );
    return personalInfoObservable;
  }

  private getBirthDate(dateString: string): string {
    const date = new Date(dateString).setUTCHours(0, 0, 0, 0);
    // NaN would be produced only in case of bad/empty dateString
    return !isNaN(date) ? new Date(date).toISOString() : '';
  }

  // private proccessPersonalInfo(taxes, account: AccountCard) {
  //   return {
  //     basicInfo: this.getBasicInfo(account, taxes),
  //     addresses: account.party.addresses
  //   };
  // }

  // private getBasicInfo({ party, cntryCd, aboNum }: AccountCard, tax) {
  //   const {
  //     givenName = '',
  //     familyName = '',
  //     preferredName,
  //     preferredLanguage,
  //     nameLanguageCd,
  //     email: { ecommAddr, contactId },
  //     birthdate,
  //     languageCd,
  //     mobilePhone,
  //     alternatePhone,
  //     partyTypeCd
  //   } = party;

  //   return {
  //     givenName,
  //     familyName,
  //     preferredName,
  //     preferredLanguage,
  //     email: ecommAddr,
  //     ecommContactId: contactId,
  //     birthdate: this.getBirthDate(birthdate),
  //     languageCd,
  //     nameLanguageCd: nameLanguageCd || languageCd, // fallback to party's languageCd
  //     cntryCd, // to validate fields depending on the country
  //     tax: tax || {},
  //     mobilePhone: mobilePhone || {},
  //     alternatePhone: alternatePhone || {},
  //     partyTypeCd
  //   };
  // }

  reducePartiesByNameTypeCD(parties: PartyList[]) {
    return parties.reduce((memo, party) => {
      // We need to filter all the names in NameList only to type Legal
      party.nameList = party.nameList.filter(
        item => item.personNameTypeCd === PERSONAL_NAME_TYPE_CD
      );

      return party.nameList.length ? [...memo, party] : memo;
    }, []);
  }

  getCountryName(countries: Country[], cntryCd: string): string {
    return _.get(
      _.find(countries, country => {
        return country.code === cntryCd;
      }),
      COUNTRY_NAME_FIELD,
      cntryCd
    );
  }

  getBaseSiteId(cntryCd: string): string {
    return COUNTRY_CODES_MAP[cntryCd] || DEFAULT_BASE_SITE_ID;
  }

  extractPartyInfo(
    party: PartyList,
    aboNum: any,
    bankDetailList: BankDetail[]
  ): Party {
    return {
      ...this.extractBasicPartyInfo(party, aboNum, bankDetailList),
      ...this.getPhones(party.phoneList),
      addresses: this.processAddresses(party.addressList)
    };
  }

  extractBasicPartyInfo(
    party: PartyList,
    aboNum: any,
    bankDetailList: BankDetail[]
  ): PartyBasic {
    const {
      partyMst: {
        partyId,
        primaryOnAccount,
        languageCd,
        birthdate,
        partyTypeCd,
        czshpCntryCd,
        genderCd
      },
      missingInfoList,
      personalIdList,
      taxCertList
    } = party;
    const { contactId, ecommAddr } = CommonService.getActualData(
      party.ecommList,
      true
    );
    const {
      preferredName = '',
      languageCd: nameLanguageCd = '',
      localeName: { familyName = '', givenName = '' } = {}
    } = _.get(party, 'nameList[0]', {} as any);

    return {
      partyId,
      email: {
        contactId,
        ecommAddr
      },
      birthdate,
      familyName,
      givenName,
      preferredName,
      nameLanguageCd,
      preferredLanguage: CommonService.getLanguageName(languageCd),
      languageCd,
      engagedName: `${familyName} ${givenName}`,
      primaryOnAccount: primaryOnAccount === PRIMARY_ON_ACCOUNT,
      hasMissingInfo: !!missingInfoList.length,
      partyTypeCd,
      czshpCntryCd,
      genderCd,
      personalIdDetail:
        personalIdList && personalIdList.length > 0
          ? personalIdList[0]
          : ({} as PersonalId),
      taxCertDetail: this.getTaxCertDetail(taxCertList, aboNum),
      bankDetail:
        bankDetailList && bankDetailList.length > 0
          ? bankDetailList[0]
          : ({} as BankDetail)
    };
  }

  getPhones(phoneList: PhoneList[]) {
    return phoneList.reduce(
      (result, phone) => {
        const mobilePhone =
          result.mobilePhone || this.getPhoneByType(phone, PHONE_TYPES.mobile);
        const alternatePhone =
          result.alternatePhone ||
          this.getPhoneByType(phone, PHONE_TYPES.alternate);

        return Object.assign({}, { mobilePhone, alternatePhone });
      },
      {} as any
    );
  }

  getPhoneByType(phone: PhoneList, type): PhoneList {
    return phone.contactPointTypeCd === type ? phone : null;
  }

  processAddresses(addresses: AddressList[]): AddressList[] {
    return USAGE_ADDRESS_TYPES.map(type => type.toString()).map(
      (type): AddressList => {
        const address = addresses.find(({ usageList }) =>
          this.hasActualUsageList(usageList, type)
        );
        return {
          ...address,
          usageList: this.getActualUsageList(
            type,
            _.get(address, 'usageList[0].careOf', '')
          )
        };
      }
    );
  }

  hasActualUsageList(usageList: UsageList[], type: string): boolean {
    return !!usageList.find(({ contactPointPurposeCd, primaryFlag }) => {
      return contactPointPurposeCd === type && primaryFlag;
    });
  }

  getActualUsageList(type: string, careOf: string): UsageList[] {
    return [
      {
        contactPointPurposeCd: type,
        primaryFlag: true,
        careOf
      }
    ];
  }
  private getTaxCertDetail(taxCertList: TaxCertificate[], aboNum) {
    if (taxCertList && taxCertList.length > 0) {
      taxCertList = taxCertList.filter(
        taxCert =>
          taxCert.countryCode === TAX_COUNTRY_CODE &&
          taxCert.taxTypeCode === TAX_TYPE_CODE &&
          taxCert.taxCertificateCode === TAX_CERT_CODE
      );
      if (taxCertList.length > 0) {
        const currentTaxCert = taxCertList.reduce(
          (prev, current) =>
            prev.controlNumber > current.controlNumber ? prev : current
        );
        currentTaxCert.docName = this.getTaxCertificateDocName(
          currentTaxCert,
          aboNum
        );
        return currentTaxCert;
      }
    }
    return {} as TaxCertificate;
  }

  private getTaxCertificateDocName(taxCert: TaxCertificate, aboNum: any) {
    let docName = 'SPP Form';
    if (taxCert && taxCert.certificateEDoc) {
      const crtName = taxCert.certificateEDoc.split(aboNum + '_')[1];
      if (crtName) {
        docName = crtName;
      }
    }
    return docName;
  }
}
