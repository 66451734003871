import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { DropdownOption } from '../../../../shared/models';
import { AboBankService } from '../../../../shared/services/abo-bank.service';
import { ActivatedRoute } from '@angular/router';
import { getBankOptions, setCurrentBankOption, getAccountTypesList, setCurrentBankAccountTypeOption, getBankAccountStatusTypes, setCurrentBankAccountStatusOption, isBankDetailChanged } from '../../../../shared/helpers/bank.helper';
import { BankDetail } from '../../../../shared/models/bank-detail.model';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../store';
import { Bank, BankListData } from '../../../../shared/models/bank-list.model';
import { AboBankListLoad } from '../../../../store/bank/bank.actions';
import { BANK_ACCOUNT_TYPES, BANK_ACCOUNT_STATUS_TYPES, FIELD_TYPES } from '../../../abo-settings';
import { CountryEnvironmentService } from '../../../../shared/services/country-environment.service';

@Component({
  selector: 'app-abo-bank-info-form',
  templateUrl: './abo-bank-info-form.component.html',
  styleUrls: ['./abo-bank-info-form.component.scss']
})
export class AboBankInformationComponent implements OnInit {
  @Output()
  cancel = new EventEmitter<any>();
  @Output() updateBankDetails = new EventEmitter<any>();
  @Input() bankDetails: BankDetail;
  @Input() accountFullDetail;
  @Input() aboBankDetailsList;
  editInfo: BankDetail;
  loaded$: Observable<boolean>;
  saving$: Observable<boolean>;
  bankList: DropdownOption[];
  bankAccountTypesList: DropdownOption[];
  bankAccountStatusTypes: DropdownOption[];
  selectedBankOption: DropdownOption;
  selectedAccountTypeOption: DropdownOption;
  selectedAccountStatusTypeOption: DropdownOption;
  bankForm: FormGroup;
  accountHolderNames;
  initialDetails: BankDetail;
  showAccountStatus: boolean;
  serverError: string;
  constructor(private bankService: AboBankService,
    private activatedRoute: ActivatedRoute,
    private store: Store<IAppState>,
    public countryEnvironmentService: CountryEnvironmentService) { }

  ngOnInit() {
    const params = this.activatedRoute.snapshot.params;
    this.editInfo = _.cloneDeep(Object.assign({}, { ...this.bankDetails }));
    this.bankForm = this.createBankForm(this.editInfo);
    this.initialDetails = _.cloneDeep(Object.assign({}, { ...this.bankDetails }))
    this.store.dispatch(new AboBankListLoad(params));
    this.loaded$ = this.store.select('bankList').pipe(map(data => data.loaded));
    this.store.select('bankList').pipe(filter(data => data.loaded))
      .subscribe(response => {
        this.bankList = getBankOptions(response.data);
        this.bankAccountTypesList = getAccountTypesList(BANK_ACCOUNT_TYPES);
        this.bankAccountStatusTypes = getBankAccountStatusTypes(BANK_ACCOUNT_STATUS_TYPES);
      });
    this.selectedBankOption = setCurrentBankOption(this.editInfo);
    this.selectedAccountTypeOption = setCurrentBankAccountTypeOption(this.editInfo);
    this.selectedAccountStatusTypeOption = setCurrentBankAccountStatusOption(this.editInfo);

    this.saving$ = this.store
      .select('aboPersonalDetails')
      .pipe(filter(data => data['BANK_SAVE'])
      ,map(data => data['BANK_SAVE'].loader));

    this.setBankAccountStatus()
  }

  fetchBankBranch(bsbNumber, fieldName) {
    let bankId;
    let branchId;
    if (bsbNumber.length === 6 && fieldName === FIELD_TYPES.BSB_NUMBER ) {
      bankId = bsbNumber.substring(0, 2);
      branchId = bsbNumber.substring(2);
    } else if (bsbNumber.length === 2 && fieldName === FIELD_TYPES.BANK_ID) {
      bankId = bsbNumber;
    } else if (bsbNumber.length === 4 && fieldName === FIELD_TYPES.BRANCH_ID) {
      branchId = bsbNumber;
      bankId = this.bankForm.controls['bankId'].value;
    } else {
      this.bankForm.controls[fieldName].setErrors({ "invalidValue": true });
    }
    if (bankId) {
      this.store.select('bankList').pipe(filter(data => data.loaded))
        .subscribe(response => {
          let bank = this.setBankField(response, bankId);
          if (bank) {
            this.setBranchField(bank, branchId);
          }
          this.bankAccountStatusTypes = getBankAccountStatusTypes(BANK_ACCOUNT_STATUS_TYPES);
        });
    }
  }

  setBankField(bankData, bankId) {
    let bank = bankData.data.find(bank => {
      return bank.bankId === bankId;
    });
    if (bank) {
      this.bankForm.controls['bankName'].setValue(bank.bankName);
      this.bankForm.controls['bankId'].setValue(bank.bankId);
      return bank;
    } else {
      this.setBankIdError();
    }
    return null;
  }

  setBankIdError() {
      this.bankForm.controls['bsbNumber'].setErrors({ "invalidBank": true });
  }

  setBranchField(bankData, branchId) {
    let branch = bankData.branchList.find(branch => {
      return branch.branchId === branchId;
    });
    if (branch) {
      this.bankForm.controls['bankBranch'].setValue(branch.branchName);
      this.bankForm.controls['bankBranchId'].setValue(branch.branchId);
    } else {
      this.setBranchIdError();
    }
  }

  setBranchIdError() {
      this.bankForm.controls['bsbNumber'].setErrors({ "invalidBranch": true });
  }

  selectBank(event) {
    this.bankForm.controls['bankId'].setValue(event.id);
    this.bankForm.controls['bankName'].setValue(event.text);
  }

  setBankAccountStatus() {
    if (this.editInfo && this.editInfo.acctUsageList && this.editInfo.acctUsageList[0].acctUseCode == 'BonusPayments') {
      this.showAccountStatus = false
    } else {
      this.showAccountStatus = true
    }

    if (_.isEmpty(this.editInfo)) {
      this.showAccountStatus = false
    }
  }

  SetBankAccountStatus(event) {
    this.bankForm.controls['bankAccStatus'].setValue(event.id);
    this.bankForm.controls['bankAccStatusText'].setValue(event.text);
  }

  selectAccountType(event) {
    this.bankForm.controls['bankAcctTypeCd'].setValue(event.id);
    this.bankForm.controls['bankAcctTypeName'].setValue(event.text);
  }

  selectAccountHolderName(event) {
    this.bankForm.controls['bankAcHlderName'].setValue(event.id);
  }

  getDropdownOption(data): DropdownOption {
    return {
      id: data,
      text: data
    };
  }

  saveChanges(): void {
    if (this.bankForm.valid) {
      this.editInfo.issuingBankId = this.bankForm.controls['bankId'].value;
      this.editInfo.issuingBankName = this.bankForm.controls['bankName'].value;
      this.editInfo.bankBranchCode = this.bankForm.controls['bankBranchId'].value;
      this.editInfo.bankAcctNum = this.bankForm.controls['bankAccNum'].value;
      this.editInfo.bankAcctHolderName = this.bankForm.controls['bankAcHlderName'].value;
      this.editInfo.bankAcctTypeCd = 'Saving';
      this.editInfo.bankBranchName = this.bankForm.controls['bankBranch'].value;
      
      if (this.editInfo && this.editInfo.acctUsageList && this.editInfo.acctUsageList[0].acctUseCode == 'AUTORENEWAL') {
        this.checkForAutoRenwal()
      } else {
        this.updateBankDetails.emit({ current: _.cloneDeep(this.editInfo), initialData: _.cloneDeep(this.initialDetails) });
      }
    } else {
      this.bankForm.controls['bankId'].markAsTouched();
      this.bankForm.controls['bankName'].markAsTouched();
      this.bankForm.controls['bankAccNum'].markAsTouched();
      this.bankForm.controls['bankAcHlderName'].markAsTouched();
      this.bankForm.controls['bsbNumber'].markAsTouched();
      this.bankForm.controls['bankBranch'].markAsTouched();
    }
  }

  checkForAutoRenwal() {
    let bankWithValidStatus;
    let bankRenewalList = _.filter(this.aboBankDetailsList, function (bank) { return bank.acctUsageList[0].acctUseCode == 'AUTORENEWAL' })

    if (bankRenewalList.length > 1) {
      bankWithValidStatus = bankRenewalList.find(bank => {
        return bank.bankAccStatus == 'VALID'
      })
    }

    if (bankWithValidStatus && !_.isEqual(bankWithValidStatus, this.bankDetails) && this.editInfo.bankAccStatus == 'VALID') {
      this.serverError = "There is another DirectDebit account with Valid status."
    } else {
      this.updateBankDetails.emit({ current: _.cloneDeep(this.editInfo), initialData: _.cloneDeep(this.initialDetails) });
    }
  }

  cancelChanges() {
    this.cancel.emit();
  }

  private createBankForm(bankInfo: BankDetail) {
    const bnkForm = {};
    bnkForm['bankName'] = new FormControl(bankInfo.issuingBankName || '');
    bnkForm['bankId'] = new FormControl(bankInfo.issuingBankId || '', [Validators.required]);
    bnkForm['bankBranchId'] = new FormControl(bankInfo.bankBranchCode || '');
    bnkForm['bankAccNum'] = new FormControl(bankInfo.bankAcctNum || '', [Validators.required]);
    bnkForm['bankAcHlderName'] = new FormControl(bankInfo.bankAcctHolderName || '', [Validators.required]);
    bnkForm['bankBranch'] = new FormControl(bankInfo.bankBranchName || '', [Validators.required]);
    bnkForm['bsbNumber'] = new FormControl(bankInfo.issuingBankId + bankInfo.bankBranchCode || '', [Validators.required]);
    return new FormGroup(bnkForm);
  }
}
