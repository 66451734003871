import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { IAppState } from '../../../store/index';
import { defaultDateFormat } from '../../configs/constants';


@Component({
  selector: 'app-block-details',
  templateUrl: './block-details.component.html',
  styleUrls: ['./block-details.component.scss']
})
export class BlockDetailsComponent implements OnInit {

  @Input() effectiveDate: string;
  @Input() expirationDate: string;
  @Input() editable = false;
  @Output() editChange = new EventEmitter<boolean>();

  dateFormat$: Observable<string>;

  constructor(
    private store: Store<IAppState>,
  ) { }

  ngOnInit() {
    this.dateFormat$ = of(defaultDateFormat); // this.store.select('settings').map(settings => settings.dateFormat);
  }

}
