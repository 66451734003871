import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService, AuthService, ErrorLogService } from '../../core/services';
import { AboSubscription } from '../models/abo-subscriptions.model';
import { salesAff, aboNum, partyId, updateSubscriptionAPI, wareHoueseListAPI } from '../configs/constants';
import { ApiPathConfigService } from '../configs/api';
import { ErrorService } from './error.service';
import { HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class SubscriptionService {
  constructor(private httpService: HttpService,
    private authService: AuthService,
    private errorService: ErrorService,
    private errorLogService: ErrorLogService,
    private apiPathService: ApiPathConfigService
  ) {}

  updateSubscription(request, payload: AboSubscription[]) {
    const api = this.apiPathService.getApiPath(updateSubscriptionAPI);
    api.path = api.path
      .replace(salesAff, request.aff)
      .replace(aboNum, request.abo)
      .replace(partyId, request.partyId);
    if (payload && payload.length > 0) {
      const rqBody = { subscriptionList: [...payload] };
      return this.httpService.callServerPUT(api, null, rqBody)
      .pipe(map(response => {
        return response
      }),catchError(error => {
        const errorMessage = this.errorService.processError(error);
        this.errorLogService.log(errorMessage);
        return of(error)
      }));
    }
    return of({});
  }


  fetchWareHouseList(){
    const wareHoueseObservable = new Observable(observer => {
      observer.next({ loading: true, data: [] });
      const token = this.authService.getOccAccessToken();
      const api = this.apiPathService.getApiPath(wareHoueseListAPI);
      // const api = wareHoueseList;
      let params = new HttpParams();
      this.httpService.callOccServerGETApi(api, params).subscribe(
        response => {
          observer.next({ loading: false, data: response });
          observer.complete();
        },
        error => {
          observer.next({ loading: false, data: [] });
          observer.complete();
        }
      );
    });
    return wareHoueseObservable;
  }
}
