import {
    ABO_BANK_LIST_LOAD,
    ABO_BANK_LIST_LOAD_SUCCESS,
    ABO_BANK_LIST_LOAD_FAIL,
    Actions
  } from './bank.actions';
import { BankListData } from '../../shared/models/bank-list.model';

  export function AboBankListReducer(
    state: BankListData,
    { type, payload }: Actions
  ): BankListData {
    switch (type) {
      case ABO_BANK_LIST_LOAD:
        return { loaded: false };
      case ABO_BANK_LIST_LOAD_SUCCESS:
        return Object.assign({ loaded: true }, { data: payload });
      case ABO_BANK_LIST_LOAD_FAIL:
        return  Object.assign({ loaded: true }, { data: [] });
      default:
        return state;
    }
  }
