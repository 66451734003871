export class AwardsResponse {
  currentAwardName: string;
  highestAwardName: string;
  constructor({
    currentAwardName = '',
    highestAwardName = ''
  }) {
    this.currentAwardName = currentAwardName;
    this.highestAwardName = highestAwardName;
  }
}
