import { Effect, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import {
  COUNTRIES_LOAD,
  CountriesLoad,
  CountriesLoadFail,
  CountriesLoadSuccess,
  ALL_COUNTRIES_LOAD,
  AllCountriesLoad,
  AllCountriesLoadFail,
  AllCountriesLoadSuccess
} from './countries.actions';
import { CountryService } from '../../shared/services/index';

@Injectable()
export class CountryEffects {
  @Effect()
  loadCountries$ = this.actions$
    .ofType(COUNTRIES_LOAD).pipe(
    switchMap((action: CountriesLoad) => {
      return this.countryService
        .getAffiliatesList()
        .pipe(map(response => new CountriesLoadSuccess(response))
        ,catchError(error => of(new CountriesLoadFail())));
    }));
  @Effect()
  loadAllCountries$ = this.actions$
    .ofType(ALL_COUNTRIES_LOAD).pipe(
    switchMap((action: AllCountriesLoad) => {
      return this.countryService
        .getCountriesList()
        .pipe(map(response => new AllCountriesLoadSuccess(response))
        ,catchError(error => of(new AllCountriesLoadFail())));
    }));

  constructor(
    private actions$: Actions,
    private countryService: CountryService
  ) {}
}
