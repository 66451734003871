import {
  Actions,
  ACCOUNT_GET,
  ACCOUNT_GET_SUCCESS,
  ACCOUNT_GET_FAIL
} from './account.actions';
import { AccountDetail } from '../../shared/models/amway/account.model';

export function accountReducer(
  state: AccountDetail,
  { type, payload }: Actions
): AccountDetail {
  switch (type) {
    case ACCOUNT_GET:
      return Object.assign({ loaded: false });
    case ACCOUNT_GET_SUCCESS:
      return Object.assign({ loaded: true }, { data: payload });
    case ACCOUNT_GET_FAIL:
      return Object.assign({ loaded: true }, {data : null});
    default:
      return state;
  }
}
