import { HttpParams } from '@angular/common/http';
import { Observable, of, forkJoin } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpService, CommonService, ErrorLogService } from '../../core/services';
import { ApiPathConfigService } from '../configs/api';
import { ContractModel, AccountBalance, SponsorsList } from '../models/amway';
import { salesAff, aboNum, accountContractsAPI, AR_BALANCE_TYPE_CODE } from '../configs/constants';
import { VolumeService } from './volume.service';
import { SponsorService } from './sponsor.service';
import { AccountBalanceService } from './account-balance.service';
import { AccountService } from './account.service';
import { ABOBusinessDetails, ABOBusinessInfo } from '../models/abo-business-details.model';
import { ABOService } from './abo.service';
import {
  getSPALabel,
  hasSPAContract,
  hasSPAMissingInfo
} from '../helpers/spa.helper';
import { ErrorService } from './error.service';
import { PlatinumUpline } from '../models/platinum-upline.model';
import { AwardsEffects } from '../../store/awards/awards.effects';
import { AboAwardsService } from './abo-awards.service';

@Injectable()
export class AboBusinessDetailService {
  constructor(
    private httpService: HttpService,
    private volumeService: VolumeService,
    private sponsorService: SponsorService,
    private accountBalanceService: AccountBalanceService,
    private accountService: AccountService,
    private aboService: ABOService,
    private errorService: ErrorService,
    private errorLogService: ErrorLogService,
    private awardsService: AboAwardsService,
    private apiPathService: ApiPathConfigService,
  ) { }
  getBusinessDetails(params: any) {
    return this.volumeService.getPeriod(params.aff).pipe(switchMap(period => {
      const businessDetailObservable: Observable<
    ABOBusinessInfo
      > = new Observable(observer => {
        forkJoin(
          this.awardsService.getCurrentHighestAwards(params, period),
          //this.getPlatinumSponsor(params, period),
          this.sponsorService.getSponsors(params),
        ).subscribe(
          ([
            awards,
            sponsors
          ]) => {
            const aboBusinessInfo = new ABOBusinessInfo();
            aboBusinessInfo.awards = awards;
            //aboBusinessInfo.platinumSponsor = sponsors;
            aboBusinessInfo.sponsorList = <SponsorsList>sponsors;
            observer.next(aboBusinessInfo);
          },
          error => observer.error(error)
          );
      });
    return businessDetailObservable;
    }));
  }

  getContracts(request: any): Observable<ContractModel[]> {
    const api = this.apiPathService.getApiPath(accountContractsAPI);
    api.path = api.path.replace(
      salesAff,
      request.aff + ''
    ).replace(aboNum, request.abo);
    return this.httpService
      .callServerGET(api, null, null)
      .pipe(map(
      (response: any) =>
        response && response.contractList ? response.contractList : []
      ));
  }

  // TODO remove from here. use account store to retrieve required data
  private getAccount(request: any) {
    return this.accountService.getAccountDetail(request);
  }

  private getVolume(request: any) {
    return this.volumeService.getVolumeDetails(request)
      .pipe(catchError(error => {
        this.LogError(error);
        return of({});
      }));
  }

  private getSponsors(request: any) {
    return this.sponsorService.getSponsors(request)
      .pipe(catchError(error => {
        this.LogError(error);
        return of({} as SponsorsList);
      }));
  }

  private getPlatinumSponsor(request: any, period): Observable<PlatinumUpline> {
   if (period) {
     const platinumSpnsrOb = new Observable<PlatinumUpline>((observer) => {
      this.sponsorService.getPlatinumSponsor(request, period)
        .subscribe(response => {
          if (response && response.platinumAbo && response.platinumAff) {
            this.accountService.getAccountData(response.platinumAff, response.platinumAbo, null, 'MinDetail')
            .subscribe(acountdetail => {
              response.platinumAboName = acountdetail && acountdetail.accountMst ? acountdetail.accountMst.accountName : '';
              observer.next(response);
              observer.complete();
            }, error => {
              this.LogError(error);
              observer.next(response);
              observer.complete();
            });
          }else {
            observer.next(response);
            observer.complete();
          }
          }, error => {
            this.LogError(error);
            observer.next({} as PlatinumUpline);
            observer.complete();
          });
     });
     return platinumSpnsrOb;
   }else {
    return of({} as PlatinumUpline);
   }
  }

  private getARBalance(request: any) {
    const acctBalType = AR_BALANCE_TYPE_CODE;
    return this.accountBalanceService
      .getAccountBalances(request, acctBalType)
      .pipe(map((accntBalList: AccountBalance[]) => {
        return accntBalList && accntBalList.length > 0
          ? accntBalList[0].balanceAmount
          : '';
      })
      ,catchError(error => {
        this.LogError(error);
        return of('');
      }));
  }

  private LogError(error) {
    const errorMessage = this.errorService.processError(error);
    this.errorLogService.log(errorMessage);
  }
}
