import { Injectable } from '@angular/core';
import { HttpService, ErrorLogService } from '../../core/services';
import { Observable, of, forkJoin } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { VouchersDetail, Voucher } from '../models/voucher.model';
import { ApiPathConfigService } from '../configs/api';
import { salesAff, aboNum, partyId, voucherId, voucherTransactionAPI, accountVouchersAPI } from '../configs/constants';
import { AccountBalanceService } from './account-balance.service';
import { AccountBalance } from '../models/amway/ar-balance.model';
import { ErrorService } from './error.service';
@Injectable()
export class VoucherService {
  constructor(
    private httpService: HttpService,
    private acccountBalService: AccountBalanceService,
    private errorService: ErrorService,
    private errorLogService: ErrorLogService,
    private apiPathService: ApiPathConfigService
  ) {}

  getVouchers(req: any): Observable<VouchersDetail> {
    const api = this.apiPathService.getApiPath(accountVouchersAPI);
    api.path = api.path.replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId);
    return this.httpService.callServerGET(api, null, null);
  }
  getVoucherTransection(req: any, voucherID: string) {
    const api = this.apiPathService.getApiPath(voucherTransactionAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId)
      .replace(voucherId, voucherID);
    return this.httpService.callServerGET(api, null, null);
  }
  getVouchersDetail(request: any): Observable<Voucher[]> {
    const vouchersDetailObserver = new Observable<Voucher[]>(observer => {
      this.getVouchers(request).subscribe(
        response => {
          const vouchers: Voucher[] =
            response && response.voucherList ? response.voucherList : [];
          if (vouchers && vouchers.length) {
            const apiCalls = [];
            for (let index = 0; index < vouchers.length; index++) {
              vouchers[index].showDetails = false;
              apiCalls.push(
                this.getVoucherTransection(request, vouchers[index].voucherID)
              );
            }
            forkJoin(...apiCalls).subscribe(transactionResponses => {
              for (let index = 0; index < vouchers.length; index++) {
                const detail: any = transactionResponses[index];
                if (detail && detail.voucherDetailList) {
                  vouchers[index].transactionDetails = detail.voucherDetailList;
                }
              }
              observer.next(vouchers);
              observer.complete();
            });
          } else {
            observer.next(vouchers);
            observer.complete();
          }
        },
        error => observer.next([] as Voucher[])
      );
    });
    return vouchersDetailObserver;
  }

  getVouchersDetailAndBalance(request: any) {
    const vouchersObservable = new Observable(observer => {
      forkJoin(
        this.getVouchersDetail(request),
        this.getVoucherBalance(request)
      ).subscribe(response => {
        const vouchers = response[0];
        const balance = response[1];
        const details: VouchersDetail = {
          voucherList: <Voucher[]>vouchers,
          balance: <AccountBalance>balance
        };
        observer.next(details);
      },
       error => {
         observer.error(error);
        });
    });
    return vouchersObservable;
  }

  getVoucherBalance(request: any): Observable<AccountBalance> {
    const defaultBalance = new AccountBalance();
    defaultBalance.pv = '0';
    defaultBalance.bv = '0';

    return this.acccountBalService
      .getAccountBalances(request, 'PVBV')
      .pipe(map(response => {
        return response && response.length ? response[0] : defaultBalance;
      }),catchError(error => {
        this.LogError(error);
        return of(defaultBalance);
      }));
  }

  private LogError(error) {
    const errorMessage = this.errorService.processError(error);
    this.errorLogService.log(errorMessage);
  }
}
