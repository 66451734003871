import {
  SET_NOTIFICATION,
  CLEAR_NOTIFICATION,
  Actions
} from './notification.actions';

export function notificationReducer(state: any, {type, payload}: Actions): any {
  switch (type) {
    case SET_NOTIFICATION:
      return { message: payload };

    case CLEAR_NOTIFICATION:
      return null;

    default:
      return state;
  }
}
