import { environment } from './../../../../../environments/environment';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChange
} from '@angular/core';
import {
  DropdownOption,
  CountryResponse,
  ABOSearchQuery
} from '../../../models/index';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../store/index';
import { CommonService } from '../../../../core/services/index';
import { CountriesLoad } from '../../../../store/countries/countries.actions';
import { Country } from '../../../models/index';
import { AboSearch } from '../../../../store/abo-search/abo-search.actions';
import { CountryService } from '../../../services/index';
import { DEFAULT_COUNTRY_OPTION } from '../../../configs/constants';
// import { empty } from 'rxjs/Observer';
import { AboDetailsValidationService } from '../../../../abo/abo-details-validation.service';
import { CountryEnvironmentService } from '../../../services/country-environment.service';

@Component({
  selector: 'app-abo-search',
  templateUrl: './abo-search.component.html',
  styleUrls: ['./abo-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AboDetailsValidationService]
})
export class ABOSearchComponent implements OnInit, OnChanges {
  @Input()
  basicUrl: string;

  @Input() placeholder;
  @Input() errorText;
  @Input() searchKey: string;
  countriesList$: Observable<DropdownOption[]>;
  query: string;
  selectedCountryOption: DropdownOption;
  aff: string;
  errorMessage: string = null;

  constructor(
    private router: Router,
    private store: Store<IAppState>,
    private commonService: CommonService,
    private cService: CountryService,
    private route: ActivatedRoute,
    private aboValidationService: AboDetailsValidationService,
    private countryEnvironmentService: CountryEnvironmentService
  ) { }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (changes.errorText && changes.errorText.currentValue) {
      this.errorMessage = changes.errorText.currentValue;
    }
  }

  ngOnInit() {
    this.placeholder = this.placeholder || 'Enter the ABO No. or Email address'
    this.selectedCountryOption = DEFAULT_COUNTRY_OPTION;

    this.countriesList$ = this.store
      .select('countries').pipe(
        filter(reponse => !reponse.loading)
        , map(data => data.countryList.map(this.getCountryDropdownOption)));
    this.setCode(this.selectedCountryOption);
    this.route.queryParams.subscribe(data => {
      if (!_.isEmpty(data)) {
        this.query = data.query
      }
    });
  }

  /**
   * checks if search is enabled
   * @returns {boolean}
   */
  searchEnabled(): boolean {
    return !!(this.aff && _.get(this.query, 'length', 0));
  }

  /**
   * handles key up event on search input
   * @param {KeyboardEvent} event
   */
  keyUpHandler(event: KeyboardEvent): void {
    this.errorMessage = null;
    this.errorText = null;
    if (this.searchEnabled() && this.commonService.isEnter(event)) {
      this.search();
    }
  }

  search() {
    this.errorMessage = null;
    this.errorText = null;

    const searchQuery: ABOSearchQuery = new ABOSearchQuery({
      searchQuery: this.query.trim(),
      salesAff: parseInt(this.aff, 10),
      countryCd: this.selectedCountryOption.id + '',
      page: 1,
      offset: 1000,
      searchType: this.searchKey
    });

    // Validate email address
    if (searchQuery.query.indexOf('@') > -1) {
      let error = this.aboValidationService.getEmailErrors(searchQuery.query)
      if (!_.isEmpty(error)) {
        this.errorMessage = 'Kindly provide valid email id'
        return
      }
    }

    this.router.navigate(this.basicUrl ? [this.basicUrl] : [], {
      queryParams: searchQuery
    });
    //this.clearInput();
  }

  setCode(data: DropdownOption): void {
    this.aff = data.code.toString();
  }

  private clearInput() {
    this.query = '';
  }

  private getCountryDropdownOption(country: Country): DropdownOption {
    return {
      id: country.code,
      text: country.name,
      code: country.salesAff
    };
  }
}
