import { SafeResourceUrl } from '@angular/platform-browser';
import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  ChangeDetectorRef,
  ElementRef
} from '@angular/core';
import { IAppState } from '../../store';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountCard, Country } from '../../shared/models';

import { NavigationService, CommonService } from '../../core/services';
import { PhotoLoad, PhotoChange } from '../../store/photo/photo.actions';
import { AccountDetail } from '../../shared/models/amway';
import { getAccountCardData } from '../../shared/helpers/account.helper';
import {
  defaultCountryCode,
  defaultCountryName,
  defaultProfileImage,
  allowedUploadImageExt,
  maxAllowedFileSizeInBytes,
  LglEntityTypeCompany,
  PartyTypeCd
} from '../../shared/configs/constants';
import { Modal } from 'ngx-modal';
import {
  arrayBufferToBlob,
  blobToObjectUrl,
  ValidateUploadedFile,
  getAccountName,
  checkAutoRenewalStatus
} from '../../shared/helpers/common.helper';
import { PhotoService, PartyService, BusinessInfoService, AccountService } from '../../shared/services';
import { AccountGet } from '../../store/account/account.actions';
import { ABOBusinessDetails, ABOBusinessInfo } from '../../shared/models/abo-business-details.model';
import { getAboBasicBusinessDetails } from '../../shared/helpers/business-detail.helper';
import { AboAward } from '../../shared/models/abo-award.model';
import { AboBusinessDetailsLoad } from '../../store/abo-business-details/abo-business-details.actions';
import { filter } from 'lodash';
import * as moment from 'moment';
import { ABO_NAME, MEMBER_NAME } from '../../shared/configs/constants';
import {PartyRenewal } from '../../shared/models/enums/index';

@Component({
  selector: 'app-account-card',
  templateUrl: './account-card.component.html',
  styleUrls: ['./account-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountCardComponent implements OnInit, OnDestroy {
  countries$: Observable<Country[]>;
  photo$: Observable<SafeResourceUrl>;
  previewLoading$: Observable<boolean>;
  profileImageUrl$: Observable<SafeResourceUrl>;
  newProfileImageFile: any;
  fileName: string;
  accountCard: AccountCard;
  country: Country;
  defaultProfileImage: string;
  @Input() accountDetails;
  @ViewChild('profileImageModal')
  profileImageModal: Modal;
  photoChangeLoader$: Observable<boolean>;
  aboBusinessInfo: ABOBusinessDetails;
  awardInfo: AboAward;
  businessDetailLoaded$: Observable<any>;
  profileImageUploadErrors: string[] = [];
  aboBusinessDetails;
  showLoader: boolean;
  businessNature: string;
  @ViewChild('reactivateConfirmation') reactivateConfirmation: Modal;
  @ViewChild('renewalConfirmation') renewalConfirmation: Modal;
  @ViewChild('reversalConfirmation') reversalConfirmation: Modal;
  hasReactivationError: boolean = false;
  reactivateErrorMsg: string = null;
  sponsorInformationLoading: boolean = true;
  accountExpiredMonths: any;
  downloadPdfLoader: boolean = false;
  showRenewal: boolean = false;
  showReverseRenewal: boolean = false;  

  constructor(
    private store: Store<IAppState>,
    private route: ActivatedRoute,
    private router: Router,
    private navigationService: NavigationService,
    private commonService: CommonService,
    private photoService: PhotoService,
    private partyService: PartyService,
    private cdr: ChangeDetectorRef,
    private businessInfoService: BusinessInfoService,
    private accountService: AccountService,
  ) {}
  ngOnInit() {
    const params = this.route.snapshot.params;
    this.defaultProfileImage = defaultProfileImage;
    this.store.dispatch(new PhotoLoad(params));
    this.photo$ = this.store.select('photo');
    this.profileImageUrl$ = this.store.select('photo');
    this.photoChangeLoader$ = this.store
      .select('photoChange')
      .pipe(map(data => data.loader));
    this.store.select('photoChange').subscribe(data => {
      if (data) {
        if (data.loader === false) {
          this.store.dispatch(new PhotoLoad(params));
          this.profileImageModal.close();
        }
      }
    });

    this.getSponsorInformation()

    this.businessNature = CommonService.getBusinessNature(this.accountDetails.data.accountMst)
    this.aboBusinessInfo = getAboBasicBusinessDetails(this.accountDetails);
    let renewalFlag = this.accountDetails.data.accountMst.renewalFlag;
    if (renewalFlag == PartyRenewal.AlreadyRenewed) {
      const { aff, abo } = this.route.snapshot.params;
      const params = { aff, abo };
      this.partyService.renewalDetails(params.aff, params.abo).subscribe(response => {
        this.showReverseRenewal = response.renewalReversalAllowedFlg === 'Y' ? true : false;
      }, err => {
        console.log(err);
      })
    } else if ((renewalFlag == PartyRenewal.CanRenew) || (renewalFlag == PartyRenewal.MustRenew) || (renewalFlag == PartyRenewal.CannotRenew)) {
      this.showRenewal = true;
    }

    if (this.aboBusinessInfo.joinDate && this.aboBusinessInfo.expiryDate) {
      this.isAccountExpired();
    }

    this.countries$ = this.store
      .select('countries')
      .pipe(map(data => data.countryList));

    this.accountCard = getAccountCardData(this.accountDetails);
    this.accountCard.cntryName =
      this.country && this.country.name
        ? this.country.name
        : defaultCountryName;

    this.accountCard.renewalStatus = checkAutoRenewalStatus(this.accountDetails)

    this.accountCard.prefferedLanguage = CommonService.getLanguageName(this.accountCard.cntryCd)

    this.countries$.subscribe(countrieslist => {
      if (countrieslist && countrieslist.length > 0) {
        const cntryCd =
          this.accountCard && this.accountCard.cntryCd
            ? this.accountCard.cntryCd
            : defaultCountryCode;
        this.country = countrieslist.find(
          cntry => cntry.cntryCd === cntryCd || cntry.code === cntryCd
        );

        if (this.accountCard && this.country) {
          this.accountCard.cntryName = this.country.name;
        }
      }
    });
    this.newProfileImageFile = null;
    this.fileName = null;

    // Business details fetch
    this.store.dispatch(new AboBusinessDetailsLoad(params));
    this.businessDetailLoaded$ = this.store.select('aboBusinessDetails').pipe(map((data) => !!data.loaded));

    this.store.select('aboBusinessDetails').subscribe(response => {
      this.businessInfoService.updatedDataSelection(response)
      this.awardInfo = response && response.data ? response.data.awards : {} as AboAward;
    });
  }


  isAccountExpired(): boolean {
    let currentDate = moment(moment().startOf('day'), 'MM.DD.YYYY');
    let expiryDate = moment(this.aboBusinessInfo.expiryDate.split('T')[0], 'YYYY.MM.DD');

    this.accountExpiredMonths = currentDate.diff(expiryDate, 'months');
    return (this.businessNature === ABO_NAME && this.accountExpiredMonths >= 6) ? true :
      (this.businessNature === MEMBER_NAME && this.accountExpiredMonths >= 3) ? true : false;
  }

  getSponsorInformation(){
    const { aff } = this.route.snapshot.params;
    if (this.accountDetails.data.sponsor && this.accountDetails.data.sponsor.sponsorABONo){
      this.accountService.getAccountData(aff, this.accountDetails.data.sponsor.sponsorABONo, null, 'MinDetail')
        .subscribe(res => {
          // condition for corporate
          if (res.accountMst.lglEntityType == LglEntityTypeCompany) {
            let personsList = filter(res.partyList, function(party) { return party.partyMst.partyTypeCd == PartyTypeCd })
            this.aboBusinessInfo.sponsorName = getAccountName('', personsList[0])
          } else {
            this.aboBusinessInfo.sponsorName = getAccountName('', res.primaryParty)
          }
          this.businessInfoService.updateSponsorName(this.aboBusinessInfo.sponsorName)
          this.sponsorInformationLoading = false
        })
    } else {
      this.sponsorInformationLoading = false
      this.businessInfoService.updateSponsorName("")
    }
  }

  updateProfileImage() {
    if (this.newProfileImageFile) {
      this.profileImageUploadErrors = ValidateUploadedFile(
        this.newProfileImageFile,
        allowedUploadImageExt,
        maxAllowedFileSizeInBytes
      );
      if (
        this.profileImageUploadErrors &&
        this.profileImageUploadErrors.length > 0
      ) {
        return;
      }
      const params = this.route.snapshot.params;
      this.store.dispatch(
        new PhotoChange({ reqParams: params, file: this.newProfileImageFile })
      );
    }
  }
  navigateToIBO(partyId: string): void {
    const { aff, abo } = this.route.snapshot.params;
    const params = { aff, abo, partyId };
    this.router.navigate(['/abo', aff, abo, partyId]);
    this.navigationService.updateIboComponent(params);
  }
  selectFile(data: any) {
    this.profileImageUploadErrors = [];
    this.previewLoading$ = of(true);
    const file =
      data.target.files && data.target.files.length > 0
        ? data.target.files[0]
        : null;
    if (file) {
      this.generateProfilePreview(file);
    } else {
      this.previewLoading$ = of(false);
      this.profileImageModal.close();
      this.profileImageModal.open();
    }
  }
  profileImageuploadModal() {
    this.profileImageUploadErrors = [];
    this.newProfileImageFile = null;
    this.fileName = null;
    this.profileImageUrl$ = this.store.select('photo');
    this.profileImageModal.open();
  }
  generateProfilePreview(file: File) {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const blob = arrayBufferToBlob(reader.result, file.type);
        this.newProfileImageFile = file;
        this.fileName = file.name;
        this.profileImageUrl$ = of(
          this.commonService.blobToObjectUrl(blob)
        );
        this.previewLoading$ = of(false);
        this.profileImageModal.close();
        this.profileImageModal.open();
      };
      reader.readAsArrayBuffer(file);
    }
  }

  reactivateAccount(){
    this.showLoader = true;
    this.reactivateConfirmation.close()
    const {aff, abo, partyId} = this.route.snapshot.params;
    const params = { aff, abo, partyId };
    this.partyService.reactivateAParty(params.aff, params.abo).subscribe(response => {
      this.navigationService.updateIboComponent(params);
      this.showLoader = false;
    }, err => {
      this.showLoader = false;
      this.reactivateErrorMsg = JSON.parse(err.error).errorMessage.message;
      this.cdr.detectChanges();
    })
  }

  renewalAccount(){
    this.showLoader = true;
    this.renewalConfirmation.close();
    const {aff, abo, partyId} = this.route.snapshot.params;
    const params = { aff, abo, partyId };
    this.partyService.renewalParty(params.aff, params.abo, params.partyId).subscribe(response => {
      this.navigationService.updateIboComponent(params);
      this.showLoader = false;
    }, err => {
      this.showLoader = false;
      this.reactivateErrorMsg = JSON.parse(err.error).errorMessage.message;
      this.cdr.detectChanges();
    })
  }

  reversalAccount(){
    this.showLoader = true;
    this.reversalConfirmation.close();
    const {aff, abo, partyId} = this.route.snapshot.params;
    const params = { aff, abo, partyId };
    this.partyService.reversalParty(params.aff, params.abo, params.partyId).subscribe(response => {
      this.navigationService.updateIboComponent(params);
      this.showLoader = false;
    }, err => {
      this.showLoader = false;
      this.reactivateErrorMsg = JSON.parse(err.error).errorMessage.message;
      this.cdr.detectChanges();
    })
  }

  reactivateConfirm(){
    this.reactivateAccount()
  }


  ngOnDestroy() {}
}
