import {
  Actions,
  PARTIES_ACCOUNT_GET,
  PARTIES_ACCOUNT_GET_SUCCESS,
  PARTIES_ACCOUNT_GET_FAIL
} from './account-parties.actions';
import { AccountDetail } from '../../shared/models/amway/account.model';

export function accountPartiesReducer(
  state,
  { type, payload }: Actions
) {
  switch (type) {
    case PARTIES_ACCOUNT_GET:
      return Object.assign({ loaded: false });
    case PARTIES_ACCOUNT_GET_SUCCESS:
      return Object.assign({ loaded: true }, { data: payload });
    case PARTIES_ACCOUNT_GET_FAIL:
      return Object.assign({ loaded: true }, {data : null});
    default:
      return state;
  }
}
