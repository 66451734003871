import { Action } from '@ngrx/store';
import { ResctrictionBlocksPayload } from '../../shared/models/amway/restriction-blocks.model';


export const ABO_RESTRICTION_BLOCKS_LOAD = '[Abo restriction blocks] load';
export const ABO_RESTRICTION_BLOCKS_LOAD_SUCCESS = '[Abo restriction blocks] load success';
export const ABO_RESTRICTION_BLOCKS_LOAD_FAIL = '[Abo restriction blocks] load fail';
export const ABO_RESTRICTION_BLOCKS_LOAD_ACTIVE = '[Active abo restriction blocks] load';
export const ABO_RESTRICTION_BLOCKS_LOAD_ACTIVE_SUCCESS = '[Active abo restriction blocks] load success';
export const ABO_RESTRICTION_BLOCKS_LOAD_ACTIVE_FAIL = '[Active abo restriction blocks] load fail';
export const ABO_RESTRICTION_BLOCKS_SAVE = '[Abo restriction blocks] edit';
export const ABO_RESTRICTION_BLOCKS_SAVE_SUCCESS = '[Abo restriction blocks] edit success';
export const ABO_RESTRICTION_BLOCKS_SAVE_FAIL = '[Abo restriction blocks] edit fail';
export const ABO_RESTRICTION_BLOCKS_DEACTIVATE_ALL = '[Abo restriction blocks] deactivate all';
export const ABO_RESTRICTION_BLOCKS_DEACTIVATE_ALL_SUCCESS = '[Abo restriction blocks] deactivate all success';
export const ABO_RESTRICTION_BLOCKS_DEACTIVATE_ALL_FAIL = '[Abo restriction blocks] deactivate all fail';

export class AboRestrictionBlocksLoad implements Action {
  readonly type = ABO_RESTRICTION_BLOCKS_LOAD;

  constructor(public payload: ResctrictionBlocksPayload = null) { }
}

export class AboRestrictionBlocksLoadSuccess implements Action {
  readonly type = ABO_RESTRICTION_BLOCKS_LOAD_SUCCESS;

  constructor(public payload: ResctrictionBlocksPayload) { }
}

export class AboRestrictionBlocksLoadFail implements Action {
  readonly type = ABO_RESTRICTION_BLOCKS_LOAD_FAIL;

  constructor(public payload: ResctrictionBlocksPayload = null) { }
}

export class AboRestrictionBlocksLoadActive implements Action {
  readonly type = ABO_RESTRICTION_BLOCKS_LOAD_ACTIVE;

  constructor(public payload: ResctrictionBlocksPayload = null) { }
}

export class AboRestrictionBlocksLoadActiveSuccess implements Action {
  readonly type = ABO_RESTRICTION_BLOCKS_LOAD_ACTIVE_SUCCESS;

  constructor(public payload: ResctrictionBlocksPayload) { }
}

export class AboRestrictionBlocksLoadActiveFail implements Action {
  readonly type = ABO_RESTRICTION_BLOCKS_LOAD_ACTIVE_FAIL;

  constructor(public payload: ResctrictionBlocksPayload = null) { }
}

export class AboRestrictionBlocksSave implements Action {
  readonly type = ABO_RESTRICTION_BLOCKS_SAVE;

  constructor(public payload: ResctrictionBlocksPayload) { }
}

export class AboRestrictionBlocksSaveSuccess implements Action {
  readonly type = ABO_RESTRICTION_BLOCKS_SAVE_SUCCESS;

  constructor(public payload: ResctrictionBlocksPayload = null) { }
}

export class AboRestrictionBlocksSaveFail implements Action {
  readonly type = ABO_RESTRICTION_BLOCKS_SAVE_FAIL;

  constructor(public payload: ResctrictionBlocksPayload = null) { }
}

export class AboRestrictionBlocksDeactivateAll implements Action {
  readonly type = ABO_RESTRICTION_BLOCKS_DEACTIVATE_ALL;

  constructor(public payload: ResctrictionBlocksPayload) { }
}

export class AboRestrictionBlocksDeactivateAllSuccess implements Action {
  readonly type = ABO_RESTRICTION_BLOCKS_DEACTIVATE_ALL_SUCCESS;

  constructor(public payload: ResctrictionBlocksPayload = null) { }
}

export class AboRestrictionBlocksDeactivateAllFail implements Action {
  readonly type = ABO_RESTRICTION_BLOCKS_DEACTIVATE_ALL_FAIL;

  constructor(public payload: ResctrictionBlocksPayload = null) { }
}

export type Actions =
  AboRestrictionBlocksLoad
  | AboRestrictionBlocksLoadSuccess
  | AboRestrictionBlocksLoadFail
  | AboRestrictionBlocksLoadActive
  | AboRestrictionBlocksLoadActiveSuccess
  | AboRestrictionBlocksLoadActiveFail
  | AboRestrictionBlocksSave
  | AboRestrictionBlocksSaveSuccess
  | AboRestrictionBlocksSaveFail
  | AboRestrictionBlocksDeactivateAll
  | AboRestrictionBlocksDeactivateAllSuccess
  | AboRestrictionBlocksDeactivateAllFail;
