import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { DeactivateReason } from '../../../../shared/models/amway/index';
import { IAppState } from '../../../../store/index';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { DeactivateGetReasons, DeactivateSelectReason } from '../../../../store/deactivation/deactivation.actions';

@Component({
  selector: 'app-deactivation-reason',
  templateUrl: './deactivation-reason.component.html',
  styleUrls: ['./deactivation-reason.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeactivationReasonComponent implements OnInit {

  @Input() params;
  reasons$: Observable<DeactivateReason[]>;

  constructor(
    private store: Store<IAppState>
  ) { }

  ngOnInit() {
    this.store.dispatch(new DeactivateGetReasons(this.params));
    this.reasons$ = this.store.select('deactivation').pipe(filter(Boolean),map(({ reasons }) => reasons));
  }

  onReasonSelect(reason) {
    this.store.dispatch(new DeactivateSelectReason({ reason }));
  }

}
