import { RestrictionBlock, RestrictionBlockConfigService } from '../models/amway/restriction-blocks.model';

/**
 * extends array of blocks adding name and description
 * @param {RestrictionBlock[]} blocks
 * @param {RestrictionBlockConfigService[]} allBlocks
 * @returns {RestrictionBlock[] & any[]}
 */
export function processRestrictionBlocks(blocks: RestrictionBlock[],
                                         allBlocks: RestrictionBlockConfigService[]) {
  return blocks.map((block) => {
    return Object.assign({}, block, allBlocks.find((b) => b.blockPrivTypeId.trim() === block.blockPrivTypeId.trim()));
  });
}

/**
 * inclusion check
 *
 * @param {RestrictionBlock[]} allBlocks
 * @param {string} typeId
 * @returns {boolean}
 */
export function hasRestriction(allBlocks: RestrictionBlock[], typeId: string): boolean {
  return !!allBlocks.find(({blockPrivTypeId}) => blockPrivTypeId === typeId);
}
