import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Observable, of, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../store';
import {
  AboDetail,
  BusinessDetails,
  BusinessNatureName
} from '../../models/amway';
import { AboDetailActionType, AboDetailNature } from '../../models/enums';
import { AboActions } from '../../models/amway';
import { Active_Status } from '../../configs/constants';
import { RegistrationsService } from '../../services';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-abo-detail-list',
  templateUrl: './abo-detail-list.component.html',
  styleUrls: ['./abo-detail-list.component.scss']
})
export class AboDetailListComponent implements OnInit {
  @Input()
  list: Observable<AboDetail[]>;
  @Input()
  prefix: string;
  @Output()
  editAction = new EventEmitter<string>();
  completedList: Observable<AboDetail[]>;
  loaded$: Observable<boolean>;
  actionTypes = AboDetailActionType;
  isPrimary: boolean;
  partyArray;

  constructor(private store: Store<IAppState>,
              private registrationsService: RegistrationsService,
              private activatedRoute: ActivatedRoute,
              private translateService: TranslateService
             ) { }
  ngOnInit() {
    this.completedList = combineLatest(
      this.list,
      this.store.select('account').pipe(filter(data => data.loaded))
    )
      .pipe(filter(([list, iboInfo]) => !!iboInfo)
      ,map(([list, accountDetail]) => {
        let isActive = false;
        let businessNatureName: BusinessNatureName = BusinessNatureName.ABO;
        if (
          accountDetail &&
          accountDetail.data &&
          accountDetail.data.accountMst
        ) {
          isActive = accountDetail.data.accountMst.statusCd === Active_Status;
          businessNatureName = accountDetail.data.accountMst
            .accountSubTypeCd as BusinessNatureName;
        }
        this.partyArray = []

        accountDetail.data.partyList.filter(data => !data.isPrimary).map(data => {
          const partyId = data.partyMst.partyId
          const givenName = data.nameList[0].localeName.givenName
          this.partyArray.push({partyId: partyId, givenName: givenName})
        })
        
        this.isPrimary = accountDetail.data.currentParty.isPrimary
        
        const lst = list
          .map(dl => this.setDetailValue({ data: {} }, dl, isActive));
        return lst;
      }));
  }
  
  /**
   * Sets AboDetail's value from the store
   * @param  {BusinessDetails|AboActions}  store    Store to get data from
   * @param  {AboDetail}                   detail   Detail to complete with value
   * @param  {boolean}                     isActive Is current ABO/Customer active
   * @return {AboDetail}                            Completed detail
   */
  private setDetailValue(
    store = { data: {} },
    detail: AboDetail,
    isActive: boolean
  ): AboDetail {
    const value = detail.keys
      .map(key => store.data[key])
      .filter(val => _.isString(val) || _.isNumber(val));

    return {
      ...detail,
      value,
      isEditDisabled: this.isEditDisabled(detail, isActive)
    };
  }
  /**
   * Filter detail by ABO/Customer nature
   * @param  {AboDetailNature}    detailNature Detail Nature
   * @param  {BusinessNatureName} userNature   ABO/Customer Nature
   * @return {boolean}                         True if we can display this detail for this ABO/Customer
   */
  private filterByBusinessNature(
    detailNature: AboDetailNature,
    userNature: BusinessNatureName
  ): boolean {
    return (
      detailNature === AboDetailNature.Any ||
      (detailNature === AboDetailNature.ABO &&
        userNature === BusinessNatureName.ABO) ||
        (detailNature === AboDetailNature.Customer &&
        userNature === BusinessNatureName.Customer)
      );
    }
    
    /**
     * defines if current detail is disabled for editing
     * @param {AboDetail} detail
     * @param {boolean} isActive
     * @returns {boolean}
     */
    private isEditDisabled(detail: AboDetail, isActive: boolean): boolean {
      return (
        detail.isEditDisabled ||
        (detail.isDisabledForInactive && !isActive) ||
        (detail.isDisabledForActive && isActive)
      );
    }

  getTranslatedString(value) {
    if (value) {
      return this.translateService.stream(value);
    } else {
      return of('');
    }
  }
}
