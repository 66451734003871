export class ABOSearchResult {
  aff: string;
  abo: string;
  partyId: number;
  engagedName: string;
  familyName: string;
  givenName: string;
  status: string;
  email: string;
  primaryOnAccount: string;
  roleInformation: string;
  businessNature: string;
  roleCd: string;
  constructor({
    partyList = [
      {
        familyName: '',
        givenName: '',
        partyId: 0,
        ecommList: [{ ecommAddr: '' }]
      }
    ],
    status = '',
    aff = '',
    abo = '',
    partyId = '',
    familyName = '',
    givenName = '',
    email = { ecommAddr: '' },
    primaryOnAccount = '',
    roleCd = '',
    businessNature = '',
    lglEntityType = ''
  }) {
    this.aff = aff;
    this.abo = abo;
    this.partyId = parseInt(partyId, 10);
    this.familyName = familyName || '';
    this.givenName = givenName || '';
    this.engagedName = (givenName || '') + ' ' + ( familyName || '');
    this.email = email.ecommAddr || '';
    this.status = status;
    let primary = primaryOnAccount
    this.primaryOnAccount =
      primaryOnAccount && primaryOnAccount === '0'
        ? 'Co-applicant'
        : 'Applicant';
    this.roleInformation = this.checkRoleType(primary, roleCd, lglEntityType);
    this.businessNature = businessNature;
    this.roleCd = roleCd;
  }

  checkRoleType(primaryOnAccount, roleCd, lglEntityType){
    if (lglEntityType == "Company Limited"){
      return 'Corporate'
    }
    if (primaryOnAccount == '0' &&  roleCd == 'BusinessOwner' && lglEntityType != "Company Limited") {
      return 'Co-Applicant'
    }
    if (primaryOnAccount == '0' &&  roleCd == 'Prospect' && lglEntityType != "Company Limited") {
      return 'Spouse'
    }
    if (primaryOnAccount == '1' && lglEntityType != "Company Limited") {
      return 'Applicant'
    }
  }
}
