import { SearchParty } from './search-party.model';
import { AccountMst } from './amway/account.model';
import { PartyList } from './amway/party-list.model';

const CUSTOMER_TYPE_CD = 'Customer';
const PERSONAL_NAME_TYPE_CD = 'Legal';

export class AccountSearch {
  aff: string;
  abo: string;
  status: string;
  partyList: SearchParty[] = [];
  accountSubType: string;
  businessNature: string;
  constructor({
    accountMst = <AccountMst>{},
    partyList = [<PartyList>{}]
  }) {
    this.abo = accountMst.aboNum;
    this.aff = accountMst.salesPlanAff;
    this.status = accountMst.statusCd;
    partyList = this.reducePartyList(partyList, accountMst);
    partyList.forEach((party) => {
      party['abo'] = this.abo;
      party['aff'] = this.aff;
      party['status'] = this.status;
      this.partyList.push(new SearchParty(party));
    });

  }

  reducePartyList(partyList, accountMst) {
    partyList = this.reducePartiesByNameTypeCD(partyList);
    partyList = accountMst.accountSubTypeCd === CUSTOMER_TYPE_CD ? [] : partyList;
    return partyList;
  }

  reducePartiesByNameTypeCD(parties: { nameList: { personNameTypeCd: string }[] }[]) {
    return parties.reduce((memo, party) => {
      party.nameList = party.nameList.filter((item) => item.personNameTypeCd === PERSONAL_NAME_TYPE_CD);
      return party.nameList.length ? [...memo, party] : memo;
    }, []);
  }

}

