import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-loading-indicator',
  template: '<span class="amw-loading-indicator"><span>.</span><span>.</span><span>.</span></span>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingIndicatorComponent {

}
