import { Component, Input, OnInit, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as moment from 'moment/moment';
import { IAppState } from '../../../store/index';
import { CalendarFieldOption, CalendarOptionList } from '../../models/index';
import { OPTIONS, MONTH_OPTIONS, DATE_OPTIONS } from './calendar.settings';
import { START_YEAR } from '../../../abo/abo-settings';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnChanges {
  @Input() currentDateString: string;
  @Input() yearsForward: number;
  @Input() disabled = false;
  @Output() currentDateChange = new EventEmitter<string>();
  @Input() showDay: boolean = true;
  currentDate: Date;
  yearOptions: Array<CalendarFieldOption>;
  monthOptions: Array<CalendarFieldOption>;
  dayOptions: Array<CalendarFieldOption>;
  currentOption: CalendarOptionList = {};

  constructor(private store: Store<IAppState>) { }

  ngOnInit() {

    if (this.currentDateString) {
      this.currentDate = new Date(this.currentDateString);
      this.setOptions();
    } else {
      this.setDefaultOptions();
    }
  }

  ngOnChanges({ currentDateString }: SimpleChanges) {
    if (
      currentDateString && currentDateString.currentValue
      && currentDateString.currentValue !== currentDateString.previousValue
    ) {
      this.currentDate = new Date(currentDateString.currentValue);
      this.setOptions();
    }
  }

  /**
   * Updates current*Option and related fields
   * @param option New Option to set
   * @param field Field to set in
   * @param updateOptions True if we need to update dayOptions
   */
  changeValue(option: CalendarFieldOption, field: string, updateOptions = false) {
    this.currentOption[field] = this[field + 'Options'].find(o => o.id === option);
    if (updateOptions) {
      this.setDayOptions();
    }
    this.updateCurrentDate();
  }

  /**
   *  emits new Date value on select change
   */
  updateCurrentDate(): void {
    if (this.currentOption.year && this.currentOption.month && this.currentOption.day) {
      this.currentDateChange.emit(new Date(
        Date.UTC(
          this.currentOption.year.id,
          this.currentOption.month.id,
          this.currentOption.day.id
        )
      ).toISOString().split('.')[0] + 'Z');
    }
  }

  /**
   * returns options for year dropdown
   * @param {number} startYear
   * @returns {Array<number>}
   */
  private getYearOptions(startYear: number, endYear: number): Array<CalendarFieldOption> {
    const yearOptions = [];
    for (let i = startYear; i <= endYear; i++) {
      yearOptions.push(this.convertNumberToOption(i));
    }
    return yearOptions;
  }

  /**
   * sets options for all dropdowns
   */
  private setOptions(): void {
    this.monthOptions = MONTH_OPTIONS;
    this.currentOption.month = this.monthOptions.find(option => option.id === this.currentDate.getUTCMonth());
    this.yearOptions = this.yearsForward
      ? this.getYearOptions(new Date().getFullYear(), new Date().getFullYear() + this.yearsForward)
      : this.getYearOptions(START_YEAR, new Date().getFullYear());
    this.checkCurrentYearoptionAndAppend()
    this.currentOption.year = this.yearOptions.find(option => option.id === this.currentDate.getUTCFullYear());
    this.setDayOptions();
    this.currentOption.day = this.dayOptions.find(option => option.id === this.currentDate.getDate());
  }

  /**
   * sets back years options also available if currentDate exist in back years
   */
  checkCurrentYearoptionAndAppend(){
    if (this.yearsForward && this.currentDate.getUTCFullYear() < (new Date().getFullYear())){
      let backYearOptions: any = []
      const startYear = this.currentDate.getUTCFullYear()
      const endYear = new Date().getFullYear()
      for (let i = startYear; i < endYear; i++) {
        backYearOptions.push(this.convertNumberToOption(i));
      }
      Array.prototype.push.apply(this.yearOptions,backYearOptions); 
    }
  }

  /**
   * sets options for day dropdown
   */
  private setDayOptions(): void {
    if (this.currentOption.month && this.currentOption.year) {
      const monthValue = this.currentOption.month.id;
      const index = this.isYearLeap() && monthValue === 1 ? 12 : monthValue;
      this.dayOptions = OPTIONS[index].map(this.convertNumberToOption);
      this.changeDaySelectionIfNeeded();
    }
  }

  /**
   * sets default options for cases when empty string was passed into component
   */
  private setDefaultOptions(): void {
    this.monthOptions = MONTH_OPTIONS;
    this.yearOptions = this.yearsForward
      ? this.getYearOptions(new Date().getFullYear(), new Date().getFullYear() + this.yearsForward)
      : this.getYearOptions(START_YEAR, new Date().getFullYear());
    this.dayOptions = DATE_OPTIONS.map(this.convertNumberToOption);
  }

  /**
   * checks if selected year is leap
   * @returns {boolean}
   */
  private isYearLeap(): boolean {
    return moment([this.currentOption.year.id]).isLeapYear();
  }

  /**
   * changes selected day to 1 in case if previously
   * selected option is no longer available
   */
  private changeDaySelectionIfNeeded(): void {
    if (
      this.currentOption.day
      && this.currentOption.day.id > this.dayOptions[this.dayOptions.length - 1].id
    ) {
      this.currentOption.day = this.dayOptions[0];
    }
  }

  /**
   * Converts number to CalendarFieldOption
   * @param numberToConvert Index to convert to option (year or day)
   */
  private convertNumberToOption(numberToConvert: number): CalendarFieldOption {
    return {
      text: numberToConvert.toString(),
      id: numberToConvert
    };
  }
}
