import { Settings } from '../models/settings.model';

export const ANA = ['US', 'CA', 'DO'];
export const LAS = ['MX', 'CO', 'GT', 'BR', 'AR', 'PA', 'CR', 'CL', 'UY', 'HN', 'SV', 'VE'];

const MARKET_COUNTRIES = {
  ANA,
  LAS
};
const MARKET_CODES = [
  ['ANA', '10', '010'],
  ['LAS', '170']
];
const DATE_FORMATS = {
  ANA: 'MMMM d, YYYY',
  LAS: 'MMMM d, YYYY'
};

export const MARKETS = MARKET_CODES.reduce((memo, [market, ...codes]) => {
  return codes.reduce((collection, code) => ({
      ...collection,
      [code]: {
        marketCode: code,
        marketName: market,
        isLas: market === 'LAS',
        isAna: market === 'ANA',
        dateFormat: DATE_FORMATS[market],
        countries: MARKET_COUNTRIES[market]
      }
    }), memo);
}, {} as Settings);
