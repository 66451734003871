import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  storage: Storage;
  localStorage: Storage;

  constructor() {
    this.storage = window.sessionStorage;
    this.localStorage = window.localStorage;
    if (!this.isSupported()) {
      throw new Error(
        'Session storage and local storage is not supported by browser'
      );
    }
  }

  /**
   * Method to store data in local storage
   * @param {string} key : Key to be stored in local storage
   * @param {T} value Value to be stored
   */
  setItem<T>(key: string, value: T): void {
    this.storage.setItem(key, JSON.stringify(value));
  }
  /**
   * Method to store data in local storage
   * @param {string} key : Key to be stored in local storage
   * @param {T} value Value to be stored
   */
  setItemInLocalStorage<T>(key: string, value: T): void {
    this.localStorage.setItem(key, JSON.stringify(value));
  }
  /**
   * Method to retrive from local storage
   * @param {string} key : Key to be stored
   * @returns {T} Value retrieved
   */
  getItem<T>(key: string): T {
    return JSON.parse(this.storage.getItem(key));
  }
  /**
   * Method to retrive from local storage
   * @param {string} key : Key to be stored
   * @returns {T} Value retrieved
   */
  getItemFromLocalStorage<T>(key: string): T {
    return JSON.parse(this.localStorage.getItem(key));
  }
  /**
   * method to delete item from local storage
   * @param {string} key : Key to be stored
   */
  removeItem(key: string): void {
    this.storage.removeItem(key);
  }

  /**
   * method to delete item from local storage
   * @param {string} key : Key to be stored
   */
  removeItemFromLocalStorage(key: string): void {
    this.localStorage.removeItem(key);
  }

  /**
   * method to clear the local storage
   */
  clear(): void {
    this.storage.clear();
  }
  /**
   * method to clear the local storage
   */
  clearLocalStorage(): void {
    this.localStorage.clear();
  }
  /**
   * method to check either local storage is supported by the browser or not
   * @return {boolean} True if browser supports "Storage API" otherwise false
   */
  private isSupported(): boolean {
    let supported = true;

    if (!this.storage && !this.localStorage) {
      supported = false;
    }

    return supported;
  }
}
