import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { IAppState } from '../../../store';

import * as moment from 'moment';
import {
  AboRestrictionBlocksLoad, AboRestrictionBlocksSave, AboRestrictionBlocksDeactivateAll
} from '../../../store/abo-restriction-blocks/abo-restriction-blocks.actions';
import { Modal } from 'ngx-modal';
import { Subscription } from 'rxjs';
import { PartyParams } from '../../../shared/models';
import { CommonService } from '../../../core/services';
import { RestrictionBlocks } from '../../../shared/models/amway';
import { RestrictionBlockConfigService, RestrictionBlock } from '../../../shared/models/amway/restriction-blocks.model';

const DEFAULT_BLOCK_TERM = 50;

@Component({
  selector: 'app-abo-restriction-blocks-form',
  templateUrl: './abo-restriction-blocks-form.component.html',
  styleUrls: ['./abo-restriction-blocks-form.component.scss']
})
export class AboRestrictionBlocksFormComponent implements OnInit, OnDestroy {

  @ViewChild('activateBlockModal') activateBlockModal: Modal;
  @ViewChild('updateBlockModal') updateBlockModal: Modal;
  @ViewChild('deactivateBlockModal') deactivateBlockModal: Modal;

  store$: Observable<RestrictionBlocks>;
  blocks$: Observable<RestrictionBlockConfigService[]>;
  activeBlocks$: Observable<RestrictionBlockConfigService[]>;
  currentBlock: RestrictionBlockConfigService;
  blockToAdd: RestrictionBlock;
  defaultEffectiveDate = this.getDefaultDate();
  defaultExpirationDate = this.getDefaultDate(true);
  yearsForward = DEFAULT_BLOCK_TERM;
  deactivateAllBlocks: boolean;
  sub: Subscription;
  restrictionBlocksRolesMap = {};

  constructor(
    private store: Store<IAppState>,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.store.dispatch(new AboRestrictionBlocksLoad({
      params: this.activatedRoute.snapshot.params as PartyParams
    }));
    this.store$ = this.store.select('aboRestrictionBlocks')
      .pipe(map(data => {
        return data[CommonService.getAboString(this.activatedRoute.snapshot.params as PartyParams)]
      })
      ,filter(Boolean));

    this.blocks$ = this.store$
      .pipe(filter(data => data.loadedAll)
      ,map(store => store.data.all));

    this.activeBlocks$ = this.blocks$.pipe(
      map(data => data.filter(block => block.activatedBlock)));

    this.sub = this.store.select('roles').subscribe(({ restrictionBlocks }) => {
      this.restrictionBlocksRolesMap = restrictionBlocks;
    });

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /**
   * Toggles block active/deactive state
   * @param {RestrictionBlockConfigService} Block to toggle
   * @param {boolean} Current state
   */
  toggleBlock(block: RestrictionBlockConfigService, state: boolean) {
    this.setCurrentBlock(block);
    state ? this.deactivateBlockModal.open() : this.activateBlockModal.open();
  }

  /**
   * Opens modal to edit block
   * @param {RestrictionBlockConfigService} Block to edit
   */
  editBlock(block: RestrictionBlockConfigService) {
    this.setCurrentBlock(block);
    this.updateBlockModal.open();
  }

  /**
   * @param modal Modal to close
   * @param {boolean} True if deactivating
   */
  saveBlock(modal: Modal, deactivate = false) {
    if (deactivate) {
      this.blockToAdd.expirationDate =
        this.isBlockValid(this.blockToAdd.effectiveDate, this.getDefaultDate())
          ? this.blockToAdd.effectiveDate
          : this.getDefaultDate();
    }
    // Fix to process data we get from CalendarComponent
    this.blockToAdd.expirationDate = this.blockToAdd.expirationDate.replace('Z', '+07:00');
    this.blockToAdd.effectiveDate = this.blockToAdd.effectiveDate.replace('Z', '+07:00');
    this.store.dispatch(new AboRestrictionBlocksSave({
      params: this.activatedRoute.snapshot.params as PartyParams,
      dataToSave: this.blockToAdd
    }));
    modal.close();
  }

  /**
   * Handles deactivate all click
   * @memberof AboRestrictionBlocksFormComponent
   */
  onDeactivateAll() {
    this.deactivateAllBlocks = true;
    this.deactivateBlockModal.open();
  }

  /**
   * Clears deactivate all flag
   * @memberof AboRestrictionBlocksFormComponent
   */
  onDeactivateClose() {
    this.deactivateAllBlocks = false;
  }

  /**
   * Triggers deactivate one / all blocks methods
   * @param {Modal} modal
   * @memberof AboRestrictionBlocksFormComponent
   */
  onDeactivateConfirm(modal: Modal) {
    return this.deactivateAllBlocks ? this.deactivateAll(modal) : this.saveBlock(modal, true);
  }

  /**
   * Deactivates all active blocks
   */
  deactivateAll(modal: Modal) {
    this.store.dispatch(new AboRestrictionBlocksDeactivateAll({
      restrictionBlocksRolesMap: this.restrictionBlocksRolesMap,
      params: this.activatedRoute.snapshot.params as PartyParams,
      dataToSave: this.getDefaultDate()
    }));
    modal.close();
  }

  /**
   * Checks if block dates are valid
   * @param  {string} Effective date
   * @param  {string} Expiration date
   * @return {boolean} Is block valid
   */
  isBlockValid(effectiveDate: string, expirationDate: string): boolean {
    return new Date(effectiveDate) >= new Date(expirationDate);
  }

  isEffectiveDateValid(expirationDate: string): boolean {
    return new Date(expirationDate) < new Date(moment().startOf('day').format());
  }

  /**
   * Sets current block for shared modals
   * @param {RestrictionBlockConfigService} Block
   */
  private setCurrentBlock(block: RestrictionBlockConfigService): void {
    this.currentBlock = block;
    this.blockToAdd = {
      blockPrivTypeId: block.blockPrivTypeId,
      expirationDate: block.activatedBlock ? block.activatedBlock.expirationDate : this.defaultExpirationDate,
      effectiveDate: block.activatedBlock ? block.activatedBlock.effectiveDate : this.defaultEffectiveDate,
      blockPrivReason: block.activatedBlock && block.activatedBlock.blockPrivReason ? block.activatedBlock.blockPrivReason : ""
    };
  }

  /**
   * Gives default date for modal's CalendarComponent
   * @param  {boolean} Is date expiration
   * @return {string}
   */
  private getDefaultDate(forExpiration = false): string {
    const date = new Date();
    if (forExpiration) {
      date.setFullYear(date.getFullYear() + DEFAULT_BLOCK_TERM);
    }
    return date.toISOString().split('.')[0] + '+07:00';
  }

}
