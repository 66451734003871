import { Action } from '@ngrx/store';

export const SET_NOTIFICATION = '[Notification] set';
export const CLEAR_NOTIFICATION = '[Notification] clear';

export class SetNotification implements Action {
  readonly type = SET_NOTIFICATION;

  constructor(public payload: any = null) {}
}

export class ClearNotification implements Action {
  readonly type = CLEAR_NOTIFICATION;

  constructor(public payload: any = null) {}
}

export type Actions =
  | SetNotification
  | ClearNotification;
