import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  ChangeDetectorRef,
  ElementRef
} from '@angular/core';

@Component({
  selector: 'employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: []
})
export class EmployeeFormComponent implements OnInit {

  elRef: ElementRef

  constructor(
    elRef: ElementRef
  ) {
    this.elRef = elRef;
  }
  ngOnInit(){

  }

}
