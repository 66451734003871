import { Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { PartyService } from '../../shared/services/index';
import { PARTIES_ACCOUNT_GET, PartiesAccount, PartiesAccountSuccess, PartiesAccountFail } from './account-parties.actions';

@Injectable()
export class AccountPartiesEffects {

  constructor(
    private action$: Actions,
    private partyService: PartyService
  ) {
  }

  @Effect()
  partiesAccoutnGet$ = this.action$
    .ofType((PARTIES_ACCOUNT_GET)).pipe(
    switchMap((action: PartiesAccount) => {
      return this.partyService.getAccountsAssociatedWithParty(action.payload)
        .pipe(map((response) => new PartiesAccountSuccess(response))
        ,catchError(error => of(new PartiesAccountFail())));
    }));
}
