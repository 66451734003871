import { AccountDetail, ABOBasicInfo, Tax } from '../models/amway';
import { AboTaxDetail } from '../models/abo-tax-detail.model';
import { getTaxCertDetail } from './tax.helper';
import { processAddresses } from './common.helper';
import { AboDetailActionType } from '../models/enums';
import { AddressList } from '../models/party-basic.models';
import { BankDetail } from '../models/bank-detail.model';
import { TaxCertificate } from '../models';
import { BANK_ACCOUNT_TYPES, BANK_ACCOUNT_STATUS_TYPES } from '../../../../src/app/abo/abo-settings';
import * as _ from 'lodash';

export function getBasicDetails(accountDetail: AccountDetail) {
  if (accountDetail && accountDetail.data) {
     return new ABOBasicInfo(accountDetail.data.currentParty); 
  }
  return {} as ABOBasicInfo;
}

export function getAboTaxDetails(accountDetail: AccountDetail, addressTypes: any[]) {
  if (accountDetail && accountDetail.data) {
    const currentParty = accountDetail.data.currentParty;
    const taxId =
      currentParty.taxList && currentParty.taxList.length > 0
        ? currentParty.taxList[0].taxId || ''
        : '';
    const tax = currentParty.taxList && currentParty.taxList.length > 0 ?
                currentParty.taxList[0] : {} as Tax;
    const taxCertDetail = getTaxCertDetail(
      currentParty.taxCertList,
      accountDetail.data.accountMst.aboNum
    );

    const taxAddresses = processAddresses(
      accountDetail.data.currentParty.addressList,
      addressTypes
    );
    
    const taxAddress = taxAddresses && taxAddresses.length > 0 ? taxAddresses[0] : {} as AddressList;
    return {
      taxId,
      tax,
      taxAddress,
      taxCertDetail,
    } as AboTaxDetail;
  }

  return {taxId: '', tax: {} as Tax , taxAddress: {} as AddressList, taxCertDetail: {} as TaxCertificate} as AboTaxDetail;
}

export function getAboAddresses(accountDetail: AccountDetail, addressTypes: any[]) {
  if (accountDetail && accountDetail.data) {
    const currentParty = accountDetail.data.currentParty;
    const aboAddresses = processAddresses(
      currentParty.addressList,
      addressTypes
    );
    return aboAddresses;
  }
  return [] as AddressList[];
}

export function getAboBankDetails (accountDetail: AccountDetail) {
    if (accountDetail && accountDetail.data) {
        const bankDetailList = accountDetail.data.bankAccountDetailList;
        if (bankDetailList && bankDetailList.length > 0) {
             let bankAccCode = bankDetailList[0].bankAcctTypeCd;
             let bankAccStatus = bankDetailList[0].bankAccStatus;
             let bankAccCodeName = getBankAccountType(bankAccCode);
             let bankAccStatusText = getBankAccountStatus(bankAccStatus);
             let bankAccObj = {};
             if((bankAccCode && bankAccCodeName) || (bankAccStatus && bankAccStatusText)) {
              if (bankAccCode && bankAccCodeName) {
                bankAccObj['bankAcctTypeName'] = bankAccCodeName;
              }

              if (bankAccStatus && bankAccStatusText) {
                bankAccObj['bankAccStatusText'] = bankAccStatusText;
              }
              return Object.assign(bankAccObj, {...bankDetailList[0]});
             } else {
              return bankDetailList[0];
             }
        }
    }
  return {} as BankDetail;
}

export function getAboBankDetailsList (accountDetail: AccountDetail) {
  if (accountDetail && accountDetail.data) {
      const bankDetailList = accountDetail.data.bankAccountDetailList;
      let bankArr:any = []
      if (bankDetailList && bankDetailList.length > 0) {
        bankDetailList.forEach(function(bankDetail, index) {
           let bankAccCode = bankDetailList[index].bankAcctTypeCd;
           let bankAccStatus = bankDetailList[index].bankAccStatus;
           let bankAccCodeName = getBankAccountType(bankAccCode);
           let bankAccStatusText = getBankAccountStatus(bankAccStatus);
           let bankAccObj = {};
           if((bankAccCode && bankAccCodeName) || (bankAccStatus && bankAccStatusText)) {
            if (bankAccCode && bankAccCodeName) {
              bankAccObj['bankAcctTypeName'] = bankAccCodeName;
            }

            if (bankAccStatus && bankAccStatusText) {
              bankAccObj['bankAccStatusText'] = bankAccStatusText;
            }
            bankArr.push(Object.assign(bankAccObj, {...bankDetailList[index]}));
            
           } else {
            bankArr.push(bankDetailList[index]);
           }
        })
        return bankArr;
      }
  }
return [] as BankDetail[];
}

export function getAboBankDetailsSelectedBank (bankDetail: BankDetail) {
      if (bankDetail) {
           let bankAccCode = bankDetail.bankAcctTypeCd;
           let bankAccStatus = bankDetail.bankAccStatus;
           let bankAccCodeName = getBankAccountType(bankAccCode);
           let bankAccStatusText = getBankAccountStatus(bankAccStatus);
           let bankAccObj = {};
           if((bankAccCode && bankAccCodeName) || (bankAccStatus && bankAccStatusText)) {
            if (bankAccCode && bankAccCodeName) {
              bankAccObj['bankAcctTypeName'] = bankAccCodeName;
            }

            if (bankAccStatus && bankAccStatusText) {
              bankAccObj['bankAccStatusText'] = bankAccStatusText;
            }
            return Object.assign(bankAccObj, {...bankDetail});
           } else {
            return bankDetail;
           }
      }
return {} as BankDetail;
}

export function getBankAccountType(bankAccTypeCode): any {
   const bankAccTypeName = BANK_ACCOUNT_TYPES.filter(bankAccTypes => bankAccTypes.AccCode === bankAccTypeCode);
   return bankAccTypeName[0] ? bankAccTypeName[0].AccText: ''; 
}

export function getBankAccountStatus(bankAccStatusCode): any {
  const bankAccStatusText = BANK_ACCOUNT_STATUS_TYPES.filter(bankAccStatus => bankAccStatus.StatusCode === bankAccStatusCode);
  return bankAccStatusText[0] ? bankAccStatusText[0].text: ''; 
}

