import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DeactivateReason, AccountDetail } from '../models/amway/index';
import { AccountSearchParam, AccountCard } from '../models/index';
import { ApiPathConfigService } from '../configs/api';
import { salesAff, aboNum, updateARBalanceAPI, deactivateAccountAPI,
   TAX_COUNTRY_CODE, defaultCurrencyCode, AR_BALANCE_TYPE_CODE } from '../configs/constants';

import * as moment from 'moment';
import { HttpService, CommonService, ErrorLogService } from '../../core/services/index';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/index';
import { ErrorService } from './error.service';

@Injectable()
export class DeactivationService {

  constructor(
    private httpService: HttpService,
    private store: Store<IAppState>,
    private errorService: ErrorService,
    private errorLogService: ErrorLogService,
    private apiPathService: ApiPathConfigService
  ) {
  }

  getDeactivationReasons(): Observable<DeactivateReason[]> {
    return of([{
      id: 'DeletedByAmwayReq',
      label: 'Deleted by Amway Request',
      resignType: 'StaffResignation',
    }, {
      id: 'DeletedByDistReq',
      label: 'Deleted by Dist Request',
      resignType: 'SelfResignation',
    }]);
  }

  deactivateAccount({ req, isRenewal, orderCode, orderStore, selectedReason, products }) {
    const params: AccountSearchParam = req;
    const reason: DeactivateReason = selectedReason;
    const renewPayload = this.getRefundARBodyParams();
    if (renewPayload && renewPayload.balanceAmount > 0) {
      return this.refundAndResign(params, reason);
    } else {
      return this.resignAccount(params, reason);
    }

  }

  refundUpdateARBalance(req: AccountSearchParam) {
    const refundObservable = new Observable((observer) => {
      const api = this.apiPathService.getApiPath(updateARBalanceAPI);
      api.path = api.path.replace(salesAff, req.aff)
        .replace(aboNum, req.abo);
      const bodyParams = this.getRefundARBodyParams();
      if (bodyParams.balanceAmount <= 0) {
        observer.next();
      } else {
        this.httpService.callServerPOST(api, null, bodyParams)
          .subscribe((response) => {
            observer.next(response);
          }, (error) => {
            observer.error(error);
          });
      }
    });
    return refundObservable;
  }

  resignAccount(req: AccountSearchParam, selectedReason: DeactivateReason) {
    const api = this.apiPathService.getApiPath(deactivateAccountAPI);
    api.path = api.path.replace(salesAff, req.aff)
      .replace(aboNum, req.abo);
    let urlParams: HttpParams = new HttpParams;
    urlParams = urlParams.set('resignationDate', moment().format());
    urlParams = urlParams.set('resignationType', selectedReason.resignType);
    urlParams = urlParams.set('requestingAboNum', req.abo);
    urlParams = urlParams.set('resignationReason', selectedReason.id);

    const resignObservable = new Observable((observer) => {
      this.httpService.callServerPOST(api, urlParams, null)
        .subscribe((response) => {
          observer.next(response);
          observer.complete();
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
    });
    return resignObservable;
  }

  getRefundARBodyParams() {
    let startDate = '';
    this.store.select('account').pipe(filter(data => data.loaded)).subscribe((account: AccountDetail) => {
      if (account && account.data ) {
        startDate = account.data.accountMst.aboEntryDate;
      }
    });
    return {
      'balanceTypeCd': AR_BALANCE_TYPE_CODE,
      'currencyCd': defaultCurrencyCode,
      'balanceAmount': CommonService.getRefundAmount(startDate),
      'txSourceCd': 'MagicDMSRenwalRefund',
      'sourcRefNum': '0',
      'txTypeCd': 'Return',
      'txIsoCntryCd': TAX_COUNTRY_CODE,
    };
  }

  refundAndResign(params, reason) {
    const resignOb = new Observable((observer) => {
      this.refundUpdateARBalance(params)
      .subscribe((response) => {
        this.resignAccount(params, reason)
       .subscribe(res => {
       observer.next(res);
       observer.complete();
       }, er => {
        this.LogError(er);
         observer.error(er);
         observer.complete();
       })
       ;
      }, error => {
      this.LogError(error);
      observer.error(error);
      observer.complete();
      });
    });
    return resignOb;
  }
  private LogError(error) {
    const errorMessage = this.errorService.processError(error);
    this.errorLogService.log(errorMessage);
  }
}


