import { ChangeDetectionStrategy, Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { AccountBalanceService } from '../../../shared/services';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'account-balance-list',
    templateUrl: './account-balance-list.component.html',
    styleUrls: ['./account-balance-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
  })

export class AccountBalanceListComponent implements OnInit {
  @Input() accountDetailsList;
  loading: boolean = true
  pager: any = {};
  pagedItems: any[];
  constructor(private accountBalanceService: AccountBalanceService,
              private activatedRoute: ActivatedRoute,
              private cdr: ChangeDetectorRef) {}

  ngOnInit () {
    this.getAccountBalanceData()
  }
  
  getAccountBalanceData(page=1){
    this.loading = true
    const {aff, abo} = this.activatedRoute.snapshot.params
    this.accountBalanceService.getAccountBalancesList(abo, aff, page)
    .subscribe(res => {
      this.loading = false
      if(res && res.accntBalList){
        this.pagedItems = res.accntBalList;
        this.pager = { totalItems: res.totalResults, currentPage: res.requestingPage, pageSize: res.pageSize}
      }
      this.cdr.detectChanges()
    })
  }

  onPageChange(page){
    let pageNum = page.page
    this.pager = {}
    this.getAccountBalanceData(pageNum)
  }
}

