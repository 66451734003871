import { Action } from '@ngrx/store';

export const ROUTE_CHANGE = '[History] route change';

export class RouteChange implements Action {
  readonly type = ROUTE_CHANGE;

  constructor(public payload: any = null) {}
}

export type Actions =
  | RouteChange;
