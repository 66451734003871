import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AbstractFieldComponent, ReversalType } from '../../../../shared/models/amway/index';
import { Modal } from 'ngx-modal';
import { ActivatedRoute, Router } from '@angular/router';
import { PhotoService, PartyService, BusinessInfoService, AccountService } from '../../../../shared/services';
import { NavigationService, CommonService } from '../../../../core/services';

@Component({
    templateUrl: './reactivate-field.component.html',
    styleUrls: ['./reactivate-field.component.scss']
})
export class ReactivateFieldComponent extends AbstractFieldComponent implements OnInit {
    @Input() params;
    @ViewChild('modal') modal: Modal;
    type = ReversalType.REGISTRATION;
    showLoader: boolean;
    reactivateErrorMsg: string = null;

    constructor(private route: ActivatedRoute,
        private partyService: PartyService,
        private navigationService: NavigationService,
        private cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.modal.open();
    }

    isAccountExpired() {
        return false;
    }

    reactivateAccount() {
        this.showLoader = true
        this.modal.close()
        const { aff, abo, partyId } = this.params;
        const params = { aff, abo, partyId };
        this.partyService.reactivateAParty(params.aff, params.abo).subscribe(response => {
            this.navigationService.updateIboComponent(params);
            this.showLoader = false
        }, err => {
            this.showLoader = false
            this.reactivateErrorMsg = JSON.parse(err.error).errorMessage.message
            this.cdr.detectChanges();
        })
    }

    reactivateConfirm() {
        this.reactivateAccount()
    }
}
