import { Action } from '@ngrx/store';

export const ABO_BUSINESS_DETAILS_LOAD = '[Abo business details] load';
export const ABO_BUSINESS_DETAILS_LOAD_SUCCESS = '[Abo business details] load success';
export const ABO_BUSINESS_DETAILS_LOAD_FAIL = '[Abo business details] load fail';
export const ABO_BUSINESS_DETAILS_COUNTRY_SAVE = '[Abo business details] country save';
export const ABO_BUSINESS_DETAILS_COUNTRY_SAVE_SUCCESS = '[Abo business details] country save success';
export const ABO_BUSINESS_DETAILS_COUNTRY_SAVE_FAIL = '[Abo business details] country save fail';
export const ABO_BUSINESS_DETAILS_SPA_SAVE = '[Abo business details] SPA save';
export const ABO_BUSINESS_DETAILS_SPA_SAVE_SUCCESS = '[Abo business details] SPA save success';
export const ABO_BUSINESS_DETAILS_SPA_SAVE_FAIL = '[Abo business details] SPA save fail';
export const CUSTOMER_SPONSOR_CHANGE = '[Abo business details] Customer sponsor change';
export const CUSTOMER_SPONSOR_CHANGE_SUCCESS = '[Abo business details] Customer sponsor change success';
export const CUSTOMER_SPONSOR_CHANGE_FAIL = '[Abo business details] Customer sponsor change fail';
export const ABO_BUSINESS_DETAILS_SPA_CONTRACT_CREATE = '[Abo business details] SPA contract create';
export const ABO_BUSINESS_DETAILS_SPA_CONTRACT_CREATE_SUCCESS = '[Abo business details] SPA contract create success';
export const ABO_BUSINESS_DETAILS_SPA_CONTRACT_CREATE_FAIL = '[Abo business details] SPA contract create fail';

export class AboBusinessDetailsLoad implements Action {
  readonly type = ABO_BUSINESS_DETAILS_LOAD;

  constructor(public payload: any = null) {}
}

export class AboBusinessDetailsLoadSuccess implements Action {
  readonly type = ABO_BUSINESS_DETAILS_LOAD_SUCCESS;

  constructor(public payload: any) {}
}

export class AboBusinessDetailsLoadFail implements Action {
  readonly type = ABO_BUSINESS_DETAILS_LOAD_FAIL;

  constructor(public payload: any = null) {}
}

export class AboBusinessDetailsCountrySave implements Action {
  readonly type = ABO_BUSINESS_DETAILS_COUNTRY_SAVE;

  constructor(public payload: any) {}
}

export class AboBusinessDetailsCountrySaveSuccess implements Action {
  readonly type = ABO_BUSINESS_DETAILS_COUNTRY_SAVE_SUCCESS;

  constructor(public payload: any = null) {}
}

export class AboBusinessDetailsCountrySaveFail implements Action {
  readonly type = ABO_BUSINESS_DETAILS_COUNTRY_SAVE_FAIL;

  constructor(public payload: any = null) {}
}

export class AboBusinessDetailsSpaSave implements Action {
  readonly type = ABO_BUSINESS_DETAILS_SPA_SAVE;

  constructor(public payload: any) {}
}

export class AboBusinessDetailsSpaSaveSuccess implements Action {
  readonly type = ABO_BUSINESS_DETAILS_SPA_SAVE_SUCCESS;

  constructor(public payload: any = null) {}
}

export class AboBusinessDetailsSpaSaveFail implements Action {
  readonly type = ABO_BUSINESS_DETAILS_SPA_SAVE_FAIL;

  constructor(public payload: any = null) {}
}

export class AboBusinessDetailsSPAContractCreate implements Action {
  readonly type = ABO_BUSINESS_DETAILS_SPA_CONTRACT_CREATE;

  constructor(public payload: any) {}
}

export class AboBusinessDetailsSPAContractCreateSuccess implements Action {
  readonly type = ABO_BUSINESS_DETAILS_SPA_CONTRACT_CREATE_SUCCESS;

  constructor(public payload: any) {}
}

export class AboBusinessDetailsSPAContractCreateFail implements Action {
  readonly type = ABO_BUSINESS_DETAILS_SPA_CONTRACT_CREATE_FAIL;

  constructor(public payload: any) {}
}

export class CustomerSponsorChange implements Action {
  readonly type = CUSTOMER_SPONSOR_CHANGE;

  constructor(public payload: any) {}
}

export class CustomerSponsorChangeSuccess implements Action {
  readonly type = CUSTOMER_SPONSOR_CHANGE_SUCCESS;

  constructor(public payload: any = null) {}
}

export class CustomerSponsorChangeFail implements Action {
  readonly type = CUSTOMER_SPONSOR_CHANGE_FAIL;

  constructor(public payload: any = null) {}
}

export type Actions =
  AboBusinessDetailsLoad
  | AboBusinessDetailsLoadSuccess
  | AboBusinessDetailsLoadFail
  | AboBusinessDetailsCountrySave
  | AboBusinessDetailsCountrySaveSuccess
  | AboBusinessDetailsCountrySaveFail
  | AboBusinessDetailsSpaSaveSuccess
  | AboBusinessDetailsSpaSaveFail
  | CustomerSponsorChange
  | CustomerSponsorChangeSuccess
  | CustomerSponsorChangeFail
  | AboBusinessDetailsSPAContractCreate
  | AboBusinessDetailsSPAContractCreateSuccess
  | AboBusinessDetailsSPAContractCreateFail
  | AboBusinessDetailsSpaSave;
