export enum ContactPointTypeCdEnum {
    Alerts = <any> 'Alerts',
    AlternateAddress = <any> 'AlternateAddress',
    AlternateFax = <any> 'AlternateFax',
    AlternatePhone = <any> 'AlternatePhone',
    BusinessAddress = <any> 'BusinessAddress',
    BusinessEmail = <any> 'BusinessEmail',
    BusinessFax = <any> 'BusinessFax',
    BusinessPhone = <any> 'BusinessPhone',
    HomeAddress = <any> 'HomeAddress',
    HomeFax = <any> 'HomeFax',
    HomePhone = <any> 'HomePhone',
    Mobile = <any> 'Mobile',
    PersonalEmail = <any> 'PersonalEmail',
    SocialMedia = <any> 'SocialMedia',
  }
