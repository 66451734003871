import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';
import { defaultAdvancedYearsValue, defaultCalanderFormat } from '../configs/constants';
import * as moment from 'moment';

@Pipe({
  name: 'FormatedDate'
})
export class FormatedDatePipe extends DatePipe implements PipeTransform {
  
  transform(value: any, args?: any): any {
    if (!value){ return '' }
    var dd = moment(value).add(defaultAdvancedYearsValue, 'year');
    return value = dd.format(defaultCalanderFormat);
  }
}