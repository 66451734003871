import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';
import { defaultDateFormat } from '../configs/constants';


@Pipe({
  name: 'appDate'
})
export class AppDatePipe extends DatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value = value ? new Date(value).toUTCString() : value;
    return super.transform(value, args ? args : defaultDateFormat);
  }

}
