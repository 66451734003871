import { Observable, forkJoin } from 'rxjs';
import { Injectable } from '@angular/core';
import { CountryService } from './country.service';
import { LanguageService } from './language.service';
import { AccountSearchParam } from '../models/index';

@Injectable()
export class ConfigService {

  constructor(
    private countryService: CountryService,
    private languageService: LanguageService
  ) {
  }

  getConfigs(reqAff: AccountSearchParam) {
    const configsObservable: Observable<Object> = new Observable((observer) => {
      forkJoin(
        this.languageService.getLanguageInfo(reqAff.aff),
        this.countryService.getCountryInfo(reqAff.aff),
        (languages, countries) => ({
          languages,
          countries
        })).subscribe((response) => {
          observer.next(response);
          observer.complete();
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
    });
    return configsObservable;
  }
}
