import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-collapse',
  templateUrl: './collapse.component.html',
  styleUrls: ['./collapse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollapseComponent implements OnInit {

  @Input() title: string;
  @Input() icon: string;
  @Input() defaultOpenState = false;
  state: boolean;

  ngOnInit() {
    this.state = this.defaultOpenState;
  }

  changeState() {
    this.state = !this.state;
  }
}
