import { Actions, SET_ROLES, SET_ADMIN_ROLE } from './roles.actions';
import { RolesState } from '../../shared/models/index';
import { ROLES_MAP } from '../../shared/configs/roles';
import * as _ from 'lodash';


/**
 * returns roles object
 * @param {Array<string>} roles
 * @returns {RolesState}
 */
function getRolesMatrix(roles: Array<string>): RolesState {
  const args = roles.map((role) => ROLES_MAP[role]);
  return mergeByTrue.apply(this, args);
}

// TODO remove all Chuck related stuff when roles come from token
function getChuckNorrisRoleMatrix(value = _.defaultsDeep({}, ...(_.values(ROLES_MAP)))) {
  return _.isObject(value) ? _.mapValues(value, getChuckNorrisRoleMatrix) : true;
}

/**
 * gets as param array of objects with the same structure
 * and returns result where property has true value
 * in case if any item from params has corresponding property with true
 * @param {Array<any>} args
 * @returns {RolesState}
 */
function mergeByTrue(...args): RolesState {
  return _.mergeWith({}, ...args, (a, b) => {
    return _.isObject(a) ? mergeByTrue(a, b) : a || b;
  }) as RolesState;
}

export function rolesReducer(state: RolesState, action: Actions): RolesState {
  switch (action.type) {
    case SET_ROLES:
      return getRolesMatrix(action.payload.roles);

    case SET_ADMIN_ROLE:
      return getChuckNorrisRoleMatrix();

    default:
      return state;
  }
}
