import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-abo-deletion-box',
  templateUrl: './abo-deletion-box.component.html',
  styleUrls: ['./abo-deletion-box.component.scss']
})
export class AboDeletionBoxComponent implements OnInit {

  @Output() cancel = new EventEmitter();
  @Output() process = new EventEmitter();
  
  constructor(
  ) {
  }

  ngOnInit() {
  }

  proceed(){
    this.process.emit();
  }

  cancelPopup(){
    this.cancel.emit();
  }
}
