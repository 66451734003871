import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { IAppState } from '../../store';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getArOrAmplusBalance } from '../../shared/helpers/account.helper';
import { AccountDetail } from '../../shared/models/amway/account.model';
import { AccountBalance } from '../../shared/models/amway';
import { AR_BALANCE_TYPE_CODE, AMPLUS_BALANCE_TYPE_CODE } from '../../shared/configs/constants';

@Component({
  selector: 'app-abo-balance-details',
  templateUrl: './abo-account-balance.component.html',
  styleUrls: ['./abo-account-balance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboAccountBalanceComponent implements OnInit, OnDestroy {
  @Input() accountDetails;
  @Input() arBalancetitle: string;
  @Output() calculatedAR = new EventEmitter();
  loaded$: Observable<boolean>;
  account$: Observable<AccountDetail>;
  accountBalance: AccountBalance;
  ARBalanceCollapse: boolean = false;

  constructor(private store: Store<IAppState>) { }

  ngOnInit() {
    let arBalannceTypeCode;
    if (this.arBalancetitle == 'Amplus') { arBalannceTypeCode = AMPLUS_BALANCE_TYPE_CODE }
    if (this.arBalancetitle == 'AR' || this.arBalancetitle == 'Staff Entitlement') { arBalannceTypeCode = AR_BALANCE_TYPE_CODE }
    this.accountBalance = getArOrAmplusBalance(this.accountDetails, arBalannceTypeCode);
    this.calculatedAR.emit(this.accountBalance);
  }

  ngOnDestroy() { }
}
