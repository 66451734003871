import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  Renderer2
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { AuthService } from '../../../core/services';
import { LoginService } from '../../shared';
import { Token } from '../../../shared/models/token.model';
import { masheryTokenSuccessMessage } from '../../../shared/configs/constants';

@Component({
  selector: 'app-occ-redirect',
  templateUrl: 'occ-redirect.component.html'
})
export class OccRedirectComponent implements OnInit {
  @ViewChild('occIframeHolder')
  private iFrameHolderRef: ElementRef;
  iFrame: HTMLIFrameElement;

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private location: Location,
    private renderer: Renderer2,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.handleRouteParams();
  }

  handleRouteParams() {
    let route = this.activatedRoute.snapshot.fragment;
    this.location.go('');
    const token = {};
    if (route) {
      route = route.substring(route.indexOf('#'), route.length);
      const routeParams = route.split('&');
      routeParams.forEach(param => {
        const property: { key: string; value: string } = {
          key: param.split('=')[0],
          value: param.split('=')[1]
        };
        token[property.key] = property.value;
      });
    }
    this.loginService.storeOccAuthToken(<Token>token);
    this.addMasheryIframe();
    window.addEventListener(
      'message',
      message => this.windowEventHandler(message),
      false
    );
  }

  private addMasheryIframe() {
    this.iFrame = this.renderer.createElement('iframe');
    this.iFrame.height = '0';
    this.iFrame.width = '0';
    this.iFrame.src = this.authService.buildMasheryAuthCodePath();
    this.renderer.appendChild(this.iFrameHolderRef.nativeElement, this.iFrame);
  }

  private windowEventHandler(eve: MessageEvent) {
    if (eve['data'] === masheryTokenSuccessMessage) {
      this.authService.handleRedirection();
      this.renderer.removeChild(
        this.iFrameHolderRef.nativeElement,
        this.iFrame
      );
    }
  }
}
