import {
  Actions,
  CONFIG_GET_FAIL,
  CONFIG_GET_SUCCESS,
  CONFIG_CONTRACTS_GET_SUCCESS,
  CONFIG_CONTRACTS_GET_FAIL,
} from './config.actions';
import { ConfigState } from '../../shared/models/amway/index';


export function configReducer(state: ConfigState, action: Actions): any {
  switch (action.type) {
    case CONFIG_GET_SUCCESS:
      return Object.assign({}, state, { [action.payload.aff]: action.payload.data });

    case CONFIG_CONTRACTS_GET_SUCCESS:
      return Object.assign({}, state, { [action.payload.data.contractTypeId]: action.payload.data });

    case CONFIG_GET_FAIL:
    case CONFIG_CONTRACTS_GET_FAIL:
      // TODO: show message when error handling is implemented on UI side
      return state;

    default:
      return state;
  }
}
