import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService, CommonService } from '../../core/services';
import { ApiPathConfigService } from '../configs/api';
import { VolumeResponse, VolumeDetails } from '../models/amway';
import { AwardsResponse } from '../models';
import { Observable } from 'rxjs';
import { getVolumeInfoAPI, getPeriodInfoAPI } from './../configs/constants';
@Injectable()
export class VolumeService {

  constructor(
    private httpService: HttpService,
    private apiPathService: ApiPathConfigService
  ) {
  }

  getPeriod(salesAffCode: string) {
    const periodObservable: Observable<string> = new Observable((observer) => {
      let urlParams: HttpParams = new HttpParams();
      urlParams = urlParams.set('aff', salesAffCode);
      const api = this.apiPathService.getApiPath(getPeriodInfoAPI);
      this.httpService.callServerGET(api, urlParams, null)
        .subscribe((response: { period: string }) => {
          observer.next(response.period);
          observer.complete();
        }, (err) => {
          observer.error(err);
          observer.complete();
        });
    });
    return periodObservable;
  }


  getVolumeAwards({ aff, abo }) {
    const awardsObservable: Observable<AwardsResponse> = new Observable((observer) => {

      this.getPeriod(aff)
        .subscribe((period: string) => {
          let urlParams: HttpParams = new HttpParams();
          urlParams = urlParams.append('affAbo', `${aff},${abo}`);
          urlParams = urlParams.append('period', period);
          const api = this.apiPathService.getApiPath(getVolumeInfoAPI);
          this.httpService.callServerGET(api, urlParams, null)
            .subscribe((response: VolumeResponse) => {
              if (response && response.volumeDtlPeriods[0] && response.volumeDtlPeriods[0].volumeDetails) {
                const details: VolumeDetails = response.volumeDtlPeriods[0].volumeDetails;
                observer.next(new AwardsResponse(details));
              } else {
                observer.next(new AwardsResponse({}));
              }
              observer.complete();
            }, (error) => {
              observer.error(error);
              observer.complete();
            });
        }, (error) => {
          observer.next(error);
          observer.complete();
        });
    });
    return awardsObservable;
  }

  getVolumeDetails({ aff, abo }) {

    const volumeDetailObservable: Observable<VolumeDetails> = new Observable((observer) => {

      this.getPeriod(aff)
        .subscribe((period: string) => {
          let urlParams: HttpParams = new HttpParams();
          urlParams = urlParams.append('affAbo', `${aff},${abo}`);
          urlParams = urlParams.append('period', period);
          const api = this.apiPathService.getApiPath(getVolumeInfoAPI);
          this.httpService.callServerGET(api, urlParams, null)
            .subscribe((response: VolumeResponse) => {
              const details: VolumeDetails = response.volumeDtlPeriods[0].volumeDetails;
              observer.next(details);
              observer.complete();
            }, (error) => {
              observer.error(error);
              observer.complete();
            });
        }, (error) => {
          observer.next(error);
          observer.complete();
        });
    });
    return volumeDetailObservable;
  }
}
