import { Effect, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { AuthService } from '../../core/services/index';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';

import {
  USER_SET_DATA, USER_SIGN_OUT,
  UserSetData, UserGetDataFail, UserSignOutSuccess
} from './user.actions';
import AppSettings from '../../app.settings';

const MAX_TOKEN_RETRIES = 3;

@Injectable()
export class UserEffects {

  @Effect()
  signOut$ = this.actions$
    .ofType(USER_SIGN_OUT)
    .pipe(tap(() => this.authService.removeUserData())
    ,map(() => new UserSignOutSuccess()));

  constructor(
    private actions$: Actions,
    private authService: AuthService) { }
}
