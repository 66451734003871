import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SponsorsList, UplineResponse } from '../models/amway';
import { UplineService } from './upline.service';
import { AccountService } from './account.service';
import { Injectable } from '@angular/core';
import { HttpService } from '../../core/services';
import { ApiPathConfigService } from '../configs/api';
import { PlatinumUpline } from '../models/platinum-upline.model';
import * as _ from 'lodash';
import { getAccountName } from '../helpers/common.helper';
import { LglEntityTypeCompany, PartyTypeCd, getPlatinumSponsorAPI } from '../configs/constants';

const DETAIL_CODE = 'MinDetail';
let SPONSOR_KEYS = ['DI', 'EM', 'PT'];
@Injectable()
export class SponsorService {
  constructor(
    private uplineService: UplineService,
    private accountService: AccountService,
    private httpService: HttpService,
    private apiPathService: ApiPathConfigService
  ) {}

  getSponsors({ abo, aff }): Observable<SponsorsList> {
    return this.uplineService
      .getUplineInfo(abo, aff)
      .pipe(switchMap((resp: UplineResponse) => {
        const uplines = resp.allUplines;
        let internationalSponsor = _.filter(uplines, function(o){ return o.sponsorType == 'MN'})
        if (internationalSponsor.length > 0) {
          SPONSOR_KEYS.push('MN')
        } else {
          var index = SPONSOR_KEYS.indexOf('MN');
          if (index != -1) {SPONSOR_KEYS.splice(index, 1); }
        }
        if (uplines && uplines.length) {
          const sponserListObservable = new Observable<SponsorsList>(
            observer => {
              forkJoin(
                ...SPONSOR_KEYS.map(sponsorKey => {
                  const upline = uplines.find(line => {
                    return line.sponsorType === sponsorKey;
                  });
                  return this.accountService.getAccountData(
                    upline ? upline.sponsorAff : '',
                    upline ? upline.sponsorAbo : '',
                    '',
                    DETAIL_CODE
                  );
                })
              ).subscribe(
                accounts => {
                  const data = SPONSOR_KEYS.reduce(
                    (sponsorsList, sponsor, key) => {
                      const memoKey = sponsor.toLowerCase();
                      const accountMst = accounts[key].accountMst;
                      // condition for corporate
                      if (accountMst.lglEntityType == LglEntityTypeCompany) {
                        let personsList = _.filter(accounts[key].partyList, function(party) { return party.partyMst.partyTypeCd == PartyTypeCd })
                        sponsorsList[`${memoKey}Name`] = getAccountName('', personsList[0])
                      } else {
                        sponsorsList[`${memoKey}Name`] = getAccountName('', accounts[key].primaryParty)
                      }
                      sponsorsList[`${memoKey}Number`] = accountMst.aboNum;
                      return sponsorsList;
                    },
                    {} as SponsorsList
                  );
                  observer.next(data);
                  observer.complete();
                },
                error => observer.error(error)
              );
            }
          );
          return sponserListObservable;
        } else {
          return of({} as SponsorsList);
        }
      }));
  }
getPlatinumSponsor(request: any, period): Observable<PlatinumUpline> {
  const api = this.apiPathService.getApiPath(getPlatinumSponsorAPI);
  api.path =  api.path + `?affAbo=${request.aff},${request.abo}&period=${period}`;
  return this.httpService.callServerGET(api, null, null)
          .pipe(map(response => {
            if (response && response.platinumUpline && response.platinumUpline.length > 0) {
              return response.platinumUpline[0];
            }
            return {} as PlatinumUpline;
          }));
}

}
