import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { AboTaxDetail } from '../../../../shared/models/abo-tax-detail.model';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../store';
import { SafeResourceUrl } from '@angular/platform-browser';
import { generateTaxDetailsPayload, getTaxPayerParty, taxAdressPayload, taxPayeableParties } from '../../../../shared/helpers/tax.helper';
import { ActivatedRoute } from '@angular/router';
import { ValidateUploadedFile } from '../../../../shared/helpers/common.helper';
import {
  Account
} from '../../../../shared/models/amway/index';

import {
  allowedUploadFileExt,
  maxAllowedFileSizeInBytes,
  LglEntityTypeCompany
} from '../../../../shared/configs/constants';
import { AddressService } from '../../../../shared/services';
import { AreaLocation } from '../../../../shared/models/area-locator.model';
import { DropdownOption } from '../../../../shared/models';
import {
  TaxPayerDetail
} from '../../../../shared/models/amway/index';

const DEFAULT_BLOCK_TERM = 50;
import * as moment from 'moment';

@Component({
  selector: 'app-abo-tax-info-form',
  templateUrl: './abo-tax-info-form.component.html',
  styleUrls: ['./abo-tax-info-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})

export class AboTaxInformationComponent implements OnInit {
  @Output()
  cancel = new EventEmitter<any>();
  @Output()
  updateTaxDetails = new EventEmitter<AboTaxDetail>();
  @Input()
  taxInfo: AboTaxDetail;
  @Input()
  taxPayerInfo: TaxPayerDetail;
  @Input()
  accountFullDetail: Account;
  editableInfo: AboTaxDetail;
  taxCertForm$: Observable<SafeResourceUrl>;
  saving$: Observable<boolean>;
  newTaxFileName: string;
  newTaxFile: File;
  taxForm: FormGroup;
  taxCertUploadErrors: string[] = [];
  locations$: Observable<AreaLocation[]>;
  locationFileldsHasError: boolean;
  payerList: Array<DropdownOption>;
  taxPayerCurrent: any;
  taxPayerReadOnly: boolean;
  taxPayerParty: any;
  yearsForward = DEFAULT_BLOCK_TERM;
  defaultEffectiveDate = this.getDefaultDate();
  taxPayerForm: any;
  effectiveDateInvalid: boolean;
  lastEffectiveDate: string;
  taxStore$: any;
  accountStore$: any;
  taxLoading$: any = null;
  accountLoading$: any = null;
  loading: boolean = false;

  constructor(
    private store: Store<IAppState>,
    private activatedRoute: ActivatedRoute,
    private addressService: AddressService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnInit() {
    this.updateTaxPayerData()

    const params = this.activatedRoute.snapshot.params;
    this.saving$ = this.store
      .select('aboPersonalDetails')
      .pipe(filter(data => data['TAX_SAVE'])
      ,map(data =>  data['TAX_SAVE'].loader));

    this.taxForm = this.createTaxForm(this.editableInfo);

    this.addressService
      .getProvinceForCountry()
      .pipe(filter((result: any) => !result.loading))
      .subscribe((res:any) => {
        if (this.editableInfo.taxAddress.stateCd) {
          this.editableInfo.taxAddress.stateName = res.data.find(
            st => st.isocode === this.editableInfo.taxAddress.stateCd
          )
            ? res.data.find(
                st => st.isocode === this.editableInfo.taxAddress.stateCd
              ).name
            : '';
          this.updateTaxFormValues(this.editableInfo);
        }
        this.loading = true
        this.cdr.detectChanges();
      });

    if (this.taxPayerInfo && this.taxPayerInfo.effectiveDate) {
      this.lastEffectiveDate = this.taxPayerInfo.effectiveDate.split('+')[0] + 'Z';
    }
  }

  saveChanges() {
    if (this.taxForm.valid) {
      let updatedEditInfo = _.cloneDeep(this.editableInfo )
      updatedEditInfo.tax.taxId = this.taxForm.controls['taxId'].value;
      updatedEditInfo.tax.taxPayerType = this.taxForm.controls['taxPayerType'].value;
      updatedEditInfo.tax.effectiveDate = this.taxForm.controls['effectiveDate'].value;
      updatedEditInfo.tax.vatRegister = this.taxForm.controls['vatRegister'].value;

      const params = this.activatedRoute.snapshot.params;
      const updatedDetails = generateTaxDetailsPayload(
        updatedEditInfo,
        params.partyId
      );
      this.updateTaxDetails.emit(updatedDetails);
    } else {
      this.taxForm.controls['taxId'].markAsTouched();
    }
  }
  cancelChanges() {
    this.taxCertUploadErrors = [];
    this.cancel.emit();
  }
  cancelUpload() {
    this.taxCertUploadErrors = [];
    this.newTaxFile = null;
    this.newTaxFileName = null;
  }
  selectFile(event) {
    this.taxCertUploadErrors = [];
    this.newTaxFile =
      event.target.files && event.target.files.length > 0
        ? event.target.files[0]
        : null;
    if (this.newTaxFile) {
      this.newTaxFileName = this.newTaxFile.name;
    } else {
      this.newTaxFileName = null;
    }
  }

  private createTaxForm(taxdetail: AboTaxDetail) {
    const txform = {};
    txform['taxId'] = new FormControl(
      {value: taxdetail.tax.taxId, disabled: true}
        , [
      Validators.required,
      Validators.minLength(15),
      Validators.maxLength(15),
      Validators.pattern('^[0-9]+$')
    ]);
    txform['adrLine1'] = new FormControl({
        value: taxdetail.taxAddress.addrStreet || '',
        disabled: true
      },
      [Validators.required, Validators.maxLength(28)]
    );
    txform['adrLine2'] = new FormControl(
      {
        value: taxdetail.taxAddress.addrLineTwo || '',
        disabled: true
      },
      [Validators.maxLength(28)]
    );
    txform['adrLine3'] = new FormControl(
      {
        value: taxdetail.taxAddress.addrLineThree || '',
        disabled: true
      },
      [Validators.maxLength(28)]
    );
    txform['locator'] = new FormControl('');
    txform['city'] = new FormControl(
      {
        value: taxdetail.taxAddress.cityName,
        disabled: true
      },
      [Validators.required]
    );

    txform['district'] = new FormControl(
      {
        value: taxdetail.taxAddress.districtName,
        disabled: true
      },
      [Validators.required]
    );

    txform['subDistrict'] = new FormControl(
      {
        value: taxdetail.taxAddress.subDistrictName,
        disabled: true
      },
      [Validators.required]
    );
    txform['province'] = new FormControl(
      {
        value: taxdetail.taxAddress.stateName,
        disabled: true
      },
      [Validators.required]
    );
    txform['stateCd'] = new FormControl(
      {
        value: taxdetail.taxAddress.stateCd,
        disabled: true
      },
      [Validators.required]
    );
    txform['postalCode'] = new FormControl(
      {
        value: taxdetail.taxAddress.postalCd,
        disabled: true
      },
      [Validators.required]
    );

    let vatRegister = 'Yes'
    if (this.taxPayerInfo && this.taxPayerInfo.vatRegister){
      vatRegister = this.taxPayerInfo.vatRegister
    }
    txform['vatRegister'] = new FormControl(vatRegister);
    txform['taxPayerType'] = new FormControl(this.taxPayerCurrent.id);
    txform['effectiveDate'] = new FormControl(this.defaultEffectiveDate);

    return new FormGroup(txform);
  }

  private updateTaxFormValues(taxdetail: AboTaxDetail) {
    this.taxForm.controls['province'].setValue(taxdetail.taxAddress.stateName);
  }
  private updateAddressLocation(location: AreaLocation) {
    this.taxForm.controls['city'].setValue(location.city);
    this.taxForm.controls['district'].setValue(location.district);
    this.taxForm.controls['subDistrict'].setValue(location.subDistrict);
    this.taxForm.controls['province'].setValue(location.province);
    this.taxForm.controls['postalCode'].setValue(location.postalCode);
    this.taxForm.controls['stateCd'].setValue(location.stateCode);
  }
  private updateAddressAndCid(taxdetail) {
    this.taxForm.controls['taxId'].setValue(taxdetail.taxId);
    this.taxForm.controls['adrLine1'].setValue(taxdetail.taxAddress.addrStreet);
    this.taxForm.controls['city'].setValue(taxdetail.taxAddress.cityName);
    this.taxForm.controls['province'].setValue(taxdetail.taxAddress.stateName);
    this.taxForm.controls['district'].setValue(taxdetail.taxAddress.district);
    this.taxForm.controls['postalCode'].setValue(taxdetail.taxAddress.postalCode);
  }
  getLocations(event) {
    this.locations$ = this.addressService
      .getAddressLocations(event)
      .pipe(filter((result: any) => !result.loading)
      ,map((response: any) => response.data));
  }
  onLocationSelect(event: AreaLocation) {
    this.locationFileldsHasError = false;
    this.updateAddressLocation(event);
  }

  updatePayer(event){
    this.taxForm.controls['taxPayerType'].setValue(event.id);
    let primaryPartyAddress = taxAdressPayload(this.accountFullDetail.primaryParty);
    if (event.id == 'Primary' || event.id == 'Both') { this.editableInfo = primaryPartyAddress; }

    if (event.id == 'CoApplicant') {
      let coapplicantPartyAddress = taxAdressPayload(this.accountFullDetail.coApplicantParty);
      this.editableInfo = coapplicantPartyAddress.taxAddress ? coapplicantPartyAddress :  primaryPartyAddress
     }
     this.updateAddressAndCid(this.editableInfo);
  }

  updateTaxPayerData(){
    this.taxPayerParty = getTaxPayerParty(this.accountFullDetail, this.taxPayerInfo)
    let taxPayerType = this.taxPayerInfo && this.taxPayerInfo.taxPayerType;

    let partiesWithCid = taxPayeableParties(this.accountFullDetail)

    if (this.accountFullDetail.accountMst.lglEntityType != LglEntityTypeCompany) {
      if (partiesWithCid.length > 1) { 
        this.taxPayerReadOnly = false
        this.payerList = [{id: 'Primary', text: 'Primary'}, {id: 'CoApplicant', text: 'CoApplicant'}, {id: 'Both', text: 'Both'}]
      } else {
        this.taxPayerReadOnly = true
        this.payerList = [{id: taxPayerType, text: taxPayerType}]
      }
      this.taxPayerCurrent = {id: taxPayerType, text: taxPayerType}
    } else {
      this.taxPayerReadOnly = true
      this.payerList = [{id: 'Primary', text: 'Corporate'}]
      this.taxPayerCurrent = {id: 'Primary', text: 'Corporate'}
    }

    this.editableInfo = _.cloneDeep(taxAdressPayload(this.taxPayerParty))
  }

    /**
   * Gives default date for modal's CalendarComponent
   * @param  {boolean} Is date expiration
   * @return {string}
   */
  private getDefaultDate(): string {
    const date = new Date();
    let startOfMonth = moment().startOf('month').add({days:1,months:1});
    return startOfMonth.toISOString().split('.')[0] + '+00:00';
  }

  private formatDate(dateToFormat){
    const date = moment(dateToFormat).format('YYYY-MM-DD')
    return date + 'T00:00:00+00:00';
  }

  updateEffectiveDate(event){
    if (new Date(event) < new Date(moment().startOf('month').format())){
      this.effectiveDateInvalid = true
      this.taxForm.controls['effectiveDate'].setErrors({'incorrect': true});
      return;
    } else {
      this.effectiveDateInvalid = false
      this.taxForm.controls['effectiveDate'].setValue(event)
    }
  }
}
