import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy
} from '@angular/core';
import { Schema, ABOSearchResult, CellType } from '../../models/index';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

const ACTIVE_STATUS = 'Active';
type SearchResults = ABOSearchResult;

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableComponent implements OnInit, OnDestroy {
  @Input()
  schema: Schema;
  @Input()
  isScrollable: boolean;
  @Input()
  data: SearchResults;
  @Output()
  action = new EventEmitter();
  selectedItem: ABOSearchResult;
  cellTypes = CellType;
  constructor(private translateService: TranslateService) {}
  ngOnInit() {}

  linkAction(payload): void {
    this.action.emit(payload);
  }
  getTranslatedString(value) {
    if (value) {
      return this.translateService.stream(value);
    } else {
      of('');
    }
  }
  isActive(value: string): boolean {
    return value === ACTIVE_STATUS;
  }

  getValue(data: SearchResults, property: string): string {
    return _.get(data, property, '');
  }

  ngOnDestroy() {}
}
