import {
  ABO_VOUCHER_DETAIL_LOAD,
  ABO_VOUCHER_DETAIL_LOAD_SUCCESS,
  ABO_VOUCHER_DETAIL_LOAD_FAIL,
  Actions
} from './voucher.actions';
import { VouchersData } from '../../shared/models/voucher.model';

export function AboVoucherDetailReducer(
  state: VouchersData,
  { type, payload }: Actions
): VouchersData {
  switch (type) {
    case ABO_VOUCHER_DETAIL_LOAD:
      return { loaded: false };
    case ABO_VOUCHER_DETAIL_LOAD_SUCCESS:
      return Object.assign({ loaded: true }, { data: payload });
    case ABO_VOUCHER_DETAIL_LOAD_FAIL:
      return  Object.assign({ loaded: true }, { data: [] });
    default:
      return state;
  }
}
