import {
  ABO_SEARCH,
  ABO_SEARCH_SUCCESS,
  ABO_SEARCH_FAIL,
  ABO_SEARCH_CLEAR,
  Actions
} from './abo-search.actions';

export function aboSearchReducer(state: any, action: Actions): any {
  switch (action.type) {
    case ABO_SEARCH:
      return { isLoading: true };

    case ABO_SEARCH_SUCCESS:
      return Object.assign({ loaded: true }, action.payload);

    case ABO_SEARCH_FAIL:
      return Object.assign({ loaded: false }, action.payload);

    case ABO_SEARCH_CLEAR:
      return null;

    default:
      return state;
  }
}
