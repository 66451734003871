import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { LoginService } from '../../shared/login.service';
import { MasheryToken } from '../../../shared/models';

@Component({
  selector: 'app-mashery-redirect',
  templateUrl: './mashery-redirect.component.html',
  styleUrls: ['./mashery-redirect.component.scss']
})
export class MasheryRedirectComponent implements OnInit {
  constructor(
    private activateRoute: ActivatedRoute,
    private location: Location,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    this.handleRouteParams();
  }

  handleRouteParams() {
    let route = this.activateRoute.snapshot.fragment;
    this.location.go('');
    const token = {};
    if (route) {
      route = route.substring(route.indexOf('#'), route.length);
      const routeParams = route.split('&');
      routeParams.forEach(param => {
        const property: { key: string; value: string } = {
          key: param.split('=')[0],
          value: param.split('=')[1]
        };
        token[property.key] = property.value;
      });
    }
    this.loginService.storeMasheryAuthCode(<MasheryToken>token);
  }
}
