import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { HttpService } from '../../core/services/index';
import {
  salesAff, aboNum, partyId, deletePartyLinkAPI,
  reactivatePartyAPI, deleteCoapplicantAPI, accountsAssociatedWithPartyAPI,
  addPartyLinkAPI, renewalPartyAPI, renewalDetailsAPI
} from './../configs/constants';
import { ApiPathConfigService } from '../configs/api';
import { Observable, of, forkJoin } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { AppDatePipe } from '../pipes/index';

@Injectable()
export class PartyService {
  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private apiPathService: ApiPathConfigService,
    private datePipe: AppDatePipe) { }

  updatePartyLink(req, call) {
    const api = this.apiPathService.getApiPath(addPartyLinkAPI);
    api.path = api.path.replace(salesAff, req.aff).replace(aboNum, req.aboNum)
    const params = this.builPatyLinkParams(req, req.linkPartyId)
    if (call == 'Single') {
      return this.hitsingleRequest(api, params)
    } else if (call == 'Multiple') {
      return this.hitMultipleRequests(api, req)
    }
  }

  deletePartyLink(aff, abo, deletePartyId) {
    const api = this.apiPathService.getApiPath(deletePartyLinkAPI);
    api.path = api.path.replace(salesAff, aff)
      .replace(aboNum, abo)
      .replace(partyId, deletePartyId)

    let param = new HttpParams();
    param = param.append('roleCd', 'Successor')
    param = param.append('reason', 'DeletedByDistReq')

    return this.httpService.callServerDELETE(api, param)
      .pipe(map(response => {
        return of(response)
      }), catchError((error) => {
        return of(error);
      })
        , retry(1));
  }

  hitsingleRequest(api, params) {
    return this.httpService.callServerPUT(api, params)
      .pipe(map(response => {
        return of(response)
      }), catchError((error) => {
        return of(error);
      })
        , retry(1));
  }

  hitMultipleRequests(api, req) {
    const party1 = req.linkPartyId[0]
    const party2 = req.linkPartyId[1]
    const params1 = this.builPatyLinkParams(req, party1)
    const params2 = this.builPatyLinkParams(req, party2)

    let successorResponse = new Observable(observer => {
      forkJoin(
        this.httpService.callServerPUT(api, params1).pipe(map((res) => res), catchError(e => of(e))),
        this.httpService.callServerPUT(api, params2).pipe(map((res) => res), catchError(e => of(e)))
      ).subscribe((response) => {
        observer.next(response);
      }, (error) => {
        observer.error(error);
      });
    })
    return successorResponse
  }

  builPatyLinkParams(req, linkPartyId) {
    let param = new HttpParams();
    param = param.append('linkPartyAboNum', req.linkPartyAboNum);
    param = param.append('linkPartyId', linkPartyId);
    param = param.append('roleCd', 'Successor')
    param = param.append('reasCd', req.reasCd)
    return param
  }

  getAccountsAssociatedWithParty({ aff, abo, sPartyId }) {
    const api = this.apiPathService.getApiPath(accountsAssociatedWithPartyAPI);
    api.path = api.path.replace(partyId, sPartyId).replace(aboNum, abo).replace(salesAff, aff)
    return this.httpService.callServerGET(api, null, null)
      .pipe(map(response => {
        return response
      }), catchError((error) => {
        return error;
      })
        , retry(1));
  }

  deleteAParty(aff, abo, deletePartyId) {
    const api = this.apiPathService.getApiPath(deleteCoapplicantAPI);
    api.path = api.path.replace(partyId, deletePartyId)
      .replace(salesAff, aff)
      .replace(aboNum, abo)

    return this.httpService.callServerDELETE(api, null, null)
      .pipe(map(response => {
        return of(response)
      }), catchError((error) => {
        return Observable.throw(error);
      }));
  }

  reactivateAParty(aff, abo) {
    let param = new HttpParams();
    param = param.append('reasonCd', 'NormalReactivation')
    param = param.append('withGroupFlg', 'true')
    const api = this.apiPathService.getApiPath(reactivatePartyAPI);
    api.path = api.path.replace(salesAff, aff).replace(aboNum, abo);
    return this.httpService.callServerPOST(api, param, null)
      .pipe(map(response => {
        return of(response)
      }), catchError((error) => {
        return Observable.throw(error);
      }));
  }

  renewalParty(aff, abo, aboPartyId) {
    let param = new HttpParams();
    const api = this.apiPathService.getApiPath(renewalPartyAPI);
    api.path = api.path.replace(salesAff, aff).replace(aboNum, abo).replace(partyId, aboPartyId);
    const bodyParams = this.getRenewalPartyBodyParams(abo);
    return this.httpService.callServerPOST(api, param, bodyParams)
      .pipe(map(response => {
        return of(response)
      }), catchError((error) => {
        return Observable.throw(error);
      }));
  }

  renewalDetails(aff, abo) {
    let param = new HttpParams();
    const api = this.apiPathService.getApiPath(renewalDetailsAPI);
    api.path = api.path.replace(salesAff, aff).replace(aboNum, abo);
    return this.httpService.callServerGET(api)
      .pipe(map(response => {
        return response.renewalDetails;
      }), catchError((error) => {
        return Observable.throw(error);
      }));
  }

  reversalParty(aff, abo, aboPartyId) {
    let api = this.apiPathService.getApiPath(renewalPartyAPI);
    let param = new HttpParams();
    let bodyParams = this.getReversalPartyBodyParams(abo);
    api.path = api.path.replace(salesAff, aff).replace(aboNum, abo).replace(partyId, aboPartyId);

    return this.httpService.callServerPOST(api, param, bodyParams)
      .pipe(map(response => {
        return of(response)
      }), catchError((error) => {
        return Observable.throw(error);
      }));
  }
  

  getRenewalPartyBodyParams(accountNumber) {
    const renewalPartyPayload = {
      actionType: "OrderCreated",
      orderType: "Renewal",
      invoiceNumber: accountNumber + this.datePipe.transform(new Date(), "yyyyMMdd"),
      processDate: moment().format()
    }
    return renewalPartyPayload;
  }

  getReversalPartyBodyParams(accountNumber) {
    const renewalPartyPayload = {
      actionType: "OrderCancelled",
      orderType: "Renewal",
      invoiceNumber: accountNumber + this.datePipe.transform(new Date(), "yyyyMMdd"), //need to change
      processDate: moment().format()
    }
    return renewalPartyPayload;
  }

}
