import { Effect, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import { switchMap, catchError, map, mergeMap } from 'rxjs/operators';
import {
  ABO_RESTRICTION_BLOCKS_LOAD,
  ABO_RESTRICTION_BLOCKS_LOAD_ACTIVE,
  ABO_RESTRICTION_BLOCKS_SAVE,
  ABO_RESTRICTION_BLOCKS_DEACTIVATE_ALL,
  AboRestrictionBlocksLoad,
  AboRestrictionBlocksLoadSuccess,
  AboRestrictionBlocksLoadFail,
  AboRestrictionBlocksLoadActive,
  AboRestrictionBlocksLoadActiveSuccess,
  AboRestrictionBlocksLoadActiveFail,
  AboRestrictionBlocksSave,
  AboRestrictionBlocksSaveSuccess,
  AboRestrictionBlocksSaveFail,
  AboRestrictionBlocksDeactivateAllSuccess,
  AboRestrictionBlocksDeactivateAllFail
} from './abo-restriction-blocks.actions';
import { AboRestrictionBlockService } from '../../shared/services/abo-restriction-blocks.service';

@Injectable()
export class AboRestrictionBlocksEffects {
  @Effect()
  loadaboRestrictionBlocks$ = this.actions$
    .ofType(ABO_RESTRICTION_BLOCKS_LOAD).pipe(
    switchMap(({ payload }: AboRestrictionBlocksLoad) => {
      return this.restrictionBlockService
        .getAllRestrictionBlocks(payload.params)
        .pipe(map(response => {
          return new AboRestrictionBlocksLoadSuccess({
            params: payload.params,
            data: response
          });
        })
        ,catchError(error => of(new AboRestrictionBlocksLoadFail())));
    }));

  @Effect()
  loadAboRestrictionBlocksActive$ = this.actions$
    .ofType(ABO_RESTRICTION_BLOCKS_LOAD_ACTIVE).pipe(
    switchMap(({ payload }: AboRestrictionBlocksLoadActive) => {
      return this.restrictionBlockService
        .getActiveRestrictionBlocks(payload.params)
        .pipe(map(response => {
          return new AboRestrictionBlocksLoadActiveSuccess({
            params: payload.params,
            data: response
          });
        })
        ,catchError(error =>
          of(
            new AboRestrictionBlocksLoadActiveFail({ params: payload.params })
          )
        ));
    }));

  @Effect()
  updateAboRestrictionBlock$ = this.actions$
    .ofType(ABO_RESTRICTION_BLOCKS_SAVE).pipe(
    switchMap(({ payload }: AboRestrictionBlocksSave) => {

      return this.restrictionBlockService
        .UpdateRestrictionBlock(payload.params, null, payload.dataToSave)
        .pipe(catchError(error => of(new AboRestrictionBlocksSaveFail()))
        ,switchMap(_ =>
          this.restrictionBlockService.getActiveRestrictionBlocks(payload.params)
        )
        ,catchError(error => of(new AboRestrictionBlocksLoadActiveFail()))
        ,map(
          response =>
            new AboRestrictionBlocksSaveSuccess({
              params: payload.params,
              data: response
            })
        ));
    }));

  @Effect()
  deactivateAllAboRestrictionBlock$ = this.actions$
    .ofType(ABO_RESTRICTION_BLOCKS_DEACTIVATE_ALL).pipe(
    switchMap(({ payload }: AboRestrictionBlocksSave) => {
      const blockRolesMap = payload.restrictionBlocksRolesMap;
      return this.restrictionBlockService
        .getActiveRestrictionBlocks(payload.params)
        .pipe(catchError(error =>
          of(
            new AboRestrictionBlocksLoadActiveFail({ params: payload.params })
          )
        )
        ,map(activeBlocks => {
          return activeBlocks
            .filter(
              block =>
                blockRolesMap[block.blockPrivTypeId] &&
                blockRolesMap[block.blockPrivTypeId].edit
            )
            .map(block => {
              block.expirationDate = payload.dataToSave;
              return this.restrictionBlockService
                .UpdateRestrictionBlock(payload.params, null, block)
                .pipe(catchError(error =>
                  of(new AboRestrictionBlocksSaveFail())
                ));
            });
        })
        ,mergeMap(apiCalls$ => forkJoin(...apiCalls$))
        ,mergeMap(_ =>
          this.restrictionBlockService.getActiveRestrictionBlocks(
            payload.params
          )
        )
        ,map(
          response =>
            new AboRestrictionBlocksDeactivateAllSuccess({
              params: payload.params,
              data: response
            })
        )
        ,catchError(error =>
          of(
            new AboRestrictionBlocksDeactivateAllFail({
              params: payload.params
            })
          )
        ));
    }));

  constructor(
    private actions$: Actions,
    private restrictionBlockService: AboRestrictionBlockService
  ) {}
}
