import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { AccountCardComponent } from './account-card/account-card.component';
import { ABORoutingModule } from './abo-routing.module';
import { ABOComponent } from './abo.component';
import { AboBusinessDetailsComponent } from './abo-business-details/abo-business-details.component';
import { AboBasicInfoComponent } from './abo-personal-details/abo-basic-info/abo-basic-info.component';
import { AboInheritanceComponent } from './abo-inheritance/abo-inheritance.component';
import { AboInheritanceFormComponent } from './abo-inheritance/abo-inheritance-form/abo-inheritance-form.component';
import { AboPersonalDetailsComponent } from './abo-personal-details/abo-personal-details.component';
import { AboAddressComponent } from './abo-personal-details/abo-address/abo-address.component';
import { AboBasicInformationComponent } from './abo-personal-details/abo-basic-info/abo-basic-info-form/abo-basic-info-form.component';
import { AboDeletionBoxComponent } from './abo-personal-details/abo-basic-info/abo-basic-info-form/abo-deletion-box/abo-deletion-box.component';
import { AboBankInformationComponent } from './abo-personal-details/abo-bank-info/abo-bank-info-form/abo-bank-info-form.component';
import { AboTaxInformationComponent  } from './abo-personal-details/abo-tax-info/abo-tax-info-form/abo-tax-info-form.component';
import { AboAddressFormComponent } from './abo-personal-details/abo-address/abo-address-form/abo-address-form.component';
import { FormsModule } from '@angular/forms';
import { AboDetailsValidationService } from './abo-details-validation.service';
import { AboRestrictionBlocksComponent } from './abo-restriction-blocks/abo-restriction-blocks.component';
// tslint:disable-next-line:max-line-length
import { AboRestrictionBlocksFormComponent } from './abo-restriction-blocks/abo-restriction-blocks-form/abo-restriction-blocks-form.component';
import { DeactivationReasonComponent } from './abo-actions/deactivation-modal/reason/deactivation-reason.component';
import { DeactivationConfirmComponent } from './abo-actions/deactivation-modal/confirm/deactivation-confirm.component';
import { DeactivateFieldComponent } from './abo-actions/fields/deactivate/deactivate-field.component';
import { DeactivationModalComponent } from './abo-actions/deactivation-modal/deactivation-modal.component';
import { AboActionsComponent } from './abo-actions/abo-actions.component';
import { RenewalFieldComponent } from './abo-actions/fields/renewal/renewal-field.component';

import { AboTermsAndConditionsComponent } from './abo-terms-and-conditions/abo-tc.component';
import { AboVouchersComponent } from './abo-vouchers/abo-vouchers.component';
import { AboTaxInfoComponent } from './abo-personal-details/abo-tax-info/abo-tax-info.component';
import { ABOBankInfoComponent } from './abo-personal-details/abo-bank-info/abo-bank-info.component';
import { AboAccountBalanceComponent } from './abo-account-balance/abo-account-balance.component';
import { AboAccountSubscriptionComponent } from './abo-subscriptions/abo-subscriptions.component';

import { EmployeeFormComponent } from './account-card/employee-form/employee-form.component';
import { ReactivateFieldComponent } from './abo-actions/fields/reactivate/reactivate-field.component';
import { AccountBalanceListComponent } from './abo-account-balance/account-balance-list/account-balance-list.component';
import { AboConnectIdComponent } from './abo-connect-id/abo-connect-id.component';
import { AboConnectFormComponent } from './abo-connect-id/abo-connect-form/abo-connect-form.component';
import { AboConnectIdConfirmationComponent } from './abo-connect-id/abo-connect-id-confirmation/abo-connect-id-confirmation.component';
@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, SharedModule, ABORoutingModule],
  declarations: [
    ABOComponent,
    AccountCardComponent,
    AboBusinessDetailsComponent,
    AboPersonalDetailsComponent,
    AboBasicInfoComponent,
    AboInheritanceComponent,
    AboInheritanceFormComponent,
    AboAddressComponent,
    AboBasicInformationComponent,
    AboDeletionBoxComponent,
    AboBankInformationComponent,
    AboTaxInformationComponent,
    AboAddressFormComponent,
    AboRestrictionBlocksComponent,
    AboRestrictionBlocksFormComponent,
    AboActionsComponent,
    DeactivationModalComponent,
    DeactivateFieldComponent,
    RenewalFieldComponent,
    DeactivationReasonComponent,
    DeactivationConfirmComponent,
    AboTermsAndConditionsComponent,
    AboVouchersComponent,
    AboTaxInfoComponent,
    ABOBankInfoComponent,
    AboAccountBalanceComponent,
    AboAccountSubscriptionComponent,
    EmployeeFormComponent,
    ReactivateFieldComponent,
    AccountBalanceListComponent,
    AboConnectIdComponent,
    AboConnectFormComponent,
    AboConnectIdConfirmationComponent
  ],
  entryComponents: [
    DeactivateFieldComponent,
    RenewalFieldComponent,
    ReactivateFieldComponent
  ],
  bootstrap: [],
  providers: [AboDetailsValidationService]
})
export class ABOModule { }
