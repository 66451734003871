import { Injectable } from '@angular/core';
import { HttpService } from '../../core/services';
import { ApiPathConfigService } from '../configs/api';
import { partyId, salesAff, aboNum, bankAcctId, getBanksandBranchesAPI, updateBankAccountAPI, createBankAccountAPI } from '../configs/constants';
import { Bank } from '../models/bank-list.model';
import { Observable, of } from '../../../../node_modules/rxjs';
import { map } from 'rxjs/operators';
import { AboBankDetailChangePayload } from '../models/abo-personal-details.model';
import { isBankDetailChanged } from '../helpers/bank.helper';
import { UpdateBankAccount, CreateBankAccount } from '../models/bank-detail.model';

@Injectable()
export class AboBankService {
  constructor(private httpService: HttpService, private apiPathService: ApiPathConfigService,) {}

  getBanksList(request: any) {
    const api = this.apiPathService.getApiPath(getBanksandBranchesAPI);
    api.path = api.path.replace(salesAff, request.aff);
    return this.httpService.callServerGET(api, null, null).pipe(map(response => {
      if (response && response.bankList) {
        return response.bankList;
      } else {
        return [] as Bank[];
      }
    }));
  }
  updateBankAccount(request, bankAccountId, payload) {
    const api = this.apiPathService.getApiPath(updateBankAccountAPI);
    api.path = api.path
      .replace(aboNum, request.abo)
      .replace(salesAff, request.aff)
      .replace(bankAcctId, bankAccountId);
    return this.httpService.callServerPUT(api, null, payload);
  }

  createNewBankAccount(request, payload) {
    const api = this.apiPathService.getApiPath(createBankAccountAPI);
    api.path = api.path
      .replace(aboNum, request.abo)
      .replace(salesAff, request.aff);

    return this.httpService.callServerPOST(api, null, payload);
  }

UpdateBankDetails (request: AboBankDetailChangePayload) {
   if (isBankDetailChanged(request.currentDetails, request.initialDetails)) {
     const bankDetails = request.currentDetails;
    if (request.currentDetails.bankAcctId) {
      const payload = {bank: {...new UpdateBankAccount(bankDetails)}};
      return this.updateBankAccount(request.routeParams, request.currentDetails.bankAcctId, payload);
    }else {
      const payload = {bank: {...new CreateBankAccount(bankDetails)}};
      return this.createNewBankAccount(request.routeParams, payload);
    }
   }
    return of({});
  }
}
