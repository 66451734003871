import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, of } from 'rxjs';
import { filter, delay } from 'rxjs/operators';
import { ErrorLogService } from '../../../core/services/index';
import { TranslateService } from '@ngx-translate/core';

const ERROR_MESSAGE_TTL = 30000;

@Component({
  selector: 'app-error-growl',
  templateUrl: './error-growl.component.html',
  styleUrls: ['./error-growl.component.scss']
})
export class ErrorGrowlComponent implements OnInit, OnDestroy {
  errors$: Observable<string[]>;
  sub: Subscription;

  constructor(
    private errorHandler: ErrorLogService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.errors$ = this.errorHandler.errors$;
    this.sub = this.errors$
      .pipe(filter(errors => !!errors.length)
      ,delay(ERROR_MESSAGE_TTL))
      .subscribe(errors => {
        this.dismiss(errors[0]);
      });
  }

  dismiss(error): void {
    this.errorHandler.remove(error);
  }
  getTranslatedString(value) {
    if (value) {
      return this.translateService.stream(value);
    } else {
      of('');
    }
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
