import { Effect, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { VoucherService } from '../../shared/services/voucher.service';
import {
  ABO_BANK_LIST_LOAD,
  AboBankListLoad,
  AboBankListLoadFail,
  AboBankListLoadSuccess
} from './bank.actions';
import { AboBankService } from '../../shared/services/abo-bank.service';
import { ErrorService } from '../../shared/services';
import { ErrorLogService } from '../../core/services';
@Injectable()
export class AboBankEffects {
  @Effect()
  loadBankList$ = this.actions$
    .ofType(ABO_BANK_LIST_LOAD).pipe(
    switchMap(({ payload }: AboBankListLoad) => {
      return this.bankService
        .getBanksList(payload)
        .pipe(catchError(error => {
          this.LogError(error);
          return of(new AboBankListLoadFail());
        })
        ,map(response => {
          return new AboBankListLoadSuccess(response);
        }));
    }));
  constructor(
    private actions$: Actions,
    private bankService: AboBankService,
    private errorService: ErrorService,
    private errorLogService: ErrorLogService
  ) {}
  private LogError(error) {
    const errorMessage = this.errorService.processError(error);
    this.errorLogService.log(errorMessage);
  }
}
