import { Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, Effect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IAppState } from '../index';
import { AccountService } from '../../shared/services/index';
import { ACCOUNT_GET, AccountGet, AccountGetSuccess, AccountGetFail } from './account.actions';

@Injectable()
export class AccountEffects {

  constructor(
    private action$: Actions,
    private accountService: AccountService
  ) {
  }

  @Effect()
  accountGet$ = this.action$
    .ofType((ACCOUNT_GET)).pipe(
    switchMap((action: AccountGet) => {
      return this.accountService.getAccountDetail(action.payload)
        .pipe(map((response) => new AccountGetSuccess(response))
        ,catchError(error => of(new AccountGetFail())));
    }));
}
