import {
  clientId,
  redirectUrl,
  salesAff,
  userName,
  aboNum,
  partyId,
  blockPrivTypeId,
  ecommContactId,
  contactId,
  taxType,
  voucherId,
  bankAcctId,
  personalIdTypeCd,
  contractId,
  cntryCd,
  addressBookId,
  enrollmentId
} from './constants';
import { Injectable } from '@angular/core';
import { ApiConfig } from '../models/api-url-config.model';
import { ServerName } from '../models/enums';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiPathConfigService {
  occEnable: boolean = environment.occEnable;
  private _apiPaths: { [key: string]: ApiConfig };
  private occV2URL = environment.occV2URL;
  private masheryV1URL = '/rest/oauth2/v1';
  private masheryRestV3URL = '/rest/mdms/v3';
  private magicV2URL = '/magic/v2';
  private oktaJwtV1URL = environment.oktaJwtV1URL;
  private masheryRestV1URL = '/rest/v1';
  constructor() {
    this._apiPaths = {
      getAppSettings: {
        path: `assets/api/settings.json`,
        server: ServerName.Local,
        requireAuthHeader: false
      },
      getOccTokenAPI: {
        path: this.occV2URL + `/authorize/accesstoken`,
        server: ServerName.Hybris,
        requireAuthHeader: false
      },
      areaLocatorAPI: {
        path: this.occV2URL + `/address-suggest/areaLocator`,
        server: ServerName.Hybris,
        requireAuthHeader: false
      },
      provinceAPI: {
        path: this.occV2URL + `/address-suggest/province`,
        server: ServerName.Hybris,
        requireAuthHeader: false
      },
      provinceStateCdAPI: {
        path: this.occV2URL + `/address-suggest/statecode`,
        server: ServerName.Hybris,
        requireAuthHeader: false
      },
      districtAPI: {
        path: this.occV2URL + `/address-suggest/district`,
        server: ServerName.Hybris,
        requireAuthHeader: false
      },
      subdistrictAPI: {
        path: this.occV2URL + `/address-suggest/subdistrict`,
        server: ServerName.Hybris,
        requireAuthHeader: false
      },
      postalCodeAPI: {
        path: this.occV2URL + `/address-suggest/postalcode`,
        server: ServerName.Hybris,
        requireAuthHeader: false
      },
      wareHoueseListAPI: {
        path: this.occV2URL + `/address-suggest/pickUpStoreList`,
        server: ServerName.Hybris,
        requireAuthHeader: false
      },
      errorLogServiceAPI: {
        path: this.occV2URL + `/log`,
        server: ServerName.Hybris,
        requireAuthHeader: true
      },
      getMasheryAuthCodeAPI: {
        path:
          this.masheryV1URL +
          `/authorize?client_id=${clientId}&response_type=authorization_code&redirect_uri=${redirectUrl}`,
        server: ServerName.Mashery,
        requireAuthHeader: false
      },
      getMasheryTokenAPI: {
        path: this.masheryV1URL + '/token',
        server: ServerName.Mashery,
        requireAuthHeader: false
      },
      getOktaJwtAuthCodeAPI: {
        path:
        this.oktaJwtV1URL + '/authorize?response_mode=fragment&response_type=id_token&scope=openid+profile&state={stateRandom}&nonce={nonceRandom}&client_id={oktaJwtClientId}&redirect_uri={oktaJwtRedirectUrl}',
        server: ServerName.Okta,
        requireAuthHeader: false
      },
      getPeriodInfoAPI: {
        path: this.magicV2URL + `/Period/getCurrent`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getVolumeInfoAPI: {
        path: this.magicV2URL + `/Volume/getDetails`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      uplineInfoAPI: {
        path: this.magicV2URL + `/Upline/getAllUplines`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getPlatinumSponsorAPI: {
        path: this.magicV2URL + `/Upline/getPlatinum`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getAwardInfoAPI: {
        path: this.magicV2URL + `/AwardLevel/HighestCurrentAwardsInfo`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getAffiliatesListAPI: {
        path:
          this.masheryRestV3URL + `/config/affiliates/${salesAff}/affiliates`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getABOSearchListAPI: {
        path: this.masheryRestV3URL + `/search/accounts/${salesAff}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getAmwayAccountByAboNumAPI: {
        path: this.masheryRestV3URL + `/accounts/${salesAff}-${aboNum}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getCountryInfoAPI: {
        path:
          this.masheryRestV3URL + `/config/affiliates/${salesAff}/countries`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getLanguageInfoAPI: {
        path:
          this.masheryRestV3URL + `/config/affiliates/${salesAff}/languages`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getRegionsListAPI: {
        path: this.masheryRestV3URL + `/config/affiliates/${salesAff}/regions`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getABOImageAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/images`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      retrieveImageByUrlAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/images/retrieve-by-url`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getABOTaxAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/taxes`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      taxPayerInformationAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/taxPayer`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      accountContractsAPI: {
        path:
          this.masheryRestV3URL + `/accounts/${salesAff}-${aboNum}/contracts`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      accountContractsUpdateAPI: {
        path:
          this.masheryRestV3URL + `/accounts/${salesAff}-${aboNum}/contracts/${contractId}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      accountBalanceAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/account-balances`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      restrictionBlocksAPI: {
        path:
          this.masheryRestV3URL +
          `/config/affiliates/${salesAff}/blocks-privileges`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      updateRestrictionBlockAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/block-privileges/${blockPrivTypeId}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      updateAllRestrictionBlocksAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/block-privileges`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      createAccountEmailAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/ecomms`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      createAccountTaxAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/taxes`,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        logRequest: (this.occEnable ? true : false)
      },
      createAccountPhoneAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/phones`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      createAccountAddressAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/addresses`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      updateAccountNameAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/names/Legal`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      updateAccountNameMultiLangAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/names/multi-language/Legal`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      updateAccountPartiesAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      deleteCoapplicantAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      registerPartialUserAPI: {
        path:
          this.masheryRestV3URL +
          `/full-accounts/${salesAff}-${aboNum}/parties`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      updateDocumentAPI: {
        path:
          this.masheryRestV3URL +
          `/full-accounts/${salesAff}-${aboNum}/parties`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      accountsAssociatedWithPartyAPI: {
        path:
          this.masheryRestV3URL +
          `/link-accounts/${salesAff}-${aboNum}/parties/${partyId}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      updateAccountEmailAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/ecomms/${ecommContactId}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      updateAccountTaxAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/taxes/${taxType}`,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        logRequest: true
      },
      updateAccountPhoneAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/phones/${contactId}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      updateAccountAddressAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/addresses/${contactId}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      deactivateAccountAPI: {
        path: this.masheryRestV3URL + `/accounts/${salesAff}-${aboNum}/resign`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getSalutaionsAPI: {
        path: this.masheryRestV3URL + `/config/countries/${cntryCd}/salutations`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      registerFullAccountAPI: {
        path: this.masheryRestV3URL + `/register/full-accounts/${salesAff}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      updateARBalanceAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/account-balances`,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        logRequest: true
      },
      listAccountBalanceAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/account-balances-history`,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        logRequest: true
      },
      deletePartyLinkAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/party-link/deletePartyLink`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      addPartyLinkAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/party-link/addPartyLink`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      accountVouchersAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/vouchers`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      voucherTransactionAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/vouchers/${voucherId}/transaction-list`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      updateVoucherAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/vouchers/${voucherId}`,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        logRequest: true
      },
      getABOContractsAPI: {
        path:
          this.masheryRestV3URL + `/accounts/${salesAff}-${aboNum}/contracts`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getContractInfoAPI: {
        path: this.masheryRestV3URL + `/config/affiliates/${salesAff}/contract`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getTaxCertificateAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/tax-certificates/retrieve-by-url`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      updateSubscriptionAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/subscriptions/subscription-details`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getBanksandBranchesAPI: {
        path:
          this.masheryRestV3URL +
          `/config/affiliates/${salesAff}/banks-branches`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      createBankAccountAPI: {
        path: this.masheryRestV3URL + `/accounts/${salesAff}-${aboNum}/banks`,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        logRequest: true
      },
      updateBankAccountAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/banks/${bankAcctId}`,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        logRequest: true
      },
      updateProfileImageAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/images`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      uploadTaxCertificateAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/tax-certificates`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getStatesListAPI: {
        path: this.masheryRestV3URL + `/config/affiliates/${salesAff}/states`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getCountriesListAPI: {
        path:
          this.masheryRestV3URL +
          `/config/affiliates/00/countries?amwayAffiliatedOnly=false`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      createPersonalIdAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/personal-identifications`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      updatePersonalIdAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/personal-identifications/${personalIdTypeCd}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      reactivatePartyAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/reactivation`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      createShippingAddressAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/addressbook`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      updateShippingAddressAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/addressbook/${addressBookId}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getShippingAddressAPI: {
        path:
          this.masheryRestV3URL +
          `/accounts/${salesAff}-${aboNum}/parties/${partyId}/addressbook`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      validatePersonalIdAPI: {
        path: this.masheryRestV3URL + `/validate/personal-id/${salesAff}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      validatePhoneNumberAPI: {
        path: this.masheryRestV3URL + `/validate/phone/${salesAff}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      validateEmailAddressAPI: {
        path: this.masheryRestV3URL + `/validate/ecomm/${salesAff}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      validateTaxIdAPI: {
        path: this.masheryRestV3URL + `/validate/tax/${salesAff}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      validateBankDetailsAPI: {
        path: this.masheryRestV3URL + `/validate/bank-account/${salesAff}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      validatePartyAPI: {
        path: this.masheryRestV3URL + `/validate/party/${salesAff}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      validateBasicInfoAPI: {
        path: this.masheryRestV3URL + `/validate/namephones/${salesAff}`,
        server: ServerName.Mashery,
        requireAuthHeader: true
      },
      getAmwayIdAPI: {
        path: this.masheryRestV1URL + `/users/${userName}`,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        reqAcceptType: true,
        reqCacheControlType: true,
        reqXAmzDate: true,
        reqApikey: true
      },
      checkAmwayIdByPartyIdAPI: {
        path: this.masheryRestV1URL + `/users`,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        reqAcceptType: true,
        reqCacheControlType: true,
        reqXAmzDate: true,
        reqApikey: true
      },
      createAmwayIdAPI: {
        path: this.masheryRestV1URL + `/enrollments`,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        reqAcceptType: true,
        reqCacheControlType: true,
        reqApikey: true
      },
      getEnrollRecordByPartyId : {
        path: this.masheryRestV1URL + `/enrollments`,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        reqAcceptType: true,
        reqCacheControlType: true,
        reqApikey: true
      },
      editAmwayIdAPI: {
        path: this.masheryRestV1URL + `/users/${userName}/username/reset `,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        reqAcceptType: true,
        reqCacheControlType: true,
        reqXAmzDate: true,
        reqApikey: true
      },
      updateEnrolledAmwayIdAPI: {
        path: this.masheryRestV1URL + `/enrollments/${enrollmentId}`,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        reqAcceptType: true,
        reqCacheControlType: true,
        reqApikey: true
      },
      emailsActivationAPI: {
        path: this.masheryRestV1URL + `/emails/activation`,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        requireAcceptLanguage: true,
        reqAcceptType: true,
        reqApikey: true
      },
      enrollmentSendEmail: {
        path: this.masheryRestV1URL + `/enrollments/${enrollmentId}/sendEmail`,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        requireAcceptLanguage: true,
        reqAcceptType: true,
        reqApikey: true
      },
      aboEventsAPI: {
        path: `/amwayid/events`,
        server: ServerName.server360,
        requireAuthHeader: false,
        reqApikey360: true
      },
      accountByPartyIdAPI: {
        path: this.masheryRestV3URL + `/parties/${partyId}/accounts`,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        reqAcceptType: true
      },
      renewalPartyAPI: {
        path: this.masheryRestV3URL + `/accounts/${salesAff}-${aboNum}/parties/${partyId}/orders`,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        reqAcceptType: true
      },
      renewalDetailsAPI: {
        path: this.masheryRestV3URL + `/accounts/${salesAff}-${aboNum}/renewal-details`,
        server: ServerName.Mashery,
        requireAuthHeader: true,
        reqAcceptType: true
      }
    };
  }

  getApiPath(key: string): ApiConfig {
    return Object.assign({}, this._apiPaths[key]);
  }
}
