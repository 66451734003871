import { Observable, of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IAppState } from '../../../../store/index';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../../../../core/services/index';
import {
  ABOBasicInfo,
  ABOBasicInfoUpdatePayload,
  ValidationError,
  Account,
  ConfigState
} from '../../../../shared/models/amway/index';
import { ConfigGet } from '../../../../store/config/config.actions';
import { ConfigResponse } from '../../../../shared/models/amway/config.model';
import { DropdownOption } from '../../../../shared/models/index';
import { TAX_ID_TYPES, FRN_TAX_TYPE, TAX_MASKS } from '../../../abo-settings';
import { Modal } from 'ngx-modal';

import * as _ from 'lodash';
import { AboDetailsValidationService } from '../../../abo-details-validation.service';
import {
  DEFAULT_COUNTRY_OPTION, DEFAULT_MOBILE_OPTION,
  DEFAULT_NONPRIMARY_CITIZENSHIP_DATA,
  DEFAULT_PRIMARY_CITIZENSHIP_DATA,
  defaultCountryCode,
  LglEntityTypeCompany,
  PartyTypeCd,
  TAX_TYPE_CODE
} from '../../../../shared/configs/constants';
import { RegistrationLoad } from '../../../../store/registrations/registrations.actions';
import { RegistrationsService } from '../../../../shared/services/registrations.service';
import { getTaxPayerParty, taxAdressPayload } from '../../../../shared/helpers/tax.helper';
import { getFullName, getFullNameLatinName } from '../../../../shared/helpers/common.helper';


import { PartyList } from '../../../../shared/models/party-basic.models';
import * as moment from 'moment';
import { PartyService, CountryService } from '../../../../shared/services';
import { AboDetailsLoad } from '../../../../store/abo-personal-details/abo-personal-details.actions';
import { TaxPayerLoad } from '../../../../store/tax-payer/tax-payer.actions';
import { CountryEnvironmentService } from '../../../../shared/services/country-environment.service';

@Component({
  selector: 'app-abo-basic-info-form',
  templateUrl: './abo-basic-info-form.component.html',
  styleUrls: ['./abo-basic-info-form.component.scss']
})
export class AboBasicInformationComponent implements OnInit {

  @Input() aboBasicInfo: ABOBasicInfo;
  @Input() accountFullDetail: Account;

  @Output() updateAboBasicInfo = new EventEmitter<ABOBasicInfoUpdatePayload>();
  @Output() cancel = new EventEmitter<any>();
  @ViewChild('markAsCoapplicantpopup') markAsCoapplicantpopup: Modal;
  @ViewChild('maritalStatusChange') maritalStatusChange: Modal;

  config$: Observable<ConfigResponse>;
  languages$: Observable<DropdownOption[]>;
  countryCodes$: Observable<DropdownOption[]>;
  saving$: Observable<boolean>;
  originalTaxTypeCd: string;
  editableInfo: ABOBasicInfo;
  taxMasks = TAX_MASKS;
  taxIdTypes = TAX_ID_TYPES;
  legalName: string;
  accountData$: any;
  isPartyPrimary: boolean;
  citizenShipData;
  showPassportInformation: boolean;
  citizenPrimaryreadonly: boolean;
  citizenPrimaryCurrentOption: DropdownOption;
  errors: ValidationError = {};
  marriedSelected: boolean;
  currentParty: PartyList;
  markCidDisabled: boolean;
  markPassportDisabled: boolean;
  coapplicantParty: PartyList;
  loader: boolean;
  actualRecordInfo: ABOBasicInfo;
  salutationList;
  languageKnownList = [
    { id: 'Korean', itemName: 'Korean' },
    { id: 'Cantonese', itemName: 'Cantonese' },
    { id: 'Thai', itemName: 'Thai' },
    { id: 'Hindi', itemName: 'Hindi' },
    { id: 'Japanese', itemName: 'Japanese' },
    { id: 'Vietnamese', itemName: 'Vietnamese' },
    { id: 'Tamil', itemName: 'Tamil' },
    { id: 'Malay', itemName: 'Malay' },
    { id: 'Bahasa', itemName: 'Bahasa' },
    { id: 'Arabic', itemName: 'Arabic' }
  ];
  gender = [
    { id: 'Male', text: 'Male' },
    { id: 'Female', text: 'Female' },
    { id: 'notSay', text: 'Prefer not to say' }
  ];
  dropdownSettings = {
    singleSelection: false,
    text: "Select Languages",
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    enableSearchFilter: false,
    classes: "myclass custom-class"
  };
  countryLoader = false;
  readOnlyCoapplicantFields = false;
  showiCrdNum = true;
  constructor(
    private store: Store<IAppState>,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private validationService: AboDetailsValidationService,
    private registrationsService: RegistrationsService,
    private cdr: ChangeDetectorRef,
    private partyService: PartyService,
    private countryService: CountryService,
    private countryEnvironmentService: CountryEnvironmentService
  ) {
  }

  ngOnInit() {
    const { aff } = this.activatedRoute.snapshot.params;
    this.editableInfo = _.cloneDeep(this.aboBasicInfo);
    this.actualRecordInfo = _.cloneDeep(this.aboBasicInfo);

    this.editableInfo['passportNumber'] = this.editableInfo.identityDetail.personalId

    if (this.editableInfo['passportNumber'] && !this.editableInfo['iCrdNum']) {
      this.showiCrdNum = false
    }

    this.loader = true
    this.registrationsService.getSalutaions('AU').subscribe(res => {
      this.loader = false
      this.salutationList = _.map(res.salutationList, function (o) {
        return { id: o.localLangSalutation, text: o.localLangSalutation }
      })
      this.cdr.detectChanges()
    })

    this.legalName = `${this.editableInfo.givenName || ''} ${this.editableInfo.familyName || ''}`;
    this.store.dispatch(new ConfigGet({ aff }));

    this.config$ = this.store.select('config')
      .pipe(filter(data => !!data)
      ,map((data: ConfigState) => data[aff]));

    this.isPartyPrimary = this.accountFullDetail.currentParty.isPrimary;

    if (!this.editableInfo.iCrdNum) {
      if (this.editableInfo.czshpCntryCd != DEFAULT_PRIMARY_CITIZENSHIP_DATA.id || this.editableInfo['passportno']) {
        this.showPassportInformation = true;
      }
      this.countryLoader = true
      this.countryService.getCountryInfo(0).subscribe(res => {
        this.citizenShipData = _.map(res, function (o: any) {
          return { id: o.code, text: o.name }
        })
        this.countryLoader = false
        this.cdr.detectChanges()
      })

      if (this.editableInfo.iCrdNum) { this.readOnlyCoapplicantFields = true }
    }

    this.currentParty = this.accountFullDetail.currentParty

    if (this.isPartyPrimary) {
      this.showCoapplicantInformation();
      this.marriedSelected = this.currentParty.partyMst.maritalStatusCd == 'Married'
    }

    this.countryCodes$ = of([{
      id: `${DEFAULT_MOBILE_OPTION.text},${DEFAULT_MOBILE_OPTION.id}`,
      text: `+${DEFAULT_MOBILE_OPTION.text} (${DEFAULT_MOBILE_OPTION.id})`
    }
    ] as DropdownOption[]);


    this.saving$ = this.store
      .select('aboPersonalDetails')
      .pipe(filter(data => data['info'])
      ,map(data => data['info'].loader));
  }

  showCoapplicantInformation() {
    let partyList = []
    if (this.accountFullDetail.accountMst.lglEntityType == LglEntityTypeCompany) {
      partyList = _.filter(this.accountFullDetail.partyList, function (party) { return party.partyMst.partyTypeCd == PartyTypeCd })
      this.coapplicantParty = _.filter(partyList, function (o) {
        return o.partyMst.partyTypeCd == 'Person' && o.partyMst.primaryOnAccount == '0'
      })[1]
    } else {
      partyList = this.accountFullDetail.partyList;
      this.coapplicantParty = _.filter(partyList, function (o) {
        return o.partyMst.partyTypeCd == 'Person' && o.partyMst.primaryOnAccount == '0'
      })[0]
    }

    if (partyList.length > 1) {
      // passport information
      if (this.coapplicantParty['personalIdList'].length > 0 &&
        this.coapplicantParty['personalIdList'][0]['personalIdTypeCd'] == 'PP' &&
        this.coapplicantParty['personalIdList'][0]['personalId']
      ) {
        this.editableInfo['passportno'] = this.coapplicantParty['personalIdList'][0]['personalId']
        this.markPassportDisabled = true
      }

      // Tax Id information
      this.editableInfo['markAsCoapplicant'] = (this.coapplicantParty.partyMst.roleCd === 'BusinessOwner')
      if (this.coapplicantParty &&
        (this.coapplicantParty.taxList.length >= 1) &&
        this.coapplicantParty.taxList[0].taxTypeCd == TAX_TYPE_CODE ) {
        this.markCidDisabled = true
        this.markPassportDisabled = true
        this.editableInfo['cid'] = this.coapplicantParty.taxList[0].taxId
      }

      // name information
      if (this.coapplicantParty.nameList && this.coapplicantParty.nameList.length > 0) {
        this.editableInfo['c_givenName'] = this.coapplicantParty.nameList[0].localeName.givenName;
        this.editableInfo['c_familyName'] = this.coapplicantParty.nameList[0].localeName.familyName;
        this.editableInfo['c_l_familyName'] = this.coapplicantParty.nameList[0].latinName.familyName;
        this.editableInfo['c_l_givenName'] = this.coapplicantParty.nameList[0].latinName.givenName;
      }
    }
  }

  createPhoneOption(info): DropdownOption {
    return {
      id: `${DEFAULT_MOBILE_OPTION.text},${DEFAULT_MOBILE_OPTION.id}`,
      text: `+${DEFAULT_MOBILE_OPTION.text} (${DEFAULT_MOBILE_OPTION.id})`
    }
  }

  createCountryOption(): DropdownOption {
    return DEFAULT_COUNTRY_OPTION;
  }

  setPhone(phoneType: string, chosenOption) {
    const [phoneCntryCd, cntryCd] = chosenOption.id.split(',');
    this.editableInfo[phoneType].phoneCntryCd = phoneCntryCd;
    this.editableInfo[phoneType].cntryCd = cntryCd;
  }

  setGender(genderCd) {
    this.editableInfo['genderCd'] = genderCd.id;
  }

  saveChanges(): void {
    if (!this.isPartyPrimary) { this.payloadCIDPIDForCoapplicant() }
    let businessNature = CommonService.getBusinessNature(this.accountFullDetail.accountMst)
    this.editableInfo['businessNature'] = businessNature;
    this.errors = this.validationService.getBasicInfoErrors(this.editableInfo);
    if (this.actualRecordInfo.maritalStatusCd != this.editableInfo.maritalStatusCd) {
      this.deleteCoapplicant();
    }

    if (this.marriedSelected) {
      this.errors = Object.assign(this.errors, this.validationService.getCoappErrors(this.editableInfo))
      this.findCoapplicantInfo();
    } else {
      this.updateBasicInfo();
    }
  }

  deleteCoapplicant() {
    const { aff, abo } = this.activatedRoute.snapshot.params;
    const params = this.activatedRoute.snapshot.params;
    if (this.accountFullDetail.partyList.length > 1) {
      let coapplicantPartyId = this.coapplicantParty.partyMst.partyId;
      this.loader = true
      this.partyService.deleteAParty(aff, abo, coapplicantPartyId).subscribe(response => {
        this.store.dispatch(new AboDetailsLoad(params));
        this.store.dispatch(new TaxPayerLoad(params));
        this.loader = false
        this.cdr.detectChanges();
      })
    }
  }

  updateBasicInfo() {
    const data = _.cloneDeep(this.editableInfo) as ABOBasicInfo;
    if (_.isEmpty(this.errors)) { this.updateAboBasicInfo.emit({ data, initialData: this.aboBasicInfo }); }
  }

  updateRouteInformation() {
    const { aff, abo } = this.activatedRoute.snapshot.params;
    this.editableInfo['salesAff'] = aff
    this.editableInfo['abo'] = abo
  }

  validateCidandPid() {
    let error = this.validationService.validateCidPidError(this.editableInfo['cid'], this.editableInfo['passportno'])
    if (_.isEmpty(this.editableInfo['passportno'])) {
      if (error['cid']) { this.errors['cid'] = error['cid'] }
    }
    if (_.isEmpty(this.editableInfo['cid'])) {
      if (error['pid']) { this.errors['passportno'] = error['pid'] }
    }

  }

  findCoapplicantInfo() {
    if (this.accountFullDetail.partyList.length > 1) {
      this.updateCoapplicantInfo();
    } else {
      this.newCoapplicantinfo();
    }
  }

  createDropdownOption(data) {
    return { id: data, text: data }
  }

  createGenderOption(data) {
    return { id: data, text: data };
  }

  createTitleOption(data) {
    return { id: data, text: data };
  }

  setSalutation(sal) {
    this.editableInfo['title'] = sal.id;
  }

  newCoapplicantinfo() {
    this.validateCidandPid();
    this.updateRouteInformation();
    let roleCd
    if (this.editableInfo.markAsCoapplicant) { roleCd = 'BusinessOwner'; }
    if (!this.editableInfo.markAsCoapplicant) { roleCd = 'Prospect'; }

    let date = moment().subtract(25, 'years').format()

    // listing keys changes in request body
    let changeKeys =
      {
        "partyId": 0, 'partyIndex': 2, 'primaryOnAccount': '0', 'roleCd': roleCd,
        'taxId': this.editableInfo['cid'],
        'genderCd': this.currentParty.partyMst.genderCd == 'Male' ? 'Female' : 'Male',
        'contactId': '', maritalStatusCd: 'Married', czshpTypeCd: '', czshpCntryCd: ''
      }

    // replace listed keys
    let newObj: any = _.cloneDeep(this.currentParty)
    _.forEach(changeKeys, (val, key) => {
      newObj = this.replacePropertyValue(key, val, newObj)
    })

    let payload = {}
    payload['editableBasicInfo'] = this.editableInfo
    newObj.partyMst['birthDate'] = date;
    newObj.nameList[0].localeName.givenName = this.editableInfo.c_givenName
    newObj.nameList[0].localeName.familyName = this.editableInfo.c_familyName
    newObj.nameList[0].latinName.familyName = this.editableInfo.c_l_familyName
    newObj.nameList[0].latinName.familyName = this.editableInfo.c_l_familyName
    newObj = this.payloadForPersonalId(newObj)

    payload['abobasicInfo'] = newObj

    if (_.isEmpty(this.errors)) {
      this.loader = true
      this.registrationsService.registerPartialAccount(payload).subscribe(response => {
        this.loader = false
        if (response.type == "partyCreationResponse") {
          const data = _.cloneDeep(this.editableInfo) as ABOBasicInfo;
          this.updateAboBasicInfo.emit({ data, initialData: this.aboBasicInfo });
        }
        this.cdr.detectChanges()
      })
    }
  }

  scrollFormToError() {
    document.getElementsByClassName('amw-basic-info__form amw-info-form')[0].scrollTop = 0;
  }

  payloadForPersonalId(newObj) {
    delete newObj['taxList']
    delete newObj['personalIdList']
    if (!_.isEmpty(this.editableInfo['cid'])) {
      newObj["taxList"] = [
        {
          "taxTypeCd": "CID",
          "taxId": this.editableInfo['cid'],
          "cntryCd": defaultCountryCode
        }
      ]
    }

    if (!_.isEmpty(this.editableInfo['passportno'])) {
      newObj["personalIdList"] = [
        {
          "personalIdTypeCd": "PID",
          "personalId": this.editableInfo['passportno'],
          "countryPersonalId": defaultCountryCode
        }
      ]
    }
    return newObj
  }

  payloadCIDPIDForCoapplicant() {
    if (!_.isEmpty(this.editableInfo['iCrdNum'])) {
      this.editableInfo["taxList"] = [
        {
          "taxTypeCd": "CID",
          "taxId": this.editableInfo['cid'],
          "cntryCd": defaultCountryCode
        }
      ]
    }

    if (!_.isEmpty(this.editableInfo['passportNumber'])) {
      this.editableInfo["personalIdList"] = [
        {
          "personalIdTypeCd": "PID",
          "personalId": this.editableInfo['passportNumber'],
          "countryPersonalId": defaultCountryCode
        }
      ]
    }
  }

  updateCoapplicantInfo() {
    this.validateCidandPid();
    this.updateRouteInformation();

    let roleCd
    if (this.editableInfo.markAsCoapplicant) { roleCd = 'BusinessOwner'; }
    if (!this.editableInfo.markAsCoapplicant) { roleCd = 'Prospect'; }

    let birthDate;
    let coapplicantParty = this.coapplicantParty;

    if (coapplicantParty.partyMst.birthdate != '') {
      birthDate = coapplicantParty.partyMst.birthdate.replace('Z', '+00:00');
    } else {
      birthDate = moment().subtract(25, 'years').format();
    }

    if (!_.isEmpty(this.errors)) {
      if (this.coapplicantParty.partyMst.roleCd == 'BusinessOwner') { this.editableInfo['markAsCoapplicant'] = true }
      this.markAsCoapplicantpopup.close();
    } else {
      let czshpTypeCd = this.currentParty.partyMst.czshpCntryCd
      let czshpCntryCd = this.currentParty.partyMst.czshpTypeCd

      if (_.isEmpty(this.editableInfo['cid']) && !_.isEmpty(this.editableInfo['passportno'])) {
        czshpTypeCd = ""
        czshpCntryCd = ""
      }

      let changeKeys = { 'roleCd': roleCd, 'relationShipToPrimaryPartyCd': '', 'genderCd': this.currentParty.partyMst.genderCd == 'Male' ? 'Female' : 'Male', maritalStatusCd: 'Married', czshpTypeCd: czshpTypeCd, czshpCntryCd: czshpCntryCd }

      let newObj: any = _.cloneDeep(coapplicantParty)

      _.forEach(changeKeys, (val, key) => {
        newObj = this.replacePropertyValue(key, val, newObj)
      })

      let payload = {}
      payload['editableBasicInfo'] = this.editableInfo
      newObj.partyMst['birthDate'] = birthDate;
      newObj = this.payloadForPersonalId(newObj)
      payload['abobasicInfo'] = newObj

      this.loader = true
      this.registrationsService.updateCoApplicantInformation(payload)
        .subscribe(response => {
          const data = _.cloneDeep(this.editableInfo) as ABOBasicInfo;
          this.loader = false
          this.updateAboBasicInfo.emit({ data, initialData: this.aboBasicInfo });
        })
    }
  }

  cancelChanges() {
    this.cancel.emit();
  }

  changeCurrentDate(dateString: string): void {
    this.editableInfo.birthdate = moment(dateString).format();
  }

  isFRNSelected(): boolean {
    return _.get(this.editableInfo, 'tax.taxTypeCd', '') === FRN_TAX_TYPE;
  }

  setTaxIdType(taxTypeCd: string): void {
  }

  updateCitizenship(event: DropdownOption) {
    this.editableInfo.czshpCntryCd = event.id
    this.editableInfo.czshpCntryName = event.text
    if (event.id === DEFAULT_PRIMARY_CITIZENSHIP_DATA.id) {
      this.showPassportInformation = false;
    } else {
      this.showPassportInformation = true;
    }
    this.cdr.detectChanges();
  }

  onMaritalStatusChange(event) {
    if (event == 'm') { this.marriedSelected = true }
    if (this.coapplicantParty && event == 'um') {
      this.maritalStatusChange.open()
    }
    if (!this.coapplicantParty && event == 'um') {
      this.marriedSelected = false
    }
  }
  changeMaritalStatus() {
    this.marriedSelected = false
    this.editableInfo.maritalStatusCd = 'Single'
    this.maritalStatusChange.close()
  }
  cancelMaritalStatus() {
    this.marriedSelected = true
    this.editableInfo.maritalStatusCd = 'Married'
    this.maritalStatusChange.close()
  }
  private getTaxIdForFRN(): string {
    const { abo, partyId } = this.activatedRoute.snapshot.params;
    return abo + partyId;
  }

  /**
   * returns tax id for displaying based on selected tax type
   * @returns {string}
   */
  private getDisplayedTaxId(): string {
    return this.commonService.getDisplayedTaxId(this.editableInfo);
  }

  replacePropertyValue(pkey, pval, object) {
    return JSON.parse(JSON.stringify(object), (key, value) => {
      if (key == pkey) {
        return pval;
      } else {
        return value;
      }
    })
  }

  private createBasicInfoForm(basicInfo: ABOBasicInfo) {
    const basicForm = {};
    basicForm['fName'] = new FormControl(basicInfo.givenName,
      [Validators.required,
      Validators.maxLength(100),
      // tslint:disable-next-line:quotemark
      Validators.pattern("^[a-zA-Z0-9,.' ]+')]")]);
    basicForm['lName'] = new FormControl(basicInfo.familyName,
      [Validators.required,
      Validators.maxLength(100),
      // tslint:disable-next-line:quotemark
      Validators.pattern("^[a-zA-Z0-9,.' ]+')]")]);

    basicForm['gndr'] = new FormControl(basicInfo.genderCd,
      [Validators.required]);
    basicForm['dob'] = new FormControl(basicInfo.birthdate,
      [Validators.required]);
    basicForm['email'] = new FormControl(basicInfo.email,
      [Validators.required,
      Validators.email,
      Validators.pattern(new RegExp('^((?!amway).)*$', 'ig'))]);
    basicForm['mobile'] = new FormControl(basicInfo.mobilePhoneInfo.phoneLocalNum);
    basicForm['alternateNumber'] = new FormControl(basicInfo.alternatePhoneInfo.phoneLocalNum);
  }

  processPartyChange() {
    this.errors = {}
    this.updateCoapplicantInfo();
    this.markAsCoapplicantpopup.close();
  }

  markAsCoapplicantpopupShow(event) {
    event.stopPropagation();
    if (this.coapplicantParty && this.coapplicantParty.partyMst.roleCd === 'BusinessOwner' && this.editableInfo['markAsCoapplicant'])
      this.markAsCoapplicantpopup.open();
  }

  markAsCoapplicantpopupclose() {
    this.editableInfo['markAsCoapplicant'] = true
    this.markAsCoapplicantpopup.close();
  }

  // onLanguageSelect(lang){
  //   console.log("selected languages before selection", this.editableInfo.languagesKnown)
  //   // this.editableInfo.languagesKnown.push(lang);
  //   // console.log("selected languages", this.editableInfo.languagesKnown);
  // }

  // OnItemDeSelect(lang){
  //   console.log(this.editableInfo.languagesKnown);
  // }

}
