import { AccountMst, PartyList } from './amway/index';
import { BusinessNatureName } from './business-nature-name.model';
import { getAccountName, getPartyName } from '../helpers/common.helper';

const INACTIVE = 'Inactive';
const ACTIVE = 'Active';

export class AccountCard {
  aboNum: string;
  accountName: string;
  loaCd: string;
  loaName: string;
  loaDisplayText: string;
  cntryCd: string;
  renewalStatus: string;
  isActive: boolean;
  isCustomer: boolean;
  statusCd: string;
  cntryName: string;
  partyName: string;
  prefferedLanguage: string;
  constructor(
    accountMst = <AccountMst>{},
    currentParty = <PartyList>{},
    coApplicant = <PartyList>{}
  ) {
    this.aboNum = accountMst.aboNum;
    this.accountName = getAccountName(accountMst.accountName, currentParty);
    this.partyName = getPartyName(accountMst.accountName, currentParty);
    this.cntryCd = accountMst.cntryCd;
    this.loaCd = accountMst.loaCd;
    this.loaName = accountMst.loaName;
    this.loaDisplayText =
      accountMst.loaName + (accountMst.loaCd ? ` (${accountMst.loaCd})` : '');
    this.isActive = accountMst.statusCd === ACTIVE;
    this.statusCd = accountMst.statusCd;
    this.isCustomer =
      accountMst.accountSubTypeCd === BusinessNatureName.Customer;
  }
}
