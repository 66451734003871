import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { ApiPathConfigService } from '../configs/api';
import { checkAmwayIdByPartyIdAPI } from '../configs/constants';
import { HttpService } from '../../core/services/http.service';

@Injectable()
export class AboIdCheckService {

  constructor(
    private httpService: HttpService,
    private apiPathService: ApiPathConfigService)
  { }

  aboIdCheck(partyIDValue) {
    const api = this.apiPathService.getApiPath(checkAmwayIdByPartyIdAPI);
    // api.path = api.path.replace(salesAff, '30');
    let urlParams: HttpParams = new HttpParams();
    // urlParams = urlParams.set('ecommAddr', 'cslrfk@zwjneso.pbd.qe');
    urlParams = urlParams.set('partyid', partyIDValue);
    return this.httpService.callServerGET(api, urlParams).pipe(map(
      response => {
      return response;
        // const locations =
        //   response && response.length
        //     ? response.map(loc => new AreaLocation(loc))
        //     : ([] as AreaLocation[]);
        // observer.next({ loading: false, data: locations });
        // observer.complete();
      },
      error => {
        console.log(error);
        // this.LogError(error);
        // observer.next({ loading: false, data: [] as AreaLocation[] });
        // observer.complete();
      }
    ));
  }


}
