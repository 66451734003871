// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  masheryBasePath: 'https://api-ts2.amwayglobal.com',
  masheryClientId: 'ye3uz4y52dfs3ett4npdf8rm',
  occBasePath: 'https://api.qa.amway.com.au',
  occClientId: 'bossclient',
  occClientSecret: 'secret',
  occAuthResponseType: 'id_token',
  occEnable: true, // Shouldn't disable OCC - It will break "Register AN Employee" functionality & IBO profile Page
  occV2URL: '/v2/amwayaustralia',
  loggerUrl: 'https://api.uat.amway.id/api/v2/amwayaustralia/log',
  AmwayIDapikey: 'y99LVDVeCjnw94ZR-bossanz',
  _360BasePath: 'https://event-api-qa.360-preprod.corp.amway.net',
  amCommClientApp: 'AmwayAU',
  amCommRedirectUrl: 'https://shop.au.test.amcomm.intranet.local/en/Auth/CreateReturn',
  oktaJwtTokenUrl:'https://amway.okta.com',
  oktaJwtV1URL: '/oauth2/aus1hgyer5sM3MMtI0h8/v1',
  jwtResponseUrl:'/searchforce-find-connect-id',
  oktaJwtClientId:'0oa1gjlv2kub3P8M20h8',
  _360ApiKey: 'J9dwmg2xIo2d1dTFbtM943pvBVSOIthaMlp43Xbf'
};