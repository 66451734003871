import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FindABORoutingModule } from './find-abo-routing.module';
import { FindABOComponent } from './find-abo.component';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  imports: [
    CommonModule,
    FindABORoutingModule,
    SharedModule
  ],
  declarations: [
    FindABOComponent
  ],
  bootstrap: []
})
export class FindABOModule { }
