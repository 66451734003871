import { LAS } from '../shared/configs/market.settings';

export const START_YEAR = 1900;

export const TAX_MANDATORY_CD = ['DO', ...LAS, 'PR'];
export const ZIP_CODE_MANDATORY_CD = ['US', 'CA'];

export const TAX_ID_TYPES = [
  {
    text: 'FIN',
    id: 'FIN',
    countries: ['US']
  },
  {
    text: 'SSN',
    id: 'SSN',
    countries: ['US', 'CA']
  },
  {
    text: 'FRN',
    id: 'FRN',
    countries: ['US', 'CA']
  },
  {
    text: 'RNC',
    id: 'RNC',
    countries: ['DO']
  },
  {
    text: 'CED',
    id: 'CED',
    countries: ['DO']
  },
  {
    text: 'NPWP',
    id: 'NPWP',
    countries: ['ID']
  },
  {
    text: 'CID',
    id: 'CID',
    countries: ['TH']
  }
];

export const TAX_MASKS = {
  FIN: '99-9999999',
  NPWP: '999999999999999',
  SSN: '999-99-9999',
  RNC: '9-99-99999-9',
  CED: '999-9999999-9',
};

export const FRN_TAX_TYPE = 'FRN';

export const CLIENT_NAME = 'CUSTOMER';
export const IBO_NAME = 'ABO';

export const BANK_ACCOUNT_TYPES = [
  {
    contryCode:'ALL',
    AccCode: 'Current',
    AccText: 'Current'
  },
  {
    contryCode:'ALL',
    AccCode: 'Saving',
    AccText: 'Saving'
  }
];

export const BANK_ACCOUNT_STATUS_TYPES = [
  {
    contryCode:'ALL',
    StatusCode: 'CANCELLED',
    text: 'Cancelled'
  },
  {
    contryCode:'ALL',
    StatusCode: 'EXPIRED',
    text: 'Expired'
  },
  {
    contryCode:'ALL',
    StatusCode: 'INVALID',
    text: 'Invalid'
  },
  {
    contryCode:'ALL',
    StatusCode: 'NEW',
    text: 'New'
  },
  {
    contryCode:'ALL',
    StatusCode: 'NOTVALD',
    text: 'Not Validated'
  },
  {
    contryCode:'ALL',
    StatusCode: 'VALID',
    text: 'Valid'
  }
];

export const FIELD_TYPES = { 
  BSB_NUMBER: 'bsbNumber',
  BANK_ID: 'bankId',
  BRANCH_ID: 'bankBranchId' 
}
