import { Injectable, Inject } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from './storage.service';
import { HttpService } from './http.service';
import { MasheryToken, CoreConfig, Token } from '../../shared/models/index';
import { coreConfig } from '../core.config';
import { ApiPathConfigService } from './../../shared/configs/api';
import {
  clientId,
  redirectUrl,
  masheryTokenSuccessMessage,
  getMasheryTokenAPI,
  getMasheryAuthCodeAPI,
  getOccTokenAPI,
  getOktaJwtAuthCodeAPI,
  stateRandom,
  nonceRandom,
  oktaJwtClientId,
  oktaJwtRedirectUrl
} from '../../shared/configs/constants';
import { User } from '../../shared/models/index';
import { UserSetData } from '../../store/user/user.actions';
import { environment } from './../../../environments/environment';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/index';
import { SetAdminRole, SetRoles } from '../../store/roles/roles.actions';
import { Observable, Subscription, interval } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';
import { CommonService } from '../../core/services/common.service';
import AppSettings from '../../app.settings';

@Injectable()
export class AuthService {
  refreshTokenSub: Subscription;
  tokenSub: Subscription;
  occEnable: boolean = environment.occEnable;
  constructor(
    private storageService: StorageService,
    private httpService: HttpService,
    private router: Router,
    private store: Store<IAppState>,
    private apiPathService: ApiPathConfigService,
    private commonService: CommonService,
    @Inject(coreConfig) private config: CoreConfig
  ) {}

  getUserData() {
    const tokenDetails = <MasheryToken>(
      this.storageService.getItem(this.config.masheryTokenKey)
    );
    return tokenDetails && tokenDetails.access_token
      ? new User({ name: tokenDetails.user_context })
      : null;
  }

  removeUserData() {
    this.storageService.clear();
  }

  buildMasheryAuthCodePath() {
    const api = this.apiPathService.getApiPath(getMasheryAuthCodeAPI);
    const basePath = window.location.origin;
    const urlPath =
      environment.masheryBasePath +
      api.path
        .replace(clientId, environment.masheryClientId)
        .replace(redirectUrl, basePath + this.config.masheryRedirectUri);
    return urlPath;
  }

  buildOktaJwtAuthCodePath() {
    const api = this.apiPathService.getApiPath(getOktaJwtAuthCodeAPI);
    const basePath = window.location.origin;
    const urlPath =
      environment.oktaJwtTokenUrl +
      api.path
        .replace(stateRandom, this.commonService.getRandomNumber(1, 99999))
        .replace(nonceRandom, this.commonService.getRandomNumber(1, 99999))
        .replace(oktaJwtClientId, environment.oktaJwtClientId)
        .replace(oktaJwtRedirectUrl, basePath + environment.jwtResponseUrl);
    return urlPath;
  }

  getOccTokenDetails(): Token {
    const occToken = <Token>(
      this.storageService.getItem(this.config.occTokenKey)
    );
    return occToken;
  }

  buildOccAuthTokenUrl() {
    const api = this.apiPathService.getApiPath(getOccTokenAPI);
    const basePath: string = window.location.origin;
    let params = new HttpParams();
    params = params.append('client_id', environment.occClientId);
    params = params.append('response_type', environment.occAuthResponseType);
    params = params.append('mac_address', 'BOSS_MAC_ADDRESS');
    params = params.append(
      'redirect_uri',
      basePath + this.config.occRedirectUri
    );
    const authUrl =
      environment.occBasePath + '/api' + api.path + '?' + params.toString();

    return authUrl;
  }
  
  getMasheryAccessToken(): string {
    const tokenDetails = <MasheryToken>(
      this.storageService.getItem(this.config.masheryTokenKey)
    );
    return tokenDetails ? tokenDetails.access_token : null;
  }

  getOccAccessToken() {
    const tokenDetails = <Token>(
      this.storageService.getItem(this.config.occTokenKey)
    );
    return tokenDetails.access_token;
  }

  getOccApiContext() {
    const tokenDetails = <Token>(
      this.storageService.getItem(this.config.occTokenKey)
    );
    return tokenDetails.apiContextRoot;
  }

  retrieveMasheryAccessTokenViaAuthCode() {
    const authToken = <MasheryToken>(
      this.storageService.getItem(this.config.masheryTokenKey)
    );
    const basePath: string = window.location.origin;
    let param = new HttpParams();
    param = param.append('client_id', environment.masheryClientId);
    param = param.append('grant_type', 'authorization_code');
    param = param.append('code', authToken.code);
    param = param.append(
      'redirect_uri',
      basePath + this.config.masheryRedirectUri
    );
    const api = this.apiPathService.getApiPath(getMasheryTokenAPI);
    this.httpService.callServerPOST(api, null, param).subscribe(
      (response: MasheryToken) => {
        this.storageService.setItem(this.config.masheryTokenKey, response);
        window.parent.postMessage(masheryTokenSuccessMessage, '*');
        this.updateUserData(response);
        this.handleRedirection();
      },
      error => {
        console.log('Unable to fetch token from AuthCode');
      }
    );
  }

  updateUserData(response: MasheryToken) {
    const user: User = new User({ name: response.user_context });
    const userRoles = user ? user.groups : [];
    this.store.dispatch(new UserSetData(user));
    // if (userRoles && userRoles.length) {
    //   this.store.dispatch(new SetRoles({ roles: ['LDAP-AMW-BOSS-Inquiry-Only'] }));
    // } else {
    //   this.store.dispatch(new SetAdminRole());
    // }
  }

  handleRedirection() {
    const tokenDetails = <MasheryToken>(
      this.storageService.getItem(this.config.masheryTokenKey)
    );
    const occToken = <Token>(
      this.storageService.getItem(this.config.occTokenKey)
    );
    if (
      (tokenDetails &&
      tokenDetails.access_token &&
      occToken &&
      occToken.access_token) && this.occEnable
    ) {
      this.refreshToken();
      this.router.navigateByUrl('/home');
    }
    else if ((tokenDetails && tokenDetails.access_token) && !this.occEnable) {
      this.refreshToken();
      this.router.navigateByUrl('/home');
    } else {
      this.removeUserData();
      this.router.navigateByUrl('/login');
    }
  }

  retrieveMasheryAccessTokenViaRefreshToken() {
    const existingToken = <MasheryToken>(
      this.storageService.getItem(this.config.masheryTokenKey)
    );
    let param = new HttpParams();
    param = param.append('client_id', environment.masheryClientId);
    param = param.append('grant_type', 'refresh_token');
    param = param.append('refresh_token', existingToken.refresh_token);
    existingToken.access_token = '';
    this.storageService.setItem(this.config.masheryTokenKey, existingToken);
    const api = this.apiPathService.getApiPath(getMasheryTokenAPI);
    return this.httpService.callServerPOST(api, null, param).pipe(map(
      (response: MasheryToken) => {
        this.storageService.setItem(this.config.masheryTokenKey, response);
        return response.access_token;
      },
      error => {
        console.log('Unable to fetch token from RefreshToken');
      }
    ));
  }
  refreshToken() {
    this.tokenSub = interval(1000 * (AppSettings.minTokenTime * 60))
      .pipe(flatMap(() => {
        return this.retrieveNewMasheryAccessTokenViaRefreshToken();
      }))
      .subscribe(() => {
        this.tokenSub.unsubscribe();
      });
    const intervalData = this.getRefreshIntervalTime();
    this.refreshTokenSub = interval(1000 * intervalData * 1)
      .pipe(flatMap(() => {
        return this.retrieveNewMasheryAccessTokenViaRefreshToken();
      }))
      .subscribe(() => {});
  }
  retrieveNewMasheryAccessTokenViaRefreshToken() {
    const existingToken = <MasheryToken>(
      this.storageService.getItem(this.config.masheryTokenKey)
    );
    let param = new HttpParams();
    param = param.append('client_id', environment.masheryClientId);
    param = param.append('grant_type', 'refresh_token');
    param = param.append('refresh_token', existingToken.refresh_token);
    const api = this.apiPathService.getApiPath(getMasheryTokenAPI);
    return this.httpService
      .callServerPOSTRefreshToken(api, null, param)
      .pipe(map(
        (response: MasheryToken) => {
          this.storageService.setItem(this.config.masheryTokenKey, response);
          return response.access_token;
        },
        error => {
          console.log('Unable to fetch token from RefreshToken');
        }
      ));
  }
  private getRefreshIntervalTime() {
    const masheryToken = <any>(
      this.storageService.getItem(this.config.masheryTokenKey)
    );

    return masheryToken && masheryToken.expires_in
      ? masheryToken.expires_in - 600
      : 3000;
  }
}
