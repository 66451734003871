import { Directive, HostListener, Input, ElementRef } from '@angular/core';

@Directive({
  selector:"[validationsOnInputField]"
})

/**
 * usage: restrict input fields entries
 *  validationsOnInputField="allowOnlyCharacters"
 * @export
 * @class validationsOnInputFieldDirective
 */
export class ValidationsOnInputFieldDirective {
  constructor(el: ElementRef){}
  @Input('validationsOnInputField') validateMethod: string;
  
  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    switch(this.validateMethod) {
      case "allowOnlyCharacters":
      case "allowOnlyNumbers":
      case 'restrictNumbersOnly': {
        return this[this.validateMethod](event.charCode)
      }
      default: {
        return true
      }
    } 
  }

  allowOnlyCharacters(k){
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32);
  }

  allowOnlyNumbers(k){
    return (k >= 48 && k <= 57)
  }

  restrictNumbersOnly(k){
    return (k != 43 && k > 31 && (k < 48 || k > 57))
  }
  
}
