import { Action } from '@ngrx/store';

export const SET_ROLES = '[Roles] set roles';
export const SET_ADMIN_ROLE = '[Roles] set admin role';

export class SetRoles implements Action {
  readonly type = SET_ROLES;

  constructor(public payload: any) { }
}

export class SetAdminRole implements Action {
  readonly type = SET_ADMIN_ROLE;

  constructor(public payload: any = null) { }
}

export type Actions =
  | SetRoles
  | SetAdminRole;
