import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FindABOComponent } from './find-abo.component';
import { AuthGuard } from '../../core/guards/auth.guard';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: 'find-abo', component: FindABOComponent, canActivate: [AuthGuard],
        data: { label: 'Search Results', parents: ['home'] }
      },
    ])
  ],
  exports: [
    RouterModule
  ]
})
export class FindABORoutingModule { }
