import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent {

  @Input() isActive = false;
  @Output() isActiveChange = new EventEmitter(this.isActive);

  toggle() {
    this.isActiveChange.emit(this.isActive);
  }

}
