import { HttpService } from '../../core/services';
import { Injectable } from '@angular/core';
import { ApiPathConfigService } from '../configs/api';
import { VolumeService } from './volume.service';
import { uplineInfoAPI } from './../configs/constants';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class UplineService {
  constructor(
    private httpService: HttpService,
    private volumeService: VolumeService,
    private apiPathService: ApiPathConfigService
  ) {}

  getUplineInfo(abo: any, aff: any) {
    const api = this.apiPathService.getApiPath(uplineInfoAPI);
    api.path = api.path + `?aff=${aff}&abo=${abo}`;
    return this.volumeService.getPeriod(aff).pipe(switchMap(period => {
      api.path = api.path + `&period=${period}`;
      return this.httpService.callServerGET(api, null, null);
    }));
  }
}
