import { Component, OnInit, OnDestroy, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Modal } from 'ngx-modal';
import { filter, map } from 'rxjs/operators';
import { IAppState } from '../../store';
import { AccountDetail, BusinessDetails, ContractModel } from '../../shared/models/amway';
import { ActivatedRoute } from '@angular/router';
import { ABOBusinessDetails, ABOBusinessInfo } from '../../shared/models/abo-business-details.model';
import { getAboBasicBusinessDetails } from '../../shared/helpers/business-detail.helper';
import { AboBusinessDetailsLoad } from '../../store/abo-business-details/abo-business-details.actions';
import { AboAward } from '../../shared/models/abo-award.model';
import {
  AboDocumentReceivedSave
} from '../../store/abo-personal-details/abo-personal-details.actions';
import { ContractsResponse } from '../../store/abo-contracts/abo-contracts.reducer';
import { SponsorsList } from '../../shared/models/amway';
import * as _ from 'lodash';
import { CommonService, NavigationService } from '../../core/services';
import { BusinessInfoService } from '../../shared/services';
import { AboRestrictionBlocksSave, AboRestrictionBlocksLoadActive } from '../../store/abo-restriction-blocks/abo-restriction-blocks.actions';
import { PartyParams } from '../../shared/models';
import { RestrictionBlockConfigService, RestrictionBlock } from '../../shared/models/amway/restriction-blocks.model';
import { AR_BALANCE_TYPE_CODE, AMPLUS_BALANCE_TYPE_CODE, contractIdNo } from '../../shared/configs/constants';
import { AccountBalance } from '../../shared/models/amway';
import { getArOrAmplusBalance } from '../../shared/helpers/account.helper';
import { EROFS } from 'constants';
import { error } from 'protractor';

const DEFAULT_BLOCK_TERM = 50;

@Component({
  selector: 'app-abo-business-details',
  templateUrl: './abo-business-details.component.html',
  styleUrls: ['./abo-business-details.component.scss']
})

export class AboBusinessDetailsComponent implements OnInit, OnDestroy {
  @Input() accountDetails;
  @Input() arBalance;
  @Input() arBalancetitle: string;
  account$: Observable<AccountDetail>;
  loaded$: Observable<boolean>;
  businessDetailLoaded: boolean = false;
  sponsorInfo$: Observable<BusinessDetails>;
  sponsorInfo: SponsorsList;
  awardInfo: AboAward;
  contractDetails: ContractModel;
  aboBusinessInfo: ABOBusinessDetails;
  contracts$: Observable<ContractsResponse>;
  loading$: Observable<boolean>;
  accMissingDocumentFlag: boolean = true;
  businessNature: string;
  orderingBlock;
  defaultEffectiveDate = this.getDefaultDate();
  defaultExpirationDate = this.getDefaultDate(true);
  blockToAdd: RestrictionBlock;
  accountBalance: AccountBalance;
  showLoader: boolean;
  accTermsNconditionse: boolean = false;
  contractSignDate: boolean = false;
  reactivateErrorMsg: string = null;
  contractList: any;
  @ViewChild('accTermsNconditions') accTermsNconditions: Modal;

  constructor(
    private store: Store<IAppState>,
    private activatedRoute: ActivatedRoute,
    private businessInfoService: BusinessInfoService,
    private cdr: ChangeDetectorRef,
    private navigationService: NavigationService
  ) {
  }

  ngOnInit() {
    const params = this.activatedRoute.snapshot.params;

    this.businessInfoService.data.subscribe(response => {
      this.businessDetailLoaded = response.loaded;
      this.sponsorInfo = response && response.data ? response.data.sponsorList : {} as SponsorsList;
      this.awardInfo = response && response.data ? response.data.awards : {} as AboAward;
    })

    this.businessNature = CommonService.getBusinessNature(this.accountDetails.data.accountMst);
    this.getAboContractList();

    this.aboBusinessInfo = getAboBasicBusinessDetails(this.accountDetails);
    this.businessInfoService.sponsorName.subscribe(response => {
      this.aboBusinessInfo.sponsorName = response
    })

    this.contractDetails = _.filter(this.accountDetails.data.missingInfoList, function (o) { return o.missingInfoId === 'Signature' })[0]
    if (this.contractDetails) {
      this.accMissingDocumentFlag = (this.contractDetails.status == 'Complete');
    }

    this.fetchRestrictionBlocks()
  }

  fetchRestrictionBlocks() {
    let store$ = this.store
      .select('aboRestrictionBlocks')
      .pipe(filter(Boolean)
        , map(
          data =>
            data[
            CommonService.getAboString(this.activatedRoute.snapshot
              .params as PartyParams)
            ]
        )
        , filter(Boolean));

    store$.pipe(filter(data => data.loadedActive))
      .subscribe(store => {
        this.orderingBlock = store.data && store.data.active && store.data.active.filter(o => o.blockPrivTypeId == "BlockOrdering");
      });

    //*************************************************To Calculate AR Balance*********************************************************/
    let arBalannceTypeCode;
    if (this.arBalancetitle == 'Amplus') { arBalannceTypeCode = AMPLUS_BALANCE_TYPE_CODE }
    if (this.arBalancetitle == 'AR' || this.arBalancetitle == 'Staff Entitlement') { arBalannceTypeCode = AR_BALANCE_TYPE_CODE }
    this.accountBalance = getArOrAmplusBalance(this.accountDetails, arBalannceTypeCode);
  }

  setCurrentBlock(block: RestrictionBlockConfigService): void {
    this.blockToAdd = {
      blockPrivTypeId: block.blockPrivTypeId,
      expirationDate: block.activatedBlock ? block.activatedBlock.expirationDate : this.defaultExpirationDate,
      effectiveDate: block.activatedBlock ? block.activatedBlock.effectiveDate : this.defaultEffectiveDate,
      blockPrivReason: block.activatedBlock && block.activatedBlock.blockPrivReason ? block.activatedBlock.blockPrivReason : ""
    };
  }

  setDocumentCompletedTrue(event: any) {
    const { aff, abo } = this.activatedRoute.snapshot.params;
    if (event.target.checked && this.contractDetails) {
      let sendData = {
        "missingInfoInput": {
          "missingInfoId": "Signature",
          "isoCntryCd": this.contractDetails.isoCntryCd,
          "receiveDate": this.contractDetails.receiveDate
        }
      }

      let data = {
        "aff": aff,
        "abo": abo,
        "sendData": sendData
      }
      this.store.dispatch(
        new AboDocumentReceivedSave({
          currentDetails: data,
          type: 'ABO_DOCUMENT_RECEIVED_SAVE'
        })
      );

      // Remove ordering block
      if (this.orderingBlock && this.orderingBlock.length > 0) {
        this.setCurrentBlock(this.orderingBlock[0])
        this.saveBlock();
      }

    }
  }

  isBlockValid(effectiveDate: string, expirationDate: string): boolean {
    return new Date(effectiveDate) <= new Date(expirationDate);
  }

  saveBlock() {
    this.blockToAdd.expirationDate =
      this.isBlockValid(this.blockToAdd.effectiveDate, this.getDefaultDate())
        ? this.getDefaultDate()
        : this.blockToAdd.effectiveDate;
    this.blockToAdd.expirationDate = this.blockToAdd.expirationDate.replace('Z', '+07:00');
    this.blockToAdd.effectiveDate = this.blockToAdd.effectiveDate.replace('Z', '+07:00');
    this.store.dispatch(new AboRestrictionBlocksSave({
      params: this.activatedRoute.snapshot.params as PartyParams,
      dataToSave: this.blockToAdd
    }));
  }

  private getDefaultDate(forExpiration = false): string {
    const date = new Date();
    if (forExpiration) {
      date.setFullYear(date.getFullYear() + DEFAULT_BLOCK_TERM);
    }
    return date.toISOString().split('.')[0] + '+07:00';
  }

  openTnCModal(event) {
    if (event.target.checked) {
      this.accTermsNconditions.open();
    }
  }

  UpdateTnC() {
    this.showLoader = true;
    this.accTermsNconditions.close();
    const { aff, abo, partyId } = this.activatedRoute.snapshot.params;
    let contractId = contractIdNo;
    const params = { aff, abo, partyId, contractId };
    this.businessInfoService.updateTnCInformation(params.aff, params.abo, params.partyId, params.contractId).subscribe((response) => {
      this.navigationService.updateAccountData(params);
      this.showLoader = false;
    }, (err) => {
      this.showLoader = false;
      this.reactivateErrorMsg = JSON.parse(err.error).errorMessage.message;
      this.cdr.detectChanges();
      this.accTermsNconditionse = false;
    })
  }

  getAboContractList() {
    const { aff, abo, partyId } = this.activatedRoute.snapshot.params;
    const params = { aff, abo, partyId };

    this.businessInfoService.getContractsListforABO(params).subscribe((response) => {
      if (response !== null) {
        let contractList = response.contractList;
        let valIndex = _.findLastIndex(contractList, { 'contractType': 'Registration' });
        this.accTermsNconditionse = valIndex >= 0 ? true : false;
        this.contractSignDate = contractList[valIndex].acceptanceDate !== null && contractList[valIndex].acceptanceDate !== '' ? true : false;
      } else {
        this.accTermsNconditionse = false;
        this.contractSignDate = false;
      }
    }, (err) => {
      this.showLoader = false;
      this.reactivateErrorMsg = JSON.parse(err.error).errorMessage.message;
    })
  }

  ngOnDestroy() {

  }
}
