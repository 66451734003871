import { Component, Input } from '@angular/core';
import { AboDetailValueType } from '../../models/enums';
import { AboDetail } from '../../models/amway';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-abo-detail',
  templateUrl: './abo-detail.component.html',
  styleUrls: ['./abo-detail.component.scss']
})
export class AboDetailComponent {
  @Input()
  detail: AboDetail;
  valueType = AboDetailValueType;
  constructor(private translateService: TranslateService) {}

  getTranslatedString(value) {
    if (value) {
      return this.translateService.stream(value);
    } else {
      of('');
    }
  }
}
