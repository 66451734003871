import { Modal } from 'ngx-modal';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ViewChild,
  Input,
  ChangeDetectorRef
} from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/index';
import { ActivatedRoute } from '@angular/router';
import {
  AboDetailsLoad,
  AboDetailsSave,
  AboAddressChange,
  AboBankDetailChange,
  AboTaxDetailChange,
  AboTaxChangeOnly,
  AboAddressDelete
} from '../../store/abo-personal-details/abo-personal-details.actions';
import {
  PersonalDetails,
  ABOBasicInfo,
  ABOAddress,
  ABOBasicInfoUpdatePayload,
  AddressEventData,
  AccountDetail,
  Tax,
  Account,
  TaxPayer,
  TaxPayerDetail
} from '../../shared/models/amway/index';
import { AccountGet } from '../../store/account/account.actions';
import { AboBusinessDetailsLoad } from '../../store/abo-business-details/abo-business-details.actions';
import {
  ContactPointPurposeCdEnum,
  AddrDeliveryTypeCdEnum,
  ContactPointTypeCdEnum
} from '../../shared/models/enums/index';
import { getBasicDetails, getAboTaxDetails, getAboAddresses, getAboBankDetails, getAboBankDetailsList, getAboBankDetailsSelectedBank } from '../../shared/helpers/personal-detail.helper';
import { AboTaxDetail } from '../../shared/models/abo-tax-detail.model';
import { AddressList } from '../../shared/models/party-basic.models';
import { BankDetail } from '../../shared/models/bank-detail.model';
import * as _ from 'lodash';
import { AboBankService } from '../../shared/services/abo-bank.service';
import { TaxPayerLoad } from '../../store/tax-payer/tax-payer.actions';
import { processAddresses } from '../../shared/helpers/common.helper';
import { AddressesGet } from '../../store/addresses/addresses.actions';
import { ErrorService } from '../../shared/services';
const ABO_ADDRESS_TYPES = [
  ContactPointPurposeCdEnum.Mailing
];
const ABO_TAX_ADDRESS_TYPES = [
  ContactPointPurposeCdEnum.Tax
];
@Component({
  selector: 'app-abo-personal-details',
  templateUrl: './abo-personal-details.component.html',
  styleUrls: ['./abo-personal-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboPersonalDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('basicInfoModal') basicInfoModal: Modal;
  @ViewChild('addressesModal') addressesModal: Modal;
  @ViewChild('bankInfoModal') bankInfoModal: Modal;
  @ViewChild('taxInfoModal') taxInfoModal: Modal;
  @Input('account') account$: Observable<AccountDetail>;
  @Input() accountDetails;
  loading$: Observable<boolean>;
  accountFullDetail: Account;
  store$: Observable<PersonalDetails>;
  aboPersonalDetails$: Observable<PersonalDetails>;
  aboBasicInfo: ABOBasicInfo;
  aboTaxDetails: AboTaxDetail;
  aboAddresses: AddressList[];
  aboBankDetails: BankDetail;
  aboBankDetailsList: BankDetail[];
  saving$: Observable<boolean>;
  addresses: AddressList[];
  sub: Subscription;
  taxPayer$: Observable<any>;
  taxPayerData: TaxPayerDetail;
  taxloading$: Observable<boolean>;
  addressBookList;
  addressLoading: boolean = true;
  newAddress: AddressList;
  hideEditAddressBtn: boolean = false;
  showAddBankBtn: boolean;
  infoServerErrors = []
  bankServerErrors = []
  taxServerErrors = []
  constructor(
    private store: Store<IAppState>,
    private activatedRoute: ActivatedRoute,
    private errorService: ErrorService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit() {
    const params = this.activatedRoute.snapshot.params;
    this.store.dispatch(new AboDetailsLoad(params));
    this.store$ = this.store.select('aboPersonalDetails').pipe(filter(data => data.loaded));

    let accountDetail = this.accountDetails
    this.accountFullDetail = accountDetail.data;
    this.aboBasicInfo = _.cloneDeep(getBasicDetails(accountDetail));
    this.aboTaxDetails = _.cloneDeep(getAboTaxDetails(accountDetail, ABO_TAX_ADDRESS_TYPES));
    this.aboAddresses = _.cloneDeep(getAboAddresses(accountDetail, ABO_ADDRESS_TYPES));
    this.addresses = _.cloneDeep(this.aboAddresses);
    this.aboBankDetailsList = _.cloneDeep(getAboBankDetailsList(accountDetail));

    this.bankInformation()
    if (this.accountFullDetail.accountMst.lglEntityType == "Company Limited") {
      this.hideEditAddressBtn = true
    }

    let address$ = this.store.select('addresses')
    address$.pipe(filter(data => data.loaded)).subscribe(res => {
      if (res.data && res.data.addressBookList) {
        let addresses = []
        for (let i = 0; i < res.data.addressBookList.length; i++) {
          for (let y = 0; y < res.data.addressBookList[i].addressList.length; y++) {
            {
              let addressList = { ...res.data.addressBookList[i].addressList[y], addressBookId: res.data.addressBookList[i].addressBookId, contactId: '' }
              addresses.push(addressList)
            }
          }
        }
        addresses.sort(function (x, y) { return x.usageList[0].primaryFlag == true ? -1 : y == true ? 1 : 0; });
        this.aboAddresses.push(...addresses)
        this.addressLoading = false
      }
    })

    this.sub = this.store$.pipe(filter(data => data.saved)).subscribe(state => {
      this.store.dispatch(new AccountGet(params));
      this.store.dispatch(new AboDetailsLoad(params));
      this.store.dispatch(new TaxPayerLoad(params));
      this.store.dispatch(new AddressesGet(params));
      this.basicInfoModal.close();
      this.bankInfoModal.close();
      this.taxInfoModal.close();
    });

    // fetching taxpayer information
    this.store.dispatch(new TaxPayerLoad(params));
    this.taxPayer$ = this.store.select('taxPayer');
    this.taxloading$ = this.taxPayer$.pipe(map(data => !data.loaded));
    this.taxPayer$.pipe(filter(({ loaded }) => loaded)).subscribe((data: TaxPayer) => {
      this.taxPayerData = data.taxPayerDetail;
    })

    this.saving$ = this.store
      .select('aboPersonalDetails').pipe(
        filter(data => data['ADDRESS_SAVE'])
        , map(data => data['ADDRESS_SAVE'].loader));

    this.handlePersonalDetailsError()

    this.newAddress = new AddressList()
    this.newAddress.usageList = [{ primaryFlag: false, contactPointPurposeCd: 'Shipping' }]
  }

  handlePersonalDetailsError() {
    this.store
      .select('aboPersonalDetails')
      .subscribe(data => {
        if (data.info && data.error && data.info.loader == null) {
          this.processErrorOnUi(data.error, 'infoServerErrors')
        }

        if (data.error && data.BANK_SAVE && data.BANK_SAVE.loader == null) {
          this.processErrorOnUi(data.error, 'bankServerErrors')
        }

        if (data.error && data.TAX_SAVE && data.TAX_SAVE.loader == null) {
          this.processErrorOnUi(data.error, 'taxServerErrors')
        }
      });
  }

  processErrorOnUi(error, serverErrors) {
    this.removeServerErrors(serverErrors);
    const errorMessage = this.errorService.processError(error);
    this[serverErrors].push(errorMessage)
    this.cdr.markForCheck();
  }

  loadPersonalDetails() {
    this.store.dispatch(
      new AboDetailsLoad(this.activatedRoute.snapshot.params)
    );
  }

  updateBasicInfo(info: ABOBasicInfoUpdatePayload) {
    this.store.dispatch(
      new AboDetailsSave({
        type: 'info',
        routeParams: this.activatedRoute.snapshot.params,
        iboDetails: info.data,
        initialData: info.initialData
      })
    );
  }

  updateAddress(
    addressData: AddressEventData,
    taxSelected: boolean = false
  ): void {
    this.store.dispatch(
      new AboAddressChange({
        routeParams: this.activatedRoute.snapshot.params,
        editedAddress: addressData.updatedData,
        unchangedAddress: addressData.initialData,
        forceSave: taxSelected,
        type: 'ADDRESS_SAVE'
      })
    );
  }
  updateBankDetails(data) {
    this.store.dispatch(
      new AboBankDetailChange({
        routeParams: this.activatedRoute.snapshot.params,
        currentDetails: data.current,
        initialDetails: data.initialData,
        type: 'BANK_SAVE'
      })
    );
  }
  updateTaxDetails(payload: AboTaxDetail) {
    this.store.dispatch(
      new AboTaxChangeOnly({
        routeParams: this.activatedRoute.snapshot.params,
        currentDetails: payload,
        initialDetails: this.aboTaxDetails,
        type: 'TAX_SAVE'
      })
    );
  }
  deleteAddress(payload) {
    this.store.dispatch(
      new AboAddressDelete({
        routeParams: this.activatedRoute.snapshot.params,
        addressBookId: payload.addressBookId,
        type: 'ADDRESS_DELETE'
      })
    );
  }
  openBankInfoModel(id) {
    let selectedBank = _.filter(this.accountFullDetail.bankAccountDetailList, function (bank) { return bank.bankAcctId == id })[0]
    this.aboBankDetails = _.cloneDeep(getAboBankDetailsSelectedBank(selectedBank));
    this.bankInfoModal.open();
  }
  openBankInfoModal() {
    this.aboBankDetails = new BankDetail
    this.bankInfoModal.open();
  }

  bankInformation() {
    let bonusBanks = _.filter(this.aboBankDetailsList, function (bank) { return bank.acctUsageList[0].acctUseCode == "BonusPayments" })
    if (bonusBanks.length == 0) {
      this.showAddBankBtn = true
    } else {
      this.showAddBankBtn = false
    }

  }
  removeServerErrors(serverErrors) {
    this[serverErrors] = []
    this.cdr.markForCheck();
  }

  isValidAcctType(): boolean {
    let hideAccountDetails: boolean = true;
    if (this.aboBankDetailsList && this.aboBankDetailsList.length === 1) {
      if (this.aboBankDetailsList[0].bankAcctTypeCd === 'CreditCard') {
        hideAccountDetails = false;
      }
    }
    return hideAccountDetails;
  }


  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
