import { Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Effect, Actions } from '@ngrx/effects';
import { CONTRACT_GET, ContractGet, ContractGetSuccess, ContractGetFail } from './abo-contracts.actions';
import { ContractsService } from '../../shared/services/index';

@Injectable()
export class AboContractsEffects {

  constructor(
    private action$: Actions,
    private contractService: ContractsService
  ) {
  }

  @Effect()
  contractsGet$ = this.action$
    .ofType(CONTRACT_GET).pipe(
    switchMap((action: ContractGet) => {
      return this.contractService.getContractDetailList(action.payload)
        .pipe(map((response) => {
          return new ContractGetSuccess(response);
        })
        ,catchError(error => of(new ContractGetFail())));
    }));
}
