import { Injectable } from '@angular/core';
import { HttpService } from '../../core/services/http.service';
import { ApiPathConfigService } from '../configs/api';
import {
  AccountUpdateName,
  AccountUpdateParties,
  AccountUpdateEcomms,
  AccountUpdateTax,
  Tax,
  AccountUpdatePhone,
  PhoneToUpdate,
  APICallData,
  ABOBasicInfo,
  PersonalId
} from '../models/amway/index';
import { AccountSearchParam } from '../models/index';
import {
  salesAff,
  aboNum,
  partyId,
  ecommContactId,
  taxType,
  contactId,
  DEFAULT_MOBILE_OPTION,
  personalIdTypeCd,
  CITIZEN_ID_TYPE,
  contractId,
  updateAccountNameMultiLangAPI,
  updateAccountPartiesAPI,
  updateAccountEmailAPI,
  createAccountPhoneAPI,
  updateAccountPhoneAPI,
  updateAccountTaxAPI,
  taxPayerInformationAPI,
  createAccountEmailAPI,
  updatePersonalIdAPI,
  createPersonalIdAPI,
  createAccountTaxAPI,
  updateDocumentAPI
} from './../configs/constants';
import { Observable, of, forkJoin } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { ErrorService } from './error.service';
import { CommonService } from '../../core/services/common.service';
import { TaxService } from './tax.service';
import { ContactPointTypeCdEnum } from '../models/enums/contact-point-type.enum';

import * as _ from 'lodash';
import { observeOn } from 'rxjs/operators';
import { ErrorLogService } from '../../core/services/error-log.service';
import { AboTaxDetail } from '../models/abo-tax-detail.model';
import { AboTaxDetailChangePayload } from '../models/abo-personal-details.model';
import { resolveEffectSource } from '@ngrx/effects/src/effects_resolver';
import { AddressService } from './address.service';
import { TaxCertificateService } from './tax-certificate.service';
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { RegistrationsService } from './registrations.service';

const UPDATE_TAX_ID = 'UPDATE_TAX_ID';
const UPDATE_TAX_ID_TYPE = 'UPDATE_TAX_ID_TYPE';
const CREATE_NEW_TAX = 'CREATE_NEW_TAX';

@Injectable()
export class UpdateDetailsService {
  constructor(
    private httpService: HttpService,
    private errorService: ErrorService,
    private errorLogService: ErrorLogService,
    private commonSerice: CommonService,
    private taxService: TaxService,
    private addressService: AddressService,
    private taxCertService: TaxCertificateService,
    private registrationsService: RegistrationsService,
    private apiPathService: ApiPathConfigService
  ) {}

  updateAccount({ routeParams, type, iboDetails, initialData }) {
    const ACCOUNT_FIELDS = {
      party: ['birthdate', 'languageCd', 'partyTypeCd', 'maritalStatusCd'],
      ecomms: ['email', 'ecommContactId'],
      phone: ['mobilePhoneInfo'],
      alternatePhone: ['alternatePhoneInfo'],
      tax: ['tax'],
      names: ['givenName', 'familyName', 'preferredName', 'languageCd', 'title']
    };
    return forkJoin(
      this.saveAccountNames(iboDetails, routeParams, initialData),
      this.saveAccountParties(iboDetails, routeParams, initialData),
      iboDetails['passportNumber']
        ? this.registrationsService.updatePidInformation(routeParams.aff, routeParams.abo, routeParams.partyId, iboDetails['passportNumber'])
        : of(''),
        this.saveAccountEcomms(iboDetails, routeParams, initialData),
      iboDetails['mobilePhoneInfo']
        ? this.saveAccountPhones(iboDetails, routeParams, initialData)
        : of(''),
      iboDetails['iCrdNum'] && initialData.iCrdNum != iboDetails.iCrdNum
        ? this.registrationsService.updateCidInformation(routeParams.aff, routeParams.abo, routeParams.partyId, iboDetails['iCrdNum'])
        : of(''),
      iboDetails['alternatePhoneInfo']
        ? this.saveAccountAlternatePhones(iboDetails, routeParams, initialData)
        : of(''),
      this.savePersonalIdDetails(iboDetails, routeParams, initialData)
    ).pipe(switchMap(
      result => {
        result = [].concat.apply([], result);
        const errors = result.filter(item => this.errorService.isError(item));
        return errors.length ? this.logError(errors) : of(result);
      },
      error => {
        Observable.throw(error);
      }
    ));
  }

  private logError(errors: any) {
    if (_.isArray(errors)) {
      errors.map(error => {
        const message = this.errorService.processError(error);
        this.errorLogService.log(message);
      });
    }

    return Observable.throw(errors);
  }

  saveAccountNames(
    update: AccountUpdateName,
    req: AccountSearchParam,
    initialData: AccountUpdateName
  ) {
    const propertiesToSave = ['givenName', 'familyName', 'languageCd', 'title', 'l_givenName', 'l_familyName'];
    const updatedValues = _.pick(update, ...propertiesToSave);
    const initailDetails = _.pick(initialData, ...propertiesToSave);
    if (_.isEqual(updatedValues, initailDetails)) {
      return of('');
    }
    const { givenName, familyName, languageCd, title, l_givenName, l_familyName } = update;
    const api = this.apiPathService.getApiPath(updateAccountNameMultiLangAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId);
    const body = {
      nameList: [{
        localeName: { givenName, familyName, title },
        latinName: { givenName: l_givenName, familyName: l_familyName, title: title },
        languageCd: languageCd,
        partyId: req.partyId
      }]
    };
    return this.httpService
      .callServerPUT(api, null, body)
      .pipe(map(response => {
        return response;
      })
      ,catchError(error => {
        this.logError(error);
        return Observable.throw(error);
      }));
  }

  saveAccountParties(
    update: AccountUpdateParties,
    req: AccountSearchParam,
    initialData: AccountUpdateParties
  ) {
    const propertiesToSave = [
      'birthdate',
      'genderCd',
      'partyTypeCd',
      'languageCd',
      'czshpCntryCd',
      'maritalStatusCd'
    ];
    const updatedValues = _.pick(update, ...propertiesToSave);
    const initailDetails = _.pick(initialData, ...propertiesToSave);
    if (_.isEqual(updatedValues, initailDetails)) {
      return of('');
    }
    const {
      birthdate,
      genderCd,
      partyTypeCd,
      languageCd,
      czshpCntryCd,
      maritalStatusCd
    } = update;
    const api = this.apiPathService.getApiPath(updateAccountPartiesAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId);
    const body = {
      birthDate: this.commonSerice.getBodyFormattedDate(birthdate),
      genderCd: genderCd,
      partyTypeCd: partyTypeCd,
      languageCd: languageCd,
      maritalStatusCd: maritalStatusCd,
      czshpCntryCd: czshpCntryCd
    };
    return this.httpService
      .callServerPUT(api, null, body)
      .pipe(map(response => {
        return response;
      })
      ,catchError(error => {
        this.logError(error);
        return Observable.throw(error);
      }));
  }

  saveAccountEcomms(
    update: AccountUpdateEcomms,
    req: AccountSearchParam,
    initialData: AccountUpdateEcomms
  ) {
    const propertiesToSave = ['ecommContactId', 'email'];
    const updatedValues = _.pick(update, ...propertiesToSave);
    const initailDetails = _.pick(initialData, ...propertiesToSave);
    if (_.isEqual(updatedValues, initailDetails)) {
      return of('');
    }
    const { ecommContactId, email } = update;
    const body = {
      ecomm: {
        contactId: ecommContactId || '',
        ecommAddr: email,
        usageList: [
          {
            contactPointPurposeCd: 'GeneralPurpose',
            primaryFlag: true
          }
        ],
        contactPointTypeCd: 'BusinessEmail',
        ecommTypeCd: 'Email',
        ecommTypeName: 'Email Service'
      }
    };

    if (ecommContactId && email) {
      return this.updateAccountEmail(body, req, ecommContactId);
    } else if (email) {
      return this.createAccountEmail(body, req);
    } else if (ecommContactId && email == '') {
      return this.deleteAccountEmail(body, req, ecommContactId);
    } else {
      return of('');
    }
  }

  deleteAccountEmail(body, req, eContanctId){
    const api = this.apiPathService.getApiPath(updateAccountEmailAPI);
    api.path = api.path
    .replace(salesAff, req.aff)
    .replace(aboNum, req.abo)
    .replace(partyId, req.partyId)
    .replace(ecommContactId, eContanctId);
  return this.httpService
    .callServerDELETE(api)
    .pipe(map(response => {
      return response;
    })
    ,catchError(error => {
      this.logError(error);
      return Observable.throw(error);
    }));
  }

  saveAccountTaxes(
    { tax }: AboTaxDetail,
    req: AccountSearchParam,
    initialData: AboTaxDetail
  ) {
    const propertiesToSave = [
      'taxTypeCd',
      'expirationDate',
      'taxId',
      'cntryCd',
      'partyId',
      'effectiveDate',
      'taxPayerType',
      'vatRegister'
    ];
    const updatedValues = _.pick(tax, ...propertiesToSave);
    const initailDetails = _.pick(initialData.tax, ...propertiesToSave);
    if (_.isEqual(updatedValues, initailDetails)) {
      return of('');
    }
    if (!tax || !tax.taxId) {
      return of('');
    }

    const originalTaxTypeCd = initialData.tax ? initialData.tax.taxTypeCd : '';

    const action = this.taxService.getAction(originalTaxTypeCd, tax.taxTypeCd);

    switch (action) {
      case UPDATE_TAX_ID:
        return this.updateTaxPayer(tax, req);

      case CREATE_NEW_TAX:
        return this.createTaxId(tax, req);

      case UPDATE_TAX_ID_TYPE:
        return this.updateTaxIdType(tax, req);

      default:
        return of('');
    }
  }

  saveAccountTaxPayer(
    { tax }: AboTaxDetail,
    req: AccountSearchParam,
    initialData: AboTaxDetail
  ) {
    const propertiesToSave = [
      'taxTypeCd',
      'expirationDate',
      'taxId',
      'cntryCd',
      'partyId',
      'effectiveDate',
      'taxPayerType',
      'vatRegister'
    ];
    const updatedValues = _.pick(tax, ...propertiesToSave);
    const initailDetails = _.pick(initialData.tax, ...propertiesToSave);
    if (_.isEqual(updatedValues, initailDetails)) {
      return of('');
    }
    if (!tax || !tax.taxId) {
      return of('');
    }
    return this.updateTaxPayer(tax, req);
  }

  saveAccountPhones(
    update: AccountUpdatePhone,
    req: AccountSearchParam,
    initialData: AccountUpdatePhone
  ) {
    const contactInfo: AccountUpdatePhone = {
      mobilePhone: null,
      alternatePhone: null,
      mobilePhoneInfo: null
    };
    if (
      !_.isEqual(
        update.mobilePhoneInfo.phoneLocalNum,
        initialData.mobilePhoneInfo.phoneLocalNum
      )
    ) {
      contactInfo.mobilePhoneInfo = this.completePhone(
        update.mobilePhoneInfo,
        ContactPointTypeCdEnum.Mobile,
        'Mobile'
      );
    }
    if (!contactInfo.mobilePhoneInfo) {
      return of('');
    } else {
      // const contactObservable = new Observable(observer => {
      return forkJoin(
        ...[contactInfo.mobilePhoneInfo].filter(phone => phone).map(phone => {
          if (!this.isPhoneDeletable(phone) && phone.contactId && phone.phoneLocalNum != "") {
            return this.updateAccountPhone(req, phone);
          } else if (!this.isPhoneDeletable(phone) && phone.contactId && phone.phoneLocalNum == "") {
            return this.deleteAccountPhone(req, phone);
          } else if (!this.isPhoneDeletable(phone) && !phone.contactId) {
            return this.createAccountPhone(req, phone);
          } else {
            return this.deleteAccountPhone(req, phone);
          }
        })
      );
    }
  }

  saveAccountAlternatePhones(
    update: AccountUpdatePhone,
    req: AccountSearchParam,
    initialData: AccountUpdatePhone
  ) {
    const contactInfo: AccountUpdatePhone = {
      mobilePhone: null,
      alternatePhone: null,
      alternatePhoneInfo: null
    };
    if (
      !_.isEqual(
        update.alternatePhoneInfo.phoneLocalNum,
        initialData.alternatePhoneInfo.phoneLocalNum
      )
    ) {
      contactInfo.alternatePhoneInfo = this.completePhone(
        update.alternatePhoneInfo,
        ContactPointTypeCdEnum.AlternatePhone,
        'Alternate'
      );
    }
    if (!contactInfo.alternatePhoneInfo) {
      return of('');
    } else {
      return forkJoin(
        ...[contactInfo.alternatePhoneInfo].filter(phone => phone).map(phone => {
          if (!this.isPhoneDeletable(phone) && phone.contactId && phone.phoneLocalNum != "") {
            return this.updateAccountPhone(req, phone);
          } else if (!this.isPhoneDeletable(phone) && !phone.contactId && phone.phoneLocalNum == "") {
            return this.deleteAccountPhone(req, phone);
          } else if (!this.isPhoneDeletable(phone) && !phone.contactId) {
            return this.createAccountPhone(req, phone);
          } else {
            return this.deleteAccountPhone(req, phone);
          }
        })
      );
    }
  }

  createAccountPhone(req: AccountSearchParam, phone: PhoneToUpdate) {
    const api = this.apiPathService.getApiPath(createAccountPhoneAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId);
    const body = {
      phone,
      applyToAllLinkedAccountFlag: true
    };

    return this.httpService
      .callServerPOST(api, null, body)
      .pipe(map(response => {
        return response;
      })
      ,catchError(error => {
        this.logError(error);
        return Observable.throw(error);
      }));
  }

  updateAccountPhone(req: AccountSearchParam, phone: PhoneToUpdate) {
    const api = this.apiPathService.getApiPath(updateAccountPhoneAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId)
      .replace(contactId, phone.contactId);
    const body = {
      phone,
      applyToAllLinkedAccountFlag: true
    };

    return this.httpService
      .callServerPUT(api, null, body)
      .pipe(map(response => {
        return response;
      })
      ,catchError(error => {
        this.logError(error);
        return Observable.throw(error);
      }));
  }

  deleteAccountPhone(req: AccountSearchParam, phone: PhoneToUpdate) {
    const api = this.apiPathService.getApiPath(updateAccountPhoneAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId)
      .replace(contactId, phone.contactId);

    return this.httpService
      .callServerDELETE(api)
      .pipe(map(response => {
        return response;
      })
      ,catchError(error => {
        this.logError(error);
        return Observable.throw(error);
      }));
  }

  updateTaxId(tax: Tax, req: AccountSearchParam) {
    const { taxTypeCd, cntryCd, taxId, expirationDate } = tax;
    const api = this.apiPathService.getApiPath(updateAccountTaxAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId)
      .replace(taxType, taxTypeCd);
    const body = {
      tax: {
        cntryCd,
        taxId,
        expirationDate: expirationDate || ''
      }
    };

    return this.httpService
      .callServerPUT(api, null, body)
      .pipe(map(response => {
        return response;
      })
      ,catchError(error => {
        this.logError(error);
        return Observable.throw(error);
      }));
  }

  updateTaxPayer(tax: Tax, req: AccountSearchParam) {
    const { vatRegister, taxPayerType, effectiveDate } = tax;
    const api = this.apiPathService.getApiPath(taxPayerInformationAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)

    const month = (moment(effectiveDate).get('month') + 1).toString();
    const year = moment(effectiveDate).get('year').toString();

    let urlParams = new HttpParams();
    urlParams = urlParams.set('vatRegister', vatRegister);
    urlParams = urlParams.set('taxPayerType', taxPayerType);
    urlParams = urlParams.set('month', month);
    urlParams = urlParams.set('year', year);

    return this.httpService
      .callServerPUT(api, urlParams, null)
      .pipe(map(response => {
        return response;
      })
      ,catchError(error => {
        this.logError(error);
        return Observable.throw(error);
      }));
  }

  createTaxId(tax: Tax, req: AccountSearchParam) {
    const { cntryCd, taxTypeCd, taxId, expirationDate } = tax;
    const api = this.apiPathService.getApiPath(createAccountTaxAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId);

    const body = {
      taxList: [
        {
          cntryCd,
          taxTypeCd,
          taxId,
          expirationDate: expirationDate || ''
        }
      ]
    };

    return this.httpService
      .callServerPOST(api, null, body)
      .pipe(map(response => {
        return response;
      })
      ,catchError(error => {
        this.logError(error);
        return Observable.throw(error);
      }));
  }

  deleteTaxId(tax: Tax, req: AccountSearchParam) {
    const { taxTypeCd, cntryCd, taxId, expirationDate } = tax;
    const api = this.apiPathService.getApiPath(updateAccountTaxAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId)
      .replace(taxType, taxTypeCd);
    const body = {
      tax: {
        cntryCd,
        taxId,
        expirationDate: expirationDate || ''
      }
    };

    return this.httpService
      .callServerDELETE(api)
      .pipe(map(response => {
        return response;
      })
      ,catchError(error => {
        this.logError(error);
        return Observable.throw(error);
      }));
  }

  updateTaxIdType(tax: Tax, req: AccountSearchParam) {
    return of(this.createTaxId(tax, req))
      .pipe(map(() => this.deleteTaxId(tax, req))
      ,catchError(error => {
        this.logError(error);
        return Observable.throw(error);
      }));
  }

  updateAccountEmail(body, req: AccountSearchParam, eContanctId: string) {
    const api = this.apiPathService.getApiPath(updateAccountEmailAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId)
      .replace(ecommContactId, eContanctId);
    return this.httpService
      .callServerPUT(api, null, body)
      .pipe(map(response => {
        return response;
      })
      ,catchError(error => {
        this.logError(error);
        return Observable.throw(error);
      }));
  }

  createAccountEmail(body, req: AccountSearchParam) {
    const api = this.apiPathService.getApiPath(createAccountEmailAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId);
    return this.httpService
      .callServerPOST(api, null, body)
      .pipe(map(response => {
        return response;
      })
      ,catchError(error => {
        this.logError(error);
        return Observable.throw(error);
      }));
  }

  completePhone(
    phone: PhoneToUpdate,
    contactPointTypeCd: ContactPointTypeCdEnum,
    phoneType?: string
  ) {
    if (!phone) {
      return phone;
    }

    const isPrimary = phoneType != 'Alternate'

    return Object.assign(
      {},
      {
        contactId: phone.contactId,
        contactPointTypeCd,
        cntryCd: phone.cntryCd,
        phoneCntryCd: phone.phoneCntryCd
          ? phone.phoneCntryCd.toString()
          : DEFAULT_MOBILE_OPTION.text,
        phoneLocalNum: phone.phoneLocalNum,
        phoneAreaCd: phone.phoneAreaCd,
        usageList: [
          {
            contactPointPurposeCd: 'GeneralPurpose',
            primaryFlag: isPrimary
          }
        ]
      }
    );
  }

  isPhoneDeletable(phone: PhoneToUpdate) {
    return !phone.phoneCntryCd && !phone.phoneAreaCd && !phone.phoneLocalNum;
  }

  updateTaxDetails(request: AboTaxDetailChangePayload) {
    const taxupdateObservable = new Observable(observer => {
      forkJoin(
        this.saveAccountTaxes(
          request.currentDetails,
          request.routeParams,
          request.initialDetails
        ),
        this.addressService.updateAddress({
          editedAddress: request.currentDetails.taxAddress,
          unchangedAddress: request.initialDetails.taxAddress,
          routeParams: request.routeParams
        }),
        request.currentDetails.taxFile
          ? this.taxCertService.uploadTaxCertificate(
              request.routeParams,
              request.currentDetails.taxFile
            )
          : of('')
      ).subscribe(
        result => {
          observer.next(result);
        },
        error => {
          _.isArray(error) ? error.map(this.LogError) : this.LogError(error);
          observer.error(error);
        }
      );
    });
    return taxupdateObservable;
  }

  saveonlyTaxInformation(request: AboTaxDetailChangePayload){
    const taxupdateObservable = new Observable(observer => {
    this.saveAccountTaxPayer(request.currentDetails, request.routeParams, request.initialDetails)
      .subscribe(result => {
        observer.next(result);
        observer.complete();
      }, error => {
        observer.error(error);
        observer.complete();
      });
    });
    return taxupdateObservable;
  }

  updateAboDocuments(req: any) {
    const api = this.apiPathService.getApiPath(updateDocumentAPI);
    api.path = api.path.replace(salesAff, req.currentDetails.aff)
                              .replace(aboNum, req.currentDetails.abo)
    return this.httpService
      .callServerPOST(api, null, req.currentDetails.sendData)
        .pipe(map(result => {
          return result;
        }),
        catchError(error => {
          this.logError(error);
          return Observable.throw(error);
        }));
  }

  savePersonalIdDetails(
    update: ABOBasicInfo,
    req: AccountSearchParam,
    initialData: ABOBasicInfo
  ) {
    const propertiesToSave = ['personalId', 'personalIdTypeCd'];
    const updatedValues = _.pick(update.identityDetail, ...propertiesToSave);
    const initailDetails = _.pick(
      initialData.identityDetail,
      ...propertiesToSave
    );
    if (_.isEqual(updatedValues, initailDetails)) {
      return of('');
    }
    // save birthdate then on success save identity detail
    if (
      update.birthdate !== initialData.birthdate &&
      update.identityDetail.personalIdTypeCd === CITIZEN_ID_TYPE
    ) {
      return this.saveAccountParties(<any>update, req, <any>(
        initialData
      )).pipe(switchMap(data => {
        if (
          update.identityDetail.personalIdTypeCd ===
          initialData.identityDetail.personalIdTypeCd
        ) {
          const reqData = {
            personalId: {
              personalId: update.identityDetail.personalId,
              expirationDate: null,
              effectiveDate: null
            }
          };
          return this.updatePersonalDetails(
            req,
            reqData,
            update.identityDetail.personalIdTypeCd
          );
        } else {
          // create new identuty detail
          const reqData = {
            personalIdList: [
              {
                personalIdTypeCd: update.identityDetail.personalIdTypeCd,
                personalId: update.identityDetail.personalId,
                expirationDate: null,
                effectiveDate: null,
                countryPersonalId: update.czshpCntryCd
              }
            ]
          };
          return this.CreatePersonalDetails(req, reqData);
        }
      }));
    } else {
      // update detail
      if (
        update.identityDetail.personalIdTypeCd ===
        initialData.identityDetail.personalIdTypeCd
      ) {
        const reqData = {
          personalId: {
            personalId: update.identityDetail.personalId,
            expirationDate: null,
            effectiveDate: null
          }
        };
        return this.updatePersonalDetails(
          req,
          reqData,
          update.identityDetail.personalIdTypeCd
        );
      } else {
        // create new identuty detail
        const reqData = {
          personalIdList: [
            {
              personalIdTypeCd: update.identityDetail.personalIdTypeCd,
              personalId: update.identityDetail.personalId,
              expirationDate: null,
              effectiveDate: null,
              countryPersonalId: update.czshpCntryCd
            }
          ]
        };
        return this.CreatePersonalDetails(req, reqData);
      }
    }
  }

  

  private updatePersonalDetails(req, body, type) {
    const api = this.apiPathService.getApiPath(updatePersonalIdAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId)
      .replace(personalIdTypeCd, type);
    return this.httpService
      .callServerPUT(api, null, body)
      .pipe(map(response => {
        return response;
      })
      ,catchError(error => {
        this.logError(error);
        return Observable.throw(error);
      }));
  }
  private CreatePersonalDetails(req, body) {
    const api = this.apiPathService.getApiPath(createPersonalIdAPI);
    api.path = api.path
      .replace(salesAff, req.aff)
      .replace(aboNum, req.abo)
      .replace(partyId, req.partyId);
    return this.httpService
      .callServerPOST(api, null, body)
      .pipe(map(response => {
        return response;
      })
      ,catchError(error => {
        this.logError(error);
        return Observable.throw(error);
      }));
  }
  private LogError(error) {
    const errorMessage = this.errorService.processError(error);
    this.errorLogService.log(errorMessage);
  }
}
