import { Params } from '@angular/router';

export class History {
  breadcrumbs: RouteInfo[] = [];
  constructor({
    breadcrumbs = []
  }) {
    breadcrumbs.forEach((element) => {
      this.breadcrumbs.push(new RouteInfo(element));
    });
  }
}

export class RouteInfo {
  label: string;
  path: string;
  queryParams?: Params;
  url: string;
  parents?: RouteInfo[] = [];
  current?: boolean;
  constructor({
    label = '',
    path = '',
    queryParams = {},
    url = '',
    parents = [],
    current = false
  }) {
    this.label = label;
    this.path = path;
    this.queryParams = queryParams;
    this.url = url;
    parents.forEach((element) => {
      this.parents.push(new RouteInfo(element));
    });
    this.current = current;
  }
}
