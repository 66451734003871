import { CommonService } from '../../core/services/common.service';
import * as _ from 'lodash';
import { PartyMst, EcommList, NameList } from './amway';

const PRIMARY_ON_ACCOUNT = '1';
const APPLICANT = 'Applicant';
const COAPPLICANT = 'Co-applicant';

export class SearchParty {
  abo: string;
  aff: string;
  status: string;
  partyId: string;
  email: { contactId: string; ecommAddr: string };
  birthdate: string;
  familyName: string;
  givenName: string;
  preferredName: string;
  nameLanguageCd: string;
  preferredLanguage: string;
  languageCd: string;
  engagedName: string;
  primaryOnAccount: string;
  hasMissingInfo: boolean;
  partyTypeCd: string;
  roleCd: string;
  constructor({
    partyMst = <PartyMst>{},
    missingInfoList = [],
    ecommList = [<EcommList>{}],
    nameList = [<NameList>{}],
    abo = '',
    aff = '',
    status = ''
  }) {
    this.abo = abo;
    this.aff = aff;
    this.status = status;
    this.partyId = partyMst.partyId;
    this.primaryOnAccount = partyMst.primaryOnAccount;
    this.languageCd = partyMst.languageCd;
    this.birthdate = partyMst.birthdate;
    this.partyTypeCd = partyMst.partyTypeCd;
    this.hasMissingInfo = !!missingInfoList.length;
    this.preferredName = nameList[0].preferredName || '';
    this.familyName = nameList[0].localeName.familyName || '';
    this.givenName = nameList[0].localeName.givenName || '';
    this.nameLanguageCd = nameList[0].languageCd;
    this.engagedName = this.familyName || '' + ' ' + this.givenName || '';
    this.email = CommonService.getActualData(ecommList, true);
    this.preferredLanguage = CommonService.getLanguageName(partyMst.languageCd);
    this.roleCd = partyMst.roleCd;
  }

  private checkPrimaryOnAccount(poa): string {
    if (poa && poa === '1') {
      return APPLICANT;
    } else if (poa && poa === '0') {
      return COAPPLICANT;
    } else {
      return '';
    }
  }
}
export interface PartyParams {
  abo: string;
  aff: string;
  partyId: string | number;
}
