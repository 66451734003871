export const ROLES_MAP = {
  'LDAP-AMW-BOSS-Inquiry-Only': {
    'iboBasicInfo': {
      'preferredName': {
        'view': true,
        'edit': false
      },
      'legalName': {
        'view': true,
        'edit': false
      },
      'preferredLanguage': {
        'view': true,
        'edit': false
      },
      'email': {
        'view': true,
        'edit': false
      },
      'dateOfBirth': {
        'view': true,
        'edit': false
      },
      'taxIdType': {
        'view': true,
        'edit': false
      },
      'taxId': {
        'view': true,
        'edit': false
      },
      'mobilePhone': {
        'view': true,
        'edit': false
      },
      'alternatePhone': {
        'view': true,
        'edit': false
      }
    },
    'iboAddress': {
      'addressName': {
        'view': true,
        'edit': false
      },
      'addressLine': {
        'view': true,
        'edit': false
      },
      'addressLineTwo': {
        'view': true,
        'edit': false
      },
      'city': {
        'view': true,
        'edit': false
      },
      'state': {
        'view': true,
        'edit': false
      },
      'zipCode': {
        'view': true,
        'edit': false
      }
    },
    'businessDetails': {
      'legalName': {
        'view': true,
        'edit': false
      },
      'country': {
        'view': true,
        'edit': false
      },
      'sponsorNumber': {
        'view': true,
        'edit': false
      },
      'platinumNumber': {
        'view': true,
        'edit': false
      },
      'diamondNumber': {
        'view': true,
        'edit': false
      },
      'internationalNumber': {
        'view': true,
        'edit': false
      },
      'usersLos': {
        'view': true,
        'edit': true
      },
      'nationalAccounts': {
        'view': true,
        'edit': false
      },
      'balance': {
        'view': true,
        'edit': false
      },
      'taxWithholding': {
        'view': true,
        'edit': false
      },
      'spa': {
        'view': true,
        'edit': false
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': false
      },
      'passwordChangeDate': {
        'view': true,
        'edit': false
      },
      'businessNature': {
        'view': true,
        'edit': false
      },
      'reRegistration': {
        'view': true,
        'edit': false
      }
    },
    'restrictionBlocks': {
      'BlockLogin': {
        'view': false,
        'edit': false
      },
      'BlockPVBVXferInit': {
        'view': true,
        'edit': false
      },
      'BlockPVBVXferRcv': {
        'view': true,
        'edit': false
      },
      'BlockAdvBackOrder': {
        'view': true,
        'edit': false
      },
      'BlockCreditCard': {
        'view': true,
        'edit': false
      },
      'BlockPaypal': {
        'view': true,
        'edit': false
      },
      'BlockOrdering': {
        'view': true,
        'edit': false
      },
      'AllowPVBVXferRcv': {
        'view': true,
        'edit': false
      },
      'BlockResignation': {
        'view': true,
        'edit': false
      },
      'BlockRenewal': {
        'view': true,
        'edit': false
      },
      'BlockLeadsABO': {
        'view': true,
        'edit': false
      },
      'BlockLeadsCustomer': {
        'view': true,
        'edit': false
      },
      'BlockLateRenewWGrp': {
        'view': true,
        'edit': false
      },
      'BlockRenewWoGrp': {
        'view': true,
        'edit': false
      },
      'BlockSponsoring': {
        'view': true,
        'edit': false
      },
      '215': {
        'view': true,
        'edit': false
      },
      'BlkLOSCommunications': {
        'view': true,
        'edit': false
      },
      'PrivacyBlock': {
        'view': true,
        'edit': false
      },
      'AllowInfoShareCustLe': {
        'view': true,
        'edit': false
      },
      'BlockProfileChanges': {
        'view': true,
        'edit': false
      },
      'BlockLeadsSponsor': {
        'view': true,
        'edit': false
      },
      'BlockBonus': {
        'view': true,
        'edit': true
      }
    }
  },
  'LDAP-AMW-BOSS-General-Edit': {
    'iboBasicInfo': {
      'preferredName': {
        'view': true,
        'edit': true
      },
      'preferredLanguage': {
        'view': true,
        'edit': true
      },
      'email': {
        'view': true,
        'edit': true
      },
      'dateOfBirth': {
        'view': true,
        'edit': true
      },
      'mobilePhone': {
        'view': true,
        'edit': true
      },
      'alternatePhone': {
        'view': true,
        'edit': true
      },
      'firstName': {
        'view': true,
        'edit': true
      },
      'lastName': {
        'view': true,
        'edit': true
      }
    },
    'iboAddress': {
      'addressName': {
        'view': true,
        'edit': true
      },
      'addressLine': {
        'view': true,
        'edit': true
      },
      'addressLineTwo': {
        'view': true,
        'edit': true
      },
      'city': {
        'view': true,
        'edit': true
      },
      'state': {
        'view': true,
        'edit': true
      },
      'zipCode': {
        'view': true,
        'edit': true
      },
      'careOf': {
        'view': true,
        'edit': true
      }
    },
    'businessDetails': {},
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': false
      },
      'unenrollIboFromLeads': {
        'view': true,
        'edit': true
      },
      'reRegistration': {
        'view': true,
        'edit': true
      },
      'refundRenewal': {
        'view': true,
        'edit': true
      },
      'deactivate': {
        'view': true,
        'edit': false
      }
    },
    'restrictionBlocks': {
      'BlkLOSCommunications': {
        'view': true,
        'edit': true
      },
      'PrivacyBlock': {
        'view': true,
        'edit': true
      },
      'AllowInfoShareCustLe': {
        'view': true,
        'edit': true
      }
    }
  },
  'LDAP-AMW-BOSS-BCR-User': {
    'iboBasicInfo': {
      'preferredName': {
        'view': true,
        'edit': false
      },
      'legalName': {
        'view': true,
        'edit': false
      },
      'preferredLanguage': {
        'view': true,
        'edit': false
      },
      'email': {
        'view': true,
        'edit': false
      },
      'dateOfBirth': {
        'view': true,
        'edit': false
      },
      'taxIdType': {
        'view': true,
        'edit': false
      },
      'taxId': {
        'view': true,
        'edit': true
      },
      'mobilePhone': {
        'view': true,
        'edit': false
      },
      'alternatePhone': {
        'view': true,
        'edit': false
      },
      'firstName': {
        'view': true,
        'edit': true
      },
      'lastName': {
        'view': true,
        'edit': true
      }
    },
    'iboAddress': {
      'addressName': {
        'view': true,
        'edit': false
      },
      'addressLine': {
        'view': true,
        'edit': false
      },
      'addressLineTwo': {
        'view': true,
        'edit': false
      },
      'city': {
        'view': true,
        'edit': false
      },
      'state': {
        'view': true,
        'edit': false
      },
      'zipCode': {
        'view': true,
        'edit': false
      },
      'careOf': {
        'view': true,
        'edit': false
      }
    },
    'businessDetails': {
      'legalName': {
        'view': true,
        'edit': true
      },
      'country': {
        'view': true,
        'edit': true
      },
      'sponsorNumber': {
        'view': true,
        'edit': true
      },
      'platinumNumber': {
        'view': true,
        'edit': true
      },
      'diamondNumber': {
        'view': true,
        'edit': true
      },
      'internationalNumber': {
        'view': true,
        'edit': true
      },
      'usersLos': {
        'view': true,
        'edit': true
      },
      'nationalAccounts': {
        'view': true,
        'edit': false
      },
      'balance': {
        'view': true,
        'edit': false
      },
      'taxWithholding': {
        'view': true,
        'edit': false
      },
      'spa': {
        'view': true,
        'edit': true
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': false
      },
      'businessNature': {
        'view': true,
        'edit': true
      },
      'reRegistration': {
        'view': true,
        'edit': false
      },
      'deactivate': {
        'view': true,
        'edit': true
      },
      'passwordChangeDate': {
        'view': true,
        'edit': false
      },
      'unenrollIboFromLeads': {
        'view': true,
        'edit': false
      }
    },
    'restrictionBlocks': {
      'BlockAdvBackOrder': {
        'view': true,
        'edit': true
      },
      'BlockPVBVXferInit': {
        'view': true,
        'edit': true
      },
      'BlockPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockCreditCard': {
        'view': true,
        'edit': false
      },
      'BlockPaypal': {
        'view': true,
        'edit': false
      },
      'BlockLogin': {
        'view': false,
        'edit': true
      },
      'BlockOrdering': {
        'view': true,
        'edit': true
      },
      'BlockReturns': {
        'view': true,
        'edit': true
      },
      'AllowPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockResignation': {
        'view': true,
        'edit': true
      },
      'BlockRenewal': {
        'view': true,
        'edit': true
      },
      'BlockLeadsABO': {
        'view': true,
        'edit': true
      },
      'BlockLeadsCustomer': {
        'view': true,
        'edit': true
      },
      'BlockLateRenewWGrp': {
        'view': true,
        'edit': true
      },
      'BlockRenewWoGrp': {
        'view': true,
        'edit': true
      },
      'BlockSponsoring': {
        'view': true,
        'edit': true
      },
      '215': {
        'view': true,
        'edit': true
      },
      'BlkLOSCommunications': {
        'view': true,
        'edit': false
      },
      'PrivacyBlock': {
        'view': true,
        'edit': false
      },
      'AllowInfoShareCustLe': {
        'view': true,
        'edit': false
      },
      'BlockProfileChanges': {
        'view': true,
        'edit': true
      },
      'BlockLeadsSponsor': {
        'view': true,
        'edit': true
      },
      'BlockBusinessNatrChg': {
        'view': true,
        'edit': true
      },
      'BlockAddApplicant': {
        'view': true,
        'edit': true
      },
      'BlockEmail': {
        'view': true,
        'edit': true
      },
      'BlockMessaging': {
        'view': true,
        'edit': true
      },
      'BlockAmwayCard': {
        'view': true,
        'edit': true
      }
    }
  },
  'LDAP-AMW-BOSS-ANA-Tax-User': {
    'iboBasicInfo': {
      'legalName': {
        'view': true,
        'edit': false
      },
      'email': {
        'view': true,
        'edit': false
      },
      'taxIdType': {
        'view': true,
        'edit': true
      },
      'taxId': {
        'view': true,
        'edit': true
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': false
      }
    }
  },
  'LDAP-AMW-BOSS-Misc-Actions-User': {
    'iboBasicInfo': {
      'legalName': {
        'view': true,
        'edit': false
      },
      'email': {
        'view': true,
        'edit': false
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': false
      }
    }
  },
  'LDAP-AMW-BOSS-SMX-Business-User': {
    'iboBasicInfo': {
      'legalName': {
        'view': true,
        'edit': false
      },
      'email': {
        'view': true,
        'edit': false
      }
    },
    'businessDetails': {
      'legalName': {
        'view': true,
        'edit': true
      },
      'spa': {
        'view': true,
        'edit': true
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': false
      }
    }
  },
  'LDAP-AMW-BOSS-NAR-User': {
    'iboBasicInfo': {
      'legalName': {
        'view': true,
        'edit': false
      },
      'email': {
        'view': true,
        'edit': false
      }
    },
    'businessDetails': {
      'nationalAccounts': {
        'view': true,
        'edit': true
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': false
      }
    }
  },
  'LDAP-AMW-BOSS-ANA-AR': {
    'iboBasicInfo': {
      'legalName': {
        'view': true,
        'edit': false
      },
      'email': {
        'view': true,
        'edit': false
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': false
      }
    },
    'restrictionBlocks': {
      'BlockCreditCard': {
        'view': true,
        'edit': true
      },
      'BlockARMOP': {
        'view': true,
        'edit': true
      },
      'BlockPaypal': {
        'view': true,
        'edit': true
      },
      'BlockBankDraft': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebit': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebitAccStatus': {
        'view': true,
        'edit': false
      }
    }
  },
  'LDAP-AMW-BOSS-BTSU-Orders-User': {
    'iboBasicInfo': {
      'legalName': {
        'view': true,
        'edit': false
      },
      'email': {
        'view': true,
        'edit': false
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': false
      }
    }
  },
  'LDAP-AMW-BOSS-ANA-Bonus': {
    'iboBasicInfo': {
      'legalName': {
        'view': true,
        'edit': false
      },
      'email': {
        'view': true,
        'edit': false
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': false
      }
    }
  },
  'BOSS-BUSINESSOWNER': {
    'BankAccountAddBtn': {
      'view': true
    },
    'BankAccountAccor': {
      'view': true
    },
    'MaritalStatus': {
      'view': true
    },
    'BusinessNatureAccor': {
      'view': true
    },
    'ViewLos': {
      'view': true
    },
    'AddCoapplicant': {
      'view': true
    },
    'RestrictionsAccor': {
      'view': true
    },
    'SubscriptionsAccor': {
      'view': true
    },
    'TnCAccor': {
      'view': true
    },
    'VouchersAccor': {
      'view': true
    },
    'SuccessorAccor': {
      'view': true
    },
    'TaxInfo': {
      'view': true
    },
    'BusinessDetailsAccor': {
      'view': true
    },
    'PersonalDetailAccor': {
      'view': true
    },
    'iboBasicInfo': {
      'preferredName': {
        'view': true,
        'edit': true
      },
      'legalName': {
        'view': true,
        'edit': true
      },
      'preferredLanguage': {
        'view': true,
        'edit': true
      },
      'email': {
        'view': true,
        'edit': true
      },
      'dateOfBirth': {
        'view': true,
        'edit': true
      },
      'taxIdType': {
        'view': true,
        'edit': true
      },
      'taxId': {
        'view': true,
        'edit': true
      },
      'mobilePhone': {
        'view': true,
        'edit': true
      },
      'alternatePhone': {
        'view': true,
        'edit': true
      },
      'firstName': {
        'view': true,
        'edit': true
      },
      'lastName': {
        'view': true,
        'edit': true
      }
    },
    'iboAddress': {
      'addressName': {
        'view': true,
        'edit': true
      },
      'addressLine': {
        'view': true,
        'edit': true
      },
      'addressLineTwo': {
        'view': true,
        'edit': true
      },
      'city': {
        'view': true,
        'edit': true
      },
      'state': {
        'view': true,
        'edit': true
      },
      'zipCode': {
        'view': true,
        'edit': true
      },
      'careOf': {
        'view': true,
        'edit': true
      }
    },
    'businessDetails': {
      'legalName': {
        'view': true,
        'edit': true
      },
      'country': {
        'view': true,
        'edit': true
      },
      'sponsorNumber': {
        'view': true,
        'edit': true
      },
      'platinumNumber': {
        'view': true,
        'edit': true
      },
      'diamondNumber': {
        'view': true,
        'edit': true
      },
      'internationalNumber': {
        'view': true,
        'edit': true
      },
      'usersLos': {
        'view': true,
        'edit': true
      },
      'nationalAccounts': {
        'view': true,
        'edit': true
      },
      'balance': {
        'view': true,
        'edit': true
      },
      'taxWithholding': {
        'view': true,
        'edit': true
      },
      'spa': {
        'view': true,
        'edit': true
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': true
      },
      'passwordChangeDate': {
        'view': true,
        'edit': true
      },
      'businessNature': {
        'view': true,
        'edit': true
      },
      'reRegistration': {
        'view': true,
        'edit': true
      },
      'unenrollIboFromLeads': {
        'view': true,
        'edit': true
      },
      'refundRenewal': {
        'view': true,
        'edit': true
      },
      'deactivate': {
        'view': true,
        'edit': true
      },
      'reactivate': {
        'view': true,
        'edit': true
      }
    },
    'restrictionBlocks': {
      '215': {
        'view': true,
        'edit': true
      },
      'BlockLogin': {
        'view': false,
        'edit': false
      },
      'BlockPVBVXferInit': {
        'view': true,
        'edit': true
      },
      'BlockPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockAdvBackOrder': {
        'view': true,
        'edit': true
      },
      'BlockCreditCard': {
        'view': true,
        'edit': true
      },
      'BlockPaypal': {
        'view': true,
        'edit': true
      },
      'BlockOrdering': {
        'view': true,
        'edit': true
      },
      'AllowPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockResignation': {
        'view': true,
        'edit': true
      },
      'BlockRenewal': {
        'view': true,
        'edit': true
      },
      'BlockLeadsABO': {
        'view': true,
        'edit': true
      },
      'BlockLeadsCustomer': {
        'view': true,
        'edit': true
      },
      'BlockLateRenewWGrp': {
        'view': true,
        'edit': true
      },
      'BlockRenewWoGrp': {
        'view': true,
        'edit': true
      },
      'BlockSponsoring': {
        'view': true,
        'edit': true
      },
      'BlkLOSCommunications': {
        'view': true,
        'edit': true
      },
      'PrivacyBlock': {
        'view': true,
        'edit': true
      },
      'AllowInfoShareCustLe': {
        'view': true,
        'edit': true
      },
      'BlockProfileChanges': {
        'view': true,
        'edit': true
      },
      'BlockLeadsSponsor': {
        'view': true,
        'edit': true
      },
      'BlockBonus': {
        'view': true,
        'edit': true
      },
      'BlockReturns': {
        'view': true,
        'edit': true
      },
      'BlockBusinessNatrChg': {
        'view': true,
        'edit': true
      },
      'BlockAddApplicant': {
        'view': true,
        'edit': true
      },
      'BlockEmail': {
        'view': true,
        'edit': true
      },
      'BlockMessaging': {
        'view': true,
        'edit': true
      },
      'BlockAmwayCard': {
        'view': true,
        'edit': true
      },
      'BlockARMOP': {
        'view': true,
        'edit': true
      },
      'BlockBankDraft': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebit': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebitAccStatus': {
        'view': true,
        'edit': false
      }
    }
  },
  'BOSS-MEMBER': {
    'BankAccountAddBtn': {
      'view': false
    },
    'BankAccountAccor': {
      'view': true
    },
    'MaritalStatus': {
      'view': true
    },
    'BusinessNatureAccor': {
      'view': true
    },
    'ViewLos': {
      'view': true
    },
    'AddCoapplicant': {
      'view': true
    },
    'RestrictionsAccor': {
      'view': true
    },
    'SubscriptionsAccor': {
      'view': true
    },
    'TnCAccor': {
      'view': true
    },
    'VouchersAccor': {
      'view': true
    },
    'SuccessorAccor': {
      'view': false
    },
    'TaxInfo': {
      'view': false
    },
    'BusinessDetailsAccor': {
      'view': true
    },
    'PersonalDetailAccor': {
      'view': true
    },
    'iboBasicInfo': {
      'preferredName': {
        'view': true,
        'edit': true
      },
      'legalName': {
        'view': true,
        'edit': true
      },
      'preferredLanguage': {
        'view': true,
        'edit': true
      },
      'email': {
        'view': true,
        'edit': true
      },
      'dateOfBirth': {
        'view': true,
        'edit': true
      },
      'taxIdType': {
        'view': true,
        'edit': true
      },
      'taxId': {
        'view': true,
        'edit': true
      },
      'mobilePhone': {
        'view': true,
        'edit': true
      },
      'alternatePhone': {
        'view': true,
        'edit': true
      },
      'firstName': {
        'view': true,
        'edit': true
      },
      'lastName': {
        'view': true,
        'edit': true
      }
    },
    'iboAddress': {
      'addressName': {
        'view': true,
        'edit': true
      },
      'addressLine': {
        'view': true,
        'edit': true
      },
      'addressLineTwo': {
        'view': true,
        'edit': true
      },
      'city': {
        'view': true,
        'edit': true
      },
      'state': {
        'view': true,
        'edit': true
      },
      'zipCode': {
        'view': true,
        'edit': true
      },
      'careOf': {
        'view': true,
        'edit': true
      }
    },
    'businessDetails': {
      'legalName': {
        'view': true,
        'edit': true
      },
      'country': {
        'view': true,
        'edit': true
      },
      'sponsorNumber': {
        'view': true,
        'edit': true
      },
      'platinumNumber': {
        'view': true,
        'edit': true
      },
      'diamondNumber': {
        'view': true,
        'edit': true
      },
      'internationalNumber': {
        'view': true,
        'edit': true
      },
      'usersLos': {
        'view': true,
        'edit': true
      },
      'nationalAccounts': {
        'view': true,
        'edit': true
      },
      'balance': {
        'view': true,
        'edit': true
      },
      'taxWithholding': {
        'view': true,
        'edit': true
      },
      'spa': {
        'view': true,
        'edit': true
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': true
      },
      'passwordChangeDate': {
        'view': true,
        'edit': true
      },
      'businessNature': {
        'view': true,
        'edit': true
      },
      'reRegistration': {
        'view': true,
        'edit': true
      },
      'unenrollIboFromLeads': {
        'view': true,
        'edit': true
      },
      'refundRenewal': {
        'view': true,
        'edit': true
      },
      'deactivate': {
        'view': true,
        'edit': true
      }
    },
    'restrictionBlocks': {
      '215': {
        'view': true,
        'edit': true
      },
      'BlockLogin': {
        'view': false,
        'edit': false
      },
      'BlockPVBVXferInit': {
        'view': true,
        'edit': true
      },
      'BlockPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockAdvBackOrder': {
        'view': true,
        'edit': true
      },
      'BlockCreditCard': {
        'view': true,
        'edit': true
      },
      'BlockPaypal': {
        'view': true,
        'edit': true
      },
      'BlockOrdering': {
        'view': true,
        'edit': true
      },
      'AllowPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockResignation': {
        'view': true,
        'edit': true
      },
      'BlockRenewal': {
        'view': true,
        'edit': true
      },
      'BlockLeadsABO': {
        'view': true,
        'edit': true
      },
      'BlockLeadsCustomer': {
        'view': true,
        'edit': true
      },
      'BlockLateRenewWGrp': {
        'view': true,
        'edit': true
      },
      'BlockRenewWoGrp': {
        'view': true,
        'edit': true
      },
      'BlockSponsoring': {
        'view': true,
        'edit': true
      },
      'BlkLOSCommunications': {
        'view': true,
        'edit': true
      },
      'PrivacyBlock': {
        'view': true,
        'edit': true
      },
      'AllowInfoShareCustLe': {
        'view': true,
        'edit': true
      },
      'BlockProfileChanges': {
        'view': true,
        'edit': true
      },
      'BlockLeadsSponsor': {
        'view': true,
        'edit': true
      },
      'BlockBonus': {
        'view': true,
        'edit': true
      },
      'BlockReturns': {
        'view': true,
        'edit': true
      },
      'BlockBusinessNatrChg': {
        'view': true,
        'edit': true
      },
      'BlockAddApplicant': {
        'view': true,
        'edit': true
      },
      'BlockEmail': {
        'view': true,
        'edit': true
      },
      'BlockMessaging': {
        'view': true,
        'edit': true
      },
      'BlockAmwayCard': {
        'view': true,
        'edit': true
      },
      'BlockARMOP': {
        'view': true,
        'edit': true
      },
      'BlockBankDraft': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebit': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebitAccStatus': {
        'view': true,
        'edit': false
      }
    }
  },
  'BOSS-EMPLOYEE': {
    'BankAccountAddBtn': {
      'view': false
    },
    'BankAccountAccor': {
      'view': false
    },
    'MaritalStatus': {
      'view': false
    },
    'BusinessNatureAccor': {
      'view': false
    },
    'ViewLos': {
      'view': false
    },
    'AddCoapplicant': {
      'view': false
    },
    'RestrictionsAccor': {
      'view': true
    },
    'SubscriptionsAccor': {
      'view': false
    },
    'TnCAccor': {
      'view': false
    },
    'VouchersAccor': {
      'view': true
    },
    'SuccessorAccor': {
      'view': false
    },
    'BusinessInformation': {
      'view': false
    },
    'TaxInfo': {
      'view': false
    },
    'BusinessDetailsAccor': {
      'view': true
    },
    'PersonalDetailAccor': {
      'view': true
    },
    'iboBasicInfo': {
      'preferredName': {
        'view': true,
        'edit': true
      },
      'legalName': {
        'view': true,
        'edit': true
      },
      'preferredLanguage': {
        'view': true,
        'edit': true
      },
      'email': {
        'view': true,
        'edit': true
      },
      'dateOfBirth': {
        'view': true,
        'edit': true
      },
      'taxIdType': {
        'view': true,
        'edit': true
      },
      'taxId': {
        'view': true,
        'edit': true
      },
      'mobilePhone': {
        'view': true,
        'edit': true
      },
      'alternatePhone': {
        'view': true,
        'edit': true
      },
      'firstName': {
        'view': true,
        'edit': true
      },
      'lastName': {
        'view': true,
        'edit': true
      }
    },
    'iboAddress': {
      'addressName': {
        'view': true,
        'edit': true
      },
      'addressLine': {
        'view': true,
        'edit': true
      },
      'addressLineTwo': {
        'view': true,
        'edit': true
      },
      'city': {
        'view': true,
        'edit': true
      },
      'state': {
        'view': true,
        'edit': true
      },
      'zipCode': {
        'view': true,
        'edit': true
      },
      'careOf': {
        'view': true,
        'edit': true
      }
    },
    'businessDetails': {
      'legalName': {
        'view': true,
        'edit': true
      },
      'country': {
        'view': true,
        'edit': true
      },
      'sponsorNumber': {
        'view': true,
        'edit': true
      },
      'platinumNumber': {
        'view': true,
        'edit': true
      },
      'diamondNumber': {
        'view': true,
        'edit': true
      },
      'internationalNumber': {
        'view': true,
        'edit': true
      },
      'usersLos': {
        'view': true,
        'edit': true
      },
      'nationalAccounts': {
        'view': true,
        'edit': true
      },
      'balance': {
        'view': true,
        'edit': true
      },
      'taxWithholding': {
        'view': true,
        'edit': true
      },
      'spa': {
        'view': true,
        'edit': true
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': true
      },
      'passwordChangeDate': {
        'view': true,
        'edit': true
      },
      'businessNature': {
        'view': true,
        'edit': true
      },
      'reRegistration': {
        'view': true,
        'edit': true
      },
      'unenrollIboFromLeads': {
        'view': true,
        'edit': true
      },
      'refundRenewal': {
        'view': true,
        'edit': true
      },
      'deactivate': {
        'view': true,
        'edit': true
      }
    },
    'restrictionBlocks': {
      '215': {
        'view': true,
        'edit': true
      },
      'BlockLogin': {
        'view': false,
        'edit': false
      },
      'BlockPVBVXferInit': {
        'view': true,
        'edit': true
      },
      'BlockPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockAdvBackOrder': {
        'view': true,
        'edit': true
      },
      'BlockCreditCard': {
        'view': true,
        'edit': true
      },
      'BlockPaypal': {
        'view': true,
        'edit': true
      },
      'BlockOrdering': {
        'view': true,
        'edit': true
      },
      'AllowPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockResignation': {
        'view': true,
        'edit': true
      },
      'BlockRenewal': {
        'view': true,
        'edit': true
      },
      'BlockLeadsABO': {
        'view': true,
        'edit': true
      },
      'BlockLeadsCustomer': {
        'view': true,
        'edit': true
      },
      'BlockLateRenewWGrp': {
        'view': true,
        'edit': true
      },
      'BlockRenewWoGrp': {
        'view': true,
        'edit': true
      },
      'BlockSponsoring': {
        'view': true,
        'edit': true
      },
      'BlkLOSCommunications': {
        'view': true,
        'edit': true
      },
      'PrivacyBlock': {
        'view': true,
        'edit': true
      },
      'AllowInfoShareCustLe': {
        'view': true,
        'edit': true
      },
      'BlockProfileChanges': {
        'view': true,
        'edit': true
      },
      'BlockLeadsSponsor': {
        'view': true,
        'edit': true
      },
      'BlockBonus': {
        'view': true,
        'edit': true
      },
      'BlockReturns': {
        'view': true,
        'edit': true
      },
      'BlockBusinessNatrChg': {
        'view': true,
        'edit': true
      },
      'BlockAddApplicant': {
        'view': true,
        'edit': true
      },
      'BlockEmail': {
        'view': true,
        'edit': true
      },
      'BlockMessaging': {
        'view': true,
        'edit': true
      },
      'BlockAmwayCard': {
        'view': true,
        'edit': true
      },
      'BlockARMOP': {
        'view': true,
        'edit': true
      },
      'BlockBankDraft': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebit': {
        'view': true,
        'edit': true
      }
    }
  },
  'AREAMANAGER-BOSS-BUSINESSOWNER': {
    'BankAccountAddBtn': {
      'view': true
    },
    'BankAccountAccor': {
      'view': true
    },
    'MaritalStatus': {
      'view': true
    },
    'BusinessNatureAccor': {
      'view': true
    },
    'ViewLos': {
      'view': true
    },
    'AddCoapplicant': {
      'view': true
    },
    'RestrictionsAccor': {
      'view': true
    },
    'SubscriptionsAccor': {
      'view': true
    },
    'TnCAccor': {
      'view': true
    },
    'VouchersAccor': {
      'view': true
    },
    'SuccessorAccor': {
      'view': true
    },
    'TaxInfo': {
      'view': true
    },
    'BusinessDetailsAccor': {
      'view': true
    },
    'PersonalDetailAccor': {
      'view': true
    },
    'iboBasicInfo': {
      'preferredName': {
        'view': true,
        'edit': true
      },
      'legalName': {
        'view': true,
        'edit': true
      },
      'preferredLanguage': {
        'view': true,
        'edit': true
      },
      'email': {
        'view': true,
        'edit': true
      },
      'dateOfBirth': {
        'view': true,
        'edit': true
      },
      'taxIdType': {
        'view': true,
        'edit': true
      },
      'taxId': {
        'view': true,
        'edit': true
      },
      'mobilePhone': {
        'view': true,
        'edit': true
      },
      'alternatePhone': {
        'view': true,
        'edit': true
      },
      'firstName': {
        'view': true,
        'edit': true
      },
      'lastName': {
        'view': true,
        'edit': true
      }
    },
    'iboAddress': {
      'addressName': {
        'view': true,
        'edit': true
      },
      'addressLine': {
        'view': true,
        'edit': true
      },
      'addressLineTwo': {
        'view': true,
        'edit': true
      },
      'city': {
        'view': true,
        'edit': true
      },
      'state': {
        'view': true,
        'edit': true
      },
      'zipCode': {
        'view': true,
        'edit': true
      },
      'careOf': {
        'view': true,
        'edit': true
      }
    },
    'businessDetails': {
      'legalName': {
        'view': true,
        'edit': true
      },
      'country': {
        'view': true,
        'edit': true
      },
      'sponsorNumber': {
        'view': true,
        'edit': true
      },
      'platinumNumber': {
        'view': true,
        'edit': true
      },
      'diamondNumber': {
        'view': true,
        'edit': true
      },
      'internationalNumber': {
        'view': true,
        'edit': true
      },
      'usersLos': {
        'view': true,
        'edit': true
      },
      'nationalAccounts': {
        'view': true,
        'edit': true
      },
      'balance': {
        'view': true,
        'edit': true
      },
      'taxWithholding': {
        'view': true,
        'edit': true
      },
      'spa': {
        'view': true,
        'edit': true
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': true
      },
      'passwordChangeDate': {
        'view': true,
        'edit': true
      },
      'businessNature': {
        'view': true,
        'edit': true
      },
      'reRegistration': {
        'view': true,
        'edit': true
      },
      'unenrollIboFromLeads': {
        'view': true,
        'edit': true
      },
      'refundRenewal': {
        'view': true,
        'edit': true
      },
      'deactivate': {
        'view': true,
        'edit': true
      }
    },
    'restrictionBlocks': {
      '215': {
        'view': true,
        'edit': true
      },
      'BlockLogin': {
        'view': false,
        'edit': false
      },
      'BlockPVBVXferInit': {
        'view': true,
        'edit': true
      },
      'BlockPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockAdvBackOrder': {
        'view': true,
        'edit': true
      },
      'BlockCreditCard': {
        'view': true,
        'edit': true
      },
      'BlockPaypal': {
        'view': true,
        'edit': true
      },
      'BlockOrdering': {
        'view': true,
        'edit': true
      },
      'AllowPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockResignation': {
        'view': true,
        'edit': true
      },
      'BlockRenewal': {
        'view': true,
        'edit': true
      },
      'BlockLeadsABO': {
        'view': true,
        'edit': true
      },
      'BlockLeadsCustomer': {
        'view': true,
        'edit': true
      },
      'BlockLateRenewWGrp': {
        'view': true,
        'edit': true
      },
      'BlockRenewWoGrp': {
        'view': true,
        'edit': true
      },
      'BlockSponsoring': {
        'view': true,
        'edit': true
      },
      'BlkLOSCommunications': {
        'view': true,
        'edit': true
      },
      'PrivacyBlock': {
        'view': true,
        'edit': true
      },
      'AllowInfoShareCustLe': {
        'view': true,
        'edit': true
      },
      'BlockProfileChanges': {
        'view': true,
        'edit': true
      },
      'BlockLeadsSponsor': {
        'view': true,
        'edit': true
      },
      'BlockBonus': {
        'view': true,
        'edit': true
      },
      'BlockReturns': {
        'view': true,
        'edit': true
      },
      'BlockBusinessNatrChg': {
        'view': true,
        'edit': true
      },
      'BlockAddApplicant': {
        'view': true,
        'edit': true
      },
      'BlockEmail': {
        'view': true,
        'edit': true
      },
      'BlockMessaging': {
        'view': true,
        'edit': true
      },
      'BlockAmwayCard': {
        'view': true,
        'edit': true
      },
      'BlockARMOP': {
        'view': true,
        'edit': true
      },
      'BlockBankDraft': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebit': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebitAccStatus': {
        'view': true,
        'edit': true
      }
    }
  },
  'AREAMANAGER-BOSS-MEMBER': {
    'BankAccountAddBtn': {
      'view': true
    },
    'BankAccountAccor': {
      'view': true
    },
    'MaritalStatus': {
      'view': true
    },
    'BusinessNatureAccor': {
      'view': true
    },
    'ViewLos': {
      'view': true
    },
    'AddCoapplicant': {
      'view': true
    },
    'RestrictionsAccor': {
      'view': true
    },
    'SubscriptionsAccor': {
      'view': true
    },
    'TnCAccor': {
      'view': true
    },
    'VouchersAccor': {
      'view': true
    },
    'SuccessorAccor': {
      'view': true
    },
    'TaxInfo': {
      'view': true
    },
    'BusinessDetailsAccor': {
      'view': true
    },
    'PersonalDetailAccor': {
      'view': true
    },
    'iboBasicInfo': {
      'preferredName': {
        'view': true,
        'edit': true
      },
      'legalName': {
        'view': true,
        'edit': true
      },
      'preferredLanguage': {
        'view': true,
        'edit': true
      },
      'email': {
        'view': true,
        'edit': true
      },
      'dateOfBirth': {
        'view': true,
        'edit': true
      },
      'taxIdType': {
        'view': true,
        'edit': true
      },
      'taxId': {
        'view': true,
        'edit': true
      },
      'mobilePhone': {
        'view': true,
        'edit': true
      },
      'alternatePhone': {
        'view': true,
        'edit': true
      },
      'firstName': {
        'view': true,
        'edit': true
      },
      'lastName': {
        'view': true,
        'edit': true
      }
    },
    'iboAddress': {
      'addressName': {
        'view': true,
        'edit': true
      },
      'addressLine': {
        'view': true,
        'edit': true
      },
      'addressLineTwo': {
        'view': true,
        'edit': true
      },
      'city': {
        'view': true,
        'edit': true
      },
      'state': {
        'view': true,
        'edit': true
      },
      'zipCode': {
        'view': true,
        'edit': true
      },
      'careOf': {
        'view': true,
        'edit': true
      }
    },
    'businessDetails': {
      'legalName': {
        'view': true,
        'edit': true
      },
      'country': {
        'view': true,
        'edit': true
      },
      'sponsorNumber': {
        'view': true,
        'edit': true
      },
      'platinumNumber': {
        'view': true,
        'edit': true
      },
      'diamondNumber': {
        'view': true,
        'edit': true
      },
      'internationalNumber': {
        'view': true,
        'edit': true
      },
      'usersLos': {
        'view': true,
        'edit': true
      },
      'nationalAccounts': {
        'view': true,
        'edit': true
      },
      'balance': {
        'view': true,
        'edit': true
      },
      'taxWithholding': {
        'view': true,
        'edit': true
      },
      'spa': {
        'view': true,
        'edit': true
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': true
      },
      'passwordChangeDate': {
        'view': true,
        'edit': true
      },
      'businessNature': {
        'view': true,
        'edit': true
      },
      'reRegistration': {
        'view': true,
        'edit': true
      },
      'unenrollIboFromLeads': {
        'view': true,
        'edit': true
      },
      'refundRenewal': {
        'view': true,
        'edit': true
      },
      'deactivate': {
        'view': true,
        'edit': true
      }
    },
    'restrictionBlocks': {
      '215': {
        'view': true,
        'edit': true
      },
      'BlockLogin': {
        'view': false,
        'edit': false
      },
      'BlockPVBVXferInit': {
        'view': true,
        'edit': true
      },
      'BlockPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockAdvBackOrder': {
        'view': true,
        'edit': true
      },
      'BlockCreditCard': {
        'view': true,
        'edit': true
      },
      'BlockPaypal': {
        'view': true,
        'edit': true
      },
      'BlockOrdering': {
        'view': true,
        'edit': true
      },
      'AllowPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockResignation': {
        'view': true,
        'edit': true
      },
      'BlockRenewal': {
        'view': true,
        'edit': true
      },
      'BlockLeadsABO': {
        'view': true,
        'edit': true
      },
      'BlockLeadsCustomer': {
        'view': true,
        'edit': true
      },
      'BlockLateRenewWGrp': {
        'view': true,
        'edit': true
      },
      'BlockRenewWoGrp': {
        'view': true,
        'edit': true
      },
      'BlockSponsoring': {
        'view': true,
        'edit': true
      },
      'BlkLOSCommunications': {
        'view': true,
        'edit': true
      },
      'PrivacyBlock': {
        'view': true,
        'edit': true
      },
      'AllowInfoShareCustLe': {
        'view': true,
        'edit': true
      },
      'BlockProfileChanges': {
        'view': true,
        'edit': true
      },
      'BlockLeadsSponsor': {
        'view': true,
        'edit': true
      },
      'BlockBonus': {
        'view': true,
        'edit': true
      },
      'BlockReturns': {
        'view': true,
        'edit': true
      },
      'BlockBusinessNatrChg': {
        'view': true,
        'edit': true
      },
      'BlockAddApplicant': {
        'view': true,
        'edit': true
      },
      'BlockEmail': {
        'view': true,
        'edit': true
      },
      'BlockMessaging': {
        'view': true,
        'edit': true
      },
      'BlockAmwayCard': {
        'view': true,
        'edit': true
      },
      'BlockARMOP': {
        'view': true,
        'edit': true
      },
      'BlockBankDraft': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebit': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebitAccStatus': {
        'view': true,
        'edit': true
      }
    }
  },
  'AREAMANAGER-BOSS-EMPLOYEE': {
    'BankAccountAddBtn': {
      'view': true
    },
    'BankAccountAccor': {
      'view': true
    },
    'MaritalStatus': {
      'view': true
    },
    'BusinessNatureAccor': {
      'view': true
    },
    'ViewLos': {
      'view': true
    },
    'AddCoapplicant': {
      'view': true
    },
    'RestrictionsAccor': {
      'view': true
    },
    'SubscriptionsAccor': {
      'view': true
    },
    'TnCAccor': {
      'view': true
    },
    'VouchersAccor': {
      'view': true
    },
    'SuccessorAccor': {
      'view': true
    },
    'TaxInfo': {
      'view': true
    },
    'BusinessDetailsAccor': {
      'view': true
    },
    'PersonalDetailAccor': {
      'view': true
    },
    'iboBasicInfo': {
      'preferredName': {
        'view': true,
        'edit': true
      },
      'legalName': {
        'view': true,
        'edit': true
      },
      'preferredLanguage': {
        'view': true,
        'edit': true
      },
      'email': {
        'view': true,
        'edit': true
      },
      'dateOfBirth': {
        'view': true,
        'edit': true
      },
      'taxIdType': {
        'view': true,
        'edit': true
      },
      'taxId': {
        'view': true,
        'edit': true
      },
      'mobilePhone': {
        'view': true,
        'edit': true
      },
      'alternatePhone': {
        'view': true,
        'edit': true
      },
      'firstName': {
        'view': true,
        'edit': true
      },
      'lastName': {
        'view': true,
        'edit': true
      }
    },
    'iboAddress': {
      'addressName': {
        'view': true,
        'edit': true
      },
      'addressLine': {
        'view': true,
        'edit': true
      },
      'addressLineTwo': {
        'view': true,
        'edit': true
      },
      'city': {
        'view': true,
        'edit': true
      },
      'state': {
        'view': true,
        'edit': true
      },
      'zipCode': {
        'view': true,
        'edit': true
      },
      'careOf': {
        'view': true,
        'edit': true
      }
    },
    'businessDetails': {
      'legalName': {
        'view': true,
        'edit': true
      },
      'country': {
        'view': true,
        'edit': true
      },
      'sponsorNumber': {
        'view': true,
        'edit': true
      },
      'platinumNumber': {
        'view': true,
        'edit': true
      },
      'diamondNumber': {
        'view': true,
        'edit': true
      },
      'internationalNumber': {
        'view': true,
        'edit': true
      },
      'usersLos': {
        'view': true,
        'edit': true
      },
      'nationalAccounts': {
        'view': true,
        'edit': true
      },
      'balance': {
        'view': true,
        'edit': true
      },
      'taxWithholding': {
        'view': true,
        'edit': true
      },
      'spa': {
        'view': true,
        'edit': true
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': true
      },
      'passwordChangeDate': {
        'view': true,
        'edit': true
      },
      'businessNature': {
        'view': true,
        'edit': true
      },
      'reRegistration': {
        'view': true,
        'edit': true
      },
      'unenrollIboFromLeads': {
        'view': true,
        'edit': true
      },
      'refundRenewal': {
        'view': true,
        'edit': true
      },
      'deactivate': {
        'view': true,
        'edit': true
      }
    },
    'restrictionBlocks': {
      '215': {
        'view': true,
        'edit': true
      },
      'BlockLogin': {
        'view': false,
        'edit': false
      },
      'BlockPVBVXferInit': {
        'view': true,
        'edit': true
      },
      'BlockPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockAdvBackOrder': {
        'view': true,
        'edit': true
      },
      'BlockCreditCard': {
        'view': true,
        'edit': true
      },
      'BlockPaypal': {
        'view': true,
        'edit': true
      },
      'BlockOrdering': {
        'view': true,
        'edit': true
      },
      'AllowPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockResignation': {
        'view': true,
        'edit': true
      },
      'BlockRenewal': {
        'view': true,
        'edit': true
      },
      'BlockLeadsABO': {
        'view': true,
        'edit': true
      },
      'BlockLeadsCustomer': {
        'view': true,
        'edit': true
      },
      'BlockLateRenewWGrp': {
        'view': true,
        'edit': true
      },
      'BlockRenewWoGrp': {
        'view': true,
        'edit': true
      },
      'BlockSponsoring': {
        'view': true,
        'edit': true
      },
      'BlkLOSCommunications': {
        'view': true,
        'edit': true
      },
      'PrivacyBlock': {
        'view': true,
        'edit': true
      },
      'AllowInfoShareCustLe': {
        'view': true,
        'edit': true
      },
      'BlockProfileChanges': {
        'view': true,
        'edit': true
      },
      'BlockLeadsSponsor': {
        'view': true,
        'edit': true
      },
      'BlockBonus': {
        'view': true,
        'edit': true
      },
      'BlockReturns': {
        'view': true,
        'edit': true
      },
      'BlockBusinessNatrChg': {
        'view': true,
        'edit': true
      },
      'BlockAddApplicant': {
        'view': true,
        'edit': true
      },
      'BlockEmail': {
        'view': true,
        'edit': true
      },
      'BlockMessaging': {
        'view': true,
        'edit': true
      },
      'BlockAmwayCard': {
        'view': true,
        'edit': true
      },
      'BlockARMOP': {
        'view': true,
        'edit': true
      },
      'BlockBankDraft': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebit': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebitAccStatus': {
        'view': true,
        'edit': true
      }
    }
  },
  'SHOPMANAGER-BOSS-BUSINESSOWNER': {
    'BankAccountAddBtn': {
      'view': true
    },
    'BankAccountAccor': {
      'view': true
    },
    'MaritalStatus': {
      'view': true
    },
    'BusinessNatureAccor': {
      'view': true
    },
    'ViewLos': {
      'view': true
    },
    'AddCoapplicant': {
      'view': true
    },
    'RestrictionsAccor': {
      'view': true
    },
    'SubscriptionsAccor': {
      'view': true
    },
    'TnCAccor': {
      'view': true
    },
    'VouchersAccor': {
      'view': true
    },
    'SuccessorAccor': {
      'view': true
    },
    'TaxInfo': {
      'view': true
    },
    'BusinessDetailsAccor': {
      'view': true
    },
    'PersonalDetailAccor': {
      'view': true
    },
    'iboBasicInfo': {
      'preferredName': {
        'view': true,
        'edit': true
      },
      'legalName': {
        'view': true,
        'edit': true
      },
      'preferredLanguage': {
        'view': true,
        'edit': true
      },
      'email': {
        'view': true,
        'edit': true
      },
      'dateOfBirth': {
        'view': true,
        'edit': true
      },
      'taxIdType': {
        'view': true,
        'edit': true
      },
      'taxId': {
        'view': true,
        'edit': true
      },
      'mobilePhone': {
        'view': true,
        'edit': true
      },
      'alternatePhone': {
        'view': true,
        'edit': true
      },
      'firstName': {
        'view': true,
        'edit': true
      },
      'lastName': {
        'view': true,
        'edit': true
      }
    },
    'iboAddress': {
      'addressName': {
        'view': true,
        'edit': true
      },
      'addressLine': {
        'view': true,
        'edit': true
      },
      'addressLineTwo': {
        'view': true,
        'edit': true
      },
      'city': {
        'view': true,
        'edit': true
      },
      'state': {
        'view': true,
        'edit': true
      },
      'zipCode': {
        'view': true,
        'edit': true
      },
      'careOf': {
        'view': true,
        'edit': true
      }
    },
    'businessDetails': {
      'legalName': {
        'view': true,
        'edit': true
      },
      'country': {
        'view': true,
        'edit': true
      },
      'sponsorNumber': {
        'view': true,
        'edit': true
      },
      'platinumNumber': {
        'view': true,
        'edit': true
      },
      'diamondNumber': {
        'view': true,
        'edit': true
      },
      'internationalNumber': {
        'view': true,
        'edit': true
      },
      'usersLos': {
        'view': true,
        'edit': true
      },
      'nationalAccounts': {
        'view': true,
        'edit': true
      },
      'balance': {
        'view': true,
        'edit': true
      },
      'taxWithholding': {
        'view': true,
        'edit': true
      },
      'spa': {
        'view': true,
        'edit': true
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': true
      },
      'passwordChangeDate': {
        'view': true,
        'edit': true
      },
      'businessNature': {
        'view': true,
        'edit': true
      },
      'reRegistration': {
        'view': true,
        'edit': true
      },
      'unenrollIboFromLeads': {
        'view': true,
        'edit': true
      },
      'refundRenewal': {
        'view': true,
        'edit': true
      },
      'deactivate': {
        'view': true,
        'edit': true
      }
    },
    'restrictionBlocks': {
      '215': {
        'view': true,
        'edit': true
      },
      'BlockLogin': {
        'view': false,
        'edit': false
      },
      'BlockPVBVXferInit': {
        'view': true,
        'edit': true
      },
      'BlockPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockAdvBackOrder': {
        'view': true,
        'edit': true
      },
      'BlockCreditCard': {
        'view': true,
        'edit': true
      },
      'BlockPaypal': {
        'view': true,
        'edit': true
      },
      'BlockOrdering': {
        'view': true,
        'edit': true
      },
      'AllowPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockResignation': {
        'view': true,
        'edit': true
      },
      'BlockRenewal': {
        'view': true,
        'edit': true
      },
      'BlockLeadsABO': {
        'view': true,
        'edit': true
      },
      'BlockLeadsCustomer': {
        'view': true,
        'edit': true
      },
      'BlockLateRenewWGrp': {
        'view': true,
        'edit': true
      },
      'BlockRenewWoGrp': {
        'view': true,
        'edit': true
      },
      'BlockSponsoring': {
        'view': true,
        'edit': true
      },
      'BlkLOSCommunications': {
        'view': true,
        'edit': true
      },
      'PrivacyBlock': {
        'view': true,
        'edit': true
      },
      'AllowInfoShareCustLe': {
        'view': true,
        'edit': true
      },
      'BlockProfileChanges': {
        'view': true,
        'edit': true
      },
      'BlockLeadsSponsor': {
        'view': true,
        'edit': true
      },
      'BlockBonus': {
        'view': true,
        'edit': true
      },
      'BlockReturns': {
        'view': true,
        'edit': true
      },
      'BlockBusinessNatrChg': {
        'view': true,
        'edit': true
      },
      'BlockAddApplicant': {
        'view': true,
        'edit': true
      },
      'BlockEmail': {
        'view': true,
        'edit': true
      },
      'BlockMessaging': {
        'view': true,
        'edit': true
      },
      'BlockAmwayCard': {
        'view': true,
        'edit': true
      },
      'BlockARMOP': {
        'view': true,
        'edit': true
      },
      'BlockBankDraft': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebit': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebitAccStatus': {
        'view': true,
        'edit': true
      }
    }
  },
  'SHOPMANAGER-BOSS-MEMBER': {
    'BankAccountAddBtn': {
      'view': true
    },
    'BankAccountAccor': {
      'view': true
    },
    'MaritalStatus': {
      'view': true
    },
    'BusinessNatureAccor': {
      'view': true
    },
    'ViewLos': {
      'view': true
    },
    'AddCoapplicant': {
      'view': true
    },
    'RestrictionsAccor': {
      'view': true
    },
    'SubscriptionsAccor': {
      'view': true
    },
    'TnCAccor': {
      'view': true
    },
    'VouchersAccor': {
      'view': true
    },
    'SuccessorAccor': {
      'view': true
    },
    'TaxInfo': {
      'view': true
    },
    'BusinessDetailsAccor': {
      'view': true
    },
    'PersonalDetailAccor': {
      'view': true
    },
    'iboBasicInfo': {
      'preferredName': {
        'view': true,
        'edit': true
      },
      'legalName': {
        'view': true,
        'edit': true
      },
      'preferredLanguage': {
        'view': true,
        'edit': true
      },
      'email': {
        'view': true,
        'edit': true
      },
      'dateOfBirth': {
        'view': true,
        'edit': true
      },
      'taxIdType': {
        'view': true,
        'edit': true
      },
      'taxId': {
        'view': true,
        'edit': true
      },
      'mobilePhone': {
        'view': true,
        'edit': true
      },
      'alternatePhone': {
        'view': true,
        'edit': true
      },
      'firstName': {
        'view': true,
        'edit': true
      },
      'lastName': {
        'view': true,
        'edit': true
      }
    },
    'iboAddress': {
      'addressName': {
        'view': true,
        'edit': true
      },
      'addressLine': {
        'view': true,
        'edit': true
      },
      'addressLineTwo': {
        'view': true,
        'edit': true
      },
      'city': {
        'view': true,
        'edit': true
      },
      'state': {
        'view': true,
        'edit': true
      },
      'zipCode': {
        'view': true,
        'edit': true
      },
      'careOf': {
        'view': true,
        'edit': true
      }
    },
    'businessDetails': {
      'legalName': {
        'view': true,
        'edit': true
      },
      'country': {
        'view': true,
        'edit': true
      },
      'sponsorNumber': {
        'view': true,
        'edit': true
      },
      'platinumNumber': {
        'view': true,
        'edit': true
      },
      'diamondNumber': {
        'view': true,
        'edit': true
      },
      'internationalNumber': {
        'view': true,
        'edit': true
      },
      'usersLos': {
        'view': true,
        'edit': true
      },
      'nationalAccounts': {
        'view': true,
        'edit': true
      },
      'balance': {
        'view': true,
        'edit': true
      },
      'taxWithholding': {
        'view': true,
        'edit': true
      },
      'spa': {
        'view': true,
        'edit': true
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': true
      },
      'passwordChangeDate': {
        'view': true,
        'edit': true
      },
      'businessNature': {
        'view': true,
        'edit': true
      },
      'reRegistration': {
        'view': true,
        'edit': true
      },
      'unenrollIboFromLeads': {
        'view': true,
        'edit': true
      },
      'refundRenewal': {
        'view': true,
        'edit': true
      },
      'deactivate': {
        'view': true,
        'edit': true
      }
    },
    'restrictionBlocks': {
      '215': {
        'view': true,
        'edit': true
      },
      'BlockLogin': {
        'view': false,
        'edit': false
      },
      'BlockPVBVXferInit': {
        'view': true,
        'edit': true
      },
      'BlockPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockAdvBackOrder': {
        'view': true,
        'edit': true
      },
      'BlockCreditCard': {
        'view': true,
        'edit': true
      },
      'BlockPaypal': {
        'view': true,
        'edit': true
      },
      'BlockOrdering': {
        'view': true,
        'edit': true
      },
      'AllowPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockResignation': {
        'view': true,
        'edit': true
      },
      'BlockRenewal': {
        'view': true,
        'edit': true
      },
      'BlockLeadsABO': {
        'view': true,
        'edit': true
      },
      'BlockLeadsCustomer': {
        'view': true,
        'edit': true
      },
      'BlockLateRenewWGrp': {
        'view': true,
        'edit': true
      },
      'BlockRenewWoGrp': {
        'view': true,
        'edit': true
      },
      'BlockSponsoring': {
        'view': true,
        'edit': true
      },
      'BlkLOSCommunications': {
        'view': true,
        'edit': true
      },
      'PrivacyBlock': {
        'view': true,
        'edit': true
      },
      'AllowInfoShareCustLe': {
        'view': true,
        'edit': true
      },
      'BlockProfileChanges': {
        'view': true,
        'edit': true
      },
      'BlockLeadsSponsor': {
        'view': true,
        'edit': true
      },
      'BlockBonus': {
        'view': true,
        'edit': true
      },
      'BlockReturns': {
        'view': true,
        'edit': true
      },
      'BlockBusinessNatrChg': {
        'view': true,
        'edit': true
      },
      'BlockAddApplicant': {
        'view': true,
        'edit': true
      },
      'BlockEmail': {
        'view': true,
        'edit': true
      },
      'BlockMessaging': {
        'view': true,
        'edit': true
      },
      'BlockAmwayCard': {
        'view': true,
        'edit': true
      },
      'BlockARMOP': {
        'view': true,
        'edit': true
      },
      'BlockBankDraft': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebit': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebitAccStatus': {
        'view': true,
        'edit': true
      },
      'reactivate': {
        'view': true,
        'edit': true
      }
    }
  },
  'SHOPMANAGER-BOSS-EMPLOYEE': {
    'BankAccountAddBtn': {
      'view': true
    },
    'BankAccountAccor': {
      'view': true
    },
    'MaritalStatus': {
      'view': true
    },
    'BusinessNatureAccor': {
      'view': true
    },
    'ViewLos': {
      'view': true
    },
    'AddCoapplicant': {
      'view': true
    },
    'RestrictionsAccor': {
      'view': true
    },
    'SubscriptionsAccor': {
      'view': true
    },
    'TnCAccor': {
      'view': true
    },
    'VouchersAccor': {
      'view': true
    },
    'SuccessorAccor': {
      'view': true
    },
    'TaxInfo': {
      'view': true
    },
    'BusinessDetailsAccor': {
      'view': true
    },
    'PersonalDetailAccor': {
      'view': true
    },
    'iboBasicInfo': {
      'preferredName': {
        'view': true,
        'edit': true
      },
      'legalName': {
        'view': true,
        'edit': true
      },
      'preferredLanguage': {
        'view': true,
        'edit': true
      },
      'email': {
        'view': true,
        'edit': true
      },
      'dateOfBirth': {
        'view': true,
        'edit': true
      },
      'taxIdType': {
        'view': true,
        'edit': true
      },
      'taxId': {
        'view': true,
        'edit': true
      },
      'mobilePhone': {
        'view': true,
        'edit': true
      },
      'alternatePhone': {
        'view': true,
        'edit': true
      },
      'firstName': {
        'view': true,
        'edit': true
      },
      'lastName': {
        'view': true,
        'edit': true
      }
    },
    'iboAddress': {
      'addressName': {
        'view': true,
        'edit': true
      },
      'addressLine': {
        'view': true,
        'edit': true
      },
      'addressLineTwo': {
        'view': true,
        'edit': true
      },
      'city': {
        'view': true,
        'edit': true
      },
      'state': {
        'view': true,
        'edit': true
      },
      'zipCode': {
        'view': true,
        'edit': true
      },
      'careOf': {
        'view': true,
        'edit': true
      }
    },
    'businessDetails': {
      'legalName': {
        'view': true,
        'edit': true
      },
      'country': {
        'view': true,
        'edit': true
      },
      'sponsorNumber': {
        'view': true,
        'edit': true
      },
      'platinumNumber': {
        'view': true,
        'edit': true
      },
      'diamondNumber': {
        'view': true,
        'edit': true
      },
      'internationalNumber': {
        'view': true,
        'edit': true
      },
      'usersLos': {
        'view': true,
        'edit': true
      },
      'nationalAccounts': {
        'view': true,
        'edit': true
      },
      'balance': {
        'view': true,
        'edit': true
      },
      'taxWithholding': {
        'view': true,
        'edit': true
      },
      'spa': {
        'view': true,
        'edit': true
      }
    },
    'actions': {
      'bonusPaymentMethod': {
        'view': true,
        'edit': true
      },
      'passwordChangeDate': {
        'view': true,
        'edit': true
      },
      'businessNature': {
        'view': true,
        'edit': true
      },
      'reRegistration': {
        'view': true,
        'edit': true
      },
      'unenrollIboFromLeads': {
        'view': true,
        'edit': true
      },
      'refundRenewal': {
        'view': true,
        'edit': true
      },
      'deactivate': {
        'view': true,
        'edit': true
      },
      'reactivate': {
        'view': true,
        'edit': true
      }
    },
    'restrictionBlocks': {
      '215': {
        'view': true,
        'edit': true
      },
      'BlockLogin': {
        'view': false,
        'edit': false
      },
      'BlockPVBVXferInit': {
        'view': true,
        'edit': true
      },
      'BlockPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockAdvBackOrder': {
        'view': true,
        'edit': true
      },
      'BlockCreditCard': {
        'view': true,
        'edit': true
      },
      'BlockPaypal': {
        'view': true,
        'edit': true
      },
      'BlockOrdering': {
        'view': true,
        'edit': true
      },
      'AllowPVBVXferRcv': {
        'view': true,
        'edit': true
      },
      'BlockResignation': {
        'view': true,
        'edit': true
      },
      'BlockRenewal': {
        'view': true,
        'edit': true
      },
      'BlockLeadsABO': {
        'view': true,
        'edit': true
      },
      'BlockLeadsCustomer': {
        'view': true,
        'edit': true
      },
      'BlockLateRenewWGrp': {
        'view': true,
        'edit': true
      },
      'BlockRenewWoGrp': {
        'view': true,
        'edit': true
      },
      'BlockSponsoring': {
        'view': true,
        'edit': true
      },
      'BlkLOSCommunications': {
        'view': true,
        'edit': true
      },
      'PrivacyBlock': {
        'view': true,
        'edit': true
      },
      'AllowInfoShareCustLe': {
        'view': true,
        'edit': true
      },
      'BlockProfileChanges': {
        'view': true,
        'edit': true
      },
      'BlockLeadsSponsor': {
        'view': true,
        'edit': true
      },
      'BlockBonus': {
        'view': true,
        'edit': true
      },
      'BlockReturns': {
        'view': true,
        'edit': true
      },
      'BlockBusinessNatrChg': {
        'view': true,
        'edit': true
      },
      'BlockAddApplicant': {
        'view': true,
        'edit': true
      },
      'BlockEmail': {
        'view': true,
        'edit': true
      },
      'BlockMessaging': {
        'view': true,
        'edit': true
      },
      'BlockAmwayCard': {
        'view': true,
        'edit': true
      },
      'BlockARMOP': {
        'view': true,
        'edit': true
      },
      'BlockBankDraft': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebit': {
        'view': true,
        'edit': true
      },
      'BlockDirectDebitAccStatus': {
        'view': true,
        'edit': true
      }
    }
  },
};

export const DEFAULT_PRIVILEGES = {
  view: false,
  edit: false
};
