import { Action } from '@ngrx/store';
import {
  SearchforceConnectIdResponse,
  SearchforceConnectIdReqPayload
} from '../../shared/models/searchforce';

import { AmwayAccountList } from '../../shared/models/logins.model';

export const SEARCHFORCE_CONNECT_ID_SEARCH = '[Search Force connect id events search] search';
export const SEARCHFORCE_CONNECT_ID_SEARCH_SUCCESS = '[Search Force connect id events search] search success';
export const SEARCHFORCE_CONNECT_ID_SEARCH_FAIL = '[Search Force connect id events search] search fail';
export const SEARCHFORCE_CONNECT_ID_SEARCH_CLEAR = '[Search Force connect id events search] search clear';

export const GET_EVENT_TYPES = '[Search Force get event types] search';
export const GET_EVENT_TYPES_SUCCESS = '[Search Force get event types success] search';
export const GET_EVENT_TYPES_FAIL = '[Search Force get event types fail] search';
// Linked Accounts
export const GET_LINKED_ACCOUNTS = '[Search Force] Get linked accounts';
export const GET_LINKED_ACCOUNTS_SUCCESS = '[Search Force] Get linked accounts success';
export const GET_LINKED_ACCOUNTS_FAIL = '[Search Force] Get linked accounts fail';

export class SearchforceConnectIdEventsSearch implements Action {
  readonly type = SEARCHFORCE_CONNECT_ID_SEARCH;

  constructor(public payload: SearchforceConnectIdReqPayload) {}
}

export class SearchforceConnectIdEventsSearchSuccess implements Action {
  readonly type = SEARCHFORCE_CONNECT_ID_SEARCH_SUCCESS;

  constructor(public payload: SearchforceConnectIdResponse) {}
}

export class SearchforceConnectIdEventsSearchFail implements Action {
  readonly type = SEARCHFORCE_CONNECT_ID_SEARCH_FAIL;

  constructor(public payload: any = null) {}
}

export class SearchforceConnectIdGetEventTypes implements Action {
  readonly type = GET_EVENT_TYPES;

  constructor(public payload = null) {}
}

export class SearchforceConnectIdGetEventTypesSuccess implements Action {
  readonly type = GET_EVENT_TYPES_SUCCESS;

  constructor(public payload: any) {}
}

export class SearchforceConnectIdGetEventTypesFail implements Action {
  readonly type = GET_EVENT_TYPES_FAIL;

  constructor(public payload: any = null) {}
}

export class SearchforceConnectIdEventsSearchClear implements Action {
  readonly type = SEARCHFORCE_CONNECT_ID_SEARCH_CLEAR;

  constructor(public payload: any = null) {}
}

export class GetLinkedAccounts implements Action {
  readonly type = GET_LINKED_ACCOUNTS;

  constructor(public payload: string) {}
}

export class GetLinkedAccountsSuccess implements Action {
  readonly type = GET_LINKED_ACCOUNTS_SUCCESS;

  constructor(public payload: AmwayAccountList[]) {}
}

export class GetLinkedAccountsFail implements Action {
  readonly type = GET_LINKED_ACCOUNTS_FAIL;

  constructor(public payload: any = null) {}
}

export type Actions =
  | SearchforceConnectIdEventsSearch
  | SearchforceConnectIdEventsSearchSuccess
  | SearchforceConnectIdEventsSearchFail
  | SearchforceConnectIdEventsSearchClear
  | SearchforceConnectIdGetEventTypes
  | SearchforceConnectIdGetEventTypesSuccess
  | SearchforceConnectIdGetEventTypesFail
  | GetLinkedAccounts
  | GetLinkedAccountsSuccess
  | GetLinkedAccountsFail;
