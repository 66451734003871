import {
  SEARCHFORCE_CONNECT_ID_SEARCH,
  SEARCHFORCE_CONNECT_ID_SEARCH_SUCCESS,
  SEARCHFORCE_CONNECT_ID_SEARCH_CLEAR,
  SEARCHFORCE_CONNECT_ID_SEARCH_FAIL,
  GET_EVENT_TYPES,
  GET_EVENT_TYPES_SUCCESS,
  GET_EVENT_TYPES_FAIL,
  GET_LINKED_ACCOUNTS,
  GET_LINKED_ACCOUNTS_SUCCESS,
  GET_LINKED_ACCOUNTS_FAIL,
  Actions
} from './find-amway-id-events.actions';
import {
  SearchforceConnectIdState,
  LinkedAccountsState
} from '../../shared/models/searchforce/connect-id.model';

const defaultAccountsState = {
  list: null,
  loading: false,
  loaded: false,
};

const defaultState = {
  results: null,
  accounts: defaultAccountsState,
  eventTypes: null,
  loading: false,
  loaded: false,
};

export function linkedAccountsReducer(state, action) {
  switch (action.type) {
    case GET_LINKED_ACCOUNTS:
      return { ...state, loading: true, loaded: false };

    case GET_LINKED_ACCOUNTS_SUCCESS:
      return { ...state, loading: false, loaded: true, list: action.payload };

    case GET_LINKED_ACCOUNTS_FAIL:
      return defaultAccountsState;

    default:
      return state;
  }
}

export function searchforceConnectIdSearchReducer(state: SearchforceConnectIdState, action: Actions): SearchforceConnectIdState {
  switch (action.type) {
    case SEARCHFORCE_CONNECT_ID_SEARCH:
      return { ...state, loading: true, loaded: false, results: null };

    case SEARCHFORCE_CONNECT_ID_SEARCH_SUCCESS:
      return { ...state, loaded: true, loading: false, results: action.payload };

    case GET_EVENT_TYPES_SUCCESS:
      return { ...state, eventTypes: action.payload };

    case SEARCHFORCE_CONNECT_ID_SEARCH_FAIL:
      return { ...state, loading: false, loaded: false };

    case GET_LINKED_ACCOUNTS:
    case GET_LINKED_ACCOUNTS_SUCCESS:
    case GET_LINKED_ACCOUNTS_FAIL:
      return { ...state, accounts: linkedAccountsReducer(state.accounts, action) };


    case SEARCHFORCE_CONNECT_ID_SEARCH_CLEAR:
      return defaultState;

    default:
      return state;
  }
}
