import { Effect, Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Response } from '@angular/http';
import { Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';
import {
  ABO_BUSINESS_DETAILS_LOAD,
  AboBusinessDetailsLoad,
  AboBusinessDetailsLoadSuccess,
  AboBusinessDetailsLoadFail
} from './abo-business-details.actions';
import { AboBusinessDetailService } from '../../shared/services/abo-business-details.service';
import { CommonService } from '../../core/services/common.service';

@Injectable()
export class AboBusinessDetailsEffects {
  @Effect()
  loadIboBusinessDetails$ = this.actions$
    .ofType(ABO_BUSINESS_DETAILS_LOAD).pipe(
    switchMap(({ payload }: AboBusinessDetailsLoad) => {
      return this.businessDetailService.getBusinessDetails(payload)
      .pipe(catchError(error => of(new AboBusinessDetailsLoadFail()))
      ,map((response) => {
        return new AboBusinessDetailsLoadSuccess(response);
      }));
    }));

  constructor(
    private actions$: Actions,
    private commonService: CommonService,
    private businessDetailService: AboBusinessDetailService
  ) {}
}
