import { PhoneInfo } from './../../shared/models/common.model';
import { Injectable } from '@angular/core';
import { PartyParams } from '../../shared/models/index';

import * as _ from 'lodash';
import * as moment from 'moment';
import { FRN_TAX_TYPE } from '../../abo/abo-settings';
import { ABOBasicInfo, BusinessNatureName } from '../../shared/models/amway/index';
import { LABELS } from '../../shared/configs/labels.settings';
import { DomSanitizer } from '@angular/platform-browser';
import allLanguagesMap from "../../shared/configs/languages";
import { ABO_NAME, MEMBER_NAME, CLIENT_NAME, EMPLOYEE_NAME } from '../../shared/configs/constants';

const ENTER_KEY = 'Enter';
const VALID_CODE = 'Valid';

@Injectable()
export class CommonService {
  constructor(private sanitizer: DomSanitizer) {}

  static getActualData<T extends { [key: string]: any }>(arr: T[], primaryFlag?: boolean): T {
    return arr.find((el: T) => {
      return el.statusCd === VALID_CODE && (!primaryFlag || _.get(el, 'usageList[0].primaryFlag', false));
    }) || {} as T;
  }

  static getLanguageName(languageCode: string): string {
    let languageCodeStr: string;
    if (languageCode ){
      languageCodeStr = languageCode.toLowerCase();
    }
    return allLanguagesMap[languageCodeStr] ? allLanguagesMap[languageCodeStr].name : languageCode;
  }

  /**
  * Formats ABO params to unique string
  * @param  {PartyParams} params IBO params
  * @return {string}             String with ABO/AFF numbers
  */
  static getAboString(params: PartyParams): string {
    return `u${params.aff}_${params.abo}`;
  }
  /**
   * renewal period:
   * from 01.09
   * till 31.12
   */
  static isRenewalPeriod() {
    const date = new Date();
    const start = new Date(date.getFullYear(), 8, 1);
    const end = new Date(date.getFullYear(), 11, 31);

    return date > start && date < end;
  }

  static getRefundAmount(date: string) {
    let returnRefundAmt = 0;
    const activateDate = new Date(date);
    const todayDate = new Date();
    const timeDiff = Math.abs(todayDate.getTime() - activateDate.getTime());
    const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
    if (dayDiff <= 100) {
      returnRefundAmt = 90909;
    }
    return returnRefundAmt;
  }

  getLabel(type: string, cntryCd: string): string {
    return _.get(LABELS, [type, cntryCd], _.get(LABELS, [type, 'default'], `{{${type}}}`));
  }


  /**
   * Gets a search value by specified key
   * @param {string} queryKey
   * @returns {string}
   * @memberof CommonService
   */
  getQueryVal(queryKey: string): string {
    const query = window.location.search.substring(1);
    return query.split('&').reduce((memo, iter) => {
      const pair = iter.split('=');
      const lookupPair = decodeURIComponent(pair[0]) === queryKey;
      memo += lookupPair ? decodeURIComponent(pair[1]) : '';
      return memo;
    }, '');
  }


  /**
   * Gets utc date in appropriate date format
   * @param {string} date
   * @param {string} format
   * @returns {string}
   * @memberof CommonService
   */
  getFormattedDate(date: string, format: string): string {
    return date ? moment.utc(date).format(format) : '';
  }

  /**
 * formats date string into proper format
 * @param {string} dateString
 * @returns {string}
 */
  getBodyFormattedDate(dateString: string): string {
    // return `${dateString.split('.')[0]}Z`;
    return dateString;

  }

  /**
   * Gets difference between 2 dates
   * @param  {Date} date1
   * @param  {Date} date2
   */
  getDaysDiff(date1, date2) {
    const dayInMs = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const timeDiff = Math.abs(date2.getTime() - date1.getTime());

    return Math.ceil(timeDiff / dayInMs);
  }

  /**
   * Gets engaged phone
   * @param {PhoneInfo} phone
   * @returns {string}
   * @memberOf CommonService
   */
  getEngagedPhone({ phoneAreaCd, phoneCntryCd, phoneLocalNum }: PhoneInfo): string {
    return (phoneCntryCd ? `+${phoneCntryCd} ` : '') +
      (phoneLocalNum ? phoneLocalNum : '');
  }

  /**
   * currency formatter
   * @param {number|string} value
   * @param {string} code
   * @returns {string}
   * @memberof CommonService
   */
  getFormattedPrice(value: number | string, code: string): string { // TODO: make currency formatter
    return `$${value}`;
  }

  /**
   * returns tax id for displaying depending on tax id type
   * @param {ABOBasicInfo} info
   * @returns {string}
   */
  getDisplayedTaxId(info: ABOBasicInfo): string {
    const taxTypeCd = _.get(info, 'tax.taxTypeCd', '');
    const taxId = _.get(info, 'tax.taxId', '');
    return taxTypeCd === FRN_TAX_TYPE ? '' : taxId;
  }


  /**
   * checks if pressed key is enter
   * @param {KeyboardEvent} event
   * @returns {boolean}
   */
  isEnter(event: KeyboardEvent): boolean {
    return event.key === ENTER_KEY;
  }

  /**
   * generates random number within the range provided
   * @param min minimum number
   * @param max maximum number
   */
  getRandomNumber(min, max) {
    return (Math.floor(Math.random() * (max - min + 1)) + min) + '';
  }

  blobToObjectUrl(blob: Blob) {
    const sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      window.URL.createObjectURL(blob)
    );
    return { url: sanitizedUrl };
  }

  static replacePropertyValue(pkey, pval, object) {
    return JSON.parse(JSON.stringify(object), (key, value) => {
      if (key==pkey) {
      return pval;
      } else {
      return value;
      }
    })
  }

  static getBusinessNature(account){
    let businessNature = ABO_NAME;
    if (account.accountSubTypeCd === BusinessNatureName.Customer) { businessNature =  CLIENT_NAME };
    if (account.accountSubTypeCd === BusinessNatureName.Member) { businessNature =  MEMBER_NAME };
    if (account.accountSubTypeCd === BusinessNatureName.Employee) { businessNature =  EMPLOYEE_NAME };
    return businessNature;
  }

}
