import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { IAppState } from '../../store';
import { AboRestrictionBlocksLoadActive } from '../../store/abo-restriction-blocks/abo-restriction-blocks.actions';
import { PartyParams } from '../../shared/models';
import { CommonService } from '../../core/services';
import {
  RestrictionBlocks,
  RestrictionBlockData
} from '../../shared/models/amway';

@Component({
  selector: 'app-abo-restriction-blocks',
  templateUrl: './abo-restriction-blocks.component.html',
  styleUrls: ['./abo-restriction-blocks.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboRestrictionBlocksComponent implements OnInit, AfterViewInit {
  store$: Observable<RestrictionBlocks>;
  blocks$: Observable<RestrictionBlockData[]>;

  constructor(
    private store: Store<IAppState>,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.updateActiveBlocks();
    this.store$ = this.store
      .select('aboRestrictionBlocks')
      .pipe(filter(Boolean)
      ,map(
        data =>
          data[
          CommonService.getAboString(this.activatedRoute.snapshot
            .params as PartyParams)
          ]
      )
      ,filter(Boolean));
    this.blocks$ = this.store$.pipe(
      filter(data => data.loadedActive)
      , map(store => {
        return store.data && store.data.active;
      }));
  }

  ngAfterViewInit() {

  }
  /**
   *  Updates active blocks on call from child component
   */
  updateActiveBlocks() {
    this.store.dispatch(
      new AboRestrictionBlocksLoadActive({
        params: this.activatedRoute.snapshot.params as PartyParams
      })
    );
  }
}
