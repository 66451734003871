import { NameList, AddressList } from '../models/party-basic.models';
import { PartyList } from '../models/amway/party-list.model';
import { UsageList } from '../models/amway/usage-list.model';
import { ContactPointTypeCdEnum, ContactPointPurposeCdEnum } from '../models/enums';

export function getPhoneNumber(cntryCode, phoneNum) {
  return `${cntryCode ? '+' : ''}${cntryCode || ''} ${phoneNum || ''}`;
}

export function getFullName(nameList: NameList[]) {
  let fullName = '';
  if (nameList && nameList.length > 0) {
    const nameInfo = nameList[0];
    fullName =
      (nameInfo.localeName.givenName || '') +
      ' ' +
      (nameInfo.localeName.familyName || '');
  }
  return fullName;
}
export function getFullNameLatinName(nameList: NameList[]) {
  let fullName = '';
  if (nameList && nameList.length > 0) {
    const nameInfo = nameList[0];
    fullName =
      (nameInfo.latinName.givenName || '') +
      ' ' +
      (nameInfo.latinName.familyName || '');
  }
  return fullName;
}
export function getAccountName(name, currentParty: PartyList) {
  let accountName = '';
  if (
    currentParty &&
    currentParty.nameList &&
    currentParty.nameList.length > 0
  ) {
    const nameInfo = currentParty.nameList[0].localeName;
    if (nameInfo) {
      accountName =
        accountName +
        `${nameInfo.givenName || ''} ${nameInfo.familyName || ''}`;
    }
  }
  return accountName;
}

export function getPartyName(name, currentParty: PartyList) {
  let accountName = '';
  if (
    currentParty &&
    currentParty.nameList &&
    currentParty.nameList.length > 0
  ) {
    const nameInfo = currentParty.nameList[0].localeName;
    if (nameInfo) {
      accountName = `${nameInfo.givenName || ''} ${nameInfo.familyName || ''}`;
    }
  } else {
    accountName = name;
  }
  return accountName;
}

export function processAddresses(
  addresses: AddressList[],
  USAGE_ADDRESS_TYPES: any[]
): AddressList[] {
  return USAGE_ADDRESS_TYPES.map(type => type.toString()).map(
    (type): AddressList => {
      const address = {
        ...addresses.find(({ usageList }) =>
          hasActualUsageList(usageList, type)
        )
      };

      // get district and sub dist info
      if (address.addrLineFour) {
        const adrInfo = address.addrLineFour.split(',');
        address.districtName = adrInfo[0];
        address.subDistrictName = adrInfo.length > 1 ? adrInfo[1] : '';
      }
      return {
        ...address,
        usageList: getActualUsageList(type, address)
      };
    }
  );
}

export function hasActualUsageList(
  usageList: UsageList[],
  type: string
): boolean {
  return !!usageList.find(({ contactPointPurposeCd, primaryFlag }) => {
    return contactPointPurposeCd === type && primaryFlag;
  });
}

export function getActualUsageList(
  type: string,
  address: AddressList
): UsageList[] {
  return [
    {
      contactPointPurposeCd: type,
      primaryFlag: true,
      careOf:
        address && address.usageList
          ? address.usageList.find(usg => usg.contactPointPurposeCd === type)
              .careOf
          : ''
    }
  ];
}
export function blobToObjectUrl(blob: Blob) {
  const sanitizedUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
    window.URL.createObjectURL(blob)
  );
  return sanitizedUrl || '';
}

export function arrayBufferToBlob(buffer, fileType) {
  const arrayBufferView = new Uint8Array(buffer);
  const blob = new Blob([arrayBufferView], { type: 'image/jpeg' });
  return blob;
}

/*
 Validate the file going to be uploaded
 Validate the file extension
 Validate the file size
*/
export function ValidateUploadedFile(
  file: File,
  allowedExt: string[],
  maxSizeAllowed: number
): string[] {
  const errors: string[] = [];
  const fileTypeError = ValidateFileType(file, allowedExt);
  const fileSizeError = ValidateFileSize(file, maxSizeAllowed);
  if (fileTypeError) {
    errors.push(fileTypeError);
  }
  if (fileSizeError) {
    errors.push(fileSizeError);
  }
  return errors;
}

export function ValidateFileSize(file: File, maxSizeAllowed: number) {
  let error = null;
  if (file) {
    if (file.size === 0) {
      error = 'File size can not be zero';
    }
    if (file.size > maxSizeAllowed) {
      error = `File can not exceed the max allowed limit (${Math.round(
        (maxSizeAllowed / 1000000) * 10
      ) / 10} MB)`;
    }
  } else {
    error = 'File Not Found';
  }
  return error;
}

export function ValidateFileType(file: File, allowedFileTypes: string[]) {
  let error = null;
  let fileType = getFileExtension(file.name);
  if (fileType) {
    fileType = ('.' + fileType).toLowerCase();
    if (allowedFileTypes.indexOf(fileType) === -1) {
      error = `Selected file type is not allowed, only ${allowedFileTypes.join(
        ', '
      )} file types are allowed.`;
    }
  } else {
    error = `Selected file type is not allowed, only ${allowedFileTypes.join(
      ', '
    )} file types are allowed.`;
  }
  return error;
}

export function getFileExtension(filename: string) {
  // tslint:disable-next-line:no-bitwise
  return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2);
}

export function getActiveEmailInfo(ecommList){
  return ecommList.filter(eCom => {
    return (
      (eCom.contactPointTypeCd ===
        ContactPointTypeCdEnum.PersonalEmail.toString() ||
        eCom.contactPointTypeCd ===
          ContactPointTypeCdEnum.BusinessEmail.toString()) &&
      eCom.ecommTypeCd === ContactPointPurposeCdEnum.Email.toString()
      && eCom.statusCd === 'Valid'
    );
  });
}

export function checkAutoRenewalStatus(accountDetails){
  let autoRenewalSub:any;
  autoRenewalSub = accountDetails.data.subscriptionList.find(subsList => {
    return subsList.subscriptionId == "50"
  })
  return (autoRenewalSub && autoRenewalSub.subscribeFlag == 'Y' ? 'Active' : 'Inactive')
}
