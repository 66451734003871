import { Injectable, Pipe } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from '../../store/index';
import { RouteChange } from '../../store/history/history.actions';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { RouteInfo } from '../../shared/models/index';

const ROUTE_DATA_LABEL = 'label';
const ROUTE_DATA_PARENTS = 'parents';

@Injectable()
export class HistoryService {
  private recorder: Subscription;

  constructor(
    private store: Store<IAppState>,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  /**
   * Starts listen router events
   * collect data for current route
   * @memberOf HistoryService
   */
  startRecord() {
    this.recorder = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
      ,map(e => this.findRoute(this.route.root.children))).pipe(
      filter(route => !!route)
      ,map(route => this.getCurrentRouteInfo(route)))
      .subscribe(routeInfo => this.store.dispatch(new RouteChange(routeInfo)));
  }

  /**
   * unsubscribe router events
   * @memberOf HistoryService
   */
  stopRecording() {
    this.recorder.unsubscribe();
  }

  /**
   * Finds active base route
   *
   * @private
   * @param {ActivatedRoute[]} routes
   * @returns {ActivatedRoute}
   *
   * @memberOf HistoryService
   */
  private findRoute(routes: ActivatedRoute[]): ActivatedRoute {
    return routes.find((route) => {
      return route.outlet === PRIMARY_OUTLET && route.snapshot.data.hasOwnProperty(ROUTE_DATA_LABEL);
    });
  }

  /**
   * Collects data from Activated route
   * @private
   * @param {ActivatedRoute} route
   * @returns {RouteInfo}
   * @memberOf HistoryService
   */
  private getCurrentRouteInfo(route: ActivatedRoute): RouteInfo {
    const params = _.get(route, 'children[0].snapshot.params', {});
    const snapshot = route.snapshot;
    const data = snapshot.data;
    const path = snapshot.routeConfig.path;

    return {
      path: path,
      url: `/${path}/${_.values(params).join('/')}`,
      label: data[ROUTE_DATA_LABEL],
      parents: this.getParentsDefaults(data[ROUTE_DATA_PARENTS]),
      queryParams: snapshot.queryParams
    };
  }

  /**
   * Fills default data for parent rotes
   * @private
   * @param {string[]} parents
   * @returns {RouteInfo[]}
   *
   * @memberOf HistoryService
   */
  private getParentsDefaults(parents: string[]): RouteInfo[] {
    return parents.map((parentPath) => {
      const { path, data } = this.router.config.find(config => config.path === parentPath);

      return {
        path,
        label: data[ROUTE_DATA_LABEL],
        url: `/${path}`
      };
    });
  }

}
