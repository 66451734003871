export class Tax {
    /**
     * <a href='http://dms-service-dev:8080/DMS_Service_Web/DomainCodeLookUp.jsp?cod
     * e=TAXTY'>Reference to Tax Type Code</a>
     */
    taxTypeCd: string;
    expirationDate: string;
    taxId: string;
    cntryCd: string;
    partyId: string;
    vatRegister: string;
    taxPayerType: string;
    effectiveDate: string;
  }

  export class TaxDetailUpdateEventData {

  }
