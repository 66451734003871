import { Injectable } from '@angular/core';
import { ApiPathConfigService } from '../configs/api';
import { HttpService } from '../../core/services';
import {
  RestrictionBlocksResponse,
  RestrictionBlockConfigService
} from '../models/amway/restriction-blocks.model';
import { Observable, forkJoin } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AccountService } from './account.service';
import { processRestrictionBlocks } from '../helpers/restriction-blocks.helper';
import { salesAff, aboNum, blockPrivTypeId, restrictionBlocksAPI, updateRestrictionBlockAPI,
  updateAllRestrictionBlocksAPI } from '../configs/constants';

@Injectable()
export class AboRestrictionBlockService {
  constructor(
    private httpService: HttpService,
    private accountService: AccountService,
    private apiPathService: ApiPathConfigService
  ) {}

  getActiveRestrictionBlocks(request: any) {
    const restrinctionBlockObservable: Observable<any> = new Observable(
      observer => {
        forkJoin(
          this.accountService.getAccountDetail(request),
          this.getAllRestrictionBlocks(request)
        ).subscribe(
          ([accountInfo, allBlocks]) => {
            const restictionblocks = processRestrictionBlocks(
              accountInfo.blockPrivilegeList,
              allBlocks
            );
            observer.next(restictionblocks);
          },
          error => {
            observer.error(error);
          }
        );
      }
    );

    return restrinctionBlockObservable;
  }
  getAllRestrictionBlocks(request) {
    const api = this.apiPathService.getApiPath(restrictionBlocksAPI);
    api.path = api.path.replace(salesAff, request.aff + '');
    return this.httpService
      .callServerGET(api, null, null)
      .pipe(catchError(error => Observable.throw(error))
      ,map(response => {
        return response && response.blockPrivilegeList
          ? response.blockPrivilegeList
          : [];
      }));
  }

  UpdateRestrictionBlock(request: any, params = null, payload = null) {
    const api = this.apiPathService.getApiPath(updateRestrictionBlockAPI);
    api.path = api.path.replace(
      salesAff,
      request.aff + ''
    )
      .replace(aboNum, request.abo + '')
      .replace(blockPrivTypeId, payload.blockPrivTypeId + '');
    return this.httpService.callServerPUT(api, params, payload);
  }

  DeactivateAllRestrictionBlocks(request: any, params = null, payload = null) {
    const api = this.apiPathService.getApiPath(updateAllRestrictionBlocksAPI);
    // const api = UpdateAllRestrictionBlocksAPIUrl;
    return this.httpService.callServerPUT(api, null, {});
  }
}
