import {
    TAX_CERTIFICATE_LOAD,
    TAX_CERTIFICATE_LOAD_SUCCESS,
    TAX_CERTIFICATE_LOAD_FAIL,
    Actions
  } from './tax-certificate.actions';

  export function TaxCertificateReducer(state: any, action: Actions): any {
    switch (action.type) {
      case TAX_CERTIFICATE_LOAD:
        return {};
      case TAX_CERTIFICATE_LOAD_SUCCESS:
        return { ...state, ...action.payload, loaded: true };
      case TAX_CERTIFICATE_LOAD_FAIL:
        return { ...state, loaded: true };
      default:
        return state;
    }
  }
