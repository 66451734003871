import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { ApiPathConfigService } from '../configs/api';
import { userName, getAmwayIdAPI, createAmwayIdAPI, editAmwayIdAPI, getEnrollRecordByPartyId, updateEnrolledAmwayIdAPI, enrollmentId } from '../configs/constants';
import { HttpService } from '../../core/services/http.service';
import { AmwayUserProfileCreatePayload } from '../models/abo-connect.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class AboConnectService {
  client_app: String;
  redirect_url: String;
  constructor(private httpService: HttpService,
    private apiPathService: ApiPathConfigService
  ) { }

  getUserData(userNameValue) {
    const api = this.apiPathService.getApiPath(getAmwayIdAPI);
    api.path = api.path.replace(userName, userNameValue);
    return this.httpService.callServerGET(api, null).pipe(map(
      response => {
        return response;
        // const locations =
        //   response && response.length
        //     ? response.map(loc => new AreaLocation(loc))
        //     : ([] as AreaLocation[]);
        // observer.next({ loading: false, data: locations });
        // observer.complete();
      },
      error => {
        console.log('error' + error);
        // this.LogError(error);
        // observer.next({ loading: false, data: [] as AreaLocation[] });
        // observer.complete();
      }
    ));


    // let apiUrl = './assets/getUser.json';
    // return this.http.get(apiUrl)
    //   .map((response) => {
    //     const data = response;
    //     return data;
    //   });
  }

  getEnrollmentRecordByPartyId(partyIDValue) {
    const api = this.apiPathService.getApiPath(getEnrollRecordByPartyId);
    let urlParams: HttpParams = new HttpParams();
    urlParams = urlParams.set('partyId', partyIDValue);
    return this.httpService.callServerGET(api, urlParams).pipe(map(
      response => {
        return response;
      },
      error => {
        console.log('error' + error);
      }
    ));
  }

  createUserProfile(searchQuery, partyId) {
    const api = this.apiPathService.getApiPath(createAmwayIdAPI);
    const bodyParams = this.getCreateUserProfileBodyParams(searchQuery, partyId);
    return this.httpService
      .callServerPOST(api, null, bodyParams)
      .pipe(map((response => {
        return response;
      })));
  }

  editUserProfile(searchQuery, oldUserName) {
    const api = this.apiPathService.getApiPath(editAmwayIdAPI);
    api.path = api.path.replace(userName, oldUserName);
    const bodyParams = this.getEditUserProfileBodyParams(searchQuery);
    return this.httpService
      .callServerPOST(api, null, bodyParams)
      .pipe(map((response => {
        return response;
      })));
  }

  updateEnrolledAmwayId(enrolledId, oldUserName) {
    const api = this.apiPathService.getApiPath(updateEnrolledAmwayIdAPI);
    api.path = api.path.replace(enrollmentId, enrolledId);
    const bodyParams = this.updateEnrolledAmwayIdBodyParams(oldUserName);
    return this.httpService.callServerPatch(api, null, bodyParams).pipe(map(
      response => {
        return response;
      },
      error => {
        console.log('error' + error);
      }
    ));
  }

  getCreateUserProfileBodyParams(profilePayload, partyId) {

    this.client_app = environment.amCommClientApp;
    this.redirect_url = environment.amCommRedirectUrl;

    const email = {
      email: profilePayload.userName,
      verified: false
    };

    // const attributes = {
    //   partyId: parseInt(partyId)
    // };

    const defaultOptions = {
      application: {
        clientApp: this.client_app,
        redirect: this.redirect_url
      }
    };

    return { ...defaultOptions, email, partyId: parseInt(partyId), isoCountryCode: "au" };

  }

  getEditUserProfileBodyParams(profilePayload) {
    return {
      username: profilePayload.userName,
      clientApp: environment.amCommClientApp
    }
  }

  updateEnrolledAmwayIdBodyParams(value) {
    return [{
      "op": "replace",
      "path": "/email/email",
      "value": value
    }]
  }

}