import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { CoreConfig } from '../shared/models/index';
import { coreConfig } from './core.config';
import {
  StorageService, HistoryService,
  AuthService, HttpService, ErrorLogService, AppErrorHandler, CommonService, NavigationService, LoggerService
} from './services/index';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from './interceptors/http-request.interceptor';
import { AuthGuard } from './guards/auth.guard';
import { ErrorService } from '../shared/services';

@NgModule({
  imports: [CommonModule],
  declarations: [],
  providers: [
    StorageService,
    HttpService,
    HistoryService,
    AuthService,
    CommonService,
    NavigationService,
    ErrorLogService,
    LoggerService,
    AuthGuard,
    {
      provide: ErrorHandler,
      deps: [ErrorLogService, ErrorService],
      useClass: AppErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true
    }
  ]
})
export class CoreModule {

  static forRoot(config: CoreConfig): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [{ provide: coreConfig, useValue: config }]
    };
  }

}
