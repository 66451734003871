import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Observable, of } from 'rxjs';
import { Modal } from 'ngx-modal/Modal';
import { StorageService, AuthService } from '../core/services/index';
import { OktaJwtToken } from '../shared/models/index';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  links: { route: string; title: string, externalRoute: boolean  }[] = [];
  eventSearchURL: string;
  externalLink: boolean;
  
  @ViewChild('registerEmployeePopup') registerEmployeePopup: Modal;

  constructor(private translateService: TranslateService,
    private authService: AuthService,
    private storage: StorageService) {}
  ngOnInit() {
    this.updateLinks();
  }
  getTranslatedString(value) {
    if (value) {
      return this.translateService.stream(value);
    } else {
      of('');
    }
  }
  updateLinks() {
    const token = <OktaJwtToken>this.storage.getItem('okta-jwt-token');
    if (token === null || token['id_token'] === undefined) {
      this.eventSearchURL = this.authService.buildOktaJwtAuthCodePath();
      this.externalLink = true;
    } else {
      this.eventSearchURL = '/searchforce-find-connect-id';
      this.externalLink = false;
    }
    this.links = [
      {
        route: '/find-abo',
        title: 'Find AN IBO/VIP Client/Employee',
        externalRoute: false
      },
      {
        route: this.eventSearchURL,
        title: 'find amway id events',
        externalRoute: this.externalLink
      }
    ];
  }
  ngOnDestroy() {}

  registerEmployee(e) {
    this.registerEmployeePopup.open();
  }

  registerEmployeePopupClose(){
    this.registerEmployeePopup.close();
  }
}

