import { HttpService } from '../../core/services/http.service';
import { Injectable } from '@angular/core';
import { ApiPathConfigService } from '../configs/api';
import { salesAff, aboNum, partyId, getTaxCertificateAPI, uploadTaxCertificateAPI, defaultCountryCode,
  TAX_CERT_CODE } from '../configs/constants';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { CommonService } from '../../core/services';
import * as moment from 'moment';
import { ApiConfig } from '../models/api-url-config.model';

@Injectable()
export class TaxCertificateService {
  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private commonService: CommonService,
    private apiPathService: ApiPathConfigService
  ) {}

  getTaxCertficate(request: any) {
    const api = this.apiPathService.getApiPath(getTaxCertificateAPI);
    api.path = api.path
      .replace(salesAff, request.aff)
      .replace(aboNum, request.abo)
      .replace(partyId, request.partyId);
    let urlParams: HttpParams = new HttpParams();
    urlParams = urlParams.set('fileRefUrl', request.fileUrl);
    return this.callServerGet('get', api, urlParams);
  }
  uploadTaxCertificate(request, payload) {
    const api = this.apiPathService.getApiPath(uploadTaxCertificateAPI);
    api.path = api.path
      .replace(salesAff, request.aff)
      .replace(aboNum, request.abo)
      .replace(partyId, request.partyId);
    let urlParams: HttpParams = new HttpParams();
    urlParams = urlParams.set('cntryCd', defaultCountryCode);
    urlParams = urlParams.set('entryDate', moment().format());
    urlParams = urlParams.set('taxTypeCd', 'NPWP');
    urlParams = urlParams.set('taxCertificateCd', TAX_CERT_CODE);
    const formData = this.fileToFormData(payload);
    return this.httpService.callServerPOSTFile(api, urlParams, formData);
  }
  private callServerGet(
    method: string,
    api: ApiConfig,
    urlSearchParams?: HttpParams
  ) {
    if (!urlSearchParams) {
      urlSearchParams = new HttpParams();
    }
    let headers = new HttpHeaders();
    headers = headers.append(
      'X-Request-Id',
      this.commonService.getRandomNumber(1, 99999)
    );
    return this.http.request(method, this.httpService.buildPath(api), {
      params: urlSearchParams,
      headers: headers,
      responseType: 'blob'
    });
  }

  private fileToFormData(file: File) {
    const form = new FormData();
    form.append('file' , file, file.name);
    return form;
   }
}
