import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as Joi from 'joi-browser';

import { TAX_MASKS, FRN_TAX_TYPE, TAX_MANDATORY_CD, ZIP_CODE_MANDATORY_CD } from './abo-settings';
import { ABOBasicInfo, ValidationError, ABOAddress } from '../shared/models/amway/index';

import { RegistrationsService } from '../shared/services/registrations.service'
import { HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { CountryEnvironmentService } from '../shared/services/country-environment.service';

const EMAIL_REQUIRED_ERROR = 'Please enter email';
const EMAIL_INVALID_ERROR = 'Please enter valid email address';
const NAME_SPECIAL_CHAR_INVALID = 'Please enter valid name. Only (. , \') special charaters are allowed';
const LANGUAGE_REQUIRED_ERROR = 'Please select preferred language';
const BIRTHDAY_REQUIRED_ERROR = 'Please select date of birth';
const BIRTHDAY_INVALID_ERROR = 'You should be older than 18 years old';
const GIVEN_NAME_REQUIRED_ERROR = 'Please enter first name';
const FAMILY_NAME_REQUIRED_ERROR = 'Please enter last name';
const TAX_ID_REQUIRED_ERROR = 'Please enter Tax ID';
const TAX_ID_INCOMPLETE_ERROR = 'Please complete Tax ID';
const TAX_ID_TYPE_REQUIRED_ERROR = 'Please enter Tax ID Type';
const ADDRESS_LINE_ONE_REQUIRED_ERROR = 'Please enter address line 1';
const CITY_NAME_REQUIRED_ERROR = 'Please enter city name';
const ZIP_CODE_REQUIRED_ERROR = 'Please enter zip code';
const STATE_REQUIRED_ERROR = 'Please select state';
const PHONE_NUMBER_REQUIRED_ERROR = 'Please enter phone number';
const PHONE_INVALID_ERROR = 'Please enter valid phone number';
const AREACD_INVALID_ERROR = 'Please enter valid area code';
const CID_REQUIRED_ERROR = 'Please enter CID';
const CID_INVALID_ERROR = 'Please enter valid CID';
const PID_INVALID_ERROR = 'Please enter valid passport';

const PHONE_REGEX  = /[^0-9]/;
const ALT_PHONE_REGEX = /^\d{7,8}$/;
const AREA_CODE_REGEX = /^\d{2}$/;

// We can't use template literals here for multiline text since regex works incorrectly with it
// const EMAIL_REGEX = '^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))' +
//   '@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])' +
//   '|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,64}))$';
const EMAIL_REGEX = /\S+@\S+\.\S+/;
const FIRST_NAME_REGEX = /[^a-zA-Z0-9,.']/;
const NUMBER_REGEX = /[^0-9]/;
const NUMB_AND_CHAR_REGX = /[^a-zA-Z0-9]/;

const AMWAY_DOMAIN = '@amway.com';

@Injectable()
export class AboDetailsValidationService {

  constructor(private registrationService: RegistrationsService,
    private countryEnvironmentService: CountryEnvironmentService) { }

  /**
   * returns all errors related to validation ABO basic info
   * @param {ABOBasicInfo} data
   * @returns {ValidationError}
   */
  getBasicInfoErrors(data: ABOBasicInfo): ValidationError {
    return Object.assign({},
      this.getFirstnameErrors(data),
      this.getLastnameErrors(data),
      this.getBirthDateError(data),
      this.getGenderCdError(data),
      this.getContactError(data),
      this.getMobilePhoneErrors(data),
      this.getMobileAlternatePhoneErrors(data),
      this.getEmailErrors(data.email)
    );
  }

  private getContactError(data) {
    if (data && !data.alternatePhoneInfo.phoneLocalNum && !data.mobilePhoneInfo.phoneLocalNum) {
      return { phoneLocalNum: 'Please provide atleast one phone number' }
    }

  }

  private getMobileAlternatePhoneErrors(data: ABOBasicInfo) {
    let alternatePhoneLocalNum = null;
    if (data && data.alternatePhoneInfo && data.alternatePhoneInfo.phoneLocalNum) {
      alternatePhoneLocalNum = data.alternatePhoneInfo.phoneLocalNum;
      if (!ALT_PHONE_REGEX.test(alternatePhoneLocalNum) || (alternatePhoneLocalNum.length > 20)) {
        return { alternatePhoneLocalNum: PHONE_INVALID_ERROR };
      }
    } else if (data && data.alternatePhoneInfo &&
      ((data.alternatePhoneInfo.phoneLocalNum && !data.alternatePhoneInfo.phoneAreaCd) ||
        !data.alternatePhoneInfo.phoneLocalNum && data.alternatePhoneInfo.phoneAreaCd)) {
      return { alternatePhoneLocalNum: 'Please enter complete landline number' }
    }
  }

  private  getMobilePhoneErrors(data: ABOBasicInfo) {
    let phoneLocalNum = null;
    if (data && data.mobilePhoneInfo) {
      phoneLocalNum = data.mobilePhoneInfo.phoneLocalNum;
    }
    if (!phoneLocalNum) { return { phoneLocalNum: 'Please enter valid mobile number' } }

    if (phoneLocalNum[0] == '0') {
      return { phoneLocalNum: 'Oops! The mobile number entered cannot start with 0.' }
    }
    if(!PHONE_REGEX.test(phoneLocalNum)){
      return { phoneLocalNum: PHONE_INVALID_ERROR };
    }
  }

  validateCid(data) {
    let cid = data.iCrdNum
    if (cid && (PHONE_REGEX.test(cid) || !(this.verifyCitizenIdChecksum(cid)))) {
      return { iCrdNum: CID_INVALID_ERROR };
    }
  }

  getEmployeeError(data) {
    let schema = Joi.object().keys({
      givenName: Joi.string().required().options({ language: { any: { required: "First name is required" } } }),
      familyName: Joi.string().required().options({ language: { any: { required: "Last name is required" } } }),
      addrStreet: Joi.string().required().options({ language: { any: { required: "Address is required" } } }),
      province: Joi.string().options({ language: { any: { required: "Province is required" } } }),
      postalCd: Joi.string().required().options({ language: { any: { required: "Postal code is required" } } }),
      aboEntryDate: Joi.string().required().options({ language: { any: { empty: "Hire date is required" } } }),
      status: Joi.string().required().options({ language: { any: { required: "Status is required" } } }),
      empNum: Joi.number().integer().required().options({ language: { any: { required: "Employee number is required" } } }),
      districtNm: Joi.string().options({ language: { any: { required: "District name is required" } } }),
      subDistrictNm: Joi.string().options({ language: { any: { required: "Sub district name is required" } } }),
      cid: Joi.string().options({ language: { any: { required: "Cid is required" } } })
    }).unknown();
    let errors = this.getJoiErrors(
      {
        givenName: data.givenName,
        familyName: data.familyName,
        addrStreet: data.addrStreet,
        province: data.province,
        postalCd: data.postalCd,
        aboEntryDate: data.aboEntryDate,
        status: data.status,
        empNum: data.empNum,
        districtNm: data.districtNm,
        subDistrictNm: data.subDistrictNm,
        cid: data.cid
      },
      schema)

    if (this.testMobileNumber(data.phoneLocalNum)) { errors['phoneLocalNum'] = 'Number is not valid' }
    if (this.testempLength(data.empNum)) { errors['empNum'] = 'Length should be only 7' }
    if (this.isFutureDate(data.aboEntryDate)) { errors['aboEntryDate'] = 'Future Date is not allowed' }
    // let cidError = this.validateCidPidError(data.cid, '')
    // if (cidError['cid']) { errors['cid'] = cidError['cid'] }

    return errors;
  }


  getCoappErrors(data): ValidationError {
    let schema = Joi.object().keys({
      c_givenName: Joi.string().required().options({
        language: {
          any: {
            required: GIVEN_NAME_REQUIRED_ERROR,
            empty: GIVEN_NAME_REQUIRED_ERROR
          }
        }
      }),
      c_familyName: Joi.string().required().options({
        language: {
          any: {
            required: FAMILY_NAME_REQUIRED_ERROR,
            empty: FAMILY_NAME_REQUIRED_ERROR
          }
        }
      }),
      c_l_givenName: Joi.string().required().options({
        language: {
          any: {
            required: GIVEN_NAME_REQUIRED_ERROR,
            empty: GIVEN_NAME_REQUIRED_ERROR
          }
        }
      }),
      c_l_familyName: Joi.string().required().options({
        language: {
          any: {
            required: FAMILY_NAME_REQUIRED_ERROR,
            empty: GIVEN_NAME_REQUIRED_ERROR
          }
        }
      })
    }).unknown();

    return Object.assign({},
      this.getFirstnameErrors(data),
      this.getLastnameErrors(data),
      this.getJoiErrors(data, schema)
    );
  }

  isFutureDate(idate) {
    if (idate) {
      let date = new Date()
      let hdate = new Date(idate)
      date = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      return hdate > date
    }
  }

  testempLength(emp) {
    if (emp) { return emp.length != 7 }
  }

  getBirthDateError(data: any) {
    if (data.businessNature != 'Member') {
      if (new Date(data.birthdate) > new Date(moment().subtract(18, 'y').format())) {
        return { birthdate: 'Applicant must be 18 years old and above' }
      }
    }

    if (data.businessNature == 'Member') {
      if (new Date(data.birthdate) > new Date(moment().subtract(15, 'y').format())) {
        return { birthdate: 'Age should greater than 15' }
      }
    }
  }

  /**
   * returns all errors related to validation Address
   * @param {ABOAddress} data
   * @returns {ValidationError}
   */
  getAddressErrors(data: any): ValidationError {
    const schema = this.getAddressValidationSchema();
    let errors = this.getJoiErrors(data, schema);
    errors = Object.assign(errors);
    return errors;
  }

  /**
   * returns errors provided by Joi validation
   * @param {ABOBasicInfo | ABOAddress} data
   * @param schema
   * @returns {ValidationError}
   */
  private getJoiErrors(data: ABOBasicInfo | ABOAddress | any, schema): ValidationError {
    const result = {};
    Joi.validate(data, schema, { abortEarly: false }, (errors) => {
      if (errors) {
        errors.details.forEach((detail) => {
          result[detail.path] = result[detail.path] || detail.message.substring(detail.path.length + 3);
        });
      }
    });
    return result;
  }

  /**
   * returns error related to age
   * @param {string} dateString
   * @returns {ValidationError}
   */
  private getAgeErrors(data): ValidationError {
    if (data.businessNature != 'Member') {
      if (new Date(data.birthdate) > new Date(moment().subtract(18, 'y').format())) {
        return { birthdate: 'You should be older than 18 years old' }
      }
    }

    if (data.businessNature == 'Member') {
      if (new Date(data.birthdate) > new Date(moment().subtract(15, 'y').format())) {
        return { birthdate: 'You should be older than 15 years old' }
      }
    }
    // return this.calculateAge(data.birthdate) < 18 ? { birthdate: BIRTHDAY_INVALID_ERROR } : {};
  }

  /**
   * returns errors related to email
   * @private
   * @param {string} email
   * @returns {ValidationError}
   * @memberOf IboDetailsValidationService
   */
  getEmailErrors(email: string): ValidationError {
    return email && email.match(EMAIL_REGEX) ? {} : { email: EMAIL_INVALID_ERROR };
  }

  checkInvalidDomain(email: string) {
    if (email && email.toLowerCase().indexOf(AMWAY_DOMAIN, email.length - AMWAY_DOMAIN.length) !== -1) {
      return { email: EMAIL_INVALID_ERROR };
    }
  }

  getGenderCdError(data) {
    if (data && !data.genderCd) {
      return { genderCd: 'Please select gender' };
    }
  }

  private getFirstnameErrors(data: any) {
    if (data && data.givenName) {
      if (data.givenName > 100) {
        return { givenName: 'Max 100 characters allowed' };
      }
    }

    if (data && data.l_givenName) {
      if (data.l_givenName > 100) {
        return { l_givenName: 'Max 100 characters allowed' };
      }
    }
  }

  private getLastnameErrors(data: any) {
    if (data && data.familyName) {
      if (data.familyName > 100) {
        return { familyName: 'Max 100 characters allowed' };
      }
    }

    if (data && data.l_familyName) {
      if (data.l_familyName > 100) {
        return { l_familyName: 'Max 100 characters allowed' };
      }
    }
  }

  /**
   * returns errors related to tax Id
   * @param {ABOBasicInfo} data
   * @returns {ValidationError}
   */
  private getTaxErrors(data: ABOBasicInfo): ValidationError {
    const result = {};
    return result;
  }

  /**
   * returns errors related to zip code
   * @param {ABOAddress} data
   * @returns {ValidationError}
   */
  private getZipCodeErrors(data: ABOAddress): ValidationError {
    return ZIP_CODE_MANDATORY_CD.indexOf(data.cntryCd) !== -1 && !data.postalCd
      ? { postalCd: ZIP_CODE_REQUIRED_ERROR } : {};
  }

  /**
   * returns validation schema for using Joi
   * @returns {ValidationError}
   */
  private getBasicInfoValidationSchema(): ValidationError {
    return Joi.object().keys({
      givenName: Joi.string().required().options({
        language: {
          any: {
            empty: GIVEN_NAME_REQUIRED_ERROR
          }
        }
      }),
      familyName: Joi.string().required().options({
        language: {
          any: {
            empty: FAMILY_NAME_REQUIRED_ERROR
          }
        }
      })
    }).unknown();
  }

  /**
   * returns validation data for using Joi
   * @returns {ValidationError}
   */
  private getAddressValidationSchema(): ValidationError {
    return Joi.object().keys({
      addrStreet: Joi.string().required().options({
        language: {
          any: {
            empty: ADDRESS_LINE_ONE_REQUIRED_ERROR
          }
        }
      })
      // ,
      // stateCd: Joi.string().required().options({
      //   language: {
      //     any: {
      //       empty: STATE_REQUIRED_ERROR
      //     }
      //   }
      // }),
      // cityName: Joi.string().required().options({
      //   language: {
      //     any: {
      //       empty: CITY_NAME_REQUIRED_ERROR
      //     }
      //   }
      // })
    }).unknown();
  }

  /**
   * returns age
   * @param {string} birthday
   * @returns {number}
   */
  private calculateAge(birthday: string): number {
    const ageDifMs = Date.now() - new Date(birthday).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  testMobileNumber(phoneLocalNum) {
    if (!phoneLocalNum) { return true }

    if (phoneLocalNum[0] == '0') {
      phoneLocalNum = phoneLocalNum.substr(1);
      if (PHONE_REGEX.test(phoneLocalNum) || (phoneLocalNum.length != 9)) {
        return { phoneLocalNum: PHONE_INVALID_ERROR };
      }
    }

    if (PHONE_REGEX.test(phoneLocalNum) || (phoneLocalNum.length != 9)) {
      return { phoneLocalNum: PHONE_INVALID_ERROR };
    }
  }

  validateCidPidError(cid: any, pid: any): any {
    let errors = {}
    if (!cid) { errors['cid'] = "Cid can't be blank" }
    if (!pid) { errors['pid'] = "Pid can't be blank" }

    if (cid && (PHONE_REGEX.test(cid) || !(this.verifyCitizenIdChecksum(cid)))) {
      errors['cid'] = CID_INVALID_ERROR;
    }
    if (pid && (NUMB_AND_CHAR_REGX.test(pid) || (pid.length >= 20))) {
      errors['pid'] = PID_INVALID_ERROR;
    }
    return errors
  }

  verifyCitizenIdChecksum(str) {
    if (str == null || str.length != 13) {
      return false;
    }
    let len = str.length - 1;
    let total = 0;
    let mul = 13;
    for (let i = 0; i < len; i++) {
      total = total + (parseInt(str.charAt(i).toString()) * mul);
      mul--;
    }
    let mod = total % 11;
    let nsub = 11 - mod;
    let mod2 = nsub % 10;
    let last = parseInt(str.charAt(12).toString());
    return mod2 == last;
  }

  validateInheritanceData(data: any): any {
    let schema = Joi.object().keys({
      aboNum: Joi.required(),
      party: Joi.string().required(),
      reason: Joi.string().required()
    }).unknown();
    let errors = this.getJoiErrors({ aboNum: data.aboNum, party: data.party, reason: data.reason }, schema)
    return errors;
  }
}
