import { salesAff, aboNum } from '../../shared/configs/constants';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs';
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ABOSearchResponse, Schema, CellType, ABOSearchQuery, ABOSearchResult, AccountSearchParam } from '../../shared/models/index';
import { Params, Router, ActivatedRoute } from '@angular/router';
import { IAppState } from '../../store/index';
import { Store } from '@ngrx/store';
import { AboSearchClear, AboSearch } from '../../store/abo-search/abo-search.actions';
import { ABOService, OfflinePaginationService } from '../../shared/services/index';
import * as _ from 'lodash';
import { CountriesLoad } from '../../store/countries/countries.actions';
import { AboDetailsValidationService } from '../../abo/abo-details-validation.service';
import { FormGroup, FormControl } from '@angular/forms';

const SEARCH_RESULTS_SCHEMA = [
  {
    title: 'Customer #',
    property: 'abo',
    type: CellType.Link
  },
  {
    title: 'Business Nature',
    property: 'businessNature',
  },
  {
    title: 'Party ID',
    property: 'partyId',
  },
  {
    title: 'name',
    property: 'engagedName'
  },
  {
    title: 'email address',
    property: 'email'
  },
  {
    title: 'Party Role',
    property: 'roleCd'
  },
  {
    title: 'Status',
    property: 'status',
    type: CellType.Status
  }
];

@Component({
  selector: 'app-find-abo',
  templateUrl: './find-abo.component.html',
  styleUrls: ['./find-abo.component.scss']
})
export class FindABOComponent implements OnInit, OnDestroy {

  results$: Observable<any>;
  sub: Subscription;
  queryParams: Params;
  resultSchema: Schema[];
  searchQuery: ABOSearchQuery;
  placeholder: string;
  errorMessage: string = null;
  searchResults;
  pager: any = {};
  pagedItems: any[];
  searchKey: string = 'ibo';
  searchTypeForm: FormGroup;

  constructor(
    private store: Store<IAppState>,
    private router: Router,
    private route: ActivatedRoute,
    private aboService: ABOService,
    private aboValidationService: AboDetailsValidationService,
    private offlinePaginationService: OfflinePaginationService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit() {
    this.searchTypeForm = this.createSearchTypeForm();
    this.placeholder = 'Enter IBO/VIP Client/Employee ID, Name, Email ID, Mobile';
    this.resultSchema = SEARCH_RESULTS_SCHEMA;
    this.results$ = this.store.select('aboSearch');
    this.results$.subscribe(response => {
      if (response && response.loaded == false && !response.payload.status) {
        this.errorMessage = ''
        if (!this.cdr['destroyed']) {
         this.cdr.detectChanges();
        }
        this.errorMessage = response.payload.error
        return;
      }
      if (response && response.data && response.data.length > 0) {
        this.searchResults = response
        this.setPage(1);
      }
    })

    this.store.dispatch(new CountriesLoad());
    this.sub = this.route.queryParams
      .subscribe((data) => {
        if (!_.isEmpty(data)) {
          this.queryParams = data;

          if (this.queryParams.query.indexOf('@') > -1) {
            const error = this.aboValidationService.getEmailErrors(this.queryParams.query)
            if (!_.isEmpty(error)) {
              this.errorMessage = 'Kindly provide valid email id';
              return;
            }
          }
          this.store.dispatch(new AboSearch(this.queryParams));
        }
      });
  }

  createSearchTypeForm(){
    return new FormGroup({
      searchtype: new FormControl()
    })
  }

  search(query: ABOSearchQuery) {
    this.searchQuery = query;
    this.store.dispatch(new AboSearch(query));
  }

  // onPageChange(page) {
  //   this.search(Object.assign({}, this.searchQuery, page));
  // }

  goToDetails({ aff, abo, partyId, businessNature }: ABOSearchResult) {
    let userType = businessNature.toLowerCase();
    userType = '/'+ userType
    this.router.navigate([userType, aff, abo, partyId]);
  }

  ngOnDestroy() {
    this.cdr.detach();
    this.store.dispatch(new AboSearchClear());
  }

  onPageChange(page) {
    this.setPage(page.page);
  }

  setPage(page) {
    if (page < 1 || page > this.pager.totalPages) { return; }
    // get pager object from service
    this.pager = this.offlinePaginationService.getPager(this.searchResults.data.length, page);
    // get current page of items
    this.pagedItems = this.searchResults.data.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  getSelectedOption(option){
    this.searchKey = option.currentTarget.value;
  }

}
