import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ABOBasicInfo, Phone, Account, PartyList} from '../../../shared/models/amway/index';
import { IAppState } from '../../../store/index';
import { CommonService } from '../../../core/services/index';
import { AccountCard, Country } from '../../../shared/models';
import { ABOService } from '../../../shared/services/abo.service';
import * as _ from 'lodash';
import { taxAdressPayload, getTaxPayerParty } from '../../../shared/helpers/tax.helper';

@Component({
  selector: 'app-abo-basic-info',
  templateUrl: './abo-basic-info.component.html',
  styleUrls: ['./abo-basic-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AboBasicInfoComponent implements OnInit {
  countries$: Observable<Country[]>;
  preferredLanguage: string;
  cntryCd: string;
  segmentCd: string;
  czshpCntryName: string;
  czshpCntryCd: string;
  cntryName: string;
  
  @Input() info: ABOBasicInfo;
  @Input() accountFullDetail: Account;
  constructor(
    private store: Store<IAppState>,
    private commonService: CommonService,
    private aboService: ABOService,

  ) {
  }

  ngOnInit() {
    if (this.accountFullDetail && this.accountFullDetail.accountMst.cntryCd){
      this.cntryCd = this.accountFullDetail.accountMst.cntryCd;
      this.segmentCd = this.accountFullDetail.accountMst.segmentCd;
      this.preferredLanguage = CommonService.getLanguageName(this.cntryCd); 
      this.czshpCntryCd = this.info.czshpCntryCd || this.cntryCd;
    }

    this.countries$ = this.store
    .select('countries')
    .pipe(map(data => data.countryList));
    
    this.countries$.subscribe(countrieslist => {
      if (countrieslist && countrieslist.length > 0) {
        this.cntryName = this.aboService.getCountryName(
              countrieslist,
              this.cntryCd
        );
        this.czshpCntryName = this.aboService.getCountryName(
          countrieslist,
          this.czshpCntryCd
        );
      }
    });
   }

  getEngagedPhone(phone: Phone): string {
    const phoneCntryCd = phone.phoneCntryCd ? `+${phone.phoneCntryCd} ` : '';
    return !_.isEmpty(phone) ? `${phoneCntryCd || ''}-${phone.phoneLocalNum || ''}` : '';
  }

  getDisplayedTaxId(info: ABOBasicInfo): string {
    return this.commonService.getDisplayedTaxId(info);
  }
}
