/*
 * General data file to call Hybris server and contain all the data related functions
 */
import { Injectable, Inject } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { StorageService } from './storage.service';
import { ServerName } from '../../shared/models/enums';
import { environment } from '../../../environments/environment';

import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpErrorResponse
} from '@angular/common/http';
import { CoreConfig, MasheryToken, Token, ApiConfig } from '../../shared/models/index';
import { coreConfig } from '../core.config';
import { CommonService } from './common.service';
import { AuthService } from './auth.service';

@Injectable()
export class HttpService {
  // Initializing the mockJSON option to true/false, for mockData or API
  private options = {
    mockJSON: false
  };

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private commonService: CommonService,
    @Inject(coreConfig) private config: CoreConfig // private exception: ExceptionService,
  ) {}

  /**
   * Common error handler
   * @param error {any} : error response
   * @returns {object} : return error|throw exception
   */
  private handleError(error: HttpErrorResponse) {
    // checking for server unreachablity
    if (error.status === 0) {
      // this.exception.serverException();
    } else {
      switch (error.status) {
        case 400:
        // throw this.exception.businessException(error);
        case 401:
        // throw this.exception.businessException(error);
        case 500:
        // throw this.exception.businessException(error);
        default:
          break;
      }
      return throwError(error);
    }
  }

  /**
   * Method to hit the backend server with defined properties
   * @param {RequestMethod} method Defines whether it is a GET, POST, PUT or DELETE Request
   * @param {API} api Defines API related particular properties
   * @param {URLSearchParams} urlSearchParams Native angular provided class for defining the URL search parameter
   * @param {any} bodyParams Providing the body parameters, if any
   * @returns {Observable<any>} Returns an observable
   */
  callServer(
    method: string,
    api: ApiConfig,
    urlSearchParams?: HttpParams,
    bodyParams: object = null,
    resType: any = null,
    contentType: any = 'application/json',
    reqResponseType: any = true,
    reqContentType: any = true,
    headerParams: object = null,
  ): Observable<any> {
    if (!urlSearchParams) {
      urlSearchParams = new HttpParams();
    }
    return this.http
      .request(method, this.buildPath(api), {
        body: bodyParams,
        params: urlSearchParams,
        headers: this.generateCommonHeader(api, contentType, reqContentType, headerParams),
        responseType: resType || 'json'
      })
      .pipe(map(res => {
        if (res && (<any>res)._body === '') {
          return {};
        }

        return res;
      })
      ,catchError(this.handleError));
  }

  callOccServerApi(
    method: string,
    api: ApiConfig,
    urlSearchParams?: HttpParams,
    bodyParams: object = null,
    resType: any = null
  ): Observable<any> {
    if (!urlSearchParams) {
      urlSearchParams = new HttpParams();
    }
    return this.http
      .request(method, this.buildOccPath(api), {
        body: bodyParams,
        params: urlSearchParams,
        headers: this.generateOccApiHeaderForRequests(),
        responseType: resType || 'json'
      })
      .pipe(map(res => {
        if (res && (<any>res)._body === '') {
          return {};
        }

        return res;
      }),catchError(this.handleError));
  }

  getApiForWindow(api: ApiConfig, urlSearchParams?: HttpParams) {
    return this.buildPath(api) + '?' + urlSearchParams.toString();
  }

  generateCommonHeader(
    api: ApiConfig,
    contentType: any,
    reqContentType: boolean,
    headerParams: any
    ): HttpHeaders {
    let headers = new HttpHeaders();
    let currentTimeDate = new Date().toISOString();
    if (api.requireAuthHeader) {
      let token = '';
      if (api.server === ServerName.Hybris) {
        const occToken = <Token>this.storage.getItem(this.config.occTokenKey);
        if (occToken) {
          token = occToken.access_token;
        }
      } else {
        const masheryToken = <MasheryToken>(
          this.storage.getItem(this.config.masheryTokenKey)
        );
        if (masheryToken) {
          token = masheryToken.access_token;
        }
        headers = headers.append(
          'X-Request-Id',
          this.commonService.getRandomNumber(1, 99999)
        );
      }
      headers = headers.append('Authorization', 'Bearer ' + token);
    }

    if (api.reqAcceptType) {
      headers = headers.append(
        'Accept', 'application/json'
      );
    }
    if (api.reqCacheControlType) {
      headers = headers.append(
        'Cache-Control', 'no-cache'
      );
    }
    if (api.reqXAmzDate) {
      headers = headers.append(
        'x-amz-date', currentTimeDate
      );
    }
    if (api.reqApikey) {
      headers = headers.append(
        'x-api-key', environment.AmwayIDapikey
      );
    }
    if (api.reqApikey360) {
      headers = headers.append(
        'x-api-key', environment._360ApiKey
      );
    }
    if (api.requireAcceptLanguage) {
      headers = headers.append(
        'Accept-Language',
        'ISO en-us'
      );
    }

    if (headerParams) {
      if (headerParams.jwtAuthToken) {
        headers = headers.append(
          'authorization',
          'Bearer ' + headerParams.jwtAuthToken
        );
      }
      if(headerParams.username){
        headers = headers.append(
          'amwayid',
          headerParams.username
        );
      }
      if(headerParams.occurred){
        headers = headers.append(
          'eventdate',
          headerParams.occurred
        );
      }
      if(headerParams.partyId){
        headers = headers.append(
          'partyid',
          headerParams.partyId
        );
      }
      if(headerParams.eventType){
        headers = headers.append(
          'eventtype',
          headerParams.eventType
        );
      }
    }


    return headers;
  }

  generateOccApiHeader(){
    let headers = new HttpHeaders();
    const occToken: any = this.storage.getItem(this.config.occTokenKey)
    if (occToken.access_token) {
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append(
        'X-Request-Id',
        this.commonService.getRandomNumber(1, 99999)
      );
    }
    return headers;
  }

  generateOccApiHeaderForRequests(){
    let headers = new HttpHeaders();
    const occToken: any = this.storage.getItem(this.config.occTokenKey)
    if (occToken.access_token) {
      headers = headers.append('Content-Type', 'application/json');
    }
    return headers;
  }

  /**
   * Method for POST Request to the backend server
   * @param {API} api Declaring API Properties
   * @param {URLSearchParams} urlSearchParams Defining the URL Search Parameters, if any
   * @param {any} bodyParams Providing the body parameters
   * @returns {Observable<any>} Returns an observable
   */
  callServerPOST(
    api: ApiConfig,
    urlSearchParams?: HttpParams,
    bodyParams: any = null
  ): Observable<any> {
    return this.callServer('post', api, urlSearchParams, bodyParams);
  }

  /**
   * Method for GET Request to the backend server
   * @param {API} api Declaring API Properties
   * @param {URLSearchParams} urlSearchParams Defining the URL Search Parameters, if any
   * @param {any} bodyParams Providing the body parameters
   * @returns {Observable<any>} Returns an observable
   */
  callServerGET(
    api: ApiConfig,
    urlSearchParams?: HttpParams,
    bodyParams: any = null,
    responseType: string = null,
    contentType: any = 'application/json',
    reqResponseType: any = true,
    reqContentType: any = true,
    headerParams: Object = null
  ): Observable<any> {
    return this.callServer(
      'get',
      api,
      urlSearchParams,
      bodyParams,
      responseType,
      contentType,
      reqResponseType,
      reqContentType,
      headerParams
    );
  }

  /**
   * Method for PUT Request to the backend server
   * @param {API} api Declaring API Properties
   * @param {URLSearchParams} urlSearchParams Defining the URL Search Parameters, if any
   * @param {any} bodyParams Providing the body parameters
   * @returns {Observable<any>} Returns an observable
   */
  callServerPUT(
    api: ApiConfig,
    urlSearchParams?: HttpParams,
    bodyParams: any = null
  ): Observable<any> {
    return this.callServer('put', api, urlSearchParams, bodyParams);
  }

  /**
   * Method for Patch Request to the backend server
   * @param {API} api Declaring API Properties
   * @param {URLSearchParams} urlSearchParams Defining the URL Search Parameters, if any
   * @param {any} bodyParams Providing the body parameters
   * @returns {Observable<any>} Returns an observable
   */
  callServerPatch(
    api: ApiConfig,
    urlSearchParams?: HttpParams,
    bodyParams: any = null
  ): Observable<any> {
    return this.callServer('patch', api, urlSearchParams, bodyParams);
  }

  /**
   * Method for DELETE Request to the backend server
   * @param {API} api Declaring API Properties
   * @param {URLSearchParams} urlSearchParams Defining the URL Search Parameters, if any
   * @param {any} bodyParams Providing the body parameters
   * @returns {Observable<any>} Returns an observable
   */
  callServerDELETE(
    api: ApiConfig,
    urlSearchParams?: HttpParams,
    bodyParams: any = null
  ): Observable<any> {
    return this.callServer('delete', api, urlSearchParams, bodyParams);
  }

  /**
   * Method for POST Request for BLOB Type
   * @param {API} api Declaring API Properties
   * @param {object} requestObj Request object
   * @returns {Observable<any>} Returns an observable
   */
  callServerResponseTypeBLOB(api: ApiConfig, requestObj: object): Observable<any> {
    const headers = new HttpHeaders();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        responseType: 'Blob'
      })
    };
    return this.http.post(this.buildPath(api), requestObj, httpOptions);
  }

  /**
   * Method to create/build the api path URL
   * @param API {method} Declaring numerous API properties
   * @returns {string} Returns the new path build
   */
  buildPath(api: ApiConfig): string {
    // return environment.masheryBasePath + api.path;
    if (api.server === ServerName.Hybris) {
      return environment.occBasePath + '/api' + api.path;
    } else if (api.server === ServerName.Local) {
      return api.path;
    } else if (api.server === ServerName.server360) {
      return environment._360BasePath + api.path;
    } else if (api.server === ServerName.Okta) {
      return environment.oktaJwtTokenUrl + api.path;
    } else {
      return environment.masheryBasePath + api.path;
    }
  }
  /**
   * Method to create/build the api path URL
   * @param API {method} Declaring numerous API properties
   * @returns {string} Returns the new path build
   */
  buildOccPath(api: ApiConfig): string {
    return environment.occBasePath + '/api' + api.path;
  }
  callServerPOSTFile(
    api: ApiConfig,
    urlSearchParams?: HttpParams,
    bodyParams: any = null
  ): Observable<any> {
    if (!urlSearchParams) {
      urlSearchParams = new HttpParams();
    }
    const headers = new HttpHeaders({
      'X-Request-Id': this.commonService.getRandomNumber(1, 99999)
    });

    const httpOptions = {
      params: urlSearchParams,
      headers: headers
    };
    return this.http.post(this.buildPath(api), bodyParams, httpOptions);
  }

  callServerPOSTRefreshToken(
    api: ApiConfig,
    urlSearchParams?: HttpParams,
    bodyParams: any = null
  ): Observable<any> {
    if (!urlSearchParams) {
      urlSearchParams = new HttpParams();
    }
    const headers = new HttpHeaders({
      'X-Request-Id': this.commonService.getRandomNumber(1, 99999)
    });

    const httpOptions = {
      params: urlSearchParams,
      headers: headers
    };
    return this.http.post(this.buildPath(api), bodyParams, httpOptions);
  }
  /**
   * Method for GET Request to the backend server
   * @param {API} api Declaring API Properties
   * @param {URLSearchParams} urlSearchParams Defining the URL Search Parameters, if any
   * @param {any} bodyParams Providing the body parameters
   * @returns {Observable<any>} Returns an observable
   */
  callOccServerGET(
    api: ApiConfig,
    urlSearchParams?: HttpParams,
    bodyParams: any = null,
    responseType: string = null
  ): Observable<any> {
    return this.callOccServer(
      'get',
      api,
      urlSearchParams,
      bodyParams,
      responseType
    );
  }
  /**
   * Method to hit the backend server with defined properties
   * @param {RequestMethod} method Defines whether it is a GET, POST, PUT or DELETE Request
   * @param {API} api Defines API related particular properties
   * @param {URLSearchParams} urlSearchParams Native angular provided class for defining the URL search parameter
   * @param {any} bodyParams Providing the body parameters, if any
   * @returns {Observable<any>} Returns an observable
   */
  callOccServer(
    method: string,
    api: ApiConfig,
    urlSearchParams?: HttpParams,
    bodyParams: object = null,
    resType: any = null
  ): Observable<any> {
    if (!urlSearchParams) {
      urlSearchParams = new HttpParams();
    }
    return this.http
      .request(method, this.buildOccPath(api), {
        body: bodyParams,
        params: urlSearchParams,
        responseType: resType || 'json'
      })
      .pipe(map(res => {
        if (res && (<any>res)._body === '') {
          return {};
        }

        return res;
      })
      ,catchError(this.handleError));
  }

  getOccApiContext() {
    const tokenDetails = <Token>this.storage.getItem(this.config.occTokenKey);
    return tokenDetails.apiContextRoot;
  }

  /**
   * Method for GET Request to the backend server
   * @param {API} api Declaring API Properties
   * @param {URLSearchParams} urlSearchParams Defining the URL Search Parameters, if any
   * @param {any} bodyParams Providing the body parameters
   * @returns {Observable<any>} Returns an observable
   */
  callOccServerGETApi(
    api: ApiConfig,
    urlSearchParams?: HttpParams,
    bodyParams: any = null,
    responseType: string = null
  ): Observable<any> {
    return this.callOccServerApi(
      'get',
      api,
      urlSearchParams,
      bodyParams,
      responseType
    );
  }
}
