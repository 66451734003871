import { ActionReducer, MetaReducer, ActionReducerMap } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';
import { config } from '../../config';

import { historyReducer } from './history/history.reducer';
import { userReducer } from './user/user.reducer';
import { notificationReducer } from './notification/notification.reducer';
import {
  User,
  History,
  Country,
  CountryResponse,
  RolesState,
  AccountCard,
  AwardsResponse,
  Settings
} from '../shared/models/index';
import { countriesReducer } from './countries/countries.reducer';
import { ABOSearchResponse } from '../shared/models/index';
import { aboSearchReducer } from './abo-search/abo-search.reducer';
import { rolesReducer } from './roles/roles.reducer';
import { accountReducer } from './account/account.reducer';
import { awardsReducer } from './awards/awards.reducer';
import { settingsReducer } from './settings/settings.reducer';
import { SafeResourceUrl } from '@angular/platform-browser';
import { photoReducer } from './photo/photos.reducer';
import { aboPersonalDetailsReducer } from './abo-personal-details/abo-personal-details.reducer';
import { AboBusinessDetailsReducer } from './abo-business-details/abo-business-details.reducer';
import { AboRestrictionBlocksReducer } from './abo-restriction-blocks/abo-restriction-blocks.reducer';
import {
  BusinessDetails,
  RestrictionsBlocksStore,
  DeactivationState,
  AccountDetail,
  RegionResponse
} from '../shared/models/amway/index';
import { ConfigState } from '../shared/models/amway/index';
import { configReducer } from './config/config.reducer';
import { regionsReducer } from './regions/regions.reducer';
import { deactivationReducer } from './deactivation/deactivation.reducer';
import {
  ContractsResponse,
  aboContractsReducer
} from './abo-contracts/abo-contracts.reducer';
import { VouchersData } from '../shared/models/voucher.model';
import { AboVoucherDetailReducer } from './voucher/voucher.reducer';
import { TaxCertificateReducer } from './tax-certificate/tax-certificate.reducer';
import { ABOBusinessInfo } from '../shared/models/abo-business-details.model';
import { BankListData } from '../shared/models/bank-list.model';
import { AboBankListReducer } from './bank/bank.reducer';
import { taxPayerReducer } from './tax-payer/tax-payer.reducer';
import { accountPartiesReducer } from './account-parties/account-parties.reducer';
import { AddressesReducer } from './addresses/addresses.reducer';
import { SearchforceConnectIdState } from '../shared/models/searchforce/connect-id.model';
import { searchforceConnectIdSearchReducer } from './find-amway-id-events/find-amway-id-events.reducer';

// all new reducers should be define here
export interface IAppState {
  history: History;
  user: User;
  notification: { message: string };
  countries: CountryResponse;
  aboSearch: ABOSearchResponse;
  roles: RolesState;
  account: AccountDetail;
  awards: AwardsResponse;
  settings: Settings;
  photo: { url: SafeResourceUrl };
  config: ConfigState;
  regions: RegionResponse;
  aboPersonalDetails: any;
  iboBusinessDetails?: any;
  deactivation: DeactivationState;
  aboBusinessDetails: BusinessDetails;
  aboRestrictionBlocks: RestrictionsBlocksStore;
  aboContracts: ContractsResponse;
  bankList: BankListData;
  aboVouchersDetails: VouchersData;
  taxForm: { url: SafeResourceUrl };
  photoChange?: {loader: boolean};
  taxPayer: any;
  accountParties: any;
  addresses: any;
  searchforceConnectIdSearch: SearchforceConnectIdState;
}

// all new reducers should be define here
export const reducers: ActionReducerMap<IAppState> = {
  history: historyReducer,
  user: userReducer,
  notification: notificationReducer,
  countries: countriesReducer,
  aboSearch: aboSearchReducer,
  roles: rolesReducer,
  account: accountReducer,
  awards: awardsReducer,
  settings: settingsReducer,
  photo: photoReducer,
  config: configReducer,
  regions: regionsReducer,
  deactivation: deactivationReducer,
  aboPersonalDetails: aboPersonalDetailsReducer,
  aboBusinessDetails: AboBusinessDetailsReducer,
  aboRestrictionBlocks: AboRestrictionBlocksReducer,
  aboContracts: aboContractsReducer,
  aboVouchersDetails: AboVoucherDetailReducer,
  taxForm: TaxCertificateReducer,
  bankList: AboBankListReducer,
  photoChange: photoReducer,
  taxPayer: taxPayerReducer,
  accountParties: accountPartiesReducer,
  addresses: AddressesReducer,
  searchforceConnectIdSearch: searchforceConnectIdSearchReducer
};

// console.log all actions
export function logger(
  reducer: ActionReducer<IAppState>
): ActionReducer<any, any> {
  return function(state: IAppState, action: any): IAppState {
    // console.log('state', state);
    // console.log('action', action);

    return reducer(state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<any>[] = !config.production
  ? [logger, storeFreeze]
  : [];