import { Actions, CONTRACT_GET, CONTRACT_GET_SUCCESS } from './abo-contracts.actions';
import { ContractModel } from '../../shared/models/amway/index';

export class ContractsResponse {
  loaded?: boolean;
  list?: ContractModel[];
}

export function aboContractsReducer(state: ContractsResponse, { type, payload }: Actions): ContractsResponse {
  switch (type) {
    case CONTRACT_GET:
      return { loaded: false };

    case CONTRACT_GET_SUCCESS:
      return { loaded: true, list: payload };

    default:
      return state;
  }
}
