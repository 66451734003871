import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpParams,
  HttpErrorResponse,
  HttpHeaderResponse,
  HttpSentEvent,
  HttpProgressEvent
} from '@angular/common/http';

import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { take, switchMap, filter, map, finalize, catchError } from 'rxjs/operators';
import { AuthService, LoggerService } from '../services';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  isRefreshingToken = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  authService: AuthService;
  occEnable: boolean = environment.occEnable;

  constructor(private injector: Injector, private router: Router, private splunckLogService: LoggerService) {}

  addHeaders(req: HttpRequest<any>): HttpRequest<any> {

    return req.clone({
      responseType: req.responseType === 'json' ? 'text' : req.responseType
    });

    // if (req.params && req.url.includes(environment.occBasePath)) {
    //   const newParams = new HttpParams({ fromString: req.params.toString() });
    //   const token = this.authService.getOccAccessToken();
    //   return req.clone({
    //     setHeaders: { Authorization: 'Bearer ' + token },
    //     responseType: req.responseType === 'json' ? 'text' : req.responseType,
    //     params: newParams
    //   });
    // } else if (req.params && req.params.has('access_token')) {
    //   const newParams = new HttpParams({ fromString: req.params.toString() });
    //   return req.clone({
    //     responseType: req.responseType === 'json' ? 'text' : req.responseType,
    //     params: newParams
    //   });
    // } else if (
    //   req.method === 'POST' &&
    //   req.body &&
    //   req.body.updates &&
    //   req.body.updates.find(
    //     data => data.param === 'grant_type' && data.value === 'refresh_token'
    //   )
    // ) {
    //   const newParams = new HttpParams({ fromString: req.params.toString() });
    //   return req.clone({
    //     responseType: req.responseType === 'json' ? 'text' : req.responseType,
    //     params: newParams
    //   });
    // } else {
    //   const newParams = new HttpParams({ fromString: req.params.toString() });
    //   const token = this.authService.getMasheryAccessToken();
    //   return req.clone({
    //     setHeaders: { Authorization: 'Bearer ' + token },
    //     responseType: req.responseType === 'json' ? 'text' : req.responseType,
    //     params: newParams
    //   });
    // }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpEvent<any>
  > {
    this.authService = this.injector.get(AuthService);
    return next
      .handle(this.addHeaders(req))
      .pipe(map(response => {
        if (response instanceof HttpResponse) {
          try {
            response = response.clone<any>({ body: JSON.parse(response.body) });
          } catch (error) {
            return response;
          }
        }
        return response;
      })
        ,catchError(error => {
          if (error instanceof HttpErrorResponse) {
            // HIDING SPLUNK LOGS THROUGH API
            if(this.occEnable){
              this.splunckLogService.LogHttpError(error);
            }
            if ((error.url).indexOf('amwayid/events') === -1 ) {
              switch ((<HttpErrorResponse>error).status) {
                case 401:
                  return this.handle401Error(req, next);
                default:
                  return throwError(error);
              }
            }
            else {
              return throwError(error);
            }
          } else {
            return throwError(error);
          }
        }));
  }

  handle401Error(req: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      this.tokenSubject.next(null);

      return this.authService
        .retrieveMasheryAccessTokenViaRefreshToken()
        .pipe(switchMap((newToken: string) => {
          if (newToken) {
            this.tokenSubject.next(newToken);
            return next.handle(this.addHeaders(req));
          }
        })
          ,catchError(error => {
            return this.logoutUser();
          })
          ,finalize(() => {
            this.isRefreshingToken = false;
          }));
    } else {
      return this.tokenSubject.pipe(
        filter(token => token != null)
        ,take(1)
        ,switchMap(token => {
          return next.handle(this.addHeaders(req));
        }));
    }
  }

  logoutUser() {
    this.authService.removeUserData();
    this.router.navigateByUrl('/login');
    return throwError('');
  }
}
