import { Action } from '@ngrx/store';

export const ABO_BANK_LIST_LOAD = '[Abo bank list] load';
export const ABO_BANK_LIST_LOAD_SUCCESS = '[Abo bank list] load success';
export const ABO_BANK_LIST_LOAD_FAIL = '[Abo bank list] load fail';

export class AboBankListLoad implements Action {
  readonly type = ABO_BANK_LIST_LOAD;
  constructor(public payload: any = null) {}
}

export class AboBankListLoadSuccess implements Action {
  readonly type = ABO_BANK_LIST_LOAD_SUCCESS;
  constructor(public payload: any) {}
}

export class AboBankListLoadFail implements Action {
  readonly type = ABO_BANK_LIST_LOAD_FAIL;
  constructor(public payload: any = null) {}
}

export type Actions =
  | AboBankListLoad
  | AboBankListLoadSuccess
  | AboBankListLoadFail;
