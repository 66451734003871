import { Injectable } from '@angular/core';
import { HttpService } from '../../core/services/index';
import { ApiPathConfigService } from '../configs/api';
import { AccountSearchParam } from '../models/index';
import { salesAff, aboNum, getABOContractsAPI, getContractInfoAPI, defaultCountryCode, defaultLanguageCode } from './../configs/constants';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContractModel } from '../models/amway/index';
import { HttpParams } from '@angular/common/http';

const ACCOUNT_SUBTYPE = 'BusinessOwner';

@Injectable()
export class ContractsService {

  constructor(
    private httpService: HttpService,
    private apiPathService: ApiPathConfigService
  ) {
  }

  getContractsListforABO(params: AccountSearchParam) {
    const contractsListObservable = new Observable((observer) => {
      const api = this.apiPathService.getApiPath(getABOContractsAPI);
      api.path = api.path.replace(salesAff, params.aff).replace(aboNum, params.abo);
      this.httpService.callServerGET(api, null)
        .subscribe((response: { contractList: ContractModel[] }) => {
          const aboContracts: ContractModel[] = [];
          if (response && response.contractList) {
            response.contractList.forEach((contract) => {
              aboContracts.push(<ContractModel>contract);
            });
          }
          observer.next(aboContracts);
          observer.complete();
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
    });
    return contractsListObservable;
  }

  getContractInfo(contract: ContractModel, params) {
    const api = this.apiPathService.getApiPath(getContractInfoAPI);
    api.path = api.path.replace(salesAff, params.aff);
    const reqParams: HttpParams = this.getContractParams(contract, params);
    return this.httpService.callServerGET(api, reqParams)
      .pipe(map((response: { contractDetail: { verbiage: '' } }) => {
        let content = '';
        if (response && response.contractDetail) {
          content = response.contractDetail.verbiage;
        }
        return content;
      }));
  }

  getContractDetailList(params: AccountSearchParam) {
    const listObservable = new Observable((observer) => {
      return this.getContractsListforABO(params)
        .subscribe((response: ContractModel[]) => {
          const apiCalls = [];
          const contractList: ContractModel[] = response;
          if (contractList.length > 0) {
            contractList.forEach((contract) => {
              apiCalls.push(this.getContractInfo(contract, params));
            });

            forkJoin(...apiCalls)
              .subscribe((contentList: string[]) => {
                contentList.forEach((content, index) => {
                  const contract = contractList[index];
                  contract.verbiage = content;
                  contractList[index] = contract;
                });
                observer.next(contractList);
              });
          } else {
            observer.next([]);
          }
        }, (error) => {
          observer.error(error);
          observer.complete();
        });
    });
    return listObservable;
  }

  getContractParams(contract: ContractModel, params): HttpParams {
    let urlParams: HttpParams = new HttpParams();
    urlParams = urlParams.append('cntryCd', defaultCountryCode);
    urlParams = urlParams.append('languageCd', defaultLanguageCode);
    urlParams = urlParams.append('contractType', contract.contractType);
    urlParams = urlParams.append('accountSubTypeCd', params.businessNature);
    urlParams = urlParams.append('contractId', contract.contractId);
    urlParams = urlParams.append('seqNo', contract.seqNo + '');
    urlParams = urlParams.append('sourceChannelId', contract.sourceChannelId);
    return urlParams;
  }

}
